import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetRiskPostureQuery } from '../graphql/queries';

const useRiskPosture = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetRiskPostureQuery, options);

    const result = {
        riskPostures: data?.listRiskPosture,
        loading,
    };

    return result;
};

export { useRiskPosture };
