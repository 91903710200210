import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserEntity } from '@modules/users/entities';
import { GetComponentOwnerBySurveyIdQuery } from '@modules/users/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetComponentOwnerBySurveyIdQueryType, GetComponentOwnerBySurveyIdQueryVariables } from '@modules/types/graphql';

const useComponentUser = (options?: QueryHookOptions<GetComponentOwnerBySurveyIdQueryType, GetComponentOwnerBySurveyIdQueryVariables>) => {
    const queryResult = useQuery<GetComponentOwnerBySurveyIdQueryType, GetComponentOwnerBySurveyIdQueryVariables>(GetComponentOwnerBySurveyIdQuery, {
        fetchPolicy: 'network-only',
        ...options,
    });

    const user = useEntity(() => new UserEntity(queryResult.data?.getComponentOwnerBySurveyId ?? null), [
        queryResult.data,
    ]);

    const result = {
        user,
        ...queryResult,
    };

    return result;
};

export { useComponentUser };
