import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { MenuItem } from '@material-ui/core';

import { TextField, Button } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { useCapabilityImpacts } from '@modules/custom-components/hooks';

import type {
    CapabilityCreateFormValues,
    CapabilityUpdateFormValues,
} from '@modules/custom-components/requests';
import { Loader } from '@modules/layout/moleculas';

type CapabilityFormProps<T> = FormProps<T> & {
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    min-width: 470px;
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CapabilityForm = <T extends FormSemanticType = 'create'>(
    props: CapabilityFormProps<
        // prettier-ignore
        T extends 'create' ? CapabilityCreateFormValues :
        T extends 'edit' ? CapabilityUpdateFormValues :
        CapabilityCreateFormValues & CapabilityUpdateFormValues
    >,
): React.ReactElement => {
    const { type, initialValues, FormWrapComponent, onSubmit, onCancel } = props;

    const isEdit = type === 'edit';

    const { capabilityImpacts, loading: capabilityImpactsLoading } = useCapabilityImpacts();

    if(capabilityImpactsLoading) {
        return <Loader />;
    }
    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                title: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;
                
                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='title'
                                label='Title'
                                value={values.title}
                                error={!!errors.title}
                                helperText={errors.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                select
                                id='impact'
                                name='impact'
                                label='Impact'
                                value={values.impact}
                                error={!!errors.impact}
                                helperText={errors.impact}
                                disabled={capabilityImpactsLoading}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <MenuItem disabled>Pick the impact</MenuItem>

                                {capabilityImpacts.map(capabilityImpact => (
                                    <MenuItem
                                        key={capabilityImpact.definition}
                                        value={capabilityImpact.id}
                                    >
                                        {capabilityImpact.id}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                fullWidth
                                multiline
                                id='keyFactors'
                                label='Key Factors'
                                rows='3'
                                value={values.keyFactors}
                                error={!!errors.keyFactors}
                                helperText={errors.keyFactors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                multiline
                                id='securityControls'
                                label='Security Controls'
                                rows='3'
                                value={values.securityControls}
                                error={!!errors.securityControls}
                                helperText={errors.securityControls}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {isEdit ? 'Update Capability' : 'Create Capability'}
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { CapabilityForm };
