import React from 'react';
import styled from 'styled-components';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { white } from '@modules/ui/colors';

import type { AccordionProps } from '@material-ui/core';

type AccordtionListItemProps = Omit<AccordionProps, 'children'> & {
    title: string | any;
    subtitle?: string | any;
    className?: string;
    children?: React.ReactNode;
    SummaryInfoComponents?: React.ReactNode;
    status?: boolean;
};

const useAccordionStyles = makeStyles({
    root: {
        width: '100%',
        padding: 16,
        backgroundColor: white[100],

        '&$rounded': {
            borderRadius: 0,
        },
    },
    rounded: {},
});

const useAccordionSummaryStyles = makeStyles({
    root: {
        flexWrap: 'wrap',
        minHeight: 0,
        padding: 0,
    },
    content: {
        flexWrap: 'wrap',
        alignItems: 'center',
        maxWidth: 'calc(100% - 50px)',
        margin: '0 auto 0 0',
    },
});

const useAccordionDetailsStyles = makeStyles({
    root: {
        flexWrap: 'wrap',
        padding: 0,
    },
});

const Description = styled.div`
    flex-basis: 60%;
    max-width: 1150px;
    padding-right: 16px;
`;
const RecommendationDesc = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Title = styled.div`
    font-size: 1.8rem;
    font-weight: 600;
`;
const Title2 = styled.div`
    font-weight: 600;
    font-size: 1.8rem;
    padding-right: 15px;
`;

const Subtitle = styled.div`
    margin-top: 12px;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 400;
`;
const Subtitle2 = styled.div`
    display: inline-block;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 12px;
    font-weight: 400;
`;

const AccordionInnerListItem = (props: AccordtionListItemProps): React.ReactElement => {
    const { title, subtitle, children, status, SummaryInfoComponents, ...otherProps } = props;

    const accordionClasses = useAccordionStyles();
    const accordionSummaryClasses = useAccordionSummaryStyles();
    const accordionDetailsClasses = useAccordionDetailsStyles();

    return (
        <Accordion classes={accordionClasses} {...otherProps}>
            <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMore />}>
                {status ? (
                    <RecommendationDesc>
                        <Title2>{title}</Title2>

                        {subtitle ? <Subtitle2>{subtitle}</Subtitle2> : null}
                    </RecommendationDesc>
                ) : (
                    <Description>
                        <Title>{title}</Title>

                        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
                    </Description>
                )}

                {SummaryInfoComponents ?? null}
            </AccordionSummary>

            <AccordionDetails classes={accordionDetailsClasses}>{children}</AccordionDetails>
        </Accordion>
    );
};

export { AccordionInnerListItem };
