import { gql } from '@apollo/client';

// @connection(key: "listAssignedProjects") can be used to set backend resolver explicitly
export const GetAssignedProjectsQuery = gql`
    query GetAssignedProjects(
        $filter: String
        $status: String
        $maturityLevelId: [Int]
        $assessmentTypes: [Int]
    ) {
        listAssignedProjects(
            filter: $filter
            filterStatus: $status
            maturityLevelId: $maturityLevelId
            assessmentTypes: $assessmentTypes
        ) {
            id
            name
            description
            status
            rr
            is_rr_impact
            assessment_type_id
            created_at
            is_assigned
            maturity_level {
                id
                name
            }
        }
    }
`;
