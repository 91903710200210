import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { ComponentEntityList } from '@modules/components/entities';
import { GetComponentsOnlyGPTQuery } from '@modules/components/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetComponentsOnlyGPTQueryType, GetComponentsOnlyGPTQueryVariables } from '@modules/types/graphql';

const useComponentsOnlyGPT = (
    options?: QueryHookOptions<GetComponentsOnlyGPTQueryType, GetComponentsOnlyGPTQueryVariables>,
) => {
    const queryResult = useQuery<GetComponentsOnlyGPTQueryType, GetComponentsOnlyGPTQueryVariables>(
        GetComponentsOnlyGPTQuery,
        options,
    );

    const { entities: gptComponents, count } = useEntity(
        () => new ComponentEntityList(queryResult.data?.listComponentsOnlyGPT ?? null),
        [queryResult.data],
    );

    const result = {
        gptComponents,
        count,
        ...queryResult,
    };

    return result;
};

export { useComponentsOnlyGPT };
