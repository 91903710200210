import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { gray } from '@modules/ui/colors';
import { black, white } from '@modules/ui/colors';
import { ListStyled } from '@modules/layout/styled';
import { Collapse, TableCell, TableRow, Typography } from '@material-ui/core';
import { CapabilityMaturityLevel } from '@modules/capabilities/moleculas';
import { GapByRisk, GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { CapabilityAssessmentLog } from '@modules/types/graphql';
import { format } from 'date-fns';
import { ReportCapabilityLog } from '@modules/assessment/organisms/report-components-list/report-capability-log';
import {
    getMaturityLevelName,
    capabilityLevelProgress as levelProgress,
} from '@modules/shared/constants/risk-level';
import {
    CapabilityMaturityLevelEnum,
    capabilityLevelColors,
} from '@modules/capabilities/constants';
import { ProjectEntity } from '@modules/projects/entities';

type ReportComponentCapabilityTableItemProps = {
    gapCapability: any;
    recommendationIdCount: any;
    project?: ProjectEntity;
};

const StyledCapabilityMaturityLevel = styled(CapabilityMaturityLevel)`
    width: max-content;
`;

const Root = styled.div`
    display: flex;
    flex-flow: column nowrap;

    border: 1px solid #f2f2f2;
    border-radius: 4px;
`;

const progressWidth = 185;
const minWidth = 25;
const Progress = styled.div<{ color?: string; width?: number }>`
    ${p => css`
        color: ${((p.width ?? 0) / 100) * 185 < minWidth ? black[100] : white[100]};
    `}
    font-size: 1.4rem;
    border-radius: 4px 0 0 4px;
    min-width: ${progressWidth}px;

    .progress {
        ${p => css`
            padding: 8px 0;
            // min-width: ${minWidth}px;

            white-space: pre;
            text-align: center;
            font-weight: 600;

            width: ${p.width}%;
            background-color: ${p.color};
        `}
    }
`;

const Label = styled.div`
    padding: 8px 16px;
    text-transform: capitalize;
    text-align: center;
`;

export const ReportFactorCapabilityTableItem = (
    props: ReportComponentCapabilityTableItemProps,
): React.ReactElement => {
    const { gapCapability, recommendationIdCount, project } = props;
    const [openHistory, setOpenHistory] = React.useState(false);
    // const history = gapCapability?.log
    let lastItem: CapabilityAssessmentLog = gapCapability.capabilityAssessment?.log[0];
    let history: CapabilityAssessmentLog[] =
        gapCapability.capabilityAssessment?.log ?? ([] as CapabilityAssessmentLog[]);

    let completionLevel: number = Number(
        levelProgress[gapCapability.capabilityAssessment.completionLevel.getMaturityLevel()]
            ?.replace('<', '')
            ?.replace('%', '')
            ?.trim() ?? 0,
    );
    const recommendationIdLevel = gapCapability.capabilityAssessment.id;
    const recommendationStatusLength = recommendationIdCount[recommendationIdLevel]?.count;
    const recommendationStatusDone = recommendationIdCount[recommendationIdLevel]?.done;

    const restCompletionLevel = 100 - completionLevel;
    const recommendationCompletationLevel = restCompletionLevel / recommendationStatusLength;
    const totalRecommendationComletationLevel =
        recommendationCompletationLevel * recommendationStatusDone + completionLevel;

    const maturityLevelName: any = getMaturityLevelName(
        totalRecommendationComletationLevel ? totalRecommendationComletationLevel : completionLevel,
    ) as CapabilityMaturityLevelEnum;

    function onHistoryOpen() {
        setOpenHistory(!openHistory);
    }
    const color = capabilityLevelColors[maturityLevelName];
    const width = Number(
        levelProgress[maturityLevelName]?.replace('<', '')?.replace('%', '')?.trim() ?? 0,
    );

    return (
        <>
            <TableRow key={gapCapability?.capabilityAssessment?.id}>
                <TableCell>
                    {/* {gapCapability.canShowCapability && ( */}
                    <>
                        <p className={'text-offset'}>
                            {gapCapability.capabilityAssessment.capability.title}
                        </p>
                        {/* <p>
                            <b>Security Controls:</b>
                        </p>
                        <p>
                            {
                                gapCapability.capabilityAssessment.capability
                                    .securityControls
                            }
                        </p> */}
                    </>
                    {/* )} */}
                </TableCell>

                <TableCell>
                    {/* {gapCapability.canShowCapability && ( */}
                    <Root>
                        <Progress color={color} width={width}>
                            <div className={'progress'}>
                                {totalRecommendationComletationLevel
                                    ? totalRecommendationComletationLevel.toFixed()
                                    : completionLevel}
                            </div>
                        </Progress>
                        <Label>{gapCapability?.name}</Label>
                    </Root>
                    {/* )} */}
                </TableCell>

                <TableCell>
                    {lastItem && (
                        <>
                            <p className={'text-offset'}>
                                {lastItem.action} at{' '}
                                {format(new Date(lastItem.action_timestamp), 'dd.MM.yyyy hh:mm')} by{' '}
                                {lastItem.user?.first_name} {lastItem.user?.last_name}
                            </p>
                            <p className={'collapsible-trigger'} onClick={onHistoryOpen}>
                                <strong>{openHistory ? 'Hide' : 'Show'} History</strong>
                            </p>
                        </>
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openHistory} timeout='auto' unmountOnExit>
                        <ReportCapabilityLog
                            projectStatus={project?.isrrimpact as boolean}
                            history={history}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
