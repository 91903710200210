import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Root = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    background:rgba(255, 255, 255, 0.46);
    z-index: 9999;
    pointer-events: auto;
    padding-top: 40px;
    
    & > div {
        display: inline-block;
        width: 40px;
        height: 40px;
        color: inherit;
        vertical-align: middle;
        pointer-events: none;
        border: 4px solid transparent;
        border-top-color: currentcolor;
        border-radius: 50%;
        animation: ${rotate} 1s linear infinite;
        position: relative;
        color: blue;
    }

    & > div:before {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        position: absolute;
        top: -4px;
        left: -4px;
        border: 4px solid #0000ff3b;
        border-radius: 50%;
    }

    & > span {
        color: #000000;
        display: block;
        padding-bottom: 40px;
        font-size: 13px;
        text-align: center; 
    }

    // & > .title {
    //     color: #333333; 
    //     font-size: 14px; 
    //     font-weight: bold; 
    // }
`;

const GPTLoader = () => (
    <Root>
        {/* <span className="title">Impact Model is Generating In Progress...</span> */}
        <div></div>
        <span>Almost ready!</span>
    </Root>
);

export { GPTLoader };
