import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { white, gray } from '@modules/ui/colors';
import { ListItemStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import { useProjectRoles } from '@modules/projects/hooks';
import { useRoles } from '@modules/assessment/hooks';
import {
    AssessmentComponentFormModal,
    AssessmentCapabilitiesList,
    AssignAssessmentToOperatorFormModal,
    AssignAssessmentCapabilityToComponentOwnerFormModal,
} from '@modules/assessment/organisms';

import type { AssessmentComponentsListItemProps } from './assessment-components-list-item';
import { AuthContext } from '@modules/auth/context';
import { DialogProjectComponentDelete } from '../dialog-project-component-delete';
import { useMutation } from '@apollo/client';
import { DeleteProjectComponentMutation } from '@modules/assessment/graphql';
import {
    DeleteProjectComponentMutationType,
    DeleteProjectComponentMutationVariables,
} from '@modules/types/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { GetProjectQuery } from '@modules/projects/graphql';
import { Loader } from '@modules/layout/moleculas';
import { useCompanyAccess } from '@modules/company/hooks';
import { GetComponentsQuery } from '@modules/components/graphql';

const StyledButton = withStyles({
    root: {
        color: gray[10],
        backgroundColor: white[100],
        width: 60,
        margin: 5,
    },
    label: {
        padding: 0,
    },
})(Button);

const SummaryActions = styled(ListItemStyled.SummaryInfo)`
    min-width: 135px;
    text-align: right;
`;

const StyledAssessmentCapabilitiesList = styled(AssessmentCapabilitiesList)`
    margin-top: 24px;
`;

const AssessmentComponentsListNotAssessedItem = (
    props: AssessmentComponentsListItemProps,
): React.ReactElement => {
    const { project, componentAssessment, open, onExpand } = props;
    const context = useContext(AuthContext);
    const { user, loading: userLoading } = useContext(AuthContext) || {};
    const adminRoles = useMemo(() => ['risk_admin', 'project_admin', 'company_admin'], []);

    const { companyAccess, loading: isCompanyLoading } = useCompanyAccess();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { projectRolesEntityList } = useProjectRoles({ variables: { projectId: project.id } });
    const { rolesEntityList } = useRoles({ variables: { assessmentId: componentAssessment.id } });
    const [
        deleteComponentFromProgram,
        { loading: deleteComponentFromProgramLoading },
    ] = useMutation<DeleteProjectComponentMutationType, DeleteProjectComponentMutationVariables>(
        DeleteProjectComponentMutation,
    );

    const [openAssignOperatorModal, setOpenAssignOperatorModal] = React.useState<boolean>(false);

    const handleOpenAssignOperatorModal = (): void => setOpenAssignOperatorModal(true);
    const handleCloseAssignOperatorModal = (): void => setOpenAssignOperatorModal(false);

    const [choosenComponentAssessmentId, setChoosenComponentAssessmentId] = React.useState<
        number | undefined
    >(undefined);

    const [deleteComponentAssessmentId, setDeleteComponentAssessmentId] = React.useState<
        number | undefined
    >(undefined);
    const [deleteComponentAssessmentTitle, setDeleteComponentAssessmentTitle] = React.useState<
        string | undefined
    >(undefined);

    const [openComponentFormModal, setOpenComponentFormModal] = React.useState<boolean>(false);
    const [openDeleteComponentModal, setOpenDeleteComponentModal] = React.useState<boolean>(false);
    const [openAssignForCOReviewModal, setOpenAssignForCOReviewModal] = React.useState<boolean>(
        false,
    );

    const handleOpenComponentFormModal = (): void => setOpenComponentFormModal(true);
    const handleOpenDeleteComponentModal = (): void => setOpenDeleteComponentModal(true);

    const handleOpenAssignForCOReviewModal = (): void => setOpenAssignForCOReviewModal(true);
    const handleCloseAssignForCOReviewModal = (): void => setOpenAssignForCOReviewModal(false);

    const handleOpenEditComponent = (componentAssessmentId: number) => (
        event: React.MouseEvent<{}>,
    ) => {
        event.stopPropagation();

        setChoosenComponentAssessmentId(componentAssessmentId);
        handleOpenComponentFormModal();
    };

    const handleCloseComponentFormModal = (): void => {
        setChoosenComponentAssessmentId(undefined);
        setOpenComponentFormModal(false);
    };

    const handleOpenDeleteComponent = (
        componentAssessmentId: number,
        componentAssessmentName: string,
    ) => (event: React.MouseEvent<{}>) => {
        event.stopPropagation();

        setDeleteComponentAssessmentId(componentAssessmentId);
        setDeleteComponentAssessmentTitle(componentAssessmentName);
        handleOpenDeleteComponentModal();
    };

    const handleCloseDeleteComponentModal = (): void => {
        setDeleteComponentAssessmentId(undefined);
        setDeleteComponentAssessmentTitle(undefined);
        setOpenDeleteComponentModal(false);
    };

    const handleDeleteComponentAssessment = async (
        componentAssessmentId: number | any,
    ): Promise<void> => {
        try {
            const { data: deleteProjectData } = await deleteComponentFromProgram({
                variables: { assessmentId: componentAssessmentId },
                refetchQueries: [
                    {
                        query: GetProjectQuery,
                        variables: { id: project.id },
                    },
                    {
                        query: GetComponentsQuery,
                    },
                ],
                awaitRefetchQueries: true,
            });
            if (deleteProjectData) {
                enqueueSuccess('Assessment component successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the Assessment Component!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const isAdminRole = useMemo(() => {
        if (user && user.roles && user.roles.length > 0) {
            return adminRoles.includes(user.roles[0]);
        }
        return false;
    }, [user, adminRoles]);

    const isDeleteEditAccess = useMemo(() => {
        return projectRolesEntityList && (
            isAdminRole ||
            projectRolesEntityList.hasProgramManager()
        );
    }, [isAdminRole, projectRolesEntityList]);


    const isAssignOPUser = useMemo(() => {
        return rolesEntityList && (
            isAdminRole ||
            rolesEntityList.hasComponentOwner()
        );
    }, [isAdminRole, rolesEntityList]);

    const hasOperator = componentAssessment.hasOperator();
    const projectInProgress = project.isAssessment();

    if (isCompanyLoading || userLoading) {
        return <Loader />;
    }
    return (
        <>
            <AccordionListItem
                title={componentAssessment.component.name}
                subtitle={componentAssessment.objectives}
                componentOwners={componentAssessment.componentOwnersToString()}
                expanded={open}
                onChange={onExpand(componentAssessment.id)}
                SummaryInfoComponents={
                    <>
                        <ListItemStyled.SummaryInfo>
                            Weight: {componentAssessment.weight}
                        </ListItemStyled.SummaryInfo>

                        <ListItemStyled.SummaryInfo>
                            Capabilities: {componentAssessment.getAccessesCapabilities()}
                        </ListItemStyled.SummaryInfo>

                        {isDeleteEditAccess ? (
                            <SummaryActions>
                                {/* {!projectInProgress ? ( */}
                                <>
                                    <StyledButton
                                        size='small'
                                        variant='outlined'
                                        onClick={handleOpenDeleteComponent(
                                            componentAssessment.id,
                                            componentAssessment.component.name,
                                        )}
                                    >
                                        <DialogProjectComponentDelete
                                            componentAssessmentTitle={
                                                deleteComponentAssessmentTitle
                                            }
                                            open={openDeleteComponentModal}
                                            onClose={handleCloseDeleteComponentModal}
                                            onDelete={() =>
                                                handleDeleteComponentAssessment(
                                                    deleteComponentAssessmentId,
                                                )
                                            }
                                            deleteLoading={deleteComponentFromProgramLoading}
                                        />
                                    </StyledButton>
                                    <StyledButton
                                        size='small'
                                        variant='outlined'
                                        onClick={handleOpenEditComponent(componentAssessment.id)}
                                    >
                                        Edit
                                    </StyledButton>
                                </>
                                {/* ) : null} */}
                            </SummaryActions>
                        ) :
                            <SummaryActions>
                            </SummaryActions>
                        }
                    </>
                }
            >
                <ListItemStyled.Details>
                    {hasOperator ? (
                        <ListItemStyled.DetailsInfo>
                            Operators: {componentAssessment.operatorsToString()}
                        </ListItemStyled.DetailsInfo>
                    ) : null}

                    <ListItemStyled.DetailsInfo>
                        Component owner: {componentAssessment.componentOwnersToString()}
                        <Button
                            size='small'
                            variant='outlined'
                            style={{ marginLeft: '5px' }}
                            onClick={handleOpenAssignForCOReviewModal}
                        >
                            Reassign CO
                        </Button>
                    </ListItemStyled.DetailsInfo>
                </ListItemStyled.Details>

                <StyledAssessmentCapabilitiesList
                    project={project}
                    componentAssessment={componentAssessment}
                />

                {isDeleteEditAccess || isAssignOPUser ? (
                    <ListItemStyled.DetailsToolbar>
                        {projectInProgress ? (
                            <Button
                                disabled={companyAccess?.isFreeze}
                                onClick={() => {
                                    handleOpenAssignOperatorModal();
                                    context?.handleSelectedSurveyId(componentAssessment.id);
                                }}
                            >
                                {hasOperator
                                    ? 'Reassign operator to review this survey'
                                    : 'Assign operator to review this survey'}
                            </Button>
                        ) : null}
                    </ListItemStyled.DetailsToolbar>
                ) : null}
            </AccordionListItem>

            {isDeleteEditAccess ? (
                <>
                    <AssessmentComponentFormModal
                        type='edit'
                        project={project}
                        componentAssessmentId={choosenComponentAssessmentId}
                        open={openComponentFormModal}
                        onClose={handleCloseComponentFormModal}
                    />
                </>
            ) : null}

            {(isDeleteEditAccess || isAssignOPUser) && projectInProgress ? (
                <AssignAssessmentToOperatorFormModal
                    componentAssessmentId={componentAssessment.id}
                    project={project}
                    open={openAssignOperatorModal}
                    onClose={handleCloseAssignOperatorModal}
                />
            ) : null}

            <AssignAssessmentCapabilityToComponentOwnerFormModal
                open={openAssignForCOReviewModal}
                componentOwnerId={componentAssessment?.capabilities[0]?.id}
                componentAssessmentId={componentAssessment.id}
                onClose={handleCloseAssignForCOReviewModal}
            />
        </>
    );
};

export { AssessmentComponentsListNotAssessedItem };
