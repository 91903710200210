import { gql } from '@apollo/client';
import { IdHolderFragment } from '../fragments';

export const GetAlignedAssetIdsQuery = gql`
    query getAlignedAssetIds($assetId: Int!) {
        getAlignedAssetIds(asset_id: $assetId) {
            ...IdHolder
        }
    }
    ${IdHolderFragment}
`;
