import { createCustomError } from '@lib/errors/create-custom-error';

import type { ComponentEntity } from '@modules/components/entities';
import type { UserListEntity } from '@modules/users/entities';
import type {
    AddProjectComponentMutationVariables,
    ComponentInput,
    CapabilityInput,
} from '@modules/types/graphql';

export type ComponentAddFormValues = {
    weight: number;
    id?: number;
    component?: ComponentEntity | null;
    componentOwner?: UserListEntity | null;
    operator?: UserListEntity | null;
    objectives?: string;
    capabilities?: (CapabilityInput | null)[];
};

export type ProjectAddComponentsFormValues = {
    components: ComponentAddFormValues[];
    companyId: number | null;
    assessmentTypeId: number | null;
    projectId: number | null;
};

class ComponentAddRequest {
    component: ComponentInput;
    projectId: AddProjectComponentMutationVariables['projectId'];

    constructor(values: ComponentAddFormValues, projectId: number) {
        if (!values.component || !values.componentOwner) {
            throw createCustomError('incorrect arguments');
        }

        this.component = {
            component_id: values.component.id,
            component_owner: values.componentOwner.id,
            weight: values.weight,
            objectives: values.objectives,
            capabilities: values.capabilities,
        };

        this.projectId = projectId;
    }
}

export { ComponentAddRequest };
