import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserHighRoleEntityList } from '@modules/assessment/entities';
import { GetUserHighRoleQuery } from '@modules/assessment/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetUserHighestRoleQueryType } from '@modules/types/graphql';
import { useAuth } from '@modules/auth/context';

const useUserHighRole = (options?: QueryHookOptions<GetUserHighestRoleQueryType>) => {
    const { loading: authLoading, authenticated } = useAuth();
    const [retryCount, setRetryCount] = useState(0);
    const { loading: queryLoading, error, data, refetch } = useQuery<GetUserHighestRoleQueryType>(
        GetUserHighRoleQuery,
        {
            skip: !authenticated,
            ...options,
        }
    );

    const { entities: userHighRolesEntityList, count } = useEntity(
        () => new UserHighRoleEntityList(data?.getUserHighestRole ?? null),
        [authenticated, data]
    );

    const loading = authLoading || queryLoading;

    useEffect(() => {
        if (authenticated && !userHighRolesEntityList && !loading && retryCount < 5) {
            refetch();
            setRetryCount((prev) => prev + 1);
        }
    }, [authenticated, userHighRolesEntityList, loading, refetch]);

    if (error) {
        console.error("Error fetching user high role:", error);
    }

    return {
        userHighRolesEntityList,
        loading,
        count,
        data,
        refetch,
    };
};

export { useUserHighRole };

// const useUserHighRole = (options?: QueryHookOptions<GetUserHighestRoleQueryType>) => {
//     const { loading, authenticated } = useAuth();

//     const { loading: queryLoading, ...queryResult } = useQuery<GetUserHighestRoleQueryType>(
//         GetUserHighRoleQuery, {
//             skip: !authenticated,
//             ...options,
//         } );

//     const { entities: userHighRolesEntityList, count } = useEntity(
//         () => new UserHighRoleEntityList(queryResult?.data?.getUserHighestRole ?? null),
//         [authenticated, queryResult.data],
//     );

//     const result = {
//         userHighRolesEntityList,
//         loading: loading || queryLoading,
//         count,
//         ...queryResult
//     };

//     return result;
// };

// export { useUserHighRole };
