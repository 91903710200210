import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';

import { AuthManager } from '@lib/auth-manager';
import { routes } from '@config/routes';
import { GRAPHQL_URL, AWS_REGION } from '@config/environment';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const resetSession = async (): Promise<void> => {
    const date = Date.now() / 1000;
    const expiration = AuthManager.getNativeCurrentSession()?.getIdToken()?.getExpiration() ?? 0;

    const isExpire = date > expiration;

    if (!isExpire) {
        return;
    }

    AuthManager.destroySession();
    // Dirty solution
    if (window.location.pathname !== routes.login.path) {
        window.location.href = routes.login.path;
    }
};

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const url = GRAPHQL_URL;
    const region = AWS_REGION;
    const auth = {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as const,
        jwtToken: async () =>
            AuthManager.getNativeCurrentSession()?.getIdToken().getJwtToken() ?? '',
    };
    const createLinkPayload = { url, region, auth };
    const httpLink = createHttpLink({ uri: url });

    const authLink = (createAuthLink(createLinkPayload) as unknown) as ApolloLink;

    // TODO: Temp solution, while backend fixes queries
    const errorLink = onError(payload => {
        const { graphQLErrors = [], response } = payload;

        graphQLErrors.forEach((error: Record<string, any>) => {
            if (error?.errorType?.includes('UnauthorizedException')) {
                console.log('UnauthorizedException');
                resetSession();
            }
        });

        if (response?.errors) {
            const criticalErrors = response.errors.filter(
                error =>
                    (error as any).errorType !== 'MappingTemplate' &&
                    !error.message.includes('Cannot return'),
            );

            if (criticalErrors.length !== 0) {
                console.log(criticalErrors);
            }

            let importAssetErrors =
                response?.errors.filter(error => error.path && error.path[0] === 'importAsset') ??
                [];
            if (importAssetErrors.length) {
                (response as any).userErrors = importAssetErrors;
            }

            (window as any).apolloLastErrors = response.errors;
            response.errors = undefined;
        }
    });

    const apolloClient = new ApolloClient({
        link: ApolloLink.from([authLink, errorLink, httpLink]),
        cache: new InMemoryCache({
            typePolicies: {
                Implementation: {
                    keyFields: object => `Implementation:${object.project_id}`,
                },
                RecommendationImplementation: {
                    keyFields: object =>
                        `RecommendationImplementation:${object.roadmap_recommendation_id}`,
                },
            },
        }),
        connectToDevTools: true,
        defaultOptions: {
            query: {
                // https://www.apollographql.com/docs/react/data/error-handling/
                errorPolicy: 'all',
            },
        },
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
