import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Organization } from '@modules/types/graphql';

export type Entity = Partial<Organization> | null;

@DescribeEntity('OrganizationDetailsEntity')
class OrganizationDetailsEntity extends BaseEntity {
    userId: number;
    username: string;
    noOfEmployees: number | null;
    sector: string | null;
    organizationType: string | null;
    orgLocation: any;
    workforce: string | null;
    userRoleId: string | null;

    constructor(organization: Entity) {
        super(organization);

        this.userId = organization?.user_id ?? -1;
        this.username = organization?.username ?? '';
        this.noOfEmployees = organization?.number_of_employees ?? -1;
        this.sector = organization?.sector ?? '';
        this.organizationType = organization?.organization_type ?? '';
        this.orgLocation = organization?.organization_location ?? '';
        this.workforce = organization?.workforce ?? '';
        this.userRoleId = organization?.user_role_id ?? '';
    }
}

export { OrganizationDetailsEntity };
