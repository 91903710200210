import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { WelcomeDetails } from '@modules/types/graphql';

export type Entity = Partial<WelcomeDetails> | null;

@DescribeEntity('UserHighRoleEntity')
class UserHighRoleEntity extends BaseEntity {
    id: number;
    title: string;
    description: string;
    iconLink: string;
    urlLink: string;
    welcomeOrder: string;
    welcomeRole: string;

    constructor(welcome: Entity) {
        super(welcome);

        this.id = welcome?.id ?? -1;
        this.title = welcome?.title ?? '';
        this.description = welcome?.description ?? '';
        this.iconLink = welcome?.icon_link ?? '';
        this.urlLink = welcome?.url_link ?? '';
        this.welcomeOrder = welcome?.welcome_order ?? '';
        this.welcomeRole = welcome?.welcome_role ?? '';
    }
}

export { UserHighRoleEntity };
