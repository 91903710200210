import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import {
    CapabilityCreateRequest,
    CapabilityLevelCreateRequest,
} from '@modules/custom-components/requests';
import { CapabilityForm } from '@modules/custom-components/organisms';
import {
    CreateCapabilityLevelMutation,
    CreateCapabilityMutation,
} from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { CapabilityCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateCapabilityLevelMutationType,
    CreateCapabilityLevelMutationVariables,
    CreateCapabilityMutationType,
    CreateCapabilityMutationVariables,
} from '@modules/types/graphql';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { useCapabilityLevelDefinitions } from '@modules/custom-components/hooks';

type CapabilityFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    projectId?: number | any;
    componentId: number | any;
    companyId?: number;
    onSuccess?: () => Promise<void> | void;
};

const FormWrap = styled.div``;

const CapabilityCreateFormModal = (props: CapabilityFormModalProps) => {
    const { componentId, companyId, projectId, onClose, onSuccess, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [createCapability] = useMutation<
        CreateCapabilityMutationType,
        CreateCapabilityMutationVariables
    >(CreateCapabilityMutation);

    const [createCapabilityLevel] = useMutation<
        CreateCapabilityLevelMutationType,
        CreateCapabilityLevelMutationVariables
    >(CreateCapabilityLevelMutation);

    const {
        capabilityLevelDefinitions,
        loading: capabilityLevelDefinitionsLoading,
    } = useCapabilityLevelDefinitions();

    const initialValues = {
        companyId,
        componentId,
        title: '',
        impact: '',
        keyFactors: '',
        securityControls: '',
    };

    const handleSubmit = async (values: CapabilityCreateFormValues) => {
        try {
            const variables = new CapabilityCreateRequest(values);

            const { data: createCapabilityData } = await createCapability({
                variables: {
                    componentId: variables.componentId,
                    title: variables.title,
                    impact: variables.impact,
                    keyFactors: variables.keyFactors,
                    securityControls: variables.securityControls,
                    companyId: variables.companyId,
                    projectId: projectId,
                },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery, variables: { componentId } }],
                awaitRefetchQueries: true,
            });

            let capabilityDetails: any;
            capabilityDetails = {};
            capabilityDetails.capabilityId = createCapabilityData?.createCapability?.id;

            capabilityLevelDefinitions?.forEach(async function (capabilityLevelDefinition: any) {
                capabilityDetails.capabilityLevelDefinitionId = capabilityLevelDefinition?.id;
                capabilityDetails.description = '';
                const CapabilityLevelDetails = new CapabilityLevelCreateRequest(capabilityDetails);
                const { data: createCapabilityLevelData } = await createCapabilityLevel({
                    variables: CapabilityLevelDetails,
                    refetchQueries: [
                        { query: GetAllAssessmentTypeQuery, variables: { componentId } },
                    ],
                    awaitRefetchQueries: true,
                });
            });

            if (createCapabilityData?.createCapability?.id) {
                enqueueSuccess('Capability successfully created!');

                if (onSuccess) {
                    await onSuccess();
                }

                onClose();
            } else {
                enqueueError('An error occurred while creating capability!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Create New Capability' onClose={onClose} {...otherProps}>
            <CapabilityForm<'create'>
                type='create'
                initialValues={initialValues}
                FormWrapComponent={FormWrap}
                onSubmit={handleSubmit}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CapabilityCreateFormModal };
