import { gql } from '@apollo/client';
import { UserFragment } from '@modules/users/graphql';

const ProgramFragment = gql`
    fragment Program on Program {
        id
        company_id
        created_at
        name
        description
        program_status
        assessment_type_id
    }
`;

const IncidentFragment = gql`
    fragment Incident on Incident {
        id
        date
        description
        impact
    }
`;

const AssetActivityFragment = gql`
    fragment AssetActivity on AssetActivity {
        id
        activity_type
        score
        source
        weight
        activity_description
        notes
        created_at
        created_by {
            first_name
            last_name
        }
    }
`;

const AssetAssessmentFragment = gql`
    fragment AssetAssessment on AssetAssessment {
        id
        assessment_type
        score
        gaps
        source
        recommendations
        notes
        created_at
        created_by {
            first_name
            last_name
        }
    }
`;

const ItemTypeFragment = gql`
    fragment ItemType on ItemType {
        id
        name
    }
`;

const ItemClassFragment = gql`
    fragment ItemClass on ItemClass {
        id
        name
    }
`;
const RiskGroupFragment = gql`
    fragment RiskGroup on RiskGroup {
        id
        name
        risk_function_id
    }
`;
const RiskFunctionFragment = gql`
    fragment RiskFunction on RiskFunction {
        id
        name
    }
`;
const AssetCategoryFragment = gql`
    fragment AssetCategory on AssetCategory {
        id
        name
        risk_function_id
        item_id
    }
`;
const AssetTypeFragment = gql`
    fragment AssetType on AssetType {
        id
        name
        category_id
    }
`;
const DefaultTypeDataFragment = gql`
    fragment DefaultTypeData on DefaultTypeData {
        category_id
        type_ids
        item_category_type_id
    }
`;

const AlignedAssetFragment = gql`
    fragment AlignedAsset on AlignedAsset {
        id
    }
`;

export const GetAssignedAssets = gql`
    query getAssignedAssets {
        getAssignedAssets {
            id
            company {
                id
                name
            }
            name
            owner {
                ...User
            }
            pm {
                ...User
            }
            impactProgram {
                ...Program
            }
            vulnerabilityProgram {
                ...Program
            }
            threatProgram {
                ...Program
            }
            total_number_of_users_from
            total_number_of_users_to
            number_of_employee_users_from
            number_of_employee_users_to
            number_of_contractor_users_from
            number_of_contractor_users_to
            active_directory_groups
            users_notes
            incidents_creation_process
            incidents_storage_retention_and_retrieval
            incidents_usage_and_processing
            incidents_is_classified_or_labeled
            incidents_notes
            risk_appetite_desc
            risk_appetite_level
            risk_tolerance_desc
            risk_tolerance_level
            risk_capacity_desc
            risk_capacity_level
            unacceptable_risk_desc
            unacceptable_risk_level
            stakeholders {
                ...User
            }
            incidents {
                ...Incident
            }
            impactActivities {
                ...AssetActivity
            }
            vulnerabilityActivities {
                ...AssetActivity
            }
            threatActivities {
                ...AssetActivity
            }
            impactAssessments {
                ...AssetAssessment
            }
            vulnerabilityAssessments {
                ...AssetAssessment
            }
            threatAssessments {
                ...AssetAssessment
            }
            impactScore
            vulnerabilityScore
            threatScore
            riskScore
            riskPosture
            alignment_type
            notes
            itemType {
                ...ItemType
            } 
            itemClass {
                ...ItemClass
            } 
            riskGroup {
                ...RiskGroup
            } 
            riskFunction {
                ...RiskFunction
            } 
            assetCategory {
                ...AssetCategory
            } 
            assetType {
                ...AssetType
            } 
            itemAlignment {
                ...DefaultTypeData
            }
            alignedAssets {
                ...AlignedAsset
            } 
        }
    }
    ${UserFragment}
    ${ProgramFragment}
    ${IncidentFragment}
    ${AssetActivityFragment}
    ${AssetAssessmentFragment}
    ${ItemTypeFragment}
    ${ItemClassFragment}
    ${RiskGroupFragment}
    ${RiskFunctionFragment}
    ${AssetCategoryFragment}
    ${AssetTypeFragment}
    ${DefaultTypeDataFragment}
    ${AlignedAssetFragment}
`;

export const GetAssetsQuery = gql`
    query GetAssets {
        listAssets {
            id
            company {
                id
                name
            }
            name
            owner {
                ...User
            }
            pm {
                ...User
            }
            impactProgram {
                ...Program
            }
            vulnerabilityProgram {
                ...Program
            }
            threatProgram {
                ...Program
            }
            total_number_of_users_from
            total_number_of_users_to
            number_of_employee_users_from
            number_of_employee_users_to
            number_of_contractor_users_from
            number_of_contractor_users_to
            active_directory_groups
            users_notes
            incidents_creation_process
            incidents_storage_retention_and_retrieval
            incidents_usage_and_processing
            incidents_is_classified_or_labeled
            incidents_notes
            risk_appetite_desc
            risk_appetite_level
            risk_tolerance_desc
            risk_tolerance_level
            risk_capacity_desc
            risk_capacity_level
            unacceptable_risk_desc
            unacceptable_risk_level
            stakeholders {
                ...User
            }
            incidents {
                ...Incident
            }
            impactActivities {
                ...AssetActivity
            }
            vulnerabilityActivities {
                ...AssetActivity
            }
            threatActivities {
                ...AssetActivity
            }
            impactAssessments {
                ...AssetAssessment
            }
            vulnerabilityAssessments {
                ...AssetAssessment
            }
            threatAssessments {
                ...AssetAssessment
            }
            impactScore
            vulnerabilityScore
            threatScore
            riskPosture
            riskScore
            alignment_type
            notes
            itemType {
                ...ItemType
            } 
            itemClass {
                ...ItemClass
            } 
            riskGroup {
                ...RiskGroup
            } 
            riskFunction {
                ...RiskFunction
            } 
            assetCategory {
                ...AssetCategory
            } 
            assetType {
                ...AssetType
            } 
            itemAlignment {
                ...DefaultTypeData
            }
            alignedAssets {
                ...AlignedAsset
            } 
        }
    }
    ${UserFragment}
    ${ProgramFragment}
    ${IncidentFragment}
    ${AssetActivityFragment}
    ${AssetAssessmentFragment}
    ${ItemTypeFragment}
    ${ItemClassFragment}
    ${RiskGroupFragment}
    ${RiskFunctionFragment}
    ${AssetCategoryFragment}
    ${AssetTypeFragment}
    ${DefaultTypeDataFragment}
    ${AlignedAssetFragment}
`;

export const GetAlignedAssetsQuery = gql`
    query GetAlignedAssets {
        listAlignedAssets {
            id
            company {
                id
                name
            }
            name
            owner {
                ...User
            }
            pm {
                ...User
            }
            impactProgram {
                ...Program
            }
            vulnerabilityProgram {
                ...Program
            }
            threatProgram {
                ...Program
            }
            total_number_of_users_from
            total_number_of_users_to
            number_of_employee_users_from
            number_of_employee_users_to
            number_of_contractor_users_from
            number_of_contractor_users_to
            active_directory_groups
            users_notes
            incidents_creation_process
            incidents_storage_retention_and_retrieval
            incidents_usage_and_processing
            incidents_is_classified_or_labeled
            incidents_notes
            risk_appetite_desc
            risk_appetite_level
            risk_tolerance_desc
            risk_tolerance_level
            risk_capacity_desc
            risk_capacity_level
            unacceptable_risk_desc
            unacceptable_risk_level
            stakeholders {
                ...User
            }
            incidents {
                ...Incident
            }
            impactActivities {
                ...AssetActivity
            }
            vulnerabilityActivities {
                ...AssetActivity
            }
            threatActivities {
                ...AssetActivity
            }
            impactAssessments {
                ...AssetAssessment
            }
            vulnerabilityAssessments {
                ...AssetAssessment
            }
            threatAssessments {
                ...AssetAssessment
            }
            impactScore
            vulnerabilityScore
            threatScore
            riskPosture
            riskScore
            alignment_type
            notes
            itemType {
                ...ItemType
            } 
            itemClass {
                ...ItemClass
            } 
            riskGroup {
                ...RiskGroup
            } 
            riskFunction {
                ...RiskFunction
            } 
            assetCategory {
                ...AssetCategory
            } 
            assetType {
                ...AssetType
            } 
            itemAlignment {
                ...DefaultTypeData
            }
            alignedAssets {
                ...AlignedAsset
            } 
        }
    }
    ${UserFragment}
    ${ProgramFragment}
    ${IncidentFragment}
    ${AssetActivityFragment}
    ${AssetAssessmentFragment}
    ${ItemTypeFragment}
    ${ItemClassFragment}
    ${RiskGroupFragment}
    ${RiskFunctionFragment}
    ${AssetCategoryFragment}
    ${AssetTypeFragment}
    ${DefaultTypeDataFragment}
    ${AlignedAssetFragment}
`;
