import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomItemCategoryTypeCreateForm } from '@modules/custom-components/organisms/custom-item-category-type-form';

type CustomCategoryTypeCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    itemId?: number;
    type: string;
};

const CustomItemCategoryTypeCreateModal = (
    props: CustomCategoryTypeCreateModalProps,
): React.ReactElement => {
    const { companyId, type, itemId, onClose, ...otherProps } = props;
    return (
        <Modal
            title={type === 'create' ? 'Create Category Type' : 'Edit Category Type'}
            onClose={onClose}
            {...otherProps}
        >
            <CustomItemCategoryTypeCreateForm itemId={itemId} onCancel={onClose} />
        </Modal>
    );
};

export { CustomItemCategoryTypeCreateModal };
