import { gql } from '@apollo/client';

export const ContactUsDetailMutation = gql`
    mutation ContactUsDetail(
        $name: String!
        $email: String!
        $phone: String!
        $description: String
    ) {
        contactUsDetail(name: $name, email: $email, phone: $phone, description: $description) {
            void_result
        }
    }
`;
