import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';
import { UserEntity } from '@modules/users/entities';
import { ComponentEntity } from '@modules/components/entities';
import { GapEntityList } from '@modules/custom-components/entities';
import { RoadmapRecommendationEntityList } from '@modules/roadmap/entities';
import { CapabilityReportEntityList } from './capability-report-entity-list';

import type { ProjectMaturityLevelEnum } from '@modules/projects/constants';
import type { GapEntity } from '@modules/custom-components/entities';
import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import type { ComponentReport, MaturityLevel } from '@modules/types/graphql';
import type { CapabilityReportEntity } from './capability-report-entity';

export type Entity = Partial<ComponentReport> | null;

@DescribeEntity('ComponentReportEntity')
class ComponentReportEntity extends BaseEntity {
    assessmentId: number;
    componentWeight: number;
    objectives: string;
    maturityLevel: MaturityLevel;

    @Relation(() => UserEntity)
    componentOwner: UserEntity;

    @Relation(() => ComponentEntity)
    component: ComponentEntity;

    @RelationList(() => CapabilityReportEntityList)
    capabilities: CapabilityReportEntity[];

    @RelationList(() => GapEntityList)
    topGaps: (GapEntity | null)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    topRecommendations: (RoadmapRecommendationEntity | never)[];

    constructor(componentReport: Entity) {
        super(componentReport);

        this.objectives = componentReport?.objectives ?? '';
        this.assessmentId = componentReport?.assessment_id ?? 0;
        this.componentWeight = componentReport?.component_weight ?? 0;
        this.maturityLevel = componentReport?.maturity_level ?? {
            id: -1,
            name: '',
        };
    }

    getMaturityLevel() {
        if (this.maturityLevel.id <= 0 || !this.maturityLevel.name) {
            return null;
        }

        const maturityLevel = this.maturityLevel.name.toLowerCase();

        return maturityLevel as ProjectMaturityLevelEnum;
    }
}

export { ComponentReportEntity };
