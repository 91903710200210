import { Asset } from "@modules/types/graphql";

//Comman Line for all prompts.
const commanItemCompanyLine: string = 'company in the';
const commanItemSectorLine: string = 'sector with over #';
const commanItemEmployeeLine: string = 'employees in';

// Threat Asset Item prompt details.
const threatAssetItemStartLine: string = 'Take on the role of risk management expert. Generate a threat assessment model for determining the degree of harm posed to the';
const threatAssetItemSecondLine: string = 'by actors (individual, groups, nation-states, environmental, etc.) who, intentionally or unintentionally, have the ability or capability to compromise the';
const threatAssetItemThirdLine: string = '(“Threat Rating”). The organization is a';
const threatAssetItemFourthLine: string = 'The model must be in the form of Components and Capabilities, whereby each "component" of the assessment is supported by multiple "capabilities." These capabilities will then be reviewed by a user to determine the Capability completion level. Six pieces of risk information are included: ';
const threatAssetItemLastLine: string = 'Include as many “Components” and “Capabilities” as necessary to be comprehensive in scope. Provide “Capabilities” in the form of declarative statements (e.g. “Threat actors that pose a threat to the [asset] are identified and classified.”  Do not include Components or Capabilities related to Vulnerability or Impact analysis.';

//Threat Risk Item prompt details.
const threatRiskItemStartLine: string = 'Take on the role of risk management expert. Generate a threat assessment model for determining the degree of harm posed to the Organization by actors (individual, groups, nation-states, environmental, etc.) who, intentionally or unintentionally, have the ability or capability to effect a';
const threatRiskItemSecondLine: string = '(“Threat Rating”). The organization is a';
const threatRiskItemThirdLine: string = 'The model must be in the form of Components and Capabilities, whereby each "component" of the assessment is supported by multiple "capabilities." These capabilities will then be reviewed by a user to determine the Capability completion level. Six pieces of risk information are included:';
const threatRiskItemFourthLine: string = '- If the “risk name” includes a hyphen, the content to the left of the hyphen is the “asset name” affected by the risk and to the right is the “risk name.” If hyphenated, develop the threat assessment with a specific focus on assessing the threats posed to the asset by the risk. If not hyphenated, then focus on assessing threats posed to the organization as a whole.';
const threatRiskItemLastLine: string = 'Include as many “Components” and “Capabilities” as necessary to be comprehensive in scope. Provide “Capabilities” in the form of declarative statements (e.g. “Threat actors that pose a threat to the [asset] are identified and classified.”  Do not include Components or Capabilities related to Vulnerability or Impact analysis.';
//'Provide the model in the following format:'

//Vulnerability Asset Item prompt details.
const vulnerabilityAssetItemStartLine: string = 'Take on the role of risk management expert. Generate a vulnerability assessment model for determining the level of controls in place to protect the';
const vulnerabilityAssetItemSecondLine: string = 'from being compromised (“Vulnerability Rating”). The organization is a';
const vulnerabilityAssetItemThirdLine: string = 'The model must be in the form of Components and Capabilities, whereby each "component" of the assessment is supported by multiple "capabilities." These capabilities will then be reviewed by a user to determine the Capability completion level. Six pieces of risk information are included:';
const vulnerabilityAssetItemLastLine: string = 'Include as many “Components” and “Capabilities” as necessary to be comprehensive in scope. Provide “Capabilities” in the form of declarative statements (e.g. “The [asset] is encrypted at rest and in transit.”) Do not include Components or Capabilities related to Threat or Impact analysis.';
//'Provide the model in the following format:'

//Vulnerability Risk Item prompt details.
const vulnerabilityRiskItemStartLine: string = 'Take on the role of risk management expert. Generate a vulnerability assessment model for determining the level of controls in place to protect the organization from';
const vulnerabilityRiskItemSecondLine: string = '(“Vulnerability Rating”). The organization is a';
const vulnerabilityRiskItemThirdhLine: string = 'The model must be in the form of Components and Capabilities, whereby each "component" of the assessment is supported by multiple "capabilities." These capabilities will then be reviewed by a user to determine the Capability completion level. Six pieces of risk information are included:';
const vulnerabilityRiskItemFourthLine: string = '- If the “risk name” includes a hyphen, the content to the left of the hyphen is the “asset name” affected by the risk and to the right is the “risk name.” If hyphenated, develop the vulnerability assessment with a specific focus on assessing the level of controls in place to protect the asset from risk. If not hyphenated, then focus on assessing the level of controls in place to protect the organization as a whole from the risk.';
const vulnerabilityRiskItemLastLine: string = 'Include as many “Components” and “Capabilities” as necessary to be comprehensive in scope. Provide “Capabilities” in the form of declarative statements (e.g. “The [asset] is encrypted at rest and in transit.”) Do not include Components or Capabilities related to Threat or Impact analysis.';
//'Provide the model in the following format:'

//Impact Asset Item prompt details.
const impactAssetItemStartLine: string = 'Take on the role of risk management expert. Generate an Impact assessment model for determining the amount of harm (Operational, Financial, Organizational, Reputational, and Legal/Compliance) that a compromise of';
const impactAssetItemSecondLine: string = 'would have to the organization. (“Impact Rating”). The organization is a';
const impactAssetItemThirdLine: string = 'The model must be in the form of Components and Impact Factors, whereby each "component" of the assessment is supported by multiple "impact factors." These impact factors will then be reviewed by a user to determine the impact rating. Six pieces of risk information are included:';
const impactAssetItemLastLine: string = 'Include as many “Components” and “Impact Factors” as necessary to be comprehensive in scope. Provide “Impact Factors” in the form of declarative statements. Do not include Components or Impact Factors related to Threat or Vulnerability analysis.';
//'Provide the model in the following format:'

//Impact Risk Item prompt details.
const impactRiskItemStartLine: string = 'Take on the role of risk management expert. Generate an impact assessment model for determining the amount of harm (Operational, Financial, Organizational, Reputational, and Legal/Compliance) to the organization that would result from';
const impactRiskItemSecondLine: string = '(“Impact Rating”). The organization is a';
const impactRiskItemThirdLine: string = 'The model must be in the form of Components and Impact Factors, whereby each "component" of the assessment is supported by multiple "Impact Factors." These impact factors will then be reviewed by a user to determine the impact rating. Six pieces of risk information are included:';
const impactRiskItemFourthLine: string = '- If the “risk name” includes a hyphen, the content to the left of the hyphen is the “asset name” affected by the risk and to the right is the “risk name.” If hyphenated, develop the impact assessment with a specific focus on assessing the amount of harm to the organization that would result from a compromise of the asset by the risk. If not hyphenated, then focus on assessing harm posed to the organization as a whole by the risk.';
const impactRiskItemLastLine: string = 'Include as many “Components” and “Impact Factors” as necessary to be comprehensive in scope. Provide “Impact Factors” in the form of declarative statements. Do not include Components or Impact Factors related to Threat or Vulnerability analysis.';
//Provide the model in the following format:

const prompts = async (itemDetails: Asset, assessmentDetails: any, orgDetails: any) => {
    const levelName = `${itemDetails?.itemType?.name}-${assessmentDetails?.assessmentTypeId}`;
    let prompt;
    switch (levelName) {
        case 'Risk-1':
            // Impact Risk Item prompt.
            const impactRiskItemPrompt: string = `${impactRiskItemStartLine} ${itemDetails?.name} ${impactRiskItemSecondLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${impactRiskItemThirdLine}
        
                Objective: ${assessmentDetails?.description}
                Risk Name: ${itemDetails?.name} ${impactRiskItemFourthLine}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Risk Category: ${itemDetails?.assetCategory?.name}
                Risk Type: ${itemDetails?.assetType?.name}

                ${impactRiskItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Operational Impact.
                Capability 1.1: Disruption in Business Operations.
                Description: Evaluate the extent to which supply chain disruptions affect day-to-day operations.
                Capability 1.2: Resource Allocation.
                Description: Assess the need for reallocating resources due to supply chain disruptions.
                `;
            prompt = impactRiskItemPrompt;
            break;
        case 'Asset-1':
            // Impact Asset Item prompt.
            const impactAssetItemPrompt: string = `${impactAssetItemStartLine} ${itemDetails?.name} ${impactAssetItemSecondLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${impactAssetItemThirdLine}
    
                Objective: ${assessmentDetails?.description}
                Asset Name: ${itemDetails?.name}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Asset Category: ${itemDetails?.assetCategory?.name}
                Asset Type: ${itemDetails?.assetType?.name}

                ${impactAssetItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Financial Impact.
                Capability 1.1: Cost of Investigation.
                Description: Financial resources will be required to investigate the extent of the data compromise.
                Capability 1.2: Mitigation Expenses.
                Description: Funds will be necessary to mitigate the effects of the data compromise, including potential data recovery efforts.
                `;
            prompt = impactAssetItemPrompt;
            break;
        case 'Risk-2':
            // Vulnerability Risk Item prompt.
            const vulnerabilityRiskItemPrompt: string = `${vulnerabilityRiskItemStartLine} ${itemDetails?.name} ${vulnerabilityRiskItemSecondLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${vulnerabilityRiskItemThirdhLine}
    
                Objective: ${assessmentDetails?.description}
                Risk Name: ${itemDetails?.name} ${vulnerabilityRiskItemFourthLine}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Risk Category: ${itemDetails?.assetCategory?.name}
                Risk Type: ${itemDetails?.assetType?.name}

                ${vulnerabilityRiskItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Inventory Management Systems.
                Capability 1.1: Automated Replenishment Alerts.
                Description: The system generates automated alerts when inventory levels drop below predefined thresholds, facilitating timely replenishment and preventing stockouts.
                Capability 1.2: Inventory Forecasting and Demand Planning.
                Description: Advanced forecasting tools are utilized to predict inventory needs based on historical data and market trends, helping to mitigate the risk of overstocking or stockouts.
                `;
            prompt = vulnerabilityRiskItemPrompt;
            break;
        case 'Asset-2':
            // Vulnerability Asset Item prompt.
            const vulnerabilityAssetItemPrompt: string = `${vulnerabilityAssetItemStartLine} ${itemDetails?.name} ${vulnerabilityAssetItemSecondLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${vulnerabilityAssetItemThirdLine}
    
                Objective: ${assessmentDetails?.description}
                Asset Name: ${itemDetails?.name}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Asset Category: ${itemDetails?.assetCategory?.name}
                Asset Type: ${itemDetails?.assetType?.name}

                ${vulnerabilityAssetItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Physical Security.
                Capability 1.1: Surveillance Systems.
                Description: The data storage facilities are monitored by surveillance cameras to detect and deter unauthorized access.
                Capability 1.2: Fire Suppression Systems.
                Description: Fire suppression systems are in place to protect data from fire-related incidents.
                `;
            prompt = vulnerabilityAssetItemPrompt;
            break;
        case 'Risk-3':
            // Threat Risk Item prompt.
            const threatRiskItemPrompt: string = `${threatRiskItemStartLine} ${itemDetails?.name} ${threatRiskItemSecondLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${threatRiskItemThirdLine}
    
                Objective: ${assessmentDetails?.description}
                Risk Name: ${itemDetails?.name} ${threatRiskItemFourthLine}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Risk Category: ${itemDetails?.assetCategory?.name}
                Risk Type: ${itemDetails?.assetType?.name}

                ${threatRiskItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Threat Identification.
                Capability 1.1: Threat actors are identified and classified.
                Description: Threat actors that pose a threat to the supply chain (test) are identified and classified as individuals, groups, nation-states, or environmental factors.
                Capability 1.2: Threat sources are documented.
                Description: Document the sources of threats, including internal and external parties, to better understand where threats may originate.
                `;
            prompt = threatRiskItemPrompt;
            break;
        case 'Asset-3':
            // Threat Asset Item prompt.
            const threatAssetItemPrompt: string = `${threatAssetItemStartLine} ${itemDetails?.name} ${threatAssetItemSecondLine} ${itemDetails?.name} ${threatAssetItemThirdLine} ${orgDetails?.organization_type} ${commanItemCompanyLine} ${orgDetails?.sector} ${commanItemSectorLine}${orgDetails?.number_of_employees} ${commanItemEmployeeLine} ${orgDetails?.organization_location}(S) ${threatAssetItemFourthLine}
        
                Objective: ${assessmentDetails?.description}
                Asset Name: ${itemDetails?.name}
                Risk Group: ${itemDetails?.riskGroup?.name}
                Risk Function: ${itemDetails?.riskFunction?.name}
                Asset Category: ${itemDetails?.assetCategory?.name}
                Asset Type: ${itemDetails?.assetType?.name}

                ${threatAssetItemLastLine}
                Provide the model in the following format:

                Component [number]: [Component name]
                Capability [sub-number]: [capability name]
                description: [description]

                Example :
                Component 1: Threat Actor Capability Assessment.
                Capability 1.1: Actor Motivation Analysis.
                Description: The motivations of identified threat actors, whether financial gain, political influence, environmental advocacy, or other, are analyzed.
                Capability 1.2: Actor Resources Evaluation.
                Description: The resources available to threat actors, such as technical expertise, financial resources, and access to equipment, are evaluated.
                `;
            prompt = threatAssetItemPrompt;
            break;
        default:
            prompt = '';
            break;
    }
    return prompt;
};

export { prompts };
