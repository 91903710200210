import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { MenuItem } from '@material-ui/core';
import { gray } from '@modules/ui/colors';
import { useUsers } from '@modules/users/hooks';
import { useMutation } from '@apollo/client';
import { AssignRiskManagers, GetRiskGroupQuery, GetRiskRegistryUsersQuery } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AssignRiskGroupUserModal } from './assign-risk-group-user';
import { UserListEntity } from '@modules/users/entities';
import { useRiskAssignUser } from '@modules/registry/hooks';
import { RiskGroupEntity } from '@modules/registry/entities';

const StyledModal = styled(Modal)`
    max-width: 600px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const EditRiskManagersModal = (
    props: ModalProps & { initialValues: Record<string, any> },
): React.ReactElement => {
    const { onClose, initialValues, ...otherProps } = props;
    const { users, loading: usersLoading } = useUsers();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [riskUsers, setRiskUsers] = useState({ riskManagers: [] });
    const [assignRiskManagers] = useMutation(AssignRiskManagers);
    const [riskGroup, setRiskGroup] = useState<RiskGroupEntity[]>([]);
    const { riskGroups, loading: riskGroupsLoading } = useRiskAssignUser();

    const [openRiskGroupAssignModal, setOpenRiskGroupAssignModal] = useState<boolean>(false);
    const [riskGroupUser, setRiskGroupUser] = useState<UserListEntity>();
    const handleAssignUser = (e: any, event: any) => {
        e.stopPropagation();
        setRiskGroupUser(event);
        
        if(riskGroups?.listRiskAssignUser !== null) {
            const filteredUsers = riskGroups?.listRiskAssignUser.filter((ele: any) => ele.user_id === event?.id);
            const newRiskGroupEntities = filteredUsers.map((user: any) => new RiskGroupEntity({
                id: user.riskGroups.id,
                name: user.riskGroups.name,
                risk_function_id: user.riskGroups.risk_function_id
            }));
            setRiskGroup(newRiskGroupEntities);
        }
    }
    return (
        <>
            <AssignRiskGroupUserModal
                open={openRiskGroupAssignModal}
                onClose={() => setOpenRiskGroupAssignModal(false)}
                initialValues={{
                    user_id: riskGroupUser?.id,
                    riskGroup: riskGroup?.map(
                        (risk: RiskGroupEntity) => risk?.id,
                    ),
                }}
            >
                <></>
            </AssignRiskGroupUserModal>
            <StyledModal title='Risk managers' onClose={onClose} {...otherProps}>
                <ExtendedFormik
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        riskManagers: yup.array().required('Required field'),
                    })}
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        try {
                            const { data: assignRiskManagersData } = await assignRiskManagers({
                                variables: { riskManagers: values.riskManagers },
                                refetchQueries: [
                                    { query: GetRiskRegistryUsersQuery },
                                    { query: GetRiskGroupQuery }
                                ],
                                awaitRefetchQueries: true,
                            });

                            if (assignRiskManagersData) {
                                enqueueSuccess('Risk managers successfully updated!');
                                onClose();
                            } else {
                                enqueueError('An error occurred while updating risk managers!');
                                onClose();
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                        const handleAssignRisk = (event: any) => {
                            handleChange(event);
                            setRiskUsers({
                                ...riskUsers,
                                riskManagers: event.target.value
                            });
                        };

                        return (
                            <StyledForm>
                                <TextField
                                    select
                                    name='riskManagers'
                                    label='Risk managers'
                                    className='is-select'
                                    value={values.riskManagers}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={usersLoading || riskGroupsLoading}
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: (value: any) => {
                                            const values = users.filter(user => value.includes(user?.id)
                                            );
                                            return values
                                                .map(user => user?.firstName + ' ' + user?.lastName)
                                                .join(', ');
                                        },
                                    }}
                                    onChange={handleAssignRisk}
                                >
                                    {users ? (
                                        users.map(user => {
                                            const { firstName, id, lastName } = user;
                                            return (
                                                <MenuItem key={id} value={id} style={{ justifyContent: 'space-between' }}>
                                                    {firstName} {lastName}
                                                    {values.riskManagers.includes(user.id) && (
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={(e) => {
                                                                handleAssignUser(e, user);
                                                                setOpenRiskGroupAssignModal(true);
                                                            }}
                                                        >
                                                            Assign Risk Group <span style={{ color: 'red' }}>*</span>
                                                        </Button>
                                                    )}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem key={0} value={''}>
                                            Select manager(s)
                                        </MenuItem>
                                    )}
                                </TextField>

                                <FormStyled.FormRowSubmit>
                                    <SubmitButton type='submit' loading={isSubmitting}>
                                        Save
                                    </SubmitButton>

                                    <SubmitButton
                                        variant='text'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SubmitButton>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </StyledModal>
        </>
    );
};

export { EditRiskManagersModal };
