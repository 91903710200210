import React from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';
import { AssignAssessmentCapabilityToComponentOwnerForm } from '@modules/assessment/organisms';

import type { ModalProps } from '@modules/ui/core';

type AssignAssessmentCapabilityToComponentOwnerFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    componentOwnerId: number;
    componentAssessmentId: number;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 625px;
    min-height: 56px;
    padding: 8px 0;
    overflow: auto;
`;

const AssignAssessmentCapabilityToComponentOwnerFormModal = (
    props: AssignAssessmentCapabilityToComponentOwnerFormModalProps,
): React.ReactElement => {
    const { componentOwnerId, componentAssessmentId, onClose, ...otherProps } = props;

    const displayTitle = 'Assign a component owner to this capability.';

    return (
        <Modal title={displayTitle} onClose={onClose} {...otherProps}>
            <AssignAssessmentCapabilityToComponentOwnerForm
                componentOwnerId={componentOwnerId}
                componentAssessmentId={componentAssessmentId}
                FormWrapComponent={FormWrap}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { AssignAssessmentCapabilityToComponentOwnerFormModal };
