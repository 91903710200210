import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { UsersListFilters } from '@modules/users/moleculas';

import { Button } from '@modules/ui/core';
import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';
import { AuthContext } from '@modules/auth/context';
import { Loader } from '@modules/layout/moleculas';
const Root = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const Filters = styled.div`
    margin-left: auto;
`;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    margin: 0px 4px;
`;

type UserListToolBarProps = {
    handleEditOrgEdit: () => void;
};

const UsersListToolbar = (props: UserListToolBarProps): React.ReactElement => {
    const { handleEditOrgEdit } = props;
    const { currentUser } = useCurrentUser();
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};

    const navigate = useNavigate();
    const handleClickRedirect = (): void => {
        navigate(routes.usersCreate.path);
    };

    if (userLoading) {
        return <Loader />
    }

    return (
        <Root>
            {/* <ButtonCreateLink onClick={handleClickRedirect}>Create New User</ButtonCreateLink>
            {currentUser.isAdmin || currentUser.isSuperAdmin ? (
                <ButtonCreateLink onClick={handleEditOrgEdit}>Edit Organization</ButtonCreateLink>
            ) : (
                ''
            )} */}
            {
                (user?.roles[0] === 'super_admin' || user?.roles[0] === 'project_admin' || user?.roles[0] === 'risk_admin' || user?.roles[0] === 'company_admin') && (
                    <>
                        <ButtonCreateLink onClick={handleClickRedirect}>Create New User</ButtonCreateLink>
                        <ButtonCreateLink onClick={handleEditOrgEdit}>Edit Organization</ButtonCreateLink>
                    </>
                )
            }
            <Filters>
                <UsersListFilters />
            </Filters>
        </Root>
    );
};

export { UsersListToolbar };
