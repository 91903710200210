import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomItemCategoryTypeEditForm } from '@modules/custom-components/organisms';

type CustomAssetCategoryTypeEditModalProps = Omit<ModalProps, 'title' | 'children'> & {
    categoryId: number;
    categoryTypeId?: number;
};

const CustomAssetCategoryTypeEditModal = (props: CustomAssetCategoryTypeEditModalProps): React.ReactElement => {
    const { categoryId, categoryTypeId, onClose, ...otherProps } = props;
    return (
        <Modal title='Edit Category Type Details' onClose={onClose} {...otherProps}>
            <CustomItemCategoryTypeEditForm
                categoryId={categoryId}
                categoryTypeId={categoryTypeId}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CustomAssetCategoryTypeEditModal };
