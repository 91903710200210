import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssetCategoryByIdQueryType,
    GetAssetCategoryByIdVariables,
} from '@modules/types/graphql';
import { GetAssetCategoryByIdQuery } from '../graphql/queries';
import { AssetCategoryEntity } from '@modules/registry/entities';

const useGetAssetCategoryById = (
    options?: QueryHookOptions<GetAssetCategoryByIdQueryType, GetAssetCategoryByIdVariables>,
) => {
    const queryResult = useQuery<GetAssetCategoryByIdQueryType, GetAssetCategoryByIdVariables>(
        GetAssetCategoryByIdQuery,
        {
            ...options,
        },
    );

    const getAssetCategoryById = useEntity(
        () => new AssetCategoryEntity(queryResult.data?.getAssetCategoryById ?? null),
        [queryResult.data],
    );

    const result = {
        getAssetCategoryById,
        ...queryResult,
    };

    return result;
};

export { useGetAssetCategoryById };
