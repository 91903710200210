import { EntityList } from '@lib/entity';
import { GetAlignedAssetIdsEntity } from './get-aligned-assetIds-entity';

import type { Entity } from './get-aligned-assetIds-entity';

class GetAlignedAssetIdsEntityList extends EntityList<GetAlignedAssetIdsEntity> {
    constructor(alignedAssets: Entity[] | null, count?: number | null) {
        super(GetAlignedAssetIdsEntity, alignedAssets, count);
    }
}

export { GetAlignedAssetIdsEntityList };
