import { gql } from '@apollo/client';

export const UpdateProjectMutation = gql`
    mutation UpdateProject($projectId: Int!, $name: String!, $description: String!, $stakeholderId: Int!, $companyId: Int!) {
        updateProject(
            project_id: $projectId
            name: $name
            description: $description
            stakeholder_id: $stakeholderId
            company_id: $companyId
        ) {
            id
        }
    }
`;
