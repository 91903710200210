import { BaseEntity, Entity as DescribeEntity, Relation } from '@lib/entity';

import { GapEntity, RecommendationEntity } from '@modules/custom-components/entities';
import { RoadmapRecommendationRefinementEntity } from './roadmap-recommendation-refinement-entity';

import type { RecommendationEntityType } from '@modules/custom-components/entities';
import type { RoadmapRecommendation } from '@modules/types/graphql';
import type { Entity as RoadmapRecommendationRefinementEntityType } from './roadmap-recommendation-refinement-entity';

export type Entity = Partial<
    Omit<RoadmapRecommendation, 'recommendation' | 'refinement'> & {
        recommendation: RecommendationEntityType;
        refinement: RoadmapRecommendationRefinementEntityType;
    }
> | null;

@DescribeEntity('RoadmapRecommendationEntity')
class RoadmapRecommendationEntity extends BaseEntity {
    id: number;
    status: string;

    @Relation(() => GapEntity)
    gap: GapEntity;

    @Relation(() => RecommendationEntity)
    recommendation: RecommendationEntity;

    @Relation(() => RoadmapRecommendationRefinementEntity)
    refinement: RoadmapRecommendationRefinementEntity;

    constructor(roadmapRecommendation: Entity) {
        super(roadmapRecommendation);

        this.id = roadmapRecommendation?.id ?? -1;
        this.status = roadmapRecommendation?.status?.status ?? '';
    }

    isRefined() {
        const result = [
            this.refinement.isAccepted(),
            this.refinement.isAcknowledged(),
            this.refinement.isModified(),
            this.refinement.isRejected(),
        ].some(predicate => predicate === true);

        return result;
    }
}

export { RoadmapRecommendationEntity };
