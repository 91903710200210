import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { useMountedState } from 'react-use';
import { Formik, Form } from 'formik';
import { FormControl } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { white } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Hint } from '@modules/layout/atoms';
import { TextField } from '@modules/layout/moleculas';

import type { CognitoUser } from 'amazon-cognito-identity-js';

type ChangePasswordFormProps = {
    user: CognitoUser | any;
    onHide: () => void;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    padding: 24px;
    background-color: ${white[100]};
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
        letter-spacing: 0.5px;
        text-align: center;
    }

    > h3 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    > p {
        margin-top: 8px;
        font-size: 1.4rem;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledFrom = styled(Form)`
    width: 100%;

    > * {
        margin-bottom: 18px;
    }
`;

const StyledHint = styled(Hint)`
    margin: 12px 0 0 0;
`;

const FormControlSubmit = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;

    > * {
        flex: 1;

        &:not(:last-of-type) {
            margin-right: 12px;
        }
    }
`;

const ChangePasswordForm = (props: ChangePasswordFormProps): React.ReactElement => {
    const { user, onHide, ...otherProps } = props;

    const isMounted = useMountedState();

    const { enqueueSuccess } = useEnqueueStacks();

    return (
        <Root {...otherProps}>
            <Header>
                <h3>Change Password</h3>
                <p>You need change temporary password</p>
            </Header>

            <Main>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        password: yup
                            .string()
                            .required('Required field')
                            .min(8, payload => `Must be at least ${payload.min} characters`)
                            .matches(
                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/,
                                'Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one digit, and one special character.',
                            ),
                        confirmPassword: yup
                            .string()
                            .required('Required field')
                            .oneOf([yup.ref('password')], 'Passwords must match'),
                    })}
                    initialValues={{
                        oldPassword: '',
                        password: '',
                        confirmPassword: '',
                    }}
                    initialStatus={{
                        error: '',
                    }}
                    onSubmit={async (values, helpers) => {
                        const { setStatus, setSubmitting } = helpers;

                        setSubmitting(true);

                        try {
                            const changedPassword = await Auth.completeNewPassword(
                                user,
                                values.password,
                            );

                            if (changedPassword) {
                                enqueueSuccess('Password successfully changed! You can signin now');
                                onHide();
                            }
                        } catch (e: any) {
                            setStatus({ error: 'message' in e ? e.message : 'Unexpected error' });
                        } finally {
                            if (isMounted()) {
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {formikProps => {
                        const {
                            status,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        } = formikProps;

                        return (
                            <StyledFrom>
                                <TextField
                                    fullWidth
                                    id='password'
                                    type='password'
                                    label='Password'
                                    placeholder='Enter your password'
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    fullWidth
                                    id='confirmPassword'
                                    type='password'
                                    label='Confirm Password'
                                    placeholder='Enter password for confirm'
                                    value={values.confirmPassword}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormControl fullWidth>
                                    <FormControlSubmit>
                                        <Button
                                            variant='outlined'
                                            disabled={isSubmitting}
                                            onClick={onHide}
                                        >
                                            Back
                                        </Button>

                                        <Button type='submit' loading={isSubmitting}>
                                            Change Password
                                        </Button>
                                    </FormControlSubmit>

                                    {!!status.error ? (
                                        <StyledHint error text={status.error} />
                                    ) : null}
                                </FormControl>
                            </StyledFrom>
                        );
                    }}
                </Formik>
            </Main>
        </Root>
    );
};

export { ChangePasswordForm };
