import { gql } from '@apollo/client';

export const DeleteItemCategoryTypeMutation = gql`
    mutation DeleteItemCategoryType($categoryTypeId: Int!, $categoryId: Int!) {
        deleteItemCategoryType(category_type_id: $categoryTypeId, category_id: $categoryId) {
            id
            name
            category_id
        }
    }
`;
