import { gql } from '@apollo/client';

export const GetAssetCategoryByIdQuery = gql`
    query GetAssetCategoryById($categoryId: Int!, $itemId: Int) {
        getAssetCategoryById(category_id: $categoryId, item_id: $itemId) {
            id
            name
            risk_function_id
            item_id
        }
    }
`;
