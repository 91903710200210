import React from 'react';
import styled from 'styled-components';
import type { ModalProps } from '@modules/ui/core';
import { Button, Modal, TextField } from '@modules/ui/core';
import * as yup from 'yup';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { MenuItem } from '@material-ui/core';
import { gray } from '@modules/ui/colors';
import { AssignRiskUser, GetRiskAssignUserQuery } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import { useAssetCategory } from '@modules/registry/hooks';

const StyledModal = styled(Modal)`
    max-width: 600px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const AssignRiskGroupUserModal = (
    props: ModalProps & { initialValues: Record<string, any> },
): React.ReactElement => {
    const { onClose, initialValues, ...otherProps } = props;
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [assignRiskUser] = useMutation(AssignRiskUser);
    return (
        <StyledModal title='Assign Risk Group' onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    riskGroup: yup.array().required('Required field'),
                })}
                initialValues={initialValues}
                onSubmit={async values => {
                    try {
                        const { data: assignRiskuserData } = await assignRiskUser({
                            variables: { userId: values.user_id, riskGroupIds: values?.riskGroup },
                            refetchQueries: [{ query: GetRiskAssignUserQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (assignRiskuserData) {
                            enqueueSuccess('Risk Group Assign successfully!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while Assigning Risk Group!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                    return (
                        <StyledForm>
                            <TextField
                                select
                                fullWidth
                                name='riskGroup'
                                label='Risk Group'
                                className='is-select'
                                value={values.riskGroup}
                                disabled={assetCategoryLoading}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (value: any) => {
                                        const values = assetCategory.filter(risk =>
                                            value?.includes(risk?.id),
                                        );
                                        return values.map(risk => risk?.name).join(', ');
                                    },
                                }}
                                onChange={handleChange}
                            >
                                {assetCategory ? (
                                    assetCategory.map(risk => {
                                        const { id, name } = risk;
                                        return (
                                            <MenuItem key={id} value={id}>
                                                {name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem key={0} value={''}>
                                        Select manager(s)
                                    </MenuItem>
                                )}
                            </TextField>

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { AssignRiskGroupUserModal };
