import React, { useEffect, useState } from 'react';
import { PageStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import styled from 'styled-components';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import {
    deleteItemCategoryTypeMutationType,
    deleteItemCategoryTypeMutationVariables,
} from '@modules/types/graphql';
import { DeleteItemCategoryTypeMutation } from '@modules/custom-components/graphql';
import { useCurrentUser } from '@modules/auth/hooks';
import { CustomTypeListToolbar } from '@modules/custom-components/moleculas/custom-items-list-toolbar';
import DefaultTypeTable from './default-type-data';
import { GetlistAllItemTypesQuery } from '@modules/registry/graphql';
import { CustomAssetCategoryTypeEditModal } from '@modules/custom-components/moleculas/component-page-modal/custom-asset-category-type-edit-modal';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { ExpandMore } from '@material-ui/icons';
import { AuthContext } from '@modules/auth/context';
import { Loader } from '@modules/layout/moleculas';
import { cognitoUserGroupsForComponentAccess } from '@helpers';

type AllComponentsProps = {
    allTypeData?: any;
    categoryId: number;
};

const DeleteSpan = styled.div`
    color: crimson;
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
    margin-top: 20px;
    position: relative;
`;

const TypeDataList = (props: AllComponentsProps): React.ReactElement => {
    const { allTypeData, categoryId } = props;
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [filterData, setFilterData] = useState<any[]>();
    const [categoryTypeId, setCategoryTypeId] = useState<number | any>(null);
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [selectedId, setSelectedId] = useState<number | any>(null);
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser } = useCurrentUser();

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const componentActionStatus = true;

    const [deleteItemCategoryType, { loading: deleteItemCategoryTypeLoading }] = useMutation<
        deleteItemCategoryTypeMutationType,
        deleteItemCategoryTypeMutationVariables
    >(DeleteItemCategoryTypeMutation);

    const handleDeleteComponent = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteTypeData } = await deleteItemCategoryType({
                variables: {
                    categoryId: categoryId,
                    categoryTypeId: id,
                },
                refetchQueries: [{ query: GetlistAllItemTypesQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteTypeData?.deleteItemCategoryType) {
                enqueueSuccess('Category Type successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the Category Type!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditComponent = (id: number) => {
        setCategoryTypeId(id);
        handleOpenEditModal();
    };

    useEffect(() => {
        const filterComponent = allTypeData?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setFilterData(filterComponent);
    }, [componentSearch, allTypeData]);
    const isAccess = cognitoUserGroupsForComponentAccess?.includes(user?.roles?.[0] || '');

    if (userLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>Type</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>
            {
                isAccess && (
                    <>
                        <PageStyled.Bridge>
                            <CustomTypeListToolbar
                                ButtonTitleName={'Create New Type'}
                                setComponentSearch={setComponentSearch}
                                componentSearch={componentSearch}
                                assessmentId={categoryId}
                            />
                        </PageStyled.Bridge>
                    </>
                )
            }
            <Root>
                {filterData?.length ? (
                    filterData?.map((component: any) => {
                        const canEditComponent = component.isPredefined()
                            ? currentUser.hasSuperAdminPermission()
                            : true;
                        return (
                            <React.Fragment key={component?.id}>
                                <AccordionListItem
                                    title={
                                        selectedId === component?.id ? (
                                            deleteItemCategoryTypeLoading ? (
                                                <DeleteSpan>Deleting...</DeleteSpan>
                                            ) : (
                                                component?.name
                                            )
                                        ) : (
                                            component?.name
                                        )
                                    }
                                    componentActionStatus={componentActionStatus}
                                    componentSearch={componentSearch}
                                    subtitle=''
                                    SummaryInfoComponents={
                                        <>
                                            {(canEditComponent && isAccess) ? (
                                                <CategoryActions
                                                    id={component?.id}
                                                    onDelete={handleDeleteComponent}
                                                    onUpdate={handleEditComponent}
                                                />
                                            ) : (
                                                <ExpandMore style={{ fontSize: '24px', color: 'gray' }} />
                                            )}
                                        </>
                                    }
                                >
                                    {/* <DefaultTypeData
                                        defaultTypeData={component?.defaultType}
                                        typeId={component?.id}
                                    /> */}
                                    <DefaultTypeTable
                                        defalutData={component?.defaultType}
                                        typeId={component.id}
                                    />
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <h2>No Category Type found</h2>
                )}

                <CustomAssetCategoryTypeEditModal
                    open={openEditModal}
                    categoryTypeId={categoryTypeId}
                    categoryId={categoryId}
                    onClose={handleCloseEditModal}
                />
            </Root>
        </>
    );
};

export { TypeDataList };
