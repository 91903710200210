export const getBaseUrl = () => {
    const subdomain = window.location.hostname;
    if (subdomain.endsWith('.app.risktko.com')) {
        return subdomain;
    } else {
        return 'risktko.com';
    }
};

// export const URL = process.env.REACT_APP_URL ?? 'localhost';
// export const URL = process.env.REACT_APP_URL ?? 'test.risktko.com';
// export const URL = process.env.REACT_APP_URL ?? 'dh2qfzg7hoi0c.cloudfront.net';
// export const URL = process.env.REACT_APP_URL ?? 'd2y9vgpfvaql2i.cloudfront.net';
// export const URL = process.env.REACT_APP_URL ?? 'd16empj47nb787.cloudfront.net';
// export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ?? 'us-east-1';
// export const AWS_REGION = process.env.REACT_APP_AWS_REGION ?? '';
// export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID ?? '';
// export const AWS_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ?? '';
// export const SECURE = process.env.REACT_APP_SECURE === 'true';
// export const OPENAI_API_KEY =
//     process.env.OPENAI_API_KEY ?? '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';
// export const OPENAI_ORGANIZATION_ID =
//     process.env.OPENAI_ORGANIZATION_ID ?? 'org-1pC4yhjF7VBpRKeX3xXQZDm0';
// export const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET ?? '';
// export const ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '';
// export const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? '';

// for production

export const URL = getBaseUrl() ? getBaseUrl() : process.env.REACT_APP_URL ?? 'risktko.com';
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ?? 'us-east-1';
export const AWS_REGION = process.env.REACT_APP_AWS_REGION ?? '';
export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID ?? '';
export const AWS_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ?? '';
export const SECURE = process.env.REACT_APP_SECURE === 'true';
export const OPENAI_API_KEY =
    process.env.OPENAI_API_KEY ?? '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';
export const OPENAI_ORGANIZATION_ID =
    process.env.OPENAI_ORGANIZATION_ID ?? 'org-1pC4yhjF7VBpRKeX3xXQZDm0';
export const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET ?? '';
export const ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '';
export const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? '';

// for ITMG

// export const URL = process.env.REACT_APP_URL ?? 'd16empj47nb787.cloudfront.net';
// export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL ?? 'us-east-1';
// export const AWS_REGION = process.env.REACT_APP_AWS_REGION ?? '';
// export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID ?? '';
// export const AWS_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ?? '';
// export const SECURE = process.env.REACT_APP_SECURE === 'true';
// export const OPENAI_API_KEY =
//     process.env.OPENAI_API_KEY ?? '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';
// export const OPENAI_ORGANIZATION_ID =
//     process.env.OPENAI_ORGANIZATION_ID ?? 'org-1pC4yhjF7VBpRKeX3xXQZDm0';
// export const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET ?? '';
// export const ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? '';
// export const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? '';
