import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { UserListItem } from '@modules/types/graphql';

export type Entity = Partial<UserListItem> | null;

@DescribeEntity('UserListEntity')
class UserListEntity extends BaseEntity {
    id: number;
    firstName: string;
    lastName: string;

    constructor(user: Entity) {
        super(user);

        this.id = user?.id ?? -1;
        this.firstName = user?.first_name ?? '';
        this.lastName = user?.last_name ?? '';
    }

    getFullName() {
        const fullName = (this.firstName + ' ' + this.lastName).trim();

        return fullName;
    }
}

export { UserListEntity };
