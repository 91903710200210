import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomItemCategoryCreateForm } from '@modules/custom-components/organisms';

type CustomComponentCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    companyId?: number;
    itemId?: number;
    type: string;
};

const CustomItemCategoryCreateModal = (
    props: CustomComponentCreateModalProps,
): React.ReactElement => {
    const { companyId, type, itemId, onClose, ...otherProps } = props;
    const itemType = itemId === 1 ? 'Risk' : 'Asset';
    return (
        <Modal
            title={type === 'create' ? `Create Item ${itemType} Category` : `Edit Item ${itemType} Category`}
            onClose={onClose}
            {...otherProps}
        >
            <CustomItemCategoryCreateForm itemId={itemId} onCancel={onClose} />
        </Modal>
    );
};

export { CustomItemCategoryCreateModal };
