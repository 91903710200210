import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { ProjectAssignedUsers } from '@modules/types/graphql';

export type Entity = Partial<ProjectAssignedUsers> | null;

@DescribeEntity('ProjectListNameEntity')
class ProjectListNameEntity extends BaseEntity {
    id: number;
    projectId: number;
    userId: number;
    roleId: string;
    projectName: string;

    constructor(projectName: Entity) {
        super(projectName);

        this.id = projectName?.id ?? -1;
        this.projectId = projectName?.project_id ?? -1;
        this.userId = projectName?.user_id ?? -1;
        this.projectName = projectName?.project_name ?? '';
        this.roleId = projectName?.role_id ?? '';
    }
}

export { ProjectListNameEntity };
