import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssetTypeByIdQueryType,
    GetAssetTypeByIdVariables,
} from '@modules/types/graphql';
import { GetAssetTypeByIdQuery } from '../graphql/queries';
import { AssetTypeEntity } from '@modules/registry/entities';

const useGetAssetTypeById = (
    options?: QueryHookOptions<GetAssetTypeByIdQueryType, GetAssetTypeByIdVariables>,
) => {
    const queryResult = useQuery<GetAssetTypeByIdQueryType, GetAssetTypeByIdVariables>(
        GetAssetTypeByIdQuery,
        {
            ...options,
        },
    );

    const getAssetTypeById = useEntity(
        () => new AssetTypeEntity(queryResult.data?.getAssetTypeById ?? null),
        [queryResult.data],
    );

    const result = {
        getAssetTypeById,
        ...queryResult,
    };

    return result;
};

export { useGetAssetTypeById };
