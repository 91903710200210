import { useState } from 'react';
import { ChatGptInstance } from './chat-gpt-instance';

const useChatBotTko = () => {
    const [response, setResponse] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const fetchChatTko = async (prompt: string) => {
        setLoading(true);
        try {
            const regex = /(\d+\.)?\s*(\n\n|\n)/;
            const completion = await ChatGptInstance.createChatCompletion({
                model: 'gpt-4o',
                messages: [{ role: 'user', content: prompt }],
                temperature: 0.1,
                max_tokens: 3097,
            });
            let result: any = completion?.data?.choices[0]?.message?.content?.split('\n');
            if (result) {
                setResponse(result || []);
            }
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };
    return [response, loading, error, fetchChatTko];
};

export { useChatBotTko };
