import React from 'react';
import styled from 'styled-components';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';

type AssessmentSurveyModalProps = Omit<ModalProps, 'title' | 'children'> & {

};

const StyledModal = styled(Modal)`
    width: 1200px;
`;

const DialogNotValid = (props: AssessmentSurveyModalProps): React.ReactElement => {
    const { onClose, ...otherProps } = props;

    return (
        <StyledModal title='Unable to Create New Project - Organization Details Missing' onClose={onClose} {...otherProps}>
            <p style={{fontSize: '15px'}}>
            You cannot create a new project because your organization details are not filled in. Please contact an Admin with access rights.
            </p>
        </StyledModal>
    );
};

export { DialogNotValid };
