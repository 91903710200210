import React from 'react';
import { useLocation } from '@reach/router';

import { AuthManager } from '@lib/auth-manager';

type User = {
    sub: string;
    roles: string[];
};

type AuthContextValues = {
    authenticated: boolean;
    showModal: boolean;
    user: User;
    loading: boolean;
    isFreeze: boolean;
    isAiAccess: boolean;
    activityTypes: any;
    selectedProjectId: any;
    selectedItemId: number;
    selectedSurveyId: number | any;
    handleSelectedProjectId: (id: number) => void;
    handleSetSelectedItemId: (id: number) => void;
    handleSelectedSurveyId: (id: number) => void;
    handleOpenProjectModal: () => void;
    handleActivityTypes: (type: string) => void;
    handleCloseProjectModal: () => void;
    setUser: (state: User | ((state: User) => User)) => void;
    setAuthenticated: (state: boolean) => void;
    setIsFreeze: (state: boolean) => void;
    setIsAiAccess: (state: boolean) => void;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

const initialUser = {
    sub: '',
    roles: [],
};

const AuthContext = React.createContext<AuthContextValues | undefined>(undefined);

const AuthProvider = (props: AuthProviderProps): React.ReactElement => {
    const { children } = props;

    const location = useLocation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [authenticated, setAuthenticated] = React.useState<boolean>(false);
    const [isAiAccess, setIsAiAccess] = React.useState<boolean>(false);
    const [isFreeze, setIsFreeze] = React.useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = React.useState<any>(null);
    const [selectedSurveyId, setSelectedSurveyId] = React.useState<any>(null);
    const [selectedItemId, setSelectedItemId] = React.useState<number>(0);
    const [user, setUser] = React.useState<User>(initialUser);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [activityTypes, setActivityTypes] = React.useState<any>('');
    // const isFreeze: boolean = companyAccess?.isFreeze;
    // const isAiAccess: boolean = companyAccess?.isAIAccess;

    const handleOpenProjectModal = () => {
        setShowModal(true);
    };
    const handleCloseProjectModal = () => {
        setShowModal(false);
    };

    const handleActivityTypes = (type: string) => {
        setActivityTypes(type);
    };

    const handleSelectedProjectId = (id: number) => {
        setSelectedProjectId(id);
    };
    const handleSelectedSurveyId = (id: number) => {
        setSelectedSurveyId(id);
    };

    const handleSetSelectedItemId = (id: number) => {
        setSelectedItemId(id);
    };

    React.useEffect(() => {
        const checkAuth = async () => {
            setLoading(true);

            try {
                const currentSession = await AuthManager.getNativeCurrentSession();

                if (currentSession === null) {
                    setAuthenticated(false);
                    setUser(initialUser);
                    return;
                }

                const tokenPayload = currentSession.getIdToken().decodePayload();

                setAuthenticated(true);
                setUser({
                    sub: tokenPayload['sub'] ?? '',
                    roles: tokenPayload['cognito:groups'] ?? [],
                });
            } catch (e) {
                console.log('[AuthContext]: Error', e);
                setAuthenticated(false);
                setUser(initialUser);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, [location.pathname]);

    return (
        <AuthContext.Provider
            value={{
                authenticated,
                user,
                loading,
                setAuthenticated,
                setUser,
                handleOpenProjectModal,
                handleCloseProjectModal,
                showModal,
                handleActivityTypes,
                activityTypes,
                isFreeze,
                isAiAccess,
                handleSelectedProjectId,
                handleSetSelectedItemId,
                selectedProjectId,
                selectedItemId,
                setIsAiAccess,
                setIsFreeze,
                selectedSurveyId,
                handleSelectedSurveyId
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextValues => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('auth context value are undefined');
    }

    return context;
};

export { AuthContext, AuthProvider, useAuth };
