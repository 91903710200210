import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';
import { CapabilityEntity, CapabilityLevelEntity } from '@modules/capabilities/entities';
import { GapEntityList } from '@modules/custom-components/entities';
import { RoadmapRecommendationEntityList } from '@modules/roadmap/entities';

import type { GapEntity } from '@modules/custom-components/entities';
import type { RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import type { CapabilityReport } from '@modules/types/graphql';
import {
    gapLevelFieldList,
    recommendationLevelFieldList,
} from '@modules/shared/constants/risk-level';

export {
    recommendationLevelFieldList,
    recommendationLevelFieldLabelList,
    gapLevelFieldList,
    gapLevelFieldLabelList,
} from '@modules/shared/constants/risk-level';

export type Entity = Partial<CapabilityReport> | null;

@DescribeEntity('CapabilityReportEntity')
export class CapabilityReportEntity extends BaseEntity {
    capabilityAssessmentId: number;

    @Relation(() => CapabilityEntity)
    capability: CapabilityEntity;

    @Relation(() => CapabilityLevelEntity)
    completionLevel: CapabilityLevelEntity;

    @RelationList(() => GapEntityList)
    superCriticalGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    criticalGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    highCriticalGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    highGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    mediumHighGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    mediumGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    lowMediumGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    lowGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    minimalLowGaps: (GapEntity | null)[];

    @RelationList(() => GapEntityList)
    minimalGaps: (GapEntity | null)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    superCriticalRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    criticalRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    highCriticalRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    highRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    mediumHighRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    mediumRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    lowMediumRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    lowRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    minimalLowRecommendations: (RoadmapRecommendationEntity | never)[];

    @RelationList(() => RoadmapRecommendationEntityList)
    minimalRecommendations: (RoadmapRecommendationEntity | never)[];

    constructor(capabilityReport: Entity) {
        super(capabilityReport);

        this.capabilityAssessmentId = capabilityReport?.capability_assessment_id ?? 0;
    }

    hasAnyGaps() {
        let result = gapLevelFieldList.some(key => Boolean(this[key]?.length));
        return result;
    }

    hasAnyRecommendations() {
        let result = recommendationLevelFieldList.some(key => Boolean(this[key]?.length));
        return result;
    }
}
