import { gql } from '@apollo/client';
export const GetProjectDetailsQuery = gql`
    query GetProjectDetailsForQuery($id: Int!) {
        getProjectDetailsForQuery(id: $id) {
            id
            name
            description
            company_id
            program_status
            assessment_type_id
            tko_scope_type
        }
    }
`;
