import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, SubTask } from '@modules/types/graphql';

export type Entity = Maybe<Partial<SubTask>>;

@DescribeEntity('SubTaskEntity')
class SubTaskEntity extends BaseEntity {
    id: number;
    taskId: number;
    recommendationId: number;
    companyId: number | null;
    subTask: String;

    constructor(SubTask: Entity) {
        super(SubTask);

        this.id = SubTask?.id ?? -1;
        this.taskId = SubTask?.task_id ?? -1;
        this.recommendationId = SubTask?.recommendation_id ?? -1;
        this.companyId = SubTask?.company_id ?? null;
        this.subTask = SubTask?.sub_task ?? '';
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { SubTaskEntity };
