export enum AssessmentType {
    InsiderRisk = 'Insider Risk',
    CyberRisk = 'Cyber Risk',
    Vulnerability = 'Vulnerability',
    Threat = 'Threat',
    DataProtection = 'Data Protection',
    Ransomware = 'Ransomware',
    Impact = 'Impact',
    Custom = 'Custom',
}

export enum RiskProgramType {
    risk = 'risk',
    impact = 'impact',
    vulnerability = 'vulnerability',
    threat = 'threat',
}

export const RiskProgramScoreType = {
    riskScore: RiskProgramType.risk,
    impactScore: RiskProgramType.impact,
    vulnerabilityScore: RiskProgramType.vulnerability,
    threatScore: RiskProgramType.threat,
};

// NA status is UI only, added to be compatible  with DB ID, which in range 1..X
export const RiskProgramTypeList: string[] = [...Object.values(RiskProgramType)];
export const RiskAssessmentTypeList: string[] = ['N/A', ...Object.values(AssessmentType)];

// impact: 1,
// vulnerability: 2,
// threat: 3,
export const RiskProgramTypeMap: Record<string, number> = RiskProgramTypeList.reduce((data, key: string, index: number) => {
    data[key] = index;
    return data;
}, {});
