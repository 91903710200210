import React, { useEffect, useMemo } from 'react';

import { ListStyled } from '@modules/layout/styled';

import { ComponentAssessmentEntity, ComponentReportEntity } from '@modules/assessment/entities';
import { ReportRoadmapComponentsListItem } from '@modules/roadmap/organisms/report-roadmap/report-roadmap-component-list-item';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { ImplementationStatus } from '@modules/shared/constants/implementation-status';
import { RecommendationComponentAssessment } from '@modules/roadmap/recommendation-by-risk';

type ReportComponentVIewProps = {
    // componentReports: (ComponentReportEntity | never)[];
    componentsAssessmentList: ComponentAssessmentEntity[];
    componentAssessmentMap: Map<number, RecommendationComponentAssessment>;
};

export const ReportComponentView = (props: ReportComponentVIewProps): React.ReactElement => {
    const { componentsAssessmentList, componentAssessmentMap } = props;

    if (!componentsAssessmentList.length) {
        return <ListStyled.Empty>No components found</ListStyled.Empty>;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Component</TableCell>
                        <TableCell>Maturity Level</TableCell>
                        <TableCell>Capabilities</TableCell>
                        <TableCell>Recommendations</TableCell>
                        <TableCell>% {ImplementationStatus.Done}</TableCell>
                        <TableCell>% {ImplementationStatus.InProgress}</TableCell>
                        <TableCell>% {ImplementationStatus.Pending}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {componentsAssessmentList.map((assessment, index) => {
                        return (
                            <ReportRoadmapComponentsListItem
                                key={`${assessment.id}-${index}`}
                                assessment={assessment}
                                componentAssessmentMap={componentAssessmentMap}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
