import { useMutation } from '@apollo/client';
import { cognitoUserGroupsForComponentAccess } from '@helpers';
import { MenuItem, TextField, Select, Checkbox, ListItemText } from '@material-ui/core';
import { AuthContext } from '@modules/auth/context';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { AssetTypeEntity } from '@modules/registry/entities';
import { AddPrevTypeDetails, GetlistAllItemTypesQuery } from '@modules/registry/graphql';
import { useAssetCategory, useAssetType } from '@modules/registry/hooks';
import { Button } from '@modules/ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  margin-top: 10px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    border: 1px solid #d1d2d2;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #2f4f6f;
    color: white;
  }
    .MuiSelect-selectMenu{
        white-space: wrap;
    }
`;

const ActionButton = styled(Button)`
    min-width: 60px;

    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

type CategoryTableProps = {
    defalutData: any[];
    typeId: number;
};

type RowType = {
    name: string;
    category: string | number;
    type: (string | number)[];
};

const DefaultTypeTable: React.FC<CategoryTableProps> = ({ defalutData, typeId }) => {
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    const context = useContext(AuthContext);
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const [itemData, setItemData] = useState<number>(0);
    const { assetTypeData, loading: assetTypeLoading } = useAssetType();
    const [addPrevTypeDetails] = useMutation(AddPrevTypeDetails);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const initialRows = defalutData.map(data => ({
        name: '',
        category: data.categoryId,
        type: data.typeIds,
    }));
    const [rows, setRows] = useState<RowType[]>(initialRows);
    const [assetTypeDetails, setAssetTypeDetails] = useState<{ [key: number]: AssetTypeEntity[] }>({});
    useEffect(() => {
        if (defalutData && assetTypeData) {
            const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
            defalutData.forEach((data, index) => {
                const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === data.categoryId);
                newAssetTypeDetails[index] = assetTypeDataValue;
            });
            setAssetTypeDetails(newAssetTypeDetails);
        }
    }, [defalutData, assetTypeData]);

    useEffect(() => {
        context?.selectedItemId === 1 ?
            setItemData(2) : context?.selectedItemId === 2 ?
                setItemData(1) : setItemData(0)
    }, [context]);
    const isAccess = cognitoUserGroupsForComponentAccess?.includes(user?.roles?.[0] || '');
    const addRow = () => {
        setRows([...rows, { name: '', category: '', type: [] }]);
    };
    const handleCategoryChange = (index: number, value: string) => {
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], category: Number(value), type: [] };

        const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(asset => asset.categoryId === Number(value));
        setAssetTypeDetails({ ...assetTypeDetails, [index]: assetTypeDataValue });
        setRows(newRows);
    };
    const handleTypeChange = (index: number, value: (string | number)[]) => {
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], type: value };
        setRows(newRows);
    };

    const handleSave = async (index: number) => {
        const row = rows[index];

        try {
            const { data: addPrevTypeDetailsData } = await addPrevTypeDetails({
                variables: { selectedTypeId: typeId, categoryId: row.category, typeId: row?.type },
                refetchQueries: [{ query: GetlistAllItemTypesQuery }],
                awaitRefetchQueries: true,
            });

            if (addPrevTypeDetailsData) {
                enqueueSuccess('Type Saved successfully!');
            } else {
                enqueueError('An error occurred while createing category and Type!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    if (assetCategoryLoading || assetTypeLoading || userLoading) {
        return <Loader />;
    }

    return (
        <TableWrapper>
            <Formik
                initialValues={{ rows }}
                onSubmit={(values) => console.log('Form values:', values.rows)}
            >
                {({ values, handleChange }) => (
                    <Form>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Type</th>
                                    <th>Add to RR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        <td style={{ width: '350px' }}>
                                            <Field
                                                as={TextField}
                                                select
                                                fullWidth
                                                required
                                                name={`rows[${index}].category`}
                                                label={itemData === 1 ? 'Risk Category' : 'Asset Category'}
                                                value={row.category || ''}
                                                disabled={!isAccess}
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    handleCategoryChange(index, e.target.value);
                                                }}
                                            >
                                                <MenuItem value=''>
                                                    <em>Select Category</em>
                                                </MenuItem>
                                                {assetCategory &&
                                                    assetCategory
                                                        .filter(risk => risk.itemId === itemData)
                                                        .map((item: { id: number; name: string }) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                            </Field>
                                        </td>
                                        <td style={{ width: '700px' }}>
                                            <Field
                                                as={Select}
                                                multiple
                                                fullWidth
                                                required
                                                name={`rows[${index}].type`}
                                                label={'Item Type'}
                                                disabled={!isAccess}
                                                value={row.type || ''}
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    handleTypeChange(index, e.target.value);
                                                }}
                                                renderValue={(selected: any) =>
                                                    selected.map((id: number) => assetTypeDetails[index]?.find(item => item.id === id)?.name).join(', ')
                                                }
                                            >
                                                <MenuItem value=''>
                                                    <em>Select Type</em>
                                                </MenuItem>
                                                {assetTypeDetails[index] &&
                                                    assetTypeDetails[index].map((item: { id: number; name: string }) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            <Checkbox checked={row.type.indexOf(item.id) > -1} />
                                                            <ListItemText primary={item.name} />
                                                        </MenuItem>
                                                    ))}
                                            </Field>
                                        </td>
                                        <td style={{ textAlign: 'center', width: '200px' }}>
                                            <ActionButton type="button" onClick={() => handleSave(index)} disabled={!isAccess}>Save</ActionButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button type="button" onClick={addRow} style={{ marginTop: 15 }} disabled={!isAccess}>
                            + Category
                        </Button>
                    </Form>
                )}
            </Formik>
        </TableWrapper>
    );
};

export default DefaultTypeTable;
