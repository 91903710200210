import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Modal, TextField } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { Asset } from '@modules/types/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import { GetAssetsQuery, UpdateAsset } from '@modules/registry/graphql';

const StyledModal = styled(Modal)`
    // max-width: 600px;
    width: 100%;
    min-width: 100%;
    >div>div:nth-child(1) {
        padding-right: 35px;
    }
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const defaultInitialValues = {
    id: '',
    total_number_of_users: '', // Ensure this is an empty string
    number_of_employee_users: '',
    number_of_contractor_users: '',
    active_directory_groups: '',
    users_notes: '',
};

const numberOrRangeRegExp = /^[0-9]+([-]?[0-9]+)?$/;
const ValidationSchema = Yup.object({
    total_number_of_users: Yup.string()
        .matches(numberOrRangeRegExp, 'Incorrect format')
        .required('Total number of users is required')
        .default(''),
    number_of_employee_users: Yup.string()
        .matches(numberOrRangeRegExp, 'Incorrect format')
        .required('Number of employee users is required')
        .default(''),
    number_of_contractor_users: Yup.string()
        .matches(numberOrRangeRegExp, 'Incorrect format')
        .required('Number of contractor users is required')
        .default(''),
});



function getRangeFromValue(value: string) {
    const pattern = /^(\d+)-(\d+)$/;
    if (pattern.test(value)) {
        return value.split('-').map(part => Number(part));
    }

    return [Number(value), Number(value)];
}

function getValueFromRange(from?: null | number, to?: null | number) {
    if (to && from !== to) {
        return from + '-' + to;
    }

    return from;
}

const EditUsersModal = (props: ModalProps & { activeAsset: null | Asset }): React.ReactElement => {
    const { onClose, activeAsset, ...otherProps } = props;
    const [initialValues, setInitialValues] = useState(defaultInitialValues);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [updateAsset] = useMutation(UpdateAsset);

    useEffect(() => {
        if (!activeAsset) {
            return;
        }

        const values: any = {
            id: activeAsset.id || '',
            total_number_of_users: getValueFromRange(
                activeAsset.total_number_of_users_from || null,
                activeAsset.total_number_of_users_to || null,
            ) || '',
            number_of_employee_users: getValueFromRange(
                activeAsset.number_of_employee_users_from || null,
                activeAsset.number_of_employee_users_to || null,
            ) || '',
            number_of_contractor_users: getValueFromRange(
                activeAsset.number_of_contractor_users_from || null,
                activeAsset.number_of_contractor_users_to || null,
            ) || '',
            active_directory_groups: activeAsset.active_directory_groups || '',
            users_notes: activeAsset.users_notes || '',
        };

        delete values.__typename;

        setInitialValues(values);
    }, [activeAsset]);


    return (
        <StyledModal title={'"' + activeAsset?.name + '" ' + activeAsset?.itemType?.name + ' - Users'} onClose={onClose} {...otherProps} className='inherit_max_width'>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={true}
                validateOnMount={false}
                validationSchema={ValidationSchema}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const {
                        id,
                        total_number_of_users,
                        number_of_employee_users,
                        number_of_contractor_users,
                        active_directory_groups,
                        users_notes,
                    } = values;

                    const [
                        total_number_of_users_from,
                        total_number_of_users_to,
                    ] = getRangeFromValue(total_number_of_users);
                    const [
                        number_of_employee_users_from,
                        number_of_employee_users_to,
                    ] = getRangeFromValue(number_of_employee_users);
                    const [
                        number_of_contractor_users_from,
                        number_of_contractor_users_to,
                    ] = getRangeFromValue(number_of_contractor_users);

                    const result = {
                        variables: {
                            input: {
                                id,
                                total_number_of_users_from,
                                total_number_of_users_to,
                                number_of_employee_users_from,
                                number_of_employee_users_to,
                                number_of_contractor_users_from,
                                number_of_contractor_users_to,
                                active_directory_groups,
                                users_notes,
                            },
                        },
                    };

                    try {
                        const { data: updateAssetData } = await updateAsset({
                            variables: {
                                input: {
                                    id,
                                    total_number_of_users_from,
                                    total_number_of_users_to,
                                    number_of_employee_users_from,
                                    number_of_employee_users_to,
                                    number_of_contractor_users_from,
                                    number_of_contractor_users_to,
                                    active_directory_groups,
                                    users_notes,
                                    alignment_type: activeAsset?.alignment_type,
                                },
                            },
                            refetchQueries: [{ query: GetAssetsQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (updateAssetData) {
                            enqueueSuccess('Asset successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while updating asset!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                        setFieldError,
                    } = formikProps;

                    const errorMsg = 'Invalid input. Please make sure value is not empty and format are correct d-d or d, where d is one or multiple digits, OR under the 9 digits';

                    // const inputNumberOrRangeMask = /^[0-9]*[-]?[0-9]*$/;
                    const inputNumberOrRangeMask = /^[0-9]{0,9}(-[0-9]{0,9})?$/;
                    const handleChangeWithMask = (
                        mask: RegExp,
                        name: string,
                        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        const value = e.target.value;
                        if (mask.test(value)) {
                            setFieldError(name, '');
                            setFieldValue(name, value || ''); // Default to empty string if null
                        } else {
                            setFieldError(name, errorMsg);
                        }
                    };

                    return (
                        <StyledForm>
                            <TextField
                                required
                                fullWidth
                                id='total_number_of_users'
                                label='Total number of users'
                                value={values.total_number_of_users || ''}
                                error={!!errors.total_number_of_users}
                                helperText={errors.total_number_of_users ? errorMsg : ''}
                                onChange={e =>
                                    handleChangeWithMask(
                                        inputNumberOrRangeMask,
                                        'total_number_of_users',
                                        e,
                                    )
                                }
                                onBlur={handleBlur}
                            />

                            <TextField
                                required
                                fullWidth
                                id='number_of_employee_users'
                                label='Number of employee users'
                                value={values.number_of_employee_users}
                                error={!!errors.number_of_employee_users}
                                helperText={errors.number_of_employee_users ? errorMsg : ''}
                                onChange={e =>
                                    handleChangeWithMask(
                                        inputNumberOrRangeMask,
                                        'number_of_employee_users',
                                        e,
                                    )
                                }
                                onBlur={handleBlur}
                            />

                            <TextField
                                required
                                fullWidth
                                id='number_of_contractor_users'
                                label='Number of contractor users'
                                value={values.number_of_contractor_users}
                                error={!!errors.number_of_contractor_users}
                                helperText={errors.number_of_contractor_users ? errorMsg : ''}
                                onChange={e =>
                                    handleChangeWithMask(
                                        inputNumberOrRangeMask,
                                        'number_of_contractor_users',
                                        e,
                                    )
                                }
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                id='active_directory_groups'
                                label='Active Directory Groups'
                                value={values.active_directory_groups}
                                error={!!errors.active_directory_groups}
                                helperText={errors.active_directory_groups}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                multiline
                                fullWidth
                                id='users_notes'
                                label='Notes'
                                value={values.users_notes}
                                error={!!errors.users_notes}
                                helperText={errors.users_notes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { EditUsersModal };
