import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Button } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { useCapabilityLevels } from '@modules/custom-components/hooks';
import {
    CapabilityLevelCreateModal,
    CapabilityLevelEditModal,
    CapabilityLevelGapList,
} from '@modules/custom-components/organisms';
import {
    GetCapabilityLevelsQuery,
    DeleteCapabilityLevelMutation,
} from '@modules/custom-components/graphql';
import { CapabilityLevelListItem } from './capability-level-list-item';

import type { CapabilityEntity } from '@modules/capabilities/entities';
import type {
    DeleteCapabilityLevelMutationType,
    DeleteCapabilityLevelMutationVariables,
} from '@modules/types/graphql';

type CapabilityLevelListProps = {
    capability: CapabilityEntity;
    capabilityId: number | null;
    withoutDetails?: boolean;
    className?: string;
};

const Root = styled.div<{ hideBorder?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-top: 16px;
    border-top: ${p => (p.hideBorder ? 'none' : `1px solid ${gray[50]}`)};
`;

const Toolbar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;

    > * {
        width: calc(20% - 4px);
        margin-bottom: 24px;
    }
`;

const CapabilityLevelList = (props: CapabilityLevelListProps): React.ReactElement => {
    const { capability, capabilityId, withoutDetails, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { currentUser } = useCurrentUser();
    const isAdmin = currentUser.hasSuperAdminPermission();
    const {
        capabilityLevels,
        count: capabilityLevelsCount,
        loading: capabilityLevelsLoading,
    } = useCapabilityLevels({
        skip: !capabilityId,
        variables: { capabilityId },
    });
    const [deleteCapabilityLevel, { loading: deleteCapabilityLevelLoading }] = useMutation<
        DeleteCapabilityLevelMutationType,
        DeleteCapabilityLevelMutationVariables
    >(DeleteCapabilityLevelMutation);
    const handleDeleteCapabilityLevel = async (capabilityLevelId: number): Promise<void> => {
        try {
            const { data: deleteCapabilityLevelData } = await deleteCapabilityLevel({
                variables: { capabilityLevelId },
                refetchQueries: [{ query: GetCapabilityLevelsQuery, variables: { capabilityId } }],
                awaitRefetchQueries: true,
            });

            if (deleteCapabilityLevelData?.deleteCapabilityLevel) {
                enqueueSuccess('Capability level successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the capability level!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [
        openCreateCapabilityLevelModal,
        setOpenCreateCapabilityLevelModal,
    ] = React.useState<boolean>(false);

    const handleOpenCreateCapabilityLevelModal = (): void =>
        setOpenCreateCapabilityLevelModal(true);

    const handleCloseCreateCapabilityLevelModal = (): void =>
        setOpenCreateCapabilityLevelModal(false);

    const [editableCapabilityLevelId, setEditableCapabilityLevelId] = React.useState<number | null>(
        null,
    );

    const handleSetEditableCapabilityLevelId = (id: number): void =>
        setEditableCapabilityLevelId(id);

    const handleResetEditableCapabilityLevelId = (): void => setEditableCapabilityLevelId(null);

    const [choosenCapabilityLevelId, setChoosenCapabilityLevelId] = React.useState<number | null>(
        null,
    );

    const handleToggleChoosenCapabilityLevelId = (id: number) =>
        setChoosenCapabilityLevelId(state => (state === id ? null : id));

    if (capabilityLevelsLoading) {
        return (
            <Root hideBorder={withoutDetails} {...otherProps}>
                <Loader />
            </Root>
        );
    }

    const cadAddCapabilityLevel = capability.isPredefined() ? isAdmin : true;

    return (
        <Root hideBorder={withoutDetails} {...otherProps}>
            {/* {cadAddCapabilityLevel ? (
                <Toolbar>
                    <Button size='small' onClick={handleOpenCreateCapabilityLevelModal}>
                        Add capability level
                    </Button>
                </Toolbar>
            ) : null} */}

            {capabilityLevelsCount === 0 ? null : (
                <List>
                    {capabilityLevels.map(capabilityLevel => (
                        <CapabilityLevelListItem
                            key={capabilityLevel.id}
                            capabilityLevel={capabilityLevel}
                            choosen={choosenCapabilityLevelId === capabilityLevel.id}
                            deleteLoading={deleteCapabilityLevelLoading}
                            withoutDetails={withoutDetails}
                            onClickDetails={handleToggleChoosenCapabilityLevelId}
                            onClickEdit={handleSetEditableCapabilityLevelId}
                            onDelete={handleDeleteCapabilityLevel}
                        />
                    ))}
                </List>
            )}

            {capabilityId && openCreateCapabilityLevelModal ? (
                <CapabilityLevelCreateModal
                    capabilityId={capabilityId}
                    open={openCreateCapabilityLevelModal}
                    onClose={handleCloseCreateCapabilityLevelModal}
                />
            ) : null}

            {capabilityId && editableCapabilityLevelId ? (
                <CapabilityLevelEditModal
                    open
                    capabilityId={capabilityId}
                    capabilityLevelId={editableCapabilityLevelId}
                    onClose={handleResetEditableCapabilityLevelId}
                />
            ) : null}

            {choosenCapabilityLevelId ? (
                <CapabilityLevelGapList capabilityLevelId={choosenCapabilityLevelId} />
            ) : null}
        </Root>
    );
};

export { CapabilityLevelList };
