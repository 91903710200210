import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { useCapability } from '@modules/custom-components/hooks';
import { CapabilityUpdateRequest } from '@modules/custom-components/requests';
import { CapabilityForm } from '@modules/custom-components/organisms';
import { UpdateCapabilityMutation } from '@modules/custom-components/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { CapabilityUpdateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateCapabilityMutationType,
    UpdateCapabilityMutationVariables,
} from '@modules/types/graphql';

type CapabilityFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capabilityId: number | any;
    componentId: number | any;
};

const FormWrap = styled.div``;

const CapabilityEditFormModal = (props: CapabilityFormModalProps) => {
    const { capabilityId, componentId, onClose, ...otherProps } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { capability, loading: capabilityLoading } = useCapability({
        skip: !capabilityId,
        variables: { capabilityId },
    });

    const [updateCapability] = useMutation<
        UpdateCapabilityMutationType,
        UpdateCapabilityMutationVariables
    >(UpdateCapabilityMutation);

    const initialValues = {
        componentId,
        capabilityId: capability.id,
        title: capability.title,
        impact: capability.impact,
        keyFactors: capability.keyFactors,
        securityControls: capability.securityControls,
    };

    const handleSubmit = async (values: CapabilityUpdateFormValues) => {
        try {
            const variables = new CapabilityUpdateRequest(values);

            const { data: updateCapabilityData } = await updateCapability({
                variables,
            });

            if (updateCapabilityData?.updateCapability?.id) {
                enqueueSuccess('Capability successfully updated!');
                onClose();
            } else {
                enqueueError('An error occurred while updating capability!');
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <Modal title='Update Capability' onClose={onClose} {...otherProps}>
            {capabilityLoading ? (
                <Loader />
            ) : (
                <CapabilityForm<'edit'>
                    type='edit'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                    onCancel={onClose}
                />
            )}
        </Modal>
    );
};

export { CapabilityEditFormModal };
