import { gql } from '@apollo/client';

export const CreateItemCategoryTypeMutation = gql`
    mutation CreateItemCategoryType($name: String!, $categoryId: Int!) {
        createItemCategoryType(
            name: $name
            category_id: $categoryId
        ) {
            id
            name
            category_id
        }
    }
`;
