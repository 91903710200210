import { gql } from '@apollo/client';

export const GetComponentOwnerBySurveyIdQuery = gql`
    query GetComponentOwnerBySurveyId($surveyId: Int!) {
        getComponentOwnerBySurveyId(survey_id: $surveyId) {
            id
            email
            username
            first_name
            last_name
            is_admin
            is_super_admin
            company_id
        }
    }
`;
