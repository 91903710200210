import { Amplify } from 'aws-amplify';
import {
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';

class AuthManagerClass {
    getStorage() {
        const storage = Amplify.Auth._storage;

        return storage;
    }

    getCurrentUserPool() {
        const userPool = Amplify.Auth.userPool;

        return userPool;
    }

    getCurrentUsername() {
        const storage = this.getStorage();
        const userPool = this.getCurrentUserPool();

        const lastUserKey = `CognitoIdentityServiceProvider.${userPool.clientId}.LastAuthUser`;
        const lastUserValue = storage.getItem(lastUserKey);

        if (!lastUserValue) {
            return null;
        }

        return lastUserValue;
    }

    getNativeCurrentSession() {
        const storage = this.getStorage();
        const userPool = this.getCurrentUserPool();
        const username = this.getCurrentUsername();

        if (!username) {
            return null;
        }

        const keyPrefix = `CognitoIdentityServiceProvider.${userPool.clientId}.${username}`;

        const idTokenKey = `${keyPrefix}.idToken`;
        const accessTokenKey = `${keyPrefix}.accessToken`;
        const refreshTokenKey = `${keyPrefix}.refreshToken`;
        const clockDriftKey = `${keyPrefix}.clockDrift`;

        if (storage.getItem(idTokenKey)) {
            const idToken = new CognitoIdToken({ IdToken: storage.getItem(idTokenKey) });

            const accessToken = new CognitoAccessToken({
                AccessToken: storage.getItem(accessTokenKey),
            });

            const refreshToken = new CognitoRefreshToken({
                RefreshToken: storage.getItem(refreshTokenKey),
            });

            const clockDrift = parseInt(storage.getItem(clockDriftKey), 0) || 0;

            const sessionData = {
                IdToken: idToken,
                AccessToken: accessToken,
                RefreshToken: refreshToken,
                ClockDrift: clockDrift,
            };

            const cachedSession = new CognitoUserSession(sessionData);

            if (cachedSession.isValid()) {
                return cachedSession;
            }
        }

        return null;
    }

    destroySession() {
        const storage = this.getStorage();
        const userPool = this.getCurrentUserPool();
        const username = this.getCurrentUsername();

        const keyPrefix = `CognitoIdentityServiceProvider.${userPool.clientId}.${username}`;

        const idTokenKey = `${keyPrefix}.idToken`;
        const accessTokenKey = `${keyPrefix}.accessToken`;
        const refreshTokenKey = `${keyPrefix}.refreshToken`;
        const clockDriftKey = `${keyPrefix}.clockDrift`;

        storage.removeItem(idTokenKey);
        storage.removeItem(accessTokenKey);
        storage.removeItem(refreshTokenKey);
        storage.removeItem(clockDriftKey);
    }
}

const AuthManager = new AuthManagerClass();

export { AuthManager };
