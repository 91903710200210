import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Button, Modal, TextField } from '@modules/ui/core';
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    MenuItem,
} from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { gray } from '@modules/ui/colors';
import { useMutation } from '@apollo/client';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AddAssetActivity } from '@modules/registry/graphql';
import { weights } from '@modules/components/constants';
import {
    getRiskLevelLabelByScore,
    getScoreByLevelLabel,
    ratingNum,
    RiskLevel10Point,
    RiskLevel10PointKeyList,
} from '@modules/shared/constants/risk-level';
import { Asset } from '@modules/types/graphql';
import { RiskProgramTypeMap } from '@modules/shared/constants/risk-assessment-type';

const StyledModal = styled(Modal)`
    width: 1400px;

    .is-empty-results {
        font-size: 14px;
        text-align: center;

        td {
            padding: 20px;
        }
    }
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }

    .is-radio-line {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 10px;

        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 1 100%;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const defaultInitialValues = {
    risk_level_id: 'Medium',
    activity_description: '',
    // unwantedEvents: '',
    notes: '',
    weight: 1,
};

const ViewActivityModal = (
    props: ModalProps & { activeAsset: Asset; activityType?: string },
): React.ReactElement => {
    const [initialValues, setInitialValues] = useState(defaultInitialValues);
    const { onClose, activeAsset, activityType, refetchQuery, ...otherProps } = props;
    const [isUpdate, setIsUpdate] = useState<null | string>(null);
    const [addAssetActivity] = useMutation(AddAssetActivity);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const activities = useMemo(() => {
        if (!activeAsset || !activityType) {
            return [];
        }

        if (typeof activeAsset[`${activityType}Activities`] === 'undefined') {
            return [];
        }

        return activeAsset[`${activityType}Activities`];
    }, [activeAsset, activityType, isUpdate]);

    useEffect(() => {
        if (!activities.length) {
            return;
        }

        const lastScore = activities[activities.length - 1]?.score;
        setInitialValues({
            ...defaultInitialValues,
            risk_level_id: getRiskLevelLabelByScore(lastScore, activityType),
        });
    }, [activities, isUpdate]);
    return (
        <StyledModal
            title={
                activityType === 'impact'
                    ? 'Impact Value'
                    : activityType === 'vulnerability'
                    ? 'Vulnerability Activity'
                    : activityType === 'threat'
                    ? 'Threat Activity'
                    : ''
            }
            onClose={onClose}
            {...otherProps}
        >
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const { risk_level_id, activity_description, notes, weight } = values;
                    const variables = {
                        input: {
                            asset_id: activeAsset?.id,
                            activity_type: RiskProgramTypeMap[activityType as string],
                            score: getScoreByLevelLabel(risk_level_id, activityType),
                            weight,
                            // source,
                            activity_description,
                            notes,
                        },
                    };

                    try {
                        const { data: addActivitytData } = await addAssetActivity({
                            variables,
                            refetchQueries: [{ query: refetchQuery }],
                            awaitRefetchQueries: true,
                        });

                        const activity = addActivitytData?.addAssetActivity;
                        setIsUpdate(activity);

                        if (!activity?.id) {
                            onClose();
                            throw new Error('An error occurred while adding the activity!');
                        }
                        enqueueSuccess(
                            `${
                                activityType === 'impact' ? 'Value' : 'Activity'
                            } was successfully updated!`,
                        );
                        onClose();
                    } catch (e) {
                        throw e;
                    }
                }}
            >
                {formikProps => {
                    const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;
                    return (
                        <StyledForm>
                            <FormLabel>
                                {activityType === 'impact'
                                    ? 'Dollar Value'
                                    : activityType === 'vulnerability'
                                    ? 'Vulnerability Level'
                                    : activityType === 'threat'
                                    ? 'Threat Level'
                                    : ''}
                            </FormLabel>
                            <RadioGroup
                                name='risk_level_id'
                                value={values.risk_level_id}
                                onChange={handleChange}
                                className='is-radio-line'
                            >
                                {RiskLevel10PointKeyList.map((key: string, index: number) => {
                                    let label = index % 1 === 0 ? RiskLevel10Point[key] : '';

                                    return (
                                        <FormControlLabel
                                            key={index}
                                            value={RiskLevel10Point[key]}
                                            control={<Radio />}
                                            label={label}
                                        />
                                    );
                                })}
                            </RadioGroup>

                            <TextField
                                select
                                fullWidth={true}
                                id='weight'
                                name='weight'
                                label='Weight'
                                value={values.weight}
                                error={!!errors.weight}
                                helperText={errors.weight}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {weights.map(weight => (
                                    <MenuItem key={weight} value={weight}>
                                        {weight}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                multiline
                                fullWidth
                                id='activity_description'
                                label={activityType === 'impact' ? 'Evidence' : 'Activity'}
                                value={values.activity_description}
                                error={!!errors.activity_description}
                                helperText={errors.activity_description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                multiline
                                fullWidth
                                id='notes'
                                label={
                                    activityType === 'impact'
                                        ? 'Assumptions'
                                        : 'Notes/Impact History'
                                }
                                value={values.notes}
                                error={!!errors.notes}
                                helperText={errors.notes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Update
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>

            <Typography variant='h4' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
                {activityType === 'impact' ? 'Value' : 'Activity'} history
            </Typography>
            <Table>
                <TableHead>
                    {activityType === 'impact' ? (
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Evidence</TableCell>
                            <TableCell>Assumptions</TableCell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>
                                {activityType === 'vulnerability' ? 'Vulnerability' : 'Threat'}{' '}
                                Activity
                            </TableCell>
                            <TableCell>Notes</TableCell>
                        </TableRow>
                    )}
                </TableHead>
                <TableBody>
                    {activities?.length ? (
                        [...activities]
                            ?.sort(
                                (a: any, b: any) =>
                                    new Date(b.created_at).getTime() -
                                    new Date(a.created_at).getTime(),
                            )
                            ?.map((activitiy: any, i: number) => {
                                const activitiyCreatedAt = activitiy?.created_at;
                                const calcStore = ratingNum(activitiy?.score, activityType);
                                return (
                                    <React.Fragment key={i}>
                                        {activityType === 'impact' ? (
                                            <TableRow>
                                                <TableCell>
                                                    {activitiyCreatedAt
                                                        ? format(
                                                              new Date(activitiyCreatedAt),
                                                              'yyyy-MM-dd',
                                                          )
                                                        : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {activitiy?.created_by?.first_name}{' '}
                                                    {activitiy?.created_by?.last_name}
                                                </TableCell>
                                                <TableCell>
                                                    {getRiskLevelLabelByScore(
                                                        activitiy?.score,
                                                        activityType,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {activitiy?.activity_description}
                                                </TableCell>
                                                <TableCell>{activitiy?.notes}</TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <TableCell>
                                                    {activitiyCreatedAt
                                                        ? format(
                                                              new Date(activitiyCreatedAt),
                                                              'yyyy-MM-dd',
                                                          )
                                                        : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {activitiy?.created_by?.first_name}{' '}
                                                    {activitiy?.created_by?.last_name}
                                                </TableCell>
                                                <TableCell>
                                                    {getRiskLevelLabelByScore(
                                                        activitiy?.score,
                                                        activityType,
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {activitiy?.activity_description}
                                                </TableCell>
                                                <TableCell>{activitiy?.notes}</TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <TableRow className='is-empty-results'>
                            <TableCell colSpan={7}>No history</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </StyledModal>
    );
};

export { ViewActivityModal };
