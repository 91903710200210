import { gql } from '@apollo/client';
import { RGenerationCountFragment } from '../fragments';

export const GetListRecommendationGenerationCountQuery = gql`
    query listRecommendationGenerationCount {
        listRecommendationGenerationCount {
            ...RGenerationCount
        }
    }
    ${RGenerationCountFragment}
`;
