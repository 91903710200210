import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, ImplementationStatus } from '@modules/types/graphql';

export type Entity = Maybe<Partial<ImplementationStatus>>;

@DescribeEntity('ImplementationStatusEntity')
class ImplementationStatusEntity extends BaseEntity {
    rowNumber: number;
    id: string;
    name: string;

    constructor(status: Entity) {
        super(status);

        this.rowNumber = status?.row_number ?? -1;
        this.id = status?.status ?? '';
        this.name = status?.status ?? '';
    }
}

export { ImplementationStatusEntity };
