import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';

import { GapEntity } from '@modules/custom-components/entities';
import { RoadmapRecommendationEntityList } from './roadmap-recommendation-entity-list';

import type { ScoredGap } from '@modules/types/graphql';
import type { RoadmapRecommendationEntity } from './roadmap-recommendation-entity';

export type Entity = Partial<
    Omit<ScoredGap, 'gap' | 'roadmap_recommendations'> & {
        gap: GapEntity;
    }
> | null;

@DescribeEntity('ScoredGapEntity')
class ScoredGapEntity extends BaseEntity {
    id: number;
    score: number;
    riskLevel: string;

    @Relation(() => GapEntity)
    gap: GapEntity;

    @RelationList(() => RoadmapRecommendationEntityList)
    roadmapRecommendations: (RoadmapRecommendationEntity | null)[];

    constructor(scoredGap: Entity) {
        super(scoredGap);

        this.id = scoredGap?.id ?? -1;
        this.score = scoredGap?.score ?? 0;
        this.riskLevel = scoredGap?.risk_level ?? '';
    }
}

export { ScoredGapEntity };
