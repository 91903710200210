import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from '@reach/router';
import { useMutation } from '@apollo/client';
import { withStyles, FormHelperText, CircularProgress } from '@material-ui/core';

import { Button, TextField } from '@modules/ui/core';
import { white, gray } from '@modules/ui/colors';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ListStyled } from '@modules/layout/styled';
import { useProjectRoles } from '@modules/projects/hooks';
import { ProjectUpdateModal } from '@modules/projects/organisms';
import { GetProjectQuery, StartProjectMutation } from '@modules/projects/graphql';
import { CustomComponentCreateModal } from '@modules/custom-components/moleculas';
import { AssessmentComponentFormModal } from '@modules/assessment/organisms';
import { ApproveAssessmentMutation } from '@modules/assessment/graphql';
import { AssessmentComponentsListItem } from './assessment-components-list-item';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import type {
    StartProjectMutationType,
    StartProjectMutationVariables,
    ApproveAssessmentMutationType,
    ApproveAssessmentMutationVariables,
} from '@modules/types/graphql';
import { UserEntity } from '@modules/users/entities';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import {
    assessmentCollectCO,
    assessmentFilterHandler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { usePropState } from '@modules/shared/hooks/use-prop-state';

import { useCurrentUser } from '@modules/auth/hooks';
import { useOrganizationDetails, useProjectDetailsQuery } from '@modules/components/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useCompanyAccess, useTkoScope } from '@modules/company/hooks';

import {
    RecommendationCreateFormValues,
    RecommendationCreateRequest,
} from '@modules/custom-components/requests';
import type {
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
} from '@modules/types/graphql';
import {
    CreateRecommendationMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';
import { ChatGptInstance } from '@helpers';

type AssessmentComponentListProps = {
    isAIAccess?: boolean;
    project: ProjectEntity;
    componentsAssessmentList: ComponentAssessmentEntity[];
};

const StyledFormHelperText = withStyles({
    root: {
        width: '100%',
        marginTop: 8,
        fontSize: '1.4rem',
    },
})(FormHelperText);

const ActionButton = withStyles({
    root: {
        padding: '0 20px',
        color: gray[10],
        backgroundColor: white[100],
    },
    label: {
        padding: 0,
    },
})(Button);

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const List = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-bottom: 6px;
    }
`;

const Actions = styled.div`
    width: 100%;
    margin-top: 24px;

    > * {
        margin-right: 16px;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 24px;
`;

const Filters = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: auto;

    > * {
        width: 250px;

        &:not(:last-of-type) {
            margin-right: 32px;
        }
    }
`;

const AssessmentComponentsList = (props: AssessmentComponentListProps): React.ReactElement => {
    const { project, isAIAccess, componentsAssessmentList: componentsAssessmentRawList } = props;

    const navigate = useNavigate();
    const projectId = project.id;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { projectRolesEntityList, loading: projectRolesEntityListLoading } = useProjectRoles({
        variables: { projectId },
    });

    const hasPmRole = projectRolesEntityList.hasProgramManager();
    const hasOpRole = projectRolesEntityList.hasOperator();
    const hasStRole = projectRolesEntityList.hasStakeholder();

    const isGapRiskLevelAvailable = componentsAssessmentRawList.some(assessment => {
        return assessment.capabilities.some(capability => {
            return (
                capability?.scoredGaps?.length &&
                capability?.scoredGaps.some(scogedGap => scogedGap?.riskLevel)
            );
        });
    });
    const componentOwnerList: UserEntity[] = Array.from(
        assessmentCollectCO(componentsAssessmentRawList).values(),
    );

    // const componentList: ComponentEntity[] = componentsAssessmentRawList.reduce((result, assessmnent) => {
    //     result.push(assessmnent.component);
    //     return result;
    // }, [] as ComponentEntity[])

    const [componentsAssessmentList, setComponentsAssessmentList] = usePropState(
        componentsAssessmentRawList,
    );
    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        assessmentFilterHandler(
            setComponentsAssessmentList,
            componentsAssessmentRawList,
            filterValue,
        );
    }

    const componentsAssessmentCount = componentsAssessmentList.length;
    const hasComponents = componentsAssessmentCount !== 0;

    const isAllComponentsWithCapabilities = componentsAssessmentList.every(componentAssessment =>
        componentAssessment.hasCapabilities(),
    );

    const isAllCapabilitiesWithLevels = componentsAssessmentList.every(componentAssessment => {
        const withCapabilityLevels = componentAssessment.capabilities.filter(capability =>
            capability.hasCapabilityLevels(),
        );

        return withCapabilityLevels.length === componentAssessment.capabilities.length;
    });

    const isAllComponentsCapabilitiesAssessed = componentsAssessmentList.every(
        componentAssessment => componentAssessment.isAllCapabilitiesAssessed(),
    );

    const isAllAssessmentsApproved =
        componentsAssessmentList.filter(componentAssessment => componentAssessment.isApproved())
            .length === componentsAssessmentCount;

    const [startProject, { loading: startProjectLoading }] = useMutation<
        StartProjectMutationType,
        StartProjectMutationVariables
    >(StartProjectMutation);

    const [approveAssessment, { loading: approveAssessmentLoading }] = useMutation<
        ApproveAssessmentMutationType,
        ApproveAssessmentMutationVariables
    >(ApproveAssessmentMutation);

    const [openEditProjectFormModal, setOpenEditProjectFormModal] = React.useState<boolean>(false);

    const handleOpenEditProjectFormModal = (): void => setOpenEditProjectFormModal(true);
    const handleCloseEditProjectFormModal = (): void => setOpenEditProjectFormModal(false);

    const [openComponentFormModal, setOpenComponentFormModal] = React.useState<boolean>(false);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<number | boolean>(false);
    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);

    const handleOpenComponentFormModal = (): void => setOpenComponentFormModal(true);
    const handleCloseComponentFormModal = (): void => setOpenComponentFormModal(false);

    const [
        openCustomComponentCreateModal,
        setOpenCustomComponentCreateModal,
    ] = React.useState<boolean>(false);

    const handleOpenCustomComponentCreateModal = (): void =>
        setOpenCustomComponentCreateModal(true);

    const handleCloseCustomComponentCreateModal = (): void =>
        setOpenCustomComponentCreateModal(false);

    const handleSubmitStartProject = async (): Promise<void> => {
        const { data: startProjectData } = await startProject({
            variables: { projectId },
            refetchQueries: [{ query: GetProjectQuery, variables: { id: projectId } }],
            awaitRefetchQueries: true,
        });

        if (startProjectData?.startProject?.id !== 0) {
            enqueueSuccess('Assessment was successfully started!');
        } else {
            enqueueError('An error occurred while started the assessment!');
        }
    };

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const { projectDetails, loading: projectDetailLoading } = useProjectDetailsQuery({
        skip: !projectId,
        variables: { id: projectId as number },
    });
    //Get Assessment Type Name
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const filteredAssessmentTypes = assessmentTypes.filter(
        type => type.id === projectDetails.assessmentTypeId,
    );
    const assessmentTypeNames = filteredAssessmentTypes.map(type => type.name);
    const assessmentTypeName = assessmentTypeNames[0];
    //Get Tko Scope Name
    const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();
    const filteredTkoscopes = tkoscopes.filter(type => type.id === projectDetails.tkoScopeId);
    const tkoScopeNames = filteredTkoscopes.map(type => type.name);
    const tkoScopeName = tkoScopeNames[0];
    const { companyAccess, loading: isCompanyLoading } = useCompanyAccess();
    // async function saveRecommendationWithChatGPT(chatGptinputData: string, gapId: number) {
    //     const chatGptResponse = async (prompt: string) => {
    //         let newData = new Array(3);
    //         const completion: any = await ChatGptInstance.createChatCompletion({
    //             model: 'gpt-3.5-turbo',
    //             messages: [
    //                 { role: 'system', content: 'You: ' + prompt },
    //                 { role: 'assistant', content: 'Assistant: ' },
    //             ],
    //             temperature: 0.7,
    //             max_tokens: 3097,
    //         });
    //         const response = completion?.data?.choices[0]?.message?.content;

    //         newData = response.split('\n\n');
    //         newData = newData.filter(value => {
    //             const number = parseInt(value.split('.')[0]);
    //             return number;
    //         });
    //         return newData;
    //     };

    //     const numericValues = await chatGptResponse(chatGptinputData);
    //     const companyId = project.companyId;
    //     numericValues?.forEach(async (items: any) => {
    //         const item = items && items?.replace(/^\d+\.\s*/, '');
    //         const recommendationInitialValues = {
    //             recommendation: item,
    //         };
    //         try {
    //             const variables = new RecommendationCreateRequest(recommendationInitialValues, {
    //                 gapId,
    //                 companyId,
    //             });
    //             const { data: createRecommendationData } = await createRecommendation({
    //                 variables,
    //                 refetchQueries: [{ query: GetRecommendationsQuery, variables: { gapId } }],
    //                 awaitRefetchQueries: true,
    //             });
    //         } catch (e) {
    //             throw e;
    //         }
    //     });
    // }

    const handleSubmitApproveAssessment = async (): Promise<void> => {
        // setShowLoader(true);
        // ChatGPT Query code here
        // let queryStart =
        //     'Take on the role of a risk management consultant hired by a company to provide expert advice. The company is a ';
        // let employees = 'employees whose workforce is primarily';
        // let beforeGap = 'assessment of the company and have identified the following gap: ';
        // let recommendationDetails =
        //     'recommendations the company can implement to improve this gap? The company has the following controls in place: ';
        // let afterRecomandation =
        //     'Please cite all privacy and data protection regulations (GDPR, DSS PCI, SOX, etc.) and security standards (NIST, ISO, etc.) that apply to';
        // let queryEnd =
        //     'companies. Please provide specific sections of each relevant regulatory and security standard that apply to each recommendation and provide your recommendations in a numbered sequence.';
        // let capabilities = componentsAssessmentList.map(itam =>
        //     itam.capabilities.map(ele => ele.scoredGaps.map(el => el?.gap)),
        // );
        // let newData = capabilities.map(ele => ele.map(el => el.map(elt => elt)));
        // for (let i = 0; i < newData.length; i++) {
        //     for (let j = 0; j < newData[i].length; j++) {
        //         for (let k = 0; k < newData[i][j].length; k++) {
        //             let gapId: number = newData[i][j][k]?.id!;
        //             let chatGPTQuery = `${queryStart} ${organizationDetails.organizationType} located in  ${organizationDetails?.orgLocation} in the ${organizationDetails?.sector} sector with ${organizationDetails.noOfEmployees} ${employees} ${organizationDetails.workforce}. You have just completed a ${assessmentTypeName} ${beforeGap} ${newData[i][j][k]?.gap} What are the ${tkoScopeName} ${recommendationDetails} MEASURES. The company wants to: RECOMMENDATIONS ${afterRecomandation} ${organizationDetails?.sector} ${queryEnd}`;
        //             await saveRecommendationWithChatGPT(chatGPTQuery, gapId);
        //         }
        //     }
        // }

        const { data: approveAssessmentData } = await approveAssessment({
            variables: { projectId },
            refetchQueries: [{ query: GetProjectQuery, variables: { id: projectId } }],
            awaitRefetchQueries: true,
        });

        if (approveAssessmentData?.approveAssessment?.id) {
            setShowLoader(false);
            enqueueSuccess('Assessment successfully approved!');
            if (project.isRiskRegistry && project.assessmentTypeId === 1) {
            } else {
                navigate(createRouteUrl(routes.projectRoadmap, { id: projectId }));
            }
        } else {
            setShowLoader(false);
            enqueueError('An error occurred while approving the assessment!');
        }
    };

    const handleExpandComponent = (panel: number) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean,
    ) => setExpanded(isExpanded ? panel : false);

    const handleRedirectToProjects = (): void => {
        navigate(routes.projects.path);
    };

    const renderActions = () => {
        if (projectRolesEntityListLoading || (!hasPmRole && hasStRole)) {
            return null;
        }

        if (!hasPmRole && !hasOpRole) {
            return (
                <Footer>
                    <Button
                        size='small'
                        disabled={!isAllComponentsCapabilitiesAssessed}
                        onClick={handleRedirectToProjects}
                    >
                        Complete
                    </Button>
                </Footer>
            );
        }

        if (project.isNew()) {
            const disabledStart =
                !hasComponents || !isAllComponentsWithCapabilities || !isAllCapabilitiesWithLevels;

            return (
                <>
                    <Actions>
                        <ActionButton
                            size='small'
                            variant='outlined'
                            onClick={handleOpenEditProjectFormModal}
                        >
                            Update project
                        </ActionButton>

                        <ActionButton
                            size='small'
                            variant='outlined'
                            onClick={handleOpenComponentFormModal}
                        >
                            Add Component
                        </ActionButton>

                        <ActionButton
                            size='small'
                            variant='outlined'
                            onClick={handleOpenCustomComponentCreateModal}
                        >
                            Create Component
                        </ActionButton>
                    </Actions>

                    <Footer>
                        <Button
                            size='small'
                            loading={startProjectLoading}
                            disabled={companyAccess?.isFreeze ? true : disabledStart}
                            onClick={handleSubmitStartProject}
                        >
                            Start assessment
                        </Button>

                        {!isAllCapabilitiesWithLevels ? (
                            <StyledFormHelperText error>
                                You need to setup capability levels for each capability
                            </StyledFormHelperText>
                        ) : null}
                    </Footer>

                    <ProjectUpdateModal
                        projectId={project?.id}
                        open={openEditProjectFormModal}
                        onClose={handleCloseEditProjectFormModal}
                    />

                    <AssessmentComponentFormModal
                        type='add'
                        project={project}
                        open={openComponentFormModal}
                        onClose={handleCloseComponentFormModal}
                    />

                    <CustomComponentCreateModal
                        projectId={project.id}
                        assessmentId={project.assessmentTypeId}
                        companyId={project.companyId}
                        open={openCustomComponentCreateModal}
                        onClose={handleCloseCustomComponentCreateModal}
                    />
                </>
            );
        }

        if (project.isAssessment()) {
            return (
                <Footer>
                    <Button
                        size='small'
                        disabled={
                            companyAccess?.isFreeze ? true : !isAllAssessmentsApproved || showLoader
                        }
                        loading={approveAssessmentLoading}
                        onClick={handleSubmitApproveAssessment}
                        style={
                            showLoader
                                ? { height: '50px', width: '200px', backgroundColor: '#0057ff' }
                                : {}
                        }
                    >
                        {showLoader ? (
                            <CircularProgress style={{ color: 'white' }} />
                        ) : (
                            'Approve assessment'
                        )}
                    </Button>
                </Footer>
            );
        }
    };

    return (
        <Root>
            <ListStyled.Header>
                <h2>Assessment</h2>

                {project.stakeholder.exists() ? (
                    <ListStyled.HeaderInfo>
                        <p>Stakeholder:</p>
                        <p>{project.stakeholder.getFullName()}</p>
                    </ListStyled.HeaderInfo>
                ) : null}
            </ListStyled.Header>

            <ProjectAssessmentComponentsFilter
                componentOwnerList={componentOwnerList}
                // componentList={componentList}
                project={project}
                hasRiskLevelFilter={isGapRiskLevelAvailable}
                onFilterChange={onFilterChange}
            />

            {componentsAssessmentCount !== 0 ? (
                <>
                    <List>
                        {componentsAssessmentList.map(componentAssessment => {
                            const isOpen = expanded === componentAssessment.id;

                            return (
                                <AssessmentComponentsListItem
                                    key={componentAssessment.id}
                                    project={project}
                                    componentAssessment={componentAssessment}
                                    open={isOpen}
                                    onExpand={handleExpandComponent}
                                />
                            );
                        })}
                    </List>
                </>
            ) : null}

            {renderActions()}
        </Root>
    );
};

export { AssessmentComponentsList };
