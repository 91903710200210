import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Packages } from '@modules/types/graphql';

export type PackagesEntity = Partial<Packages> | null;

@DescribeEntity('PackagesTypeEntity')
class PackagesTypeEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(packages: PackagesEntity) {
        super(packages);

        this.id = packages?.id ?? -1;
        this.name = packages?.name ?? '';
    }
}

export { PackagesTypeEntity };
