import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, Gap } from '@modules/types/graphql';

export type Entity = Maybe<Partial<Gap>>;

@DescribeEntity('GapEntity')
class GapEntity extends BaseEntity {
    id: number;
    gap: string;
    capabilityLevelId: number;
    riskLevel: string;
    score: number;
    companyId: number | null;

    constructor(gap: Entity) {
        super(gap);

        this.id = gap?.id ?? -1;
        this.gap = gap?.gap ?? '';
        this.capabilityLevelId = gap?.capability_level_id ?? -1;
        this.riskLevel = gap?.risk_level ?? '';
        this.score = gap?.score ?? 0;
        this.companyId = gap?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { GapEntity };
