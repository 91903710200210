import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { AccordionInnerListItem, TableColumn } from '@modules/layout/organisms';
import { scoreVariantColorsMap } from '@modules/shared/constants';
import { gray } from '@modules/ui/colors';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { RecommendationAssessmentRoadmapReport } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { ProjectEntity } from '@modules/projects/entities';
import { ReportPrioritizedRoadmapListItem } from './report-prioritized-roadmap-list-item';
import { ListStyled } from '@modules/layout/styled';

type ReportPrioritizedRoadmapListSummaryProps = {
    recommendations: RecommendationAssessmentRoadmapReport[];
    project: ProjectEntity;
    withStatus?: boolean;
    riskLevelKey: string;
    label: string;
    open: boolean;
    onExpand?: (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => void;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    background-color: ${gray[60]};
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > * {
        width: 20%;
        font-size: 1.4rem;
    }
`;

const InnerHeaderExtra = styled.div`
    > h3 {
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const ItemTitle = styled.div<Pick<ReportPrioritizedRoadmapListSummaryProps, 'riskLevelKey'>>`
    width: 100%;

    > h3 {
        display: inline-flex;
        padding: 4px;
        font-size: 2.2rem;
        font-weight: 700;
        border-radius: 4px;

        ${({ riskLevelKey }) => css`
            color: ${scoreVariantColorsMap[riskLevelKey].color};
            background-color: ${scoreVariantColorsMap[riskLevelKey].background};
        `}
    }
`;

export const ReportPrioritizedRoadmapListSummary = (
    props: ReportPrioritizedRoadmapListSummaryProps,
): React.ReactElement => {
    const { riskLevelKey, label, recommendations, project, withStatus, open, onExpand } = props;
    console.log(recommendations, 'recommendations');
    const [expanded, setExpanded] = useState<boolean>(open);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const handleExpand = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
        if (onExpand) {
            onExpand(riskLevelKey)(event, isExpanded);
        }
    };

    const filterImplementatonStatus = React.useMemo(() => {
        return recommendations.every(rec => rec.roadmapRecommendation.status === "");
    }, [recommendations]);

    const initialAccordionTitle: TableColumn[] = [
        {
            id: 'pending',
            label: 'Pending',
        },
        {
            id: 'inProgress',
            label: 'In progress',
        },
        {
            id: 'done',
            label: 'Done',
        },
    ];

    const [accordionTitle, setAccordionTitle] = useState<TableColumn[]>(initialAccordionTitle);

    useEffect(() => {
        if (filterImplementatonStatus) {
            setAccordionTitle([
                {
                    id: 'pending',
                    label: 'Pending',
                }
            ]);
        } else {
            setAccordionTitle(initialAccordionTitle);
        }
    }, [filterImplementatonStatus]);

    const onExpandRow = (riskLevel: string) => {
        setExpandedRow(expandedRow === riskLevel ? null : riskLevel);
    };
    let colSpan = withStatus ? 5 : 4;
    return (
        <Root>
            <AccordionInnerListItem
                title={''}
                subtitle={''}
                expanded={expanded}
                onChange={handleExpand}
                SummaryInfoComponents={
                    <Header>
                        <InnerHeaderExtra>
                            <ItemTitle riskLevelKey={riskLevelKey}>
                                <h3>{label}</h3>
                            </ItemTitle>
                        </InnerHeaderExtra>
                    </Header>
                }
            >
                {accordionTitle.map((title: any) => {
                    const isPendingSection = title.label === 'Pending';
                    const statusToFilter = isPendingSection ? ['Pending', ''] : title.label;

                    return (
                        <AccordionInnerListItem
                            key={title.id}
                            title={title.label}
                            subtitle={''}
                            expanded={expandedRow === title.id}
                            onChange={() => onExpandRow(title.id)}
                            SummaryInfoComponents={null}
                        >
                            <ListStyled.Container className={'no-top-offset'}>
                                <Droppable droppableId={`${riskLevelKey}-${title.id}`}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            // style={{
                                            //     backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey',
                                            //     ...provided.droppableProps
                                            // }}
                                        >
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Recommendation</TableCell>
                                                        <TableCell>Risk Level</TableCell>
                                                        <TableCell>Component</TableCell>
                                                        <TableCell>Capability</TableCell>
                                                        {withStatus && <TableCell>Status</TableCell>}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {recommendations.filter(recommendation => {
                                                        const status = recommendation.componentRecommendation.recommendation.status;
                                                        return isPendingSection ? (status === '' || status === 'Pending') : status === statusToFilter;
                                                    }).length > 0 ? (
                                                        recommendations.filter(recommendation => {
                                                            const status = recommendation.componentRecommendation.recommendation.status;
                                                            return isPendingSection ? (status === '' || status === 'Pending') : status === statusToFilter;
                                                        }).map((recommendation, i) => (
                                                            <ReportPrioritizedRoadmapListItem
                                                                index={i}
                                                                key={`${recommendation.componentRecommendation.componentId}-${recommendation.componentRecommendation.roadmapRecommendationId}`}
                                                                recommendation={recommendation}
                                                                project={project}
                                                                colSpan={colSpan}
                                                                withStatus={withStatus}
                                                            />
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={colSpan}>
                                                                Recommendations not found, but drag and drop
                                                                available for putting recommendations here from
                                                                another list
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {provided.placeholder}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    )}
                                </Droppable>
                            </ListStyled.Container>
                        </AccordionInnerListItem>
                    );
                })}
            </AccordionInnerListItem>
        </Root>
    );
};
