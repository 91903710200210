import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from '@reach/router';
import { useMutation } from '@apollo/client';
import { routes } from '@config/routes';
import { createRouteUrl } from '@lib/routing';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import {
    useAssignedProjects,
    useProjectsTableFiltersQuery,
} from '@modules/projects/hooks';
import { ProjectListRequest } from '@modules/projects/requests';
import { ProjectDataTable } from '@modules/projects/moleculas';
import { ProjectListToolbar, ProjectUpdateModal } from '@modules/projects/organisms';
import { GetAssignedProjectsQuery, DeleteProjectMutation } from '@modules/projects/graphql';

import type { TableColumn } from '@modules/layout/organisms';
import type { Page } from '@modules/types/page';
import type {
    DeleteProjectMutationType,
    DeleteProjectMutationVariables,
} from '@modules/types/graphql';
import { OrgProfilesFormModal } from '@modules/custom-components/organisms';
import { useOrganizationDetails } from '@modules/components/hooks';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { useCompany, useCompanyAccess } from '@modules/company/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { AuthContext } from '@modules/auth/context';
import { DialogNotValid } from '@modules/custom-components/organisms/org-not-valid-message';

const columns: TableColumn[] = [
    {
        id: 'projectName',
        label: 'Project Name',
    },
    {
        id: 'createdAt',
        label: 'Created Date',
    },
    {
        id: 'rr',
        label: 'Module',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'assessmentType',
        label: 'Assessment Type',
    },
    {
        id: 'maturityLevel',
        label: 'Maturity Level',
    },
    {
        id: 'actions',
        label: '',
    },
];

const ProjectsPage = (props: Page): React.ReactElement => {
    const { title = 'Projects' } = props;
    const navigate = useNavigate();
    const params = useParams();
    let authContext = useContext(AuthContext);
    const { user, loading: userLoading } = useContext(AuthContext) || {};
    const adminRoles = React.useMemo(() => ['risk_admin', 'project_admin', 'company_admin'], []);

    const proId = params.id ? +params.id : null;
    let variablesId: any = {};
    variablesId.id = proId as number;
    let openCreateModal: any = authContext?.showModal;
    const [handleModal, setHandleModal] = React.useState<boolean>(false);
    const [handleNotValidModal, setHandleNotValidModal] = React.useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = React.useState<number | any>(null);
    const [openEditProjectFormModal, setOpenEditProjectFormModal] = React.useState<boolean>(false);
    const handleCloseEditProjectFormModal = () => {
        setOpenEditProjectFormModal(false);
    };
    function handleCloseCreateModal() {
        authContext?.handleCloseProjectModal();
    }
    const [editProjectId, setEditProjectId] = React.useState<number | any>(null);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { filters } = useProjectsTableFiltersQuery();
    const { userHighRolesEntityList, loading: userHighestLoading } = useUserHighRole();

    const variables = new ProjectListRequest(filters);

    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const { company, loading: loadingCompany } = useCompany({
        skip: !currentUser?.companyId,
        variables: { id: currentUser?.companyId as number },
    });
    const { entities, loading: assessmentTypeLoading } = useAssessmentTypes();
    const userId = currentUser.id;
    const userName = currentUser?.getFullName();
    // const isPm = currentUser?.isAdmin;
    // const isSuperAdmin = currentUser?.isSuperAdmin;
    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();

    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const userRoleId: any = userHighRolesEntityList[0]?.welcomeRole;
    const onHandleOrgModal = (): void => {
        setHandleModal(!handleModal);
        handleCloseCreateModal();
    };
    const onHandleNotValidModal = (): void => {
        setHandleNotValidModal(!handleNotValidModal);
        handleCloseCreateModal();
    };
    // const modalStatus: any = localStorage.getItem('modalStatus');
    const { projects, loading: projectsLoading } = useAssignedProjects({
        fetchPolicy: 'network-only',
        variables,
    });
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const [deleteProject, { loading: deleteProjectLoading }] = useMutation<
        DeleteProjectMutationType,
        DeleteProjectMutationVariables
    >(DeleteProjectMutation);

    const isCreateProject = companyAccess?.isCreateProject;
    const isFreeze = companyAccess?.isFreeze;

    const createProjectAccess = useMemo(() => {
        if (orgDetailLoading) {
            return null;
        }
        if (organizationDetails && organizationDetails?.user_id) {
            return true;
        } else {
            return false;
        }
    }, [organizationDetails]);

    const isAdminRole = useMemo(() => {
        if (userLoading) {
            return null;
        }
        if (user && user.roles && user.roles.length > 0) {
            return adminRoles.includes(user.roles[0]);
        }
        return false;
    }, [user, adminRoles]);

    const handleOpenProject = (project: any) => {
        let redirectUrl = routes.projectAssessment;

        if (project.isRoadMap()) {
            redirectUrl = routes.projectRoadmap;
        }

        if (project.isImplementation()) {
            redirectUrl = routes.projectImplementation;
        }

        navigate(createRouteUrl(redirectUrl, { id: project.id }));
    };

    const handleDeleteProject = async (projectId: number): Promise<void> => {
        setSelectedProjectId(projectId);
        try {
            const { data: deleteProjectData } = await deleteProject({
                variables: { id: projectId },
                refetchQueries: [{ query: GetAssignedProjectsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteProjectData?.deleteProject?.id) {
                enqueueSuccess('Project successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the project!');
            }
        } catch (e) {
            console.log(e);
        }
    };
    const handleUpdateProject = async (projectId: number): Promise<void> => {
        setEditProjectId(projectId);
        setOpenEditProjectFormModal(true);
    };

    // useEffect(() => {
    //     isOrgFormFilled = organizationDetails?.user_id ? true : false;
    //     setHandleModal(true);
    //     if (isSuperAdmin && isOrgFormFilled) {
    //         setHandleModal(false);
    //         localStorage.setItem('modalStatus', 'false');
    //     } else if (isPm && isOrgFormFilled) {
    //         setHandleModal(false);
    //         localStorage.setItem('modalStatus', 'false');
    //     } else if (isPm || isSuperAdmin) {
    //         setHandleModal(true);
    //     } else {
    //         setHandleModal(false);
    //     }
    //     if (modalStatus) {
    //         setHandleModal(false);
    //         localStorage.setItem('modalStatus', 'false');
    //     }
    // }, [organizationDetails, isOrgFormFilled]);

    useEffect(() => {
        if (orgDetailLoading) {
            return;
        }
        if (!createProjectAccess && isAdminRole) {
            setHandleModal(true);
        } else if (!createProjectAccess && openCreateModal && isAdminRole) {
            setHandleModal(true);
        } else if (!createProjectAccess && openCreateModal && !isAdminRole) {
            setHandleNotValidModal(true);
        }
    }, [createProjectAccess, openCreateModal, isAdminRole])

    if (orgDetailLoading || loadingCompany || companyLoading || currentUserLoading || userHighestLoading || userLoading) {
        return <Loader />;
    }
    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <ProjectListToolbar isFreeze={isFreeze} isCreateProject={isCreateProject} />
            </PageStyled.Bridge>

            <ProjectDataTable
                handleDeleteProject={handleDeleteProject}
                handleUpdateProject={handleUpdateProject}
                handleClickRedirect={handleOpenProject}
                deleteProjectLoading={deleteProjectLoading}
                selectedProjectId={selectedProjectId}
                canDeleteUser={canDeleteUser}
                columns={columns}
                projectsLoading={projectsLoading}
                assessmentTypeLoading={assessmentTypeLoading}
                projects={projects}
                assessmentEntities={entities}
            />

            <OrgProfilesFormModal
                userId={userId}
                username={userName}
                userRoleId={userRoleId}
                open={handleModal}
                companyName={company?.name}
                onClose={onHandleOrgModal}
            />

            <DialogNotValid
                open={handleNotValidModal}
                onClose={onHandleNotValidModal}
            />

            <ProjectUpdateModal
                projectId={editProjectId}
                open={openEditProjectFormModal}
                onClose={handleCloseEditProjectFormModal}
            />
        </>
    );
};

export { ProjectsPage };
