import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { useCompanies } from '@modules/company/hooks';
import { UpdateUserRequest } from '@modules/users/requests';
import { useUserRoles } from '@modules/users/hooks';
import { UserForm } from '@modules/users/organisms';
import { GetUsersDetailsQuery, UpdateUserMutation } from '@modules/users/graphql';

import type { UpdateUserFormValues } from '@modules/users/requests';
import type { Page } from '@modules/types/page';
import type { UpdateUserMutationType, UpdateUserMutationVariables } from '@modules/types/graphql';
import { useUserDetail } from '@modules/users/hooks/use-user-detail';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const UsersEditPage = (props: Page): React.ReactElement => {
    const { title = 'Edit User' } = props;

    const navigate = useNavigate();
    const params = useParams();
    const userId = Number(params.id);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    // const { user, loading: userLoading } = useUser({ variables: { id: userId } });
    const { userDetail, loading: userLoadings } = useUserDetail({ variables: { id: userId } });
    const { companies, loading: companiesLoading } = useCompanies();
    const { currentUser } = useCurrentUser();
    const { userRoles, loading: userRolesLoading } = useUserRoles();
    const [updateUser, { loading: updateLoading }] = useMutation<
        UpdateUserMutationType,
        UpdateUserMutationVariables
    >(UpdateUserMutation);

    const initialValues: UpdateUserFormValues = {
        id: userDetail.id,
        username: userDetail.username,
        email: userDetail.email,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        isAdmin:
            userRoles.find(role => role.name === userDetail?.userRoles)?.name !== 'Risk Manager' &&
            userDetail.isAdmin,
        company: companies.find(company => company.id === userDetail.companyId) ?? null,
        userRole: userRoles.find(role => role.name === userDetail?.userRoles) ?? '',
        schemaName: companies.find(company => company.id === userDetail.companyId)?.schema_name ?? '',
    };

    const handleSubmit = async (values: UpdateUserFormValues): Promise<void> => {
        try {
            if (currentUser.isSuperAdmin) {
                if (
                    values?.userRole?.id === 'OP' ||
                    values?.userRole?.id === 'CO' ||
                    values?.userRole?.id === 'ST' ||
                    values?.userRole?.id === 'RE' ||
                    values?.userRole?.id === 'AO'
                ) {
                    values.isAdmin = false;
                } else if (values?.userRole?.id === 'RM' || values?.userRole?.id === 'PM') {
                    values.isAdmin = true;
                } else {
                    values.isAdmin = false;
                }
            } else {
                if (values?.userRole?.id === 'RM' || values?.userRole?.id === 'PM') {
                    values.isAdmin = true;
                } else {
                    values.isAdmin = false;
                }
            }

            const variables = new UpdateUserRequest(values);
            variables.schemaName = values.company?.schema_name;
            const { data: updateUserData } = await updateUser({
                variables,
                refetchQueries: [{ query: GetUsersDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (updateUserData?.updateUser) {
                enqueueSuccess('User successfully updated!');
                navigate(routes.users.path);
            } else {
                enqueueError('An error occurred while updating the user!');
            }
        } catch (e) {
            throw e;
        }
    };

    if (userLoadings || userRolesLoading || companiesLoading) {
        return <Loader />;
    }
    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                {userLoadings ? (
                    <Loader />
                ) : (
                    <UserForm<'edit'>
                        type='edit'
                        initialValues={initialValues}
                        FormWrapComponent={FormWrap}
                        onSubmit={handleSubmit}
                    />
                )}
            </Main>
        </>
    );
};

export { UsersEditPage };
