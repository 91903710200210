export {
    ComponentSingleFragment,
    GapSingleFragment,
    RecommendationSingleFragment,
    AddAssessmentCategoriesFragment,
    AddAssessmentTypeFragment,
    AssetCategoryFragment,
} from './fragments';
export {
    GetCapabilitiesQuery,
    GetCapabilityQuery,
    GetCapabilityImpactsQuery,
    GetCapabilityLevelsQuery,
    GetCapabilityLevelQuery,
    GetGapsQuery,
    GetGapQuery,
    GetRecommendationsQuery,
    GetCapabilityLevelDefinitionsQuery,
    ListAssessmentCategoriesQuery,
    GetAssessmentTypeByIdQuery,
    GetAssetCategoryByIdQuery,
    GetAssetTypeByIdQuery,
} from './queries';
export {
    CreateComponentMutation,
    DeleteComponentMutation,
    CreateCapabilityMutation,
    UpdateCapabilityMutation,
    DeleteCapabilityMutation,
    CreateCapabilityLevelMutation,
    UpdateCapabilityLevelMutation,
    DeleteCapabilityLevelMutation,
    AddCustomGapMutation,
    CreateGapMutation,
    UpdateGapMutation,
    DeleteGapMutation,
    AddCustomRecommendationMutation,
    CreateRecommendationMutation,
    UpdateRecommendationMutation,
    DeleteRecommendationMutation,
    CreateRequirementMutation,
    UpdateRequirementMutation,
    DeleteRequirementMutation,
    CreateTaskMutation,
    UpdateTaskMutation,
    DeleteTaskMutation,
    DeleteAssetMutation,
    AddAssessmentCategoriesMutation,
    UpdateAssessmentCategoriesMutation,
    DeleteAssessmentCategoriesMutation,
    AddAssessmentTypeMutation,
    DeleteAssessmentTypeMutation,
    CreateItemCategoryMutation,
    UpdateItemCategoryMutation,
    DeleteItemCategoryMutation,
    CreateItemCategoryTypeMutation,
    UpdateItemCategoryTypeMutation,
    DeleteItemCategoryTypeMutation,
} from './mutations';
