import { gql } from '@apollo/client';

export const GetAssetTypeByIdQuery = gql`
    query GetAssetTypeById($categoryTypeId: Int!, $categoryId: Int) {
        getAssetTypeById(category_type_id: $categoryTypeId, category_id: $categoryId) {
            id
            name
            category_id
        }
    }
`;
