import React, { useMemo } from 'react';

import { ListStyled } from '@modules/layout/styled';
import { Doughnut, Bar } from 'react-chartjs-2';

import { useImpactLevels, useMaturityLevels } from '@modules/shared/hooks';
import styled from 'styled-components';
import { UserListCard } from '@modules/assessment/user-list-card';
import { ProjectEntity } from '@modules/projects/entities';
import {
    getCountByComponent,
    getCountByRisk,
    getComponentMaturityData,
    getTopList,
    useComponentMaturityChartConfig,
    useMaturityLevelConfig,
    useCountByRiskConfig,
    useCountByComponentConfig,
    useMitigationStatusConfig,
    useMaturityImpactLevelConfig,
    useMaturityImpactLevel,
} from '@modules/assessment/organisms/report-components-list/report-general-chart-data';
import { RatingPlateCursor } from '@modules/registry/organisms/rating-card/rating-plate-cursor';
import { format } from 'date-fns';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { GapByRisk, GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';
import { Loader } from '@modules/layout/moleculas';

type ReportComponentsListProps = {
    componentsAssessmentRawList: ComponentAssessmentEntity[];
    componentAssessmentRawMap: Map<number, GapCapabilityAssessment[]>;
    project: ProjectEntity;
    isGeneralEditAllowed: boolean;
    activeTab: number;
    // projectAssign: ProjectEntity;
    // projectLoading: boolean;
};

// const BarChartInfoWidth= styled(ListStyled.BarChartInfo)<{ count: number }>`
//     width: ${p => p.count*100}px;
// `;

const BarChartInfoWidth = styled(ListStyled.BarChartInfo)<{ count: number }>`
    width: ${p =>
        p.count === 1
            ? p.count * 450
            : p.count === 2
            ? p.count * 225
            : p.count === 3
            ? p.count * 150
            : p.count === 4
            ? p.count * 115
            : p.count * 100}px;
`;

export const ReportGeneralInfo = (props: ReportComponentsListProps): React.ReactElement => {
    const {
        componentAssessmentRawMap,
        componentsAssessmentRawList: componentsAssessmentList,
        project,
        isGeneralEditAllowed,
        activeTab,
    } = props;
    console.log(props, 'propes');
    const gapViewData: GapViewData = useMemo(
        () => GapByRisk.gapAssessmentFromPreparedMap(componentAssessmentRawMap),
        [componentsAssessmentList],
    );
    const factorsCount = useMemo(() => {
        return componentsAssessmentList.reduce((total, ele) => total + ele.capabilities.length, 0);
    }, [componentsAssessmentList]);
    const { allList, topList } = getTopList(gapViewData);
    const maturityLevelChartConfig = useMaturityLevelConfig(project.maturityLevel);
    const maturityImpactLevelChartConfigImpact = useMaturityImpactLevelConfig(
        project.maturityLevel,
    );
    const maturityImpactLevel = useMaturityImpactLevel(project.maturityLevel.name);
    const overalCount: number = allList.length;
    const countByComponent: Array<[number, string]> = getCountByComponent(
        componentsAssessmentList,
        componentAssessmentRawMap,
    );
    const { ImpactLevels, loading: isImpactLoading } = useImpactLevels();

    const countByRisk: Array<[number, string]> = getCountByRisk(gapViewData.gapByRisk);
    const countByRiskConfig = useCountByRiskConfig(countByRisk);
    const countByComponentConfig = useCountByComponentConfig(countByComponent);

    const { maturityLevels, loading: isMaturityLoading } = useMaturityLevels();
    const componentMaturityResult = getComponentMaturityData(
        project.isrrimpact ? ImpactLevels : maturityLevels,
        componentsAssessmentList,
    );

    const componentByMaturity: Array<[number, string]> =
        componentMaturityResult.componentByMaturity;
    const maturityMap: Map<number, string> = componentMaturityResult.maturityMap;
    const componentMaturityBarChartConfig = useComponentMaturityChartConfig(
        componentByMaturity,
        maturityMap,
    );
    const mitigationChartConfig = useMitigationStatusConfig(gapViewData.scoreList);

    if (isImpactLoading) {
        return <Loader />;
    }

    return (
        <ListStyled.HeaderSecondary
            style={{ justifyContent: project?.isrrimpact ? 'space-between' : '' }}
        >
            <ListStyled.UserInfo>
                {project.stakeholder.exists() ? (
                    <UserListCard
                        title={'Stakeholder'}
                        projectId={project.id}
                        isEditAllowed={isGeneralEditAllowed}
                        userList={[
                            {
                                id: project?.stakeholder?.id,
                                fullName: project.stakeholder.getFullName(),
                                role: { id: 'ST', name: 'Stakeholder' },
                            },
                        ]}
                    />
                ) : null}

                {project.programManager ? (
                    <UserListCard
                        title={'Project Manager'}
                        projectId={project.id}
                        isEditAllowed={isGeneralEditAllowed}
                        userList={[
                            {
                                id: project.programManager?.id,
                                fullName: project.programManager.getFullName(),
                                role: { id: 'PM', name: 'Project Manager' },
                            },
                        ]}
                    />
                ) : null}
            </ListStyled.UserInfo>

            {activeTab === 0 && (
                <>
                    <ListStyled.PieArcChartInfo>
                        <p className={'chart-title'}>
                            {project?.isrrimpact ? 'Impact Rating' : 'Program Maturity'}
                        </p>
                        {/* <Doughnut
                            options={maturityLevelChartConfig.pieChartOptions}
                            data={maturityLevelChartConfig.pieChartData}
                            className={'chart-align'}
                        /> */}

                        {project?.isrrimpact ? (
                            <Doughnut
                                options={maturityImpactLevelChartConfigImpact.pieChartOptions}
                                data={maturityImpactLevelChartConfigImpact.pieChartData}
                                className={'chart-align'}
                            />
                        ) : (
                            <Doughnut
                                options={maturityLevelChartConfig.pieChartOptions}
                                data={maturityLevelChartConfig.pieChartData}
                                className={'chart-align'}
                            />
                        )}

                        <RatingPlateCursor
                            className='rating-cursor'
                            rotateDeg={maturityLevelChartConfig.degree}
                        />
                        <div className='rating-score'>
                            {project?.isrrimpact ? maturityImpactLevel : project.maturityLevel.name}
                        </div>
                    </ListStyled.PieArcChartInfo>
                    {!project?.isrrimpact ? (
                        <ListStyled.PieChartInfo>
                            <p className={'chart-title'}>Gaps By Component</p>
                            <Doughnut
                                options={countByComponentConfig.pieChartOptions}
                                data={countByComponentConfig.pieChartData}
                            />
                        </ListStyled.PieChartInfo>
                    ) : (
                        ''
                    )}
                    <BarChartInfoWidth
                        count={componentMaturityBarChartConfig.barChartData.datasets[0].data.length}
                    >
                        <p>{!project?.isrrimpact ? 'Component Ratings' : 'Component Ratings'}</p>
                        {!isMaturityLoading && (
                            <Bar
                                options={componentMaturityBarChartConfig.barChartOptions}
                                data={componentMaturityBarChartConfig.barChartData}
                            />
                        )}
                    </BarChartInfoWidth>
                </>
            )}

            {activeTab === 1 && (
                <>
                    <ListStyled.Top5TableBlock>
                        {project?.isrrimpact ? <p>Top Factors</p> : <p>Top Gaps</p>}
                        <ListStyled.Top5Table>
                            <div key={0} className={'top5table-header'}>
                                <ListStyled.Top5TableGap>
                                    {project?.isrrimpact ? 'Factor' : 'Gap'}
                                </ListStyled.Top5TableGap>
                                <ListStyled.Top5TableRiskLevel>
                                    {project?.isrrimpact ? 'Impact Level' : 'Risk Level'}
                                </ListStyled.Top5TableRiskLevel>
                                <ListStyled.Top5TableComponent>
                                    Component
                                </ListStyled.Top5TableComponent>
                                <ListStyled.Top5TableComponentOwner>
                                    Component Owner
                                </ListStyled.Top5TableComponentOwner>
                            </div>
                            {topList.map(gapReport => (
                                <div key={gapReport.scoredGap.id}>
                                    <ListStyled.Top5TableGap>
                                        {gapReport.scoredGap.gap.gap}
                                    </ListStyled.Top5TableGap>
                                    <ListStyled.Top5TableRiskLevel>
                                        {gapReport.scoredGap.riskLevel}
                                    </ListStyled.Top5TableRiskLevel>
                                    <ListStyled.Top5TableComponent>
                                        {gapReport.componentAssessment.component.name}
                                    </ListStyled.Top5TableComponent>
                                    <ListStyled.Top5TableComponentOwner>
                                        {gapReport.componentAssessment.componentOwnersToString()}
                                    </ListStyled.Top5TableComponentOwner>
                                </div>
                            ))}
                        </ListStyled.Top5Table>
                    </ListStyled.Top5TableBlock>
                    <ListStyled.PieChartInfo>
                        <p className={'chart-title'}>
                            {project?.isrrimpact ? 'Factors By Impact Level' : 'Gaps By Risk Level'}
                        </p>
                        <Doughnut
                            options={countByRiskConfig.pieChartOptions}
                            data={countByRiskConfig.pieChartData}
                        />
                    </ListStyled.PieChartInfo>
                    {!project?.isrrimpact ? (
                        <ListStyled.PieArcChartInfo>
                            <p className={'chart-title'}>Mitigation Status</p>
                            <Doughnut
                                options={mitigationChartConfig.pieChartOptions}
                                data={mitigationChartConfig.pieChartData}
                                className={'chart-align'}
                            />
                            <RatingPlateCursor
                                className='rating-cursor'
                                rotateDeg={mitigationChartConfig.degree}
                            />
                            <div className='rating-score'>{mitigationChartConfig.score}%</div>
                        </ListStyled.PieArcChartInfo>
                    ) : (
                        ''
                    )}
                </>
            )}

            <ListStyled.TextContent>
                <p className={'text-offset font-size-normal'}>
                    <b>Number of {project?.isrrimpact ? 'Factors' : 'Gaps'}:</b>{' '}
                    {project?.isrrimpact ? factorsCount : overalCount}
                </p>
                <p className={'text-offset font-size-normal'}>
                    <b>Date/Time:</b> {format(new Date(), 'MM/dd/yyyy - hh:mm')}
                </p>
            </ListStyled.TextContent>
        </ListStyled.HeaderSecondary>
    );
};
