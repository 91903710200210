import React, { useContext, useMemo, useState } from 'react';

import { roadmapRecommendationTitle } from '@helpers';
import { RecommendationAssessmentRoadmapReport } from '@modules/roadmap/recommendation-assessment-roadmap-report';
import { TableCell } from '@material-ui/core';
import { RoadmapNotes } from '@modules/roadmap/organisms/roadmap-recommendations-list/roadmap-notes';
import { ProjectEntity } from '@modules/projects/entities';
import { useRoles } from '@modules/assessment/hooks';
import { AddNotesForm } from '@modules/roadmap/moleculas';
import { AuthContext } from '@modules/auth/context';

type ReportPrioritizedRoadmapRowProps = {
  recommendation: RecommendationAssessmentRoadmapReport;
  project: ProjectEntity;
  withStatus?: boolean;
};

export const ReportPrioritizedRoadmapRow = (
  props: ReportPrioritizedRoadmapRowProps,
): React.ReactElement => {
  const { recommendation: recommendationExtra, project, withStatus } = props;

  const { user, loading: userLoading } = useContext(AuthContext) || {};
  const adminRoles = useMemo(() => ['risk_admin', 'project_admin', 'company_admin'], []);

  const isAdminRole = useMemo(() => {
    if (user && user.roles && user.roles.length > 0) {
      return adminRoles.includes(user.roles[0]);
    }
    return false;
  }, [user, adminRoles]);

  let roadmapRecommendation = recommendationExtra.componentRecommendation.recommendation;
  let history = roadmapRecommendation.refinement;
  let notes = roadmapRecommendation.refinement?.notesLog ?? [];
  const [showNotes, setShowNotes] = useState(false);

  const { rolesEntityList } = useRoles({ variables: { assessmentId: recommendationExtra.componentRecommendation.assessmentId } });

  const addNotesAccess = useMemo(() => {
    return rolesEntityList && (
      isAdminRole ||
      rolesEntityList.hasComponentOwner() ||
      rolesEntityList.hasProgramManager() ||
      rolesEntityList.hasStakeholder()
    );
  }, [isAdminRole, rolesEntityList]);

  return (
    <>
      <TableCell>
        <p className={'text-offset'}>{roadmapRecommendationTitle(roadmapRecommendation)}</p>
        {notes.length === 0 ?
          (addNotesAccess ? (
            <AddNotesForm
              project={project}
              roadmapRecommendation={roadmapRecommendation}
            // onAddNote={onAddNote}
            />
          ) : null)
          :
          (
            <>
              {/*<p className={'block-offset'}><b>{history.status} at {format(new Date(history.refined_at), 'dd.MM.yyyy hh:mm')} by {history?.refined_by?.first_name} {history?.refined_by?.last_name}</b></p>*/}
              {/*<p>Dependencies: {history.dependencies}</p>*/}
              {/*<p>Constraints: {history.constraints}</p>*/}
              <p>
                {/*<span>Notes: {history.notes} </span>*/}
                <a
                  href='./'
                  onClick={e => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setShowNotes(state => !state);
                  }}
                >
                  <strong className={'strong'}>({showNotes ? 'Hide' : 'Show'} all notes)</strong>
                </a>
              </p>
            </>
          )}
        <RoadmapNotes
          notes={notes}
          showNotes={showNotes}
          addNotesAccess={addNotesAccess}
          project={project}
          roadmapRecommendation={roadmapRecommendation}
        />
      </TableCell>
      <TableCell>{roadmapRecommendation?.gap.riskLevel ?? ''}</TableCell>
      <TableCell>{recommendationExtra.componentRecommendation.component.name}</TableCell>
      <TableCell>
        <p className={'text-offset'}>{recommendationExtra.capabilityAssessment.capability.title}</p>
        {/*<p className={'text-offset'}><b>Gap:</b> {roadmapRecommendation.gap.gap}</p>*/}
        <p><b>Security Controls:</b> {recommendationExtra.capabilityAssessment.capability.securityControls}</p>
      </TableCell>
      {/*<TableCell>{recommendationExtra.componentRecommendation.componentOwner.getFullName()}</TableCell>*/}
      {withStatus && (
        <TableCell>{roadmapRecommendation.status}</TableCell>
      )}
    </>
  );
};