import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    UseTableRowProps,
} from 'react-table';
import {
    Table as MaterialTable,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    CardHeader,
    InputAdornment,
    Card,
    MenuItem,
} from '@material-ui/core';
import {
    AccountCircle,
    ArrowDownward,
    ArrowUpward,
    Brightness1,
    CheckCircle,
    CloudCircle,
    PauseCircleFilled,
    PlayCircleFilled,
} from '@material-ui/icons';
import { Button, TextField } from '@modules/ui/core';
import { Assessment, Search, UnfoldMore, UnfoldLess } from '@material-ui/icons';
import {
    AssetTableRow,
    IncidentHistoryModal,
    ViewActivityModal,
    AssetManagementModal,
    ColumnFilter,
    EditUsersModal,
    EditAssetModal,
    NewAssessmentModal,
    AssetScoreLabel,
} from '@modules/registry/organisms';
import { useAssets, useAssignAssets, useItemType } from '@modules/registry/hooks';
import { Asset } from '@modules/types/graphql';
import { ViewAssessmentModal } from '@modules/registry/organisms/view-assessment-modal/view-assessment-modal';
import {
    getRiskProgramScore,
    getScoreIntervalMap,
    RiskLevel10Point,
    RiskLevel10PointKeyList,
    scoreIntervalGeneralMap,
    scoreIntervalImpactMap,
} from '@modules/shared/constants/risk-level';
import {
    RiskProgramScoreType,
    RiskProgramType,
} from '@modules/shared/constants/risk-assessment-type';
import { AssetImportButton } from '@modules/registry/organisms/assets-datatable/assest-import-button';
import { useLocation } from '@reach/router';
import { Loader } from '@modules/layout/moleculas';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { GetAssetsQuery, GetAssignedAssets } from '@modules/registry/graphql';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useOrganizationDetails } from '@modules/components/hooks';
import { RiskRegistryListFilters } from '../risk-registry-list-toolbar';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useRiskPosture } from '@modules/company/hooks/use-risk-posture';
const CustomIntegrator = styled.div`
    display: flex;
    gap: 20px;
    div {
        display: flex;
        gap: 4px;
        align-items: center;
        i {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        span {
            display: inline-block;
        }
    }
    div:nth-child(1) {
        i {
            background: #000000;
        }
        span {
            color: #000000;
        }
    }
    div:nth-child(2) {
        i {
            background: #0b6bcb;
        }
        span {
            color: #0b6bcb;
        }
    }
`;
const Styles = styled.div`
    h4 {
        font-weight: bold;
    }

    table {
        .is-active-row {
            td {
                box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.15);
                background: #f5f5f5;
            }
        }

        tr:not(.is-active-row):hover {
            cursor: pointer;
        }

        td {
            vertical-align: top;
            width: 20%;
        }
    }

    .is-chip {
        background: #0057ff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        &--small {
            margin-top: 5px;
            margin-bottom: -5px;

            span {
                font-size: 10px !important;
                line-height: 20px;
            }
        }
    }

    .is-status {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #adadad;
        font-size: 12px;
        min-height: 50px;
        margin: 10px 0;

        &--small {
            min-height: initial;
            margin: 0;
            padding: 10px 0 0;
        }
    }

    .is-status-donut {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 10px;
        padding: 10px 0;

        svg {
            width: 50px;
            height: 50px;
        }
    }

    .is-paper-link {
        transition: background 0.2s ease-in-out;

        &:hover {
            background: #efefef;
        }
    }
`;

const ActionButton = styled(Button)`
    min-width: 120px;

    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

const StyledSlider = styled.div`
    padding: 0 10px;

    * {
        font-size: 14px;
    }
`;

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
        width: 250px;

        &:not(:last-of-type) {
            margin-right: 32px;
        }
    }
`;

const Filters = styled.div`
    margin-left: auto;
`;

// Define a default UI for filtering

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    handleSelectItemTypeFilter,
    handleSelectRiskPostureFilter,
}: any) {
    const count = preGlobalFilteredRows.length;
    const { itemType, loading: itemTypeLoading } = useItemType();
    const { riskPostures, loading: riskPosturesLoading } = useRiskPosture();
    const [value, setValue] = useState(globalFilter);
    const [itemValue, setItemValue] = useState(0);
    const [postureValue, setPostureValue] = useState(0);
    const handleItemTypeChangeField = (event: any) => {
        setItemValue(event.target.value);
        handleSelectItemTypeFilter(event.target.value);
    };
    const handleRiskPostureChangeField = (event: any) => {
        setPostureValue(event.target.value);
        handleSelectRiskPostureFilter(event.target.value);
    };
    useEffect(() => {
        if (value !== globalFilter) {
            // setValue(globalFilter);
            setTimeout(() => {
                setGlobalFilter(String(value || ''));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilter]);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(String(value || ''));
    }, 200);

    return (
        <>
            <TextField
                corner='round'
                name='search'
                label='Search'
                style={{ minWidth: 350 }}
                value={value || ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search
                                style={{
                                    fontSize: 24,
                                    opacity: 0.5,
                                }}
                            />
                        </InputAdornment>
                    ),
                }}
                placeholder={`${count} assets...`}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            <TextField
                select
                name='itemTypeId'
                label='Item Type'
                // classes={backgroundClasses}
                value={itemValue}
                InputLabelProps={{ shrink: true }}
                disabled={itemTypeLoading}
                onChange={handleItemTypeChangeField}
            >
                <MenuItem value={0}>Choose the Item Type</MenuItem>
                {itemType.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                name='riskPostureId'
                label='Risk Posture'
                // classes={backgroundClasses}
                value={postureValue}
                InputLabelProps={{ shrink: true }}
                disabled={riskPosturesLoading}
                onChange={handleRiskPostureChangeField}
            >
                <MenuItem value={0}>Choose the Risk Posture</MenuItem>
                {riskPostures &&
                    riskPostures.map((riskPosture: any) => (
                        <MenuItem key={riskPosture?.id} value={riskPosture?.id}>
                            {riskPosture?.name}
                        </MenuItem>
                    ))
                    // ) : (
                    //     <MenuItem key={0} value={''}>
                    //         Select Risk Posture
                    //     </MenuItem>
                }
            </TextField>
        </>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: any) {
    const count = preFilteredRows.length;

    return (
        <>
            <TextField
                name='search'
                label='Search'
                style={{ minWidth: 350 }}
                value={filterValue || ''}
                placeholder={`${count} assets...`}
                onChange={e => {
                    setFilter(e.target.value || undefined);
                }}
            />
        </>
    );
}

// This is a custom filter UI for selecting
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const options = [
        {
            value: 'pending',
            title: 'Pending',
        },
        ...RiskLevel10PointKeyList.map((key: string) => {
            return {
                value: RiskLevel10Point[key],
                title: RiskLevel10Point[key],
            };
        }),
    ];

    return (
        <TextField
            select
            fullWidth
            value={filterValue || []}
            InputLabelProps={{ shrink: true }}
            SelectProps={{
                multiple: true,
            }}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
        >
            {options.map((option: any, i) => (
                <MenuItem key={i} value={option.value}>
                    {option.title}
                </MenuItem>
            ))}
        </TextField>
    );
}

function selectFilterFn(rows: UseTableRowProps<Asset>, fieldRaw: string, filterValue: any) {
    const field = Array.isArray(fieldRaw) ? fieldRaw[0] : fieldRaw;
    const limits = getScoreIntervalMap(field);

    const isBetween = (limit: string, value: number) => {
        return value >= limits[limit].min && value < limits[limit].max;
    };

    if (!filterValue.length) {
        return rows;
    }

    return (rows as any).filter((row: any) => {
        const rowValue = row.values[field];
        return filterValue.some((value: string) => {
            if (value === 'pending') {
                return rowValue === null;
            }
            return isBetween(value, rowValue);
        });
    });
}

function printDocument(event: any) {
    const input = document.getElementById('app-page-container') as HTMLElement;
    html2canvas(input).then((canvas: HTMLCanvasElement) => {
        // document.body.appendChild(canvas)

        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF({ unit: 'mm', compress: true });
        // give some top padding to first page
        let topOffset = 0; //(window as any).offset;

        doc.addImage(imgData, 'PNG', 0, topOffset, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
            // top padding for other pages
            topOffset += heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, topOffset, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        // doc.output('dataurlnewwindow');
        doc.save(`risk-registry-report-${new Date().toISOString().replaceAll(':', '-')}.pdf`);
    });
}

// Let the table remove the filter if the string is empty
selectFilterFn.autoRemove = (val: any) => !val;

function Table({
    columns,
    data,
    setActiveAssetId,
    setOpenIncidentHistoryModal,
    setOpenViewActivityModal,
    setOpenEditUsersModal,
    setOpenAssetManagementModal,
    setOpenEditAssetModal,
    setOpenNewAssessmentModal,
    setOpenViewAssessmentModal,
    setOpenEditAssessmentModal,
    activeRow,
    setActiveRow,
    handleOpenAssetModal,
    isExpandAll,
    setIsExpandAll,
    setActivityType,
    setFilteredAssets,
    setActiveAssessment,
    handleClickRating,
    userRole,
    riskManagerRole,
}: any) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new filter type.
            selectFilter: selectFilterFn,
            text: (rows: any, id: any, filterValue: any) => {
                return rows.filter((row: any) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              ?.toLowerCase()
                              ?.startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        [],
    );
    const globalFilterFunction = React.useCallback((rows: any, id: any, filterValue: any) => {
        if (!filterValue || typeof filterValue !== 'string') {
            return rows;
        }
        const searchText = filterValue.toLowerCase();
        return rows.filter((row: any) => {
            const { name, owner, pm, stakeholders } = row.original;
            const ownerName = owner.full_name.toLowerCase();
            const pmName = pm.full_name.toLowerCase();
            const lowerCaseName = name?.toLowerCase() || '';
            const stakeholderNames = stakeholders.some((ele: any) =>
                ele.full_name.toLowerCase().includes(searchText),
            );
            return (
                lowerCaseName.includes(searchText) ||
                ownerName.includes(searchText) ||
                pmName.includes(searchText) ||
                stakeholderNames
            );
        });
    }, []);
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    }: any = useTable(
        {
            columns,
            data,
            // @ts-ignore
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            globalFilter: globalFilterFunction,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
    );
    const [newRows, setNewRows] = useState(rows);
    const [itemTypeId, setItemTypeId] = useState<number>(0);
    const [riskPostureId, setRiskPostureId] = useState<number>(0);
    useEffect(() => {
        let filteredRows = rows;
        if (riskPostureId) {
            filteredRows = filteredRows.filter(
                (row: any) => row.original?.riskPosture === riskPostureId,
            );
        }
        if (itemTypeId) {
            filteredRows = filteredRows.filter(
                (row: any) => row.original?.itemType?.id === itemTypeId,
            );
        }
        if (state.globalFilter) {
            filteredRows = filteredRows.filter((row: any) =>
                JSON.stringify(row.original)
                    .toLowerCase()
                    .includes(state.globalFilter.toLowerCase()),
            );
        }
        setNewRows(filteredRows);
        setFilteredAssets(filteredRows);
    }, [rows, state.globalFilter, itemTypeId, riskPostureId]);

    const handleSelectItemTypeFilter = (itemId: number) => {
        setItemTypeId(itemId);
    };
    const handleSelectRiskPostureFilter = (postureId: number) => {
        setRiskPostureId(postureId);
    };
    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10);
    return (
        <Card>
            <CardHeader
                title={
                    <>
                        <Root>
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                handleSelectItemTypeFilter={handleSelectItemTypeFilter}
                                handleSelectRiskPostureFilter={handleSelectRiskPostureFilter}
                            />
                            {/* <Filters>
                                <RiskRegistryListFilters />
                            </Filters> */}
                            <CustomIntegrator>
                                <div>
                                    <i></i>
                                    <span>Risks</span>
                                </div>
                                <div>
                                    <i></i>
                                    <span>Assets</span>
                                </div>
                            </CustomIntegrator>
                        </Root>
                    </>
                }
                action={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: 10,
                        }}
                    >
                        <AssetImportButton />
                        <ActionButton
                            variant='outlined'
                            style={{ marginRight: 12 }}
                            onClick={printDocument}
                        >
                            <Assessment style={{ marginRight: 10 }} />
                            <span>Report</span>
                        </ActionButton>
                        {!isExpandAll ? (
                            <ActionButton variant='outlined' onClick={() => setIsExpandAll(true)}>
                                <UnfoldMore style={{ marginRight: 10 }} />
                                <span>Expand all</span>
                            </ActionButton>
                        ) : (
                            <ActionButton
                                variant='outlined'
                                onClick={() => {
                                    setActiveRow(null);
                                    setIsExpandAll(false);
                                }}
                            >
                                <UnfoldLess style={{ marginRight: 10 }} />
                                <span>Collapse all</span>
                            </ActionButton>
                        )}
                    </div>
                }
            />

            {/*  Table here  */}
            <MaterialTable {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup: any) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <TableCell {...column.getHeaderProps()}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <span {...column.getSortByToggleProps()}>
                                                {column.render('Header')}
                                            </span>

                                            <span
                                                style={{
                                                    padding: 5,
                                                    position: 'relative',
                                                    top: 2,
                                                }}
                                            >
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <ArrowDownward />
                                                    ) : (
                                                        <ArrowUpward />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>

                                        <ColumnFilter column={column} />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {newRows?.map((row: UseTableRowProps<Asset>, i: any) => {
                        prepareRow(row);
                        if (isExpandAll || activeRow === i) {
                            return (
                                <AssetTableRow
                                    refetchQuery={
                                        userRole === 'RM' ||
                                        userRole === 'RE' ||
                                        userRole === 'RA' ||
                                        userRole === 'CA' ||
                                        riskManagerRole
                                            ? GetAssetsQuery
                                            : GetAssignedAssets
                                    }
                                    key={i}
                                    row={row}
                                    setActiveAssetId={setActiveAssetId}
                                    setOpenIncidentHistoryModal={setOpenIncidentHistoryModal}
                                    setOpenViewActivityModal={setOpenViewActivityModal}
                                    setOpenEditUsersModal={setOpenEditUsersModal}
                                    setOpenAssetManagementModal={setOpenAssetManagementModal}
                                    setOpenEditAssetModal={setOpenEditAssetModal}
                                    setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                                    setOpenViewAssessmentModal={setOpenViewAssessmentModal}
                                    setOpenEditAssessmentModal={setOpenEditAssessmentModal}
                                    setActivityType={setActivityType}
                                    setActiveAssessment={setActiveAssessment}
                                    riskManagerRole={riskManagerRole}
                                />
                            );
                        }

                        return (
                            <TableRow
                                {...row.getRowProps()}
                                onClick={() => {
                                    setActiveRow(i);
                                    handleClickRating(row?.id);
                                }}
                            >
                                {row.cells.map((cell: any, i: number) => {
                                    const content = cell.render('Cell');
                                    if (RiskProgramScoreType[cell.column.id]) {
                                        let score = Number(cell.value);
                                        return (
                                            <TableCell {...cell.getCellProps()}>
                                                {score === null || !score ? (
                                                    <span className='is-status is-status--small'>
                                                        Pending
                                                    </span>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <AssetScoreLabel
                                                            score={score}
                                                            type={
                                                                RiskProgramScoreType[cell.column.id]
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </TableCell>
                                        );
                                    }

                                    return (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            style={{
                                                color:
                                                    cell?.row?.original?.itemType?.name === 'Risk'
                                                        ? '#000000'
                                                        : '#0b6bcb',
                                                fontWeight: 600,
                                                fontSize: '15px',
                                            }}
                                        >
                                            {content === null ? 'Pending' : content}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell colSpan={5}>
                            <Button
                                onClick={() => {
                                    handleOpenAssetModal('new', true);
                                }}
                                disabled={userRole === 'RM' || riskManagerRole ? false : true}
                            >
                                + Add
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </MaterialTable>
        </Card>
    );
}

function AssetsDataTable({
    setFilteredAssets,
    refetchQuery,
    handleClickRating,
    riskManagerRole,
    is_rm_re,
}: any) {
    const [activeRow, setActiveRow] = useState(0);
    const [activeAssetId, setActiveAssetId] = useState<number | undefined>();
    const [activeAssessment, setActiveAssessment] = useState<any>();
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [modalType, setModalType] = useState<'edit' | 'new'>('edit');
    const [activityType, setActivityType] = useState();
    const [openIncidentHistoryModal, setOpenIncidentHistoryModal] = useState<boolean>(false);
    const [openViewActivityModal, setOpenViewActivityModal] = useState<boolean>(false);
    const [openEditUsersModal, setOpenEditUsersModal] = useState<boolean>(false);
    const [openAssetManagementModal, setOpenAssetManagementModal] = useState<boolean>(false);
    const [openEditAssetModal, setOpenEditAssetModal] = useState<boolean>(false);
    const [openNewAssessmentModal, setOpenNewAssessmentModal] = useState<boolean>(false);
    const [openViewAssessmentModal, setOpenViewAssessmentModal] = useState<boolean>(false);
    const [openEditAssessmentModal, setOpenEditAssessmentModal] = useState<boolean>(false);
    const [validRoleUser, setValidRoleUser] = useState<boolean>(false);
    const { state } = useLocation();
    const stateid = (state as { id?: number })?.id || null;
    const [stateId, setStateId] = useState<number | null>(stateid);

    const handleOpenAssetModal = (type: 'edit' | 'new', state: boolean): void => {
        setOpenEditAssetModal(state);
        setModalType(type);
        if (type === 'new') {
            setActiveAssetId(undefined);
        }
    };
    useEffect(() => {
        if (stateId) {
            handleOpenAssetModal('new', true);
        }
        return () => setStateId(null);
    }, [stateId]);

    // TODO: find out why error happens
    //  Error: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
    // function computeRiskScore(assetsRaw: {listAssets: Asset[]}) {
    //   return {
    //     listAssets: assetsRaw.listAssets.map((asset: Asset) => {
    //       asset.riskScore = getRiskProgramScore(asset.vulnerabilityScore, asset.threatScore);
    //       return asset;
    //     })
    //   };
    // }
    //
    // const { assets: assetsRaw, loading: assetsLoading } = useAssets();
    // const [assets, setAssets] = useState(computeRiskScore(assetsRaw));
    // useEffect(() => {
    //   setAssets(() => computeRiskScore(assetsRaw));
    // }, [assetsRaw]);

    const { assets, loading: assetsLoading } = useAssets();
    // const { assets, loading: assetsLoading } = useAssets({
    //     variables: {
    //         is_rm_re: is_rm_re
    //     },
    // });
    const { assignAssets, loading: assetssLoading } = useAssignAssets();
    const { userHighRolesEntityList, loading: roleLoading } = useUserHighRole();
    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const userRole = useMemo(() => userHighRolesEntityList[0]?.welcomeRole, [
        userHighRolesEntityList,
    ]);
    useEffect(() => {
        if (
            userRole === 'RM' ||
            userRole === 'RE' ||
            userRole === 'RA' ||
            userRole === 'CA' ||
            riskManagerRole
        ) {
            setValidRoleUser(true);
        } else {
            setValidRoleUser(false);
        }
    }, []);

    const activeAsset: Asset = useMemo(() => {
        if (!activeAssetId) return (null as any) as Asset;

        if (validRoleUser) {
            return assets?.listAssets.find((asset: Asset) => asset.id === activeAssetId) as Asset;
        } else {
            return assignAssets?.getAssignedAssets.find(
                (asset: Asset) => asset.id === activeAssetId,
            ) as Asset;
        }
    }, [activeAssetId, assignAssets, assets, validRoleUser]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Item',
                accessor: 'name',
                filter: 'fuzzyText',
            },
            {
                Header: 'Risk',
                accessor: 'riskScore',
                Filter: SelectColumnFilter,
                filter: 'selectFilter',
            },
            {
                Header: 'Impact',
                accessor: 'impactScore',
                Filter: SelectColumnFilter,
                filter: 'selectFilter',
            },
            {
                Header: 'Vulnerability',
                accessor: 'vulnerabilityScore',
                Filter: SelectColumnFilter,
                filter: 'selectFilter',
            },
            {
                Header: 'Threat',
                accessor: 'threatScore',
                Filter: SelectColumnFilter,
                filter: 'selectFilter',
            },
        ],
        [],
    );

    const data: Asset[] = React.useMemo(() => {
        if (!assets) {
            return [];
        }
        if (validRoleUser) {
            return assets?.listAssets.filter((asset: Asset) => asset !== null);
        } else {
            return assignAssets?.getAssignedAssets.filter((asset: Asset) => asset !== null);
        }
    }, [assets, assignAssets, validRoleUser]);

    if (assetssLoading || assetsLoading || roleLoading || orgDetailLoading) {
        return <Loader />;
    }
    return (
        <Styles>
            <Table
                activeRow={activeRow}
                setActiveRow={setActiveRow}
                setActivityType={setActivityType}
                setActiveAssetId={setActiveAssetId}
                isExpandAll={isExpandAll}
                setIsExpandAll={setIsExpandAll}
                columns={columns}
                data={data}
                setOpenIncidentHistoryModal={setOpenIncidentHistoryModal}
                setOpenViewActivityModal={setOpenViewActivityModal}
                setOpenEditUsersModal={setOpenEditUsersModal}
                setOpenAssetManagementModal={setOpenAssetManagementModal}
                setOpenEditAssetModal={setOpenEditAssetModal}
                setOpenNewAssessmentModal={setOpenNewAssessmentModal}
                setOpenViewAssessmentModal={setOpenViewAssessmentModal}
                setOpenEditAssessmentModal={setOpenEditAssessmentModal}
                handleOpenAssetModal={handleOpenAssetModal}
                setFilteredAssets={setFilteredAssets}
                setActiveAssessment={setActiveAssessment}
                handleClickRating={handleClickRating}
                userRole={userRole}
                riskManagerRole={riskManagerRole}
            />

            <IncidentHistoryModal
                refetchQuery={refetchQuery}
                open={openIncidentHistoryModal}
                onClose={() => setOpenIncidentHistoryModal(false)}
                activeAsset={activeAsset}
            >
                <></>
            </IncidentHistoryModal>

            <ViewActivityModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                open={openViewActivityModal}
                activityType={activityType}
                activeAsset={activeAsset}
                onClose={() => setOpenViewActivityModal(false)}
            >
                <></>
            </ViewActivityModal>

            <EditUsersModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                activeAsset={activeAsset}
                open={openEditUsersModal}
                onClose={() => setOpenEditUsersModal(false)}
            >
                <></>
            </EditUsersModal>

            <AssetManagementModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                activeAsset={activeAsset}
                open={openAssetManagementModal}
                onClose={() => setOpenAssetManagementModal(false)}
                setActiveRow={setActiveRow}
                assetData={data}
            >
                <></>
            </AssetManagementModal>

            <EditAssetModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                open={openEditAssetModal}
                onClose={() => {
                    setOpenEditAssetModal(false);
                    setModalType('edit');
                }}
                type={modalType}
                activeAsset={activeAsset}
                assetData={data}
            >
                <></>
            </EditAssetModal>

            <NewAssessmentModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                open={openNewAssessmentModal}
                activityType={activityType}
                activeAsset={activeAsset}
                activeAssessment={activeAssessment}
                organizationDetails={organizationDetails}
                onClose={() => setOpenNewAssessmentModal(false)}
            >
                <></>
            </NewAssessmentModal>

            <ViewAssessmentModal
                refetchQuery={validRoleUser ? GetAssetsQuery : GetAssignedAssets}
                open={openViewAssessmentModal}
                activityType={activityType}
                activeAsset={activeAsset}
                activeAssessment={activeAssessment}
                onClose={() => setOpenViewAssessmentModal(false)}
            >
                <></>
            </ViewAssessmentModal>
        </Styles>
    );
}

export { AssetsDataTable };
