import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, ImpactLevel } from '@modules/types/graphql';

export type Entity = Maybe<Partial<ImpactLevel>>;

@DescribeEntity('MaturityLevelEntity')
class ImpactLevelEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(level: Entity) {
        super(level);

        this.id = level?.id ?? -1;
        this.name = level?.name ?? '';
    }
}

export { ImpactLevelEntity };
