import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { CustomItemCategoryCreateRequest } from '@modules/custom-components/requests';
import { CreateItemCategoryMutation } from '@modules/custom-components/graphql';

import type { CustomItemCategoryCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateItemCategoryMutationType,
    CreateItemCategoryMutationVariables,
} from '@modules/types/graphql';
import { MenuItem } from '@material-ui/core';
import { useRiskFunction } from '@modules/registry/hooks';
import { GetlistAllItemTypesQuery } from '@modules/registry/graphql';

type CustomItemCategoryCreateFormProps = {
    itemId?: number;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomItemCategoryCreateForm = (
    props: CustomItemCategoryCreateFormProps,
): React.ReactElement => {
    const { onCancel, itemId } = props;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { riskFunction, loading: riskFunctionLoading } = useRiskFunction();
    const [createItemCategory] = useMutation<
        CreateItemCategoryMutationType,
        CreateItemCategoryMutationVariables
    >(CreateItemCategoryMutation);

    const initialValues: CustomItemCategoryCreateFormValues = {
        name: '',
        itemId: itemId,
        riskFunctionId: null,
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = new CustomItemCategoryCreateRequest(values);
                    const { data: createItemCategoryData } = await createItemCategory({
                        variables,
                        refetchQueries: [{ query: GetlistAllItemTypesQuery }],
                        awaitRefetchQueries: true,
                    });

                    const categoryIds = createItemCategoryData?.createItemCategory?.id;

                    if (categoryIds) {
                        enqueueSuccess('Item Category successfully created!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while creating Item Category!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                } = formikProps;

                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Category Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <TextField
                            select
                            fullWidth
                            multiline
                            name='riskFunctionId'
                            // label='Select Risk Function'
                            error={!!errors.riskFunctionId}
                            helperText={errors.riskFunctionId}
                            value={values?.riskFunctionId || ''}
                            disabled={riskFunctionLoading}
                            onChange={handleChange}
                        >
                            <MenuItem value=''>
                                <em>Select Risk Function</em>
                            </MenuItem>
                            {riskFunction &&
                                riskFunction.map((Packages: { id: number; name: string }) => (
                                    <MenuItem key={Packages.id} value={Packages.id}>
                                        {Packages.name}
                                    </MenuItem>
                                ))}
                        </TextField>


                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Create Category
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomItemCategoryCreateForm };
