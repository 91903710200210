import styled from 'styled-components';

import { gray } from '@modules/ui/colors';
import { color } from 'html2canvas/dist/types/css/types/color';

const ListStyled = {
    Container: styled.div`
        margin-top: 32px;
        width: 100%;

        &.no-top-offset {
            margin-top: 0;
        }

        &.width-auto {
            width: auto;
        }

        .row-expand {
            cursor: pointer;
        }

        .collapsible-trigger {
            cursor: pointer;
        }

        .text-offset {
            margin-bottom: 10px;
        }
        .block-offset {
            margin-bottom: 20px;
        }

        strong {
            font-weight: 600;
        }

        .container-flex {
            display: flex;
            flex-flow: row nowrap;
        }
        .col-info {
            min-width: 200px;
            margin-right: 20px;
        }
        .col-table {
        }
        .col-table-full-width {
            width: 100%;
        }
    `,

    TextContent: styled.div`
        p.text-offset {
            margin-bottom: 10px;
        }

        .font-size-normal {
            font-size: 1.6rem;
        }
    `,

    Root: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
    `,

    Header: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;

        > h2 {
            font-size: 3.6rem;
        }

        @media print {
            > h2 {
                font-size: 2.4rem;
            }
        }
    `,

    HeaderSecondary: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin-top: 30px;

        > h2 {
            font-size: 3.6rem;
        }

        & > div + div {
            margin-left: 15px;
        }

        @media print {
            > h2 {
                font-size: 2.4rem;
            }
        }
    `,

    HeaderToolbar: styled.div`
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-top: 20px;

        .tab-header {
            margin-bottom: 20px;
            margin-right: 20px;

            button {
                flex-grow: 1;

                span {
                    font-size: 1.2rem;
                    font-weight: bold;
                    // text-transform: none;
                }
            }
        }
    `,

    HeaderInfo: styled.div`
        display: inline-flex;
        flex-wrap: wrap;
        margin-left: 64px;
        font-size: 1.6rem;

        > p {
            margin-right: 18px;
            font-size: 1.6rem;
            font-weight: 400;

            &:first-child {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }

        @media print {
            width: 100%;
            margin-left: 0;
            margin-top: 8px;
        }
    `,

    UserInfo: styled.div`
        display: inline-flex;
        flex-flow: row wrap;
        width: 300px;

        & > div {
            flex: 0 0 100%;
        }

        & > div + div {
            margin-top: 20px;
        }
    `,

    PieChartInfo: styled.div`
        font-size: 1.6rem;
        max-width: 400px;

        > p.chart-title {
            margin-bottom: 15px;
            font-size: 1.6rem;
            font-weight: 400;
            text-align: center;

            &:first-child {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }

        > p.chart-info {
            font-size: 1.2rem;
        }
    `,

    PieArcChartInfo: styled.div`
        font-size: 1.6rem;
        width: 400px;
        height: 250px;
        position: relative;
        overflow: hidden;

        > p.chart-title {
            margin-bottom: 15px;
            font-size: 1.6rem;
            font-weight: 400;
            text-align: center;

            &:first-child {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }

        > p.chart-info {
            font-size: 1.2rem;
        }

        .chart-align {
            transform: translateY(-70px);
        }

        .rating-cursor {
            bottom: 20px;
        }
        .rating-score {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            font-size: 16px;
            font-weight: bold;
            text-align: center;
        }
    `,

    PieChartComponentInfo: styled.div`
        max-width: 200px;
    `,

    BarChartInfo: styled.div`
        font-size: 1.6rem;
        min-width: 350px;
        max-width: 600px;

        > p {
            margin-bottom: 15px;
            font-size: 1.6rem;
            font-weight: 400;
            text-align: center;

            &:first-child {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }
    `,

    Top5TableBlock: styled.div`
        margin-bottom: 20px;
        font-size: 1.6rem;
        // width: 30%;
        width: 750px;
        overflow: hidden;

        > p {
            margin-bottom: 15px;
            font-size: 1.6rem;
            font-weight: 400;

            &:first-child {
                font-size: 1.8rem;
                font-weight: 700;
            }
        }

        @media print {
            width: 100%;
            margin-left: 0;
            margin-top: 8px;
        }
    `,
    Top5Table: styled.div`
        > div {
            display: flex;
            flex-flow: row nowrap;
        }

        .top5table-header {
            font-weight: 600;
        }

        > div + div {
            margin-top: 10px;
        }

        > div > div {
        }

        > div > div + div {
            margin-left: 10px;
        }
    `,

    Top5TableGap: styled.div`
        flex: 0 0 350px;
    `,
    Top5TableComponent: styled.div`
        flex: 0 0 150px;
    `,
    Top5TableComponentOwner: styled.div`
        flex: 0 0 125px;
    `,
    Top5TableRiskLevel: styled.div`
        flex: 0 0 100px;
    `,

    List: styled.div`
        width: 100%;
        margin-top: 32px;

        > * {
            margin-bottom: 6px;
        }
    `,

    Empty: styled.div`
        width: 100%;
        font-size: 1.8rem;
    `,
};

const ListItemStyled = {
    SummaryInfo: styled.div`
        min-width: 115px;
        margin-left: auto;
        font-size: 1.4rem;
        font-weight: 400;
    `,

    Details: styled.div`
        display: flex;

        > *:not(:last-child) {
            margin-right: 32px;
        }
    `,

    DetailsInfo: styled.div`
        font-size: 1.4rem;
        line-height: 22px;
    `,

    ComponentDetailsInfo: styled.div`
        font-size: 1.4rem;
        line-height: 22px;
        margin-bottom: 20px;
    `,

    DetailsToolbar: styled.div`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-top: 12px;

        > p {
            margin-left: 8px;
            color: ${gray[30]};
            font-size: 1.2rem;
        }
    `,
};

export { ListStyled, ListItemStyled };
