import React from 'react';

import { Modal } from '@modules/ui/core';
import { Loader } from '@modules/layout/moleculas';
import { useComponentAssessment } from '@modules/components/hooks';
import { AssessmentComponentForm } from './assessment-component-form';

import type { ModalProps } from '@modules/ui/core';
import type { ProjectEntity } from '@modules/projects/entities';
import type { AssessmentComponentChangesFormValues } from '@modules/assessment/requests';

type AssessmentComponentFormModalProps = Omit<ModalProps, 'title' | 'children'> & {
    type: 'add' | 'edit';
    project: ProjectEntity;
    componentAssessmentId?: number;
};

const AssessmentComponentFormModal = (
    props: AssessmentComponentFormModalProps,
): React.ReactElement | null => {
    const { type, project, componentAssessmentId, open, onClose, ...otherProps } = props;

    const isAddForm = type === 'add';

    const displayTitle = isAddForm ? 'Add component to the project' : 'Edit the project component';

    const { componentAssessment, loading: componentAssessmentLoading } = useComponentAssessment({
        fetchPolicy: 'network-only',
        skip: !componentAssessmentId,
        variables: { id: componentAssessmentId as number },
    });

    if (componentAssessmentLoading) {
        return (
            <Modal title={displayTitle} open={open} onClose={onClose} {...otherProps}>
                <Loader />
            </Modal>
        );
    }

    const initialValues: AssessmentComponentChangesFormValues = {
        weight: componentAssessment.weight ?? 1,
        component: componentAssessment.hasComponent() ? componentAssessment.component : null,
        componentOwner: componentAssessment.componentOwners[0] ?? null,
        objectives: componentAssessment.objectives,
        capabilities: componentAssessment.getCapabilitiesInput(),
    };


    if (!open) {
        return null;
    }

    return (
        <Modal title={displayTitle} open={open} onClose={onClose} {...otherProps}>
            <AssessmentComponentForm
                type={isAddForm ? 'add' : 'edit'}
                project={project}
                componentAssessmentId={componentAssessment.id}
                initialValues={initialValues}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { AssessmentComponentFormModal };
