import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { UserDetails } from '@modules/types/graphql';
import { UserRolesEntity } from './user-roles-entity';

export type UserDetEntity = Partial<UserDetails> | null;

@DescribeEntity('UserDetailsEntity')
class UserDetailsEntity extends BaseEntity {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    cognitoId: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    companyId: number;
    totalUsageTime: number;
    isEditable: boolean;
    userRoles: UserRolesEntity | any;

    constructor(user: UserDetails) {
        super(user);

        this.id = user?.id ?? -1;
        this.username = user?.username ?? '';
        this.email = user?.email ?? '';
        this.firstName = user?.first_name ?? '';
        this.lastName = user?.last_name ?? '';
        this.companyName = user?.company_name ?? '';
        this.cognitoId = user?.cognito_id ?? '';
        this.isAdmin = user?.is_admin ?? false;
        this.isSuperAdmin = user?.is_super_admin ?? false;
        this.companyId = user?.company_id ?? -1;
        this.userRoles = user?.user_role ?? '';
        this.totalUsageTime = user?.total_usage_time ?? '';
        this.isEditable = user?.is_editable ?? false;
    }

    getFullName() {
        const fullName = (this.firstName + ' ' + this.lastName).trim();

        return fullName;
    }

    isUserEditable() {
        const is_edit = this.isEditable === true;

        return is_edit;
    }
}

export { UserDetailsEntity };
