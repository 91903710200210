import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';

type DialogEditProps = {
    onUpdate: (id: any) => void;
    id: number;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogEdit = (props: DialogEditProps): React.ReactElement => {
    const { onUpdate, id } = props;

    return (
        <>
            <StyledDialog
                title='Are you sure you want to Update it?'
                acceptText='Update'
            // onAccept={}
            >
                {dialogProps => {
                    const { handleOpen } = dialogProps;

                    return <MenuItem onClick={
                        (e) => {
                            e.stopPropagation();
                            onUpdate(id)
                        }
                    }>Edit</MenuItem>;
                }}
            </StyledDialog>
        </>
    );
};

export { DialogEdit };
