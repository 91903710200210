import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { Button, Autocomplete } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Form, ExtendedFormik } from '@modules/layout/organisms';
import { useComponentUser, useUsers } from '@modules/users/hooks';
import {
    GetComponentAssessmentQuery,
    AssignAssessmentCapabilityToComponentOwnerMutation,
} from '@modules/assessment/graphql';

import type { UserListEntity } from '@modules/users/entities';

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const AssignAssessmentCapabilityToComponentOwnerForm = (props: any) => {
    const { componentOwnerId, componentAssessmentId, FormWrapComponent, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { users, loading: usersLoading } = useUsers();

    const { user, loading: userLoading } = useComponentUser({ variables: { surveyId: componentOwnerId } });

    const [assignSurveyToComponentOwner] = useMutation(AssignAssessmentCapabilityToComponentOwnerMutation);

    const initialValues: any = {
        componentAssessmentId,
        operator: user,
    };

    const getOperatorOptionLabel = React.useCallback(option => option?.getFullName() ?? '-', []);

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                operator: yup.mixed().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = {
                        assessmentId: componentAssessmentId,
                        userId: values.operator?.id,
                    };
                    const { data: assignOperatorData } = await assignSurveyToComponentOwner({
                        variables,
                        refetchQueries: [
                            {
                                query: GetComponentAssessmentQuery,
                                variables: { id: componentAssessmentId },
                            },
                        ],
                        awaitRefetchQueries: true,
                    });
                    if (assignOperatorData?.assignSurveyToComponentOwner?.id) {
                        enqueueSuccess('Component Owner successfully assigned!');
                        onCancel();
                    } else {
                        enqueueError('An error occurred while assigning the Component Owner!');
                    }
                } catch (e) {
                    console.log('Assign assessment to Component Owner form [Error]:', e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, setFieldValue, isSubmitting } = formikProps;

                const handleChangeOperator = (
                    _: React.ChangeEvent<{}>,
                    value: UserListEntity | null,
                ) => setFieldValue('operator', value);

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <FormStyled.FormRow>
                                <Autocomplete<UserListEntity | null, false, false, false>
                                    fullWidth
                                    id='operator'
                                    label='Operator'
                                    placeholder='Choose the Component Owner'
                                    options={users}
                                    value={values.operator}
                                    error={!!errors.operator}
                                    helperText={errors.operator as string}
                                    disabled={usersLoading || userLoading}
                                    getOptionLabel={getOperatorOptionLabel}
                                    onChange={handleChangeOperator}
                                />
                            </FormStyled.FormRow>

                            <FormStyled.FormRowSubmit>
                                <Button type='submit' loading={isSubmitting}>
                                    Assign Component Owner
                                </Button>

                                <Button variant='outlined' onClick={onCancel}>
                                    Cancel
                                </Button>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { AssignAssessmentCapabilityToComponentOwnerForm };
