import * as React from 'react';
import {
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    TableSortLabel,
    Table as MuiTable,
    makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { TableRow } from './table-row';
import { useProjectList } from '@modules/users/hooks';
import { TableCellText } from '@modules/layout/atoms';
import { UsersListItemActions } from '@modules/users/moleculas';
import { Loader } from '@modules/layout/moleculas';
import { useCurrentUser } from '@modules/auth/hooks';
import { ProjectListNameEntity, UserDetailsEntity } from '@modules/users/entities';
import { useDebounce } from '@helpers';
import { FullLoader } from '@components/three-dot-loader';
import { AuthContext } from '@modules/auth/context';

const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
        width: '100%',
    },
});

const ProjectNameText = styled.h4`
    text-transform: capitalize;
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
`;
const TextFieldStyle = styled.div`
    font-size: 13px;
    margin: 10px -6px -10px -6px;
    input {
        padding: 4px;
        border-right: transparent !important;
        border-top: transparent !important;
        border-left: transparent !important;
        border: 0.3px solid #a7a7a7;
    }

    input:focus {
        outline: none !important;
        border-bottom: 1px solid #605c5c;
    }
`;

interface UserDetailInterface {
    firstName: string;
    lastName: string;
    companyName: string;
    userRoles: string;
    email: string;
    totalUsageTime: string;
    projectName: string;
}
interface Data {
    firstName: string;
    lastName: string;
    companyId: string;
    userRoles: string;
    email: string;
    usage: string;
    actions: any;
}

type Order = 'asc' | 'desc';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};
type queryType = {
    value: any;
};
type viewUserAccess = {
    viewProjectName: boolean;
    viewUsage: boolean;
    actionAccess: boolean;
    viewCompanyName: boolean;
}
interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    columns: TableColumn[];
    setQuery: (value: any) => void;
    query: string;
    onChangeHandle: (e: React.ChangeEvent<HTMLInputElement>, columnId: any) => void;
    onVisibilityChange: (viewUserAccess: viewUserAccess) => void;
}

interface handleClickProps {
    usersDetails: any;
    selectedUserId: number | any;
    deleteUserLoading: boolean;
    usersDetailsLoading: boolean;
    handleClickRedirect: (userId: number) => void;
    canDeleteUser: boolean;
    columns: TableColumn[];
    handleDeleteUser: (userId: number) => Promise<void>;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    let { order, orderBy, columns, onRequestSort, setQuery, query, onChangeHandle, onVisibilityChange } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const [viewProjectName, setViewProjectName] = React.useState(false);
    const [viewUsage, setViewUsage] = React.useState(false);
    const [actionAccess, setActionAccess] = React.useState(false);
    const [viewCompanyName, setViewCompanyName] = React.useState(false);

    React.useEffect(() => {
        if (!user || !currentUser) return;

        const userRole = user?.roles[0];
        const isSuperAdmin = (userRole === 'super_admin' && currentUser.isSuperAdmin);
        const isCompanyAdmin = userRole === 'company_admin';
        const isProjectOrRiskAdmin = ['project_admin', 'risk_admin'].includes(userRole);

        setActionAccess(isSuperAdmin || isCompanyAdmin || isProjectOrRiskAdmin);
        setViewProjectName(isCompanyAdmin || isProjectOrRiskAdmin);
        setViewUsage(isSuperAdmin || isCompanyAdmin);
        setViewCompanyName(isSuperAdmin);

        if (onVisibilityChange) {
            onVisibilityChange({
                viewProjectName,
                viewUsage,
                actionAccess,
                viewCompanyName
            });
        }

    }, [user, currentUser, onVisibilityChange]);

    columns = columns?.filter((ele) => {
        if (!viewCompanyName && ele.id === 'companyName') return false;
        if (!viewUsage && ele.id === 'totalUsageTime') return false;
        if (!viewProjectName && ele.id === 'projectName') return false;
        if (!actionAccess && ele.id === 'actions') return false;
        return true;
    });

    if (userLoading || currentUserLoading) {
        return <Loader />;
    }
    return (
        <TableHead>
            <TableRow>
                {columns?.map((headCell, index) => (
                    <TableCell
                        style={{ textAlign: headCell.id === 'actions' ? 'right' : 'left' }}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id !== 'actions' ? (
                            <>
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                <TextFieldStyle>
                                    <input
                                        type='text'
                                        placeholder={headCell.label}
                                        onChange={e => onChangeHandle(e, headCell.id)}
                                    />
                                </TextFieldStyle>
                            </>
                        ) : (
                            'Actions'
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function TableSort(props: handleClickProps) {
    const {
        handleClickRedirect,
        canDeleteUser,
        columns,
        handleDeleteUser,
        selectedUserId,
        deleteUserLoading,
        usersDetailsLoading,
        usersDetails,
        ...otherProps
    } = props;
    const [order, setOrder] = React.useState<Order>('asc');
    const [query, setQuery] = React.useState<string>('');
    const debouncedQuery = useDebounce(query, 500);

    const [orderBy, setOrderBy] = React.useState<keyof Data>('firstName');
    const tableClasses = useTableStyles();
    const { projectListName, loading: projectListNameLoading } = useProjectList();
    const { currentUser } = useCurrentUser();
    const [filteredData, setFilteredData] = React.useState<any>([]);
    const [visibilityData, setVisibilityData] = React.useState({
        viewProjectName: false,
        viewUsage: false,
        actionAccess: false,
        viewCompanyName: false,
    });
    const userDetailDataWithProjectName = usersDetails.map((user: UserDetailsEntity) => {
        const projects = projectListName
            .filter((project: ProjectListNameEntity) => project.userId === user.id)
            .map((ele: ProjectListNameEntity) => ele.projectName);
        return {
            ...user,
            projectName: projects,
        };
    });
    console.log(visibilityData, 'visibilityData');
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    React.useEffect(() => {
        const filteredResults = userDetailDataWithProjectName.filter((item: any) => {
            if (Object.values(query).some(value => value !== '')) {
                return Object.keys(item).some((key: any) => {
                    const searchValue = query[key]?.toLowerCase();
                    if (searchValue) {
                        return (
                            item[key].toString().toLowerCase().includes(searchValue) ||
                            item[key].toString().toUpperCase().includes(searchValue)
                        );
                    }
                    return false;
                });
            } else {
                return true;
            }
        });
        setFilteredData(filteredResults);
    }, [debouncedQuery, query]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, columnId: string) => {
        const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
        setQuery((prevSearchQueries: any) => ({
            ...prevSearchQueries,
            [columnId]: searchValue,
        }));
    };

    React.useEffect(() => {
        setFilteredData(userDetailDataWithProjectName);
    }, [projectListName]);

    const handleVisibilityChange = (updatedVisibilityData: {
        viewProjectName: boolean;
        viewUsage: boolean;
        actionAccess: boolean;
        viewCompanyName: boolean;
    }) => {
        if (
            updatedVisibilityData.viewProjectName !== visibilityData.viewProjectName ||
            updatedVisibilityData.viewUsage !== visibilityData.viewUsage ||
            updatedVisibilityData.actionAccess !== visibilityData.actionAccess ||
            updatedVisibilityData.viewCompanyName !== visibilityData.viewCompanyName
        ) {
            setVisibilityData(updatedVisibilityData);
        }
    };
    if (usersDetailsLoading || projectListNameLoading) {
        return <Loader />;
    }
    return (
        <div>
            <TableContainer style={{ marginTop: ' 20px' }}>
                <MuiTable classes={tableClasses} {...otherProps}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={userDetailDataWithProjectName.length}
                        columns={columns}
                        setQuery={setQuery}
                        query={query}
                        onChangeHandle={handleSearch}
                        onVisibilityChange={handleVisibilityChange}
                    />
                    <TableBody
                        style={
                            deleteUserLoading
                                ? { background: 'transparent' }
                                : { background: '#e7e7e7' }
                        }
                    >
                        {filteredData
                            ?.sort((a: any, b: any) => {
                                if (orderBy === undefined) {
                                    return 0;
                                }
                                const aValue = a[orderBy];
                                const bValue = b[orderBy];

                                if (aValue < bValue) {
                                    return order === 'asc' ? -1 : 1;
                                }
                                if (aValue > bValue) {
                                    return order === 'asc' ? 1 : -1;
                                }
                                return 0;
                            })
                            .map((user: any) => {
                                return deleteUserLoading && selectedUserId === user?.id ? (
                                    <FullLoader />
                                ) : !visibilityData?.viewCompanyName ? (
                                    <TableRow
                                        key={user.id}
                                        size='m'
                                    // onClick={() => handleClickRedirect(user.id)}
                                    >
                                        <TableCell>
                                            <TableCellText>{user.firstName}</TableCellText>
                                        </TableCell>

                                        <TableCell>
                                            <TableCellText>{user.lastName}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{user.userRoles}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{user.email}</TableCellText>
                                        </TableCell>
                                        {visibilityData.viewUsage &&
                                            <TableCell>
                                                <TableCellText>{user.totalUsageTime} min</TableCellText>
                                            </TableCell>
                                        }
                                        {visibilityData.viewProjectName &&
                                            <TableCell>
                                                <TableCellText>
                                                    {user.projectName.map(
                                                        (ele: string[], index: number) => (
                                                            <ProjectNameText key={index}>
                                                                {ele}
                                                            </ProjectNameText>
                                                        ),
                                                    )}
                                                </TableCellText>
                                            </TableCell>
                                        }
                                        {visibilityData.actionAccess &&
                                            <TableCell align='right' onClick={e => e.stopPropagation()}>
                                                {(canDeleteUser && user?.isEditable) ? (
                                                    <UsersListItemActions
                                                        userId={user.id}
                                                        onUpdate={handleClickRedirect}
                                                        onDelete={handleDeleteUser}
                                                    />
                                                ) : null}
                                            </TableCell>
                                        }
                                    </TableRow>
                                ) : (
                                    <TableRow
                                        key={user.id}
                                        size='m'
                                    // onClick={() => handleClickRedirect(user.id)}
                                    >
                                        <TableCell>
                                            <TableCellText>{user.firstName}</TableCellText>
                                        </TableCell>

                                        <TableCell>
                                            <TableCellText>{user.lastName}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{user.companyName}</TableCellText>
                                        </TableCell>

                                        <TableCell>
                                            <TableCellText>{user.userRoles}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{user.email}</TableCellText>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellText>{user.totalUsageTime} min</TableCellText>
                                        </TableCell>
                                        <TableCell align='right' onClick={e => e.stopPropagation()}>
                                            {(canDeleteUser && user?.isEditable) ? (
                                                <UsersListItemActions
                                                    userId={user.id}
                                                    onUpdate={handleClickRedirect}
                                                    onDelete={handleDeleteUser}
                                                />
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
    );
}
export { TableSort };
