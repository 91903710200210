import { gql } from '@apollo/client';
import { RiskPostureFragment } from '../fragments';

export const GetRiskPostureQuery = gql`
    query listRiskPosture {
        listRiskPosture {
            ...RiskPosture
        }
    }
    ${RiskPostureFragment}
`;
