import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, AssignedUser } from '@modules/types/graphql';

export type Entity = Maybe<Partial<AssignedUser>>;

@DescribeEntity('AssignedUserEntity')
class AssignedUserEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(assignedUser: Entity) {
        super(assignedUser);

        this.id = assignedUser?.id ?? -1;
        this.name = assignedUser?.name ?? '';
    }
}

export { AssignedUserEntity };
