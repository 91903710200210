import React, { useContext, useState } from 'react';

import { PageStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import type { Page } from '@modules/types/page';

import styled from 'styled-components';
import { useAllItemTypes } from '@modules/registry/hooks';
import CategoryDataList from './category-data-list';
import { Loader } from '@modules/layout/moleculas';
import { AuthContext } from '@modules/auth/context';

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d3cece;
    margin-top: 20px;
    position: relative;
`;

const ItemDetailsPage = (props: Page): React.ReactElement => {
    const { title = 'Item Details' } = props;

    const { currentUser, loading: userLoading } = useCurrentUser();
    const { allItems, loading: allItemTypeLoading } = useAllItemTypes();
    const [expandCategory, setExpandCategory] = useState<boolean>(false);
    const context = useContext(AuthContext);

    const handleEventData = (event: any) => {
        const itemId = event.target.value;
        if (itemId) {
            context?.handleSetSelectedItemId(itemId);
            setExpandCategory(true);
        }
    };
    if (allItemTypeLoading || userLoading) {
        return <Loader />
    }
    return (
        <>
            {/* {currentUser.hasSuperAdminPermission() && */}
            <PageStyled.Header className='card_header_tab_style'>
                <PageStyled.HeaderTitle style={{ marginLeft: '20px' }}>
                    <a href='/components'>Category</a>
                </PageStyled.HeaderTitle>
                <PageStyled.HeaderTitle className='act'>
                    <span>Item Details</span>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>
            {/* } */}
            <Root>
                <div style={{ paddingBottom: '10px' }}>
                    <span style={{ fontWeight: 700, fontSize: 'x-large' }}>Item Type</span>
                </div>
                {allItems &&
                    allItems?.map(ele => {
                        return (
                            <React.Fragment key={ele.id}>
                                <AccordionListItem
                                    title={
                                        ele.name
                                    }
                                    componentActionStatus={true}
                                    subtitle=''
                                    componentSearch={false}
                                    SummaryInfoComponents={
                                        <></>
                                    }
                                    onClick={() => handleEventData({ target: { value: ele?.id } })}
                                >
                                    <CategoryDataList
                                        categoryData={ele?.itemCategory}
                                        itemId={ele.id}
                                        expandCategory={expandCategory}
                                        expandCategoryType={false}
                                    />
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })}
            </Root >
        </>
    );
};

export { ItemDetailsPage };
