import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { LearnTko } from '@modules/types/graphql';

export type Entity = Partial<LearnTko> | null;

@DescribeEntity('LearnsTkoEntity')
class LearnsTkoEntity extends BaseEntity {
    id: number;
    menu: string;
    title: string;
    // description: string;
    // videoUrl: string;
    // subTitleLink: string;
    // createdAt: string;

    constructor(learnsTko: Entity) {
        super(learnsTko);
        this.id = learnsTko?.id ?? -1;
        this.menu = learnsTko?.menu ?? '';
        this.title = learnsTko?.title ?? '';
        // this.description = learnsTko?.description ?? '';
        // this.videoUrl = learnsTko?.video_url ?? '';
        // this.subTitleLink = learnsTko?.sub_title_link ?? '';
        // this.createdAt = learnsTko?.created_at ?? '';
    }
}

export { LearnsTkoEntity };
