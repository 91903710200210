import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DocumentNode, useMutation } from '@apollo/client';

import { Modal } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ProjectCreateRequest } from '@modules/projects/requests';
import { ProjectForm, ProjectComponentsForm } from '@modules/projects/organisms';
import { GetAssignedProjectsQuery, CreateProjectMutation } from '@modules/projects/graphql';
import { ComponentAddRequest, ProjectAddComponentsFormValues } from '@modules/components/requests';
import { AddProjectComponentMutation } from '@modules/assessment/graphql';

import type { ModalProps } from '@modules/ui/core';
import type { ProjectCreateFormValues } from '@modules/projects/requests';
import type {
    Program,
    CreateProjectMutationType,
    CreateProjectMutationVariables,
    AddProjectComponentMutationType,
    AddProjectComponentMutationVariables,
} from '@modules/types/graphql';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import { useCompanies, useCompanyAccess } from '@modules/company/hooks';
import { RvHookup } from '@material-ui/icons';
import { useCurrentUser } from '@modules/auth/hooks';
import { AuthContext } from '@modules/auth/context';
import { routes } from '@config/routes';
import { navigate } from '@reach/router';
import { createRouteUrl } from '@lib/routing';
import { GPTLoader } from '@modules/layout/moleculas/gpt-loader';
import { Loader } from '@modules/layout/moleculas';

type ProjectCreateModalProps = Omit<ModalProps, 'title' | 'children'> & {
    projectType: string;
    refetchQuery: DocumentNode;
    project?: Program;
    projectFn?: () => Program;
};

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
    overflow: auto;
    padding: 10px;
    width: 100%;
`;

const ProjectCreateModal = (props: ProjectCreateModalProps): React.ReactElement => {
    const {
        onClose,
        project: projectRaw,
        projectType,
        projectFn,
        refetchQuery,
        ...otherProps
    } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { companies, loading: companiesLoading } = useCompanies();
    const [companyValue, setCompanyValue] = useState<any>(null);
    const [isPmRole, setIsPmRole] = useState<any>(null);
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const isAIAccess: boolean = companyAccess?.isAIAccess;
    const isSuperAdmin: boolean = currentUser.isSuperAdmin;
    useEffect(() => {
        const companyIds: any = companies && companies[0]?.id;
        setCompanyValue(companyIds);
    }, [companies]);

    useEffect(() => {
        if (currentUser.isAdmin === true && isSuperAdmin === true) {
            setIsPmRole(true);
        }
    }, [currentUser, isSuperAdmin]);

    const context = useContext(AuthContext);

    const [project, setProject] = usePropState<Partial<Program> | null>(
        projectRaw || (projectFn && projectFn()) || null,
    );
    const projectId = project?.id ?? null;

    useEffect(() => {
        if (!props.open) {
            document.body.style.paddingRight = '';
            document.body.style.overflow = '';
        }
    }, [props.open]);

    const [createProject] = useMutation<CreateProjectMutationType, CreateProjectMutationVariables>(
        CreateProjectMutation,
    );

    const [addComponent] = useMutation<
        AddProjectComponentMutationType,
        AddProjectComponentMutationVariables
    >(AddProjectComponentMutation);

    const addComponentsInitialValues: ProjectAddComponentsFormValues = {
        components: [],
        companyId: project?.company_id ?? null,
        assessmentTypeId: project?.assessment_type_id ?? null,
        projectId: project?.id ?? null,
    };

    let createInitialValues: ProjectCreateFormValues = {
        name: '',
        description: '',
        company: isPmRole ? null : companyValue,
        assessmentCategoriesId: null,
        tkoscope: null,
        stakeholder: null,
        assessmentTypeId: null,
        pmId: currentUser.id,
    };
    const handleSubmit = async (values: ProjectCreateFormValues): Promise<void> => {
        try {
            let variables = new ProjectCreateRequest(values);
            variables.pmId = currentUser.id;
            const { data: createProjectData } = await createProject({
                variables,
                refetchQueries: [{ query: refetchQuery }],
                awaitRefetchQueries: true,
            });

            const project: any = createProjectData?.createProgram;
            context?.handleSelectedProjectId(project?.id as number);

            if (!project?.id) {
                onClose();
                throw new Error('An error occurred while creating the project!');
            }

            enqueueSuccess('Project was successfully created!');
            setProject(project);
        } catch (e) {
            throw e;
        }
    };
    let redirectUrl = routes.projectAssessment;
    const handleSubmitAddComponents = async (
        values: ProjectAddComponentsFormValues,
    ): Promise<void> => {
        if (!projectId) {
            return;
        }

        try {
            const promises = [];

            for (const component of values.components) {
                const variables = new ComponentAddRequest(component, projectId);
                promises.push(addComponent({ variables }));
            }

            const results = await Promise.all(promises);

            const isSuccess =
                results.findIndex(result => !result.data?.addComponentToProgram?.id) === -1;

            if (isSuccess) {
                enqueueSuccess('Components were successfully added!');
                // navigate(`/projects/${selectedProjectId}/assessment`)
                navigate(createRouteUrl(redirectUrl, { id: context?.selectedProjectId }));
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                enqueueError('An error occurred while adding components to the project!');
            }

            setProject(null);
        } catch (e) {
            throw e;
        } finally {
            onClose();
        }
    };

    const title = !projectId ? 'Create New Project' : 'Add Components';

    if (currentUserLoading) {
        return <Loader />;
    }
    return (
        <Modal title={title} onClose={onClose} {...otherProps}>
            <>
                {!projectId && (
                    <ProjectForm
                        initialValues={createInitialValues}
                        FormWrapComponent={FormWrap}
                        onSubmit={handleSubmit}
                        onCancel={onClose}
                        isPmRole={isPmRole}
                        isAIAccess={isAIAccess}
                        isSuperAdmin={isSuperAdmin}
                    />
                )}

                {!!projectId && (
                    <ProjectComponentsForm
                        projectType={projectType}
                        initialValues={addComponentsInitialValues}
                        FormWrapComponent={FormWrap}
                        onSubmit={handleSubmitAddComponents}
                        onCancel={() => {
                            setProject(null);
                            onClose();
                        }}
                    />
                )}
            </>
        </Modal>
    );
};

export { ProjectCreateModal };
