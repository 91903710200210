import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { useMountedState } from 'react-use';
import { Formik, Form } from 'formik';
import { FormControl } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { white, gray } from '@modules/ui/colors';
import { routes } from '@config/routes';
import { Hint } from '@modules/layout/atoms';
import { TextField } from '@modules/layout/moleculas';

import type { CognitoUser } from 'amazon-cognito-identity-js';

type ConfirmSigninFormProps = {
    className?: string;
    user: CognitoUser | any;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    padding: 24px;
    background-color: ${white[100]};
    border: 1px solid ${gray[50]};
    border-radius: 4px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
        letter-spacing: 0.5px;
        text-align: center;
    }

    > h3 {
        font-size: 3rem;
        text-transform: uppercase;
    }

    > p {
        margin-top: 8px;
        font-size: 1.4rem;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledFrom = styled(Form)`
    width: 100%;

    > * {
        margin-bottom: 18px;
    }
`;

const StyledHint = styled(Hint)`
    margin: 12px 0 0 0;
`;

const ConfirmSigninForm = (props: ConfirmSigninFormProps): React.ReactElement => {
    const { user } = props;

    const isMounted = useMountedState();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    return (
        <Root {...props}>
            <Header>
                <h3>Verify that it's you</h3>
                <p>Enter the verification code from your phone app</p>
            </Header>

            <Main>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        code: yup.string().required('Required field'),
                    })}
                    initialValues={{
                        code: '',
                    }}
                    initialStatus={{
                        error: '',
                    }}
                    onSubmit={async (values, helpers) => {
                        const { setStatus, setSubmitting } = helpers;

                        setSubmitting(true);

                        try {
                            await Auth.confirmSignIn(user, values.code, 'SOFTWARE_TOKEN_MFA');

                            await apolloClient.resetStore();
                            await navigate(routes.welcome.path);
                        } catch (e: any) {
                            setStatus({ error: 'message' in e ? e.message : 'Unexpected error' });
                        } finally {
                            if (isMounted()) {
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {formikProps => {
                        const {
                            status,
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        } = formikProps;

                        return (
                            <StyledFrom>
                                <TextField
                                    fullWidth
                                    id='code'
                                    label='Verification code'
                                    placeholder='Verification code'
                                    value={values.code}
                                    error={!!errors.code}
                                    helperText={errors.code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormControl fullWidth>
                                    <Button type='submit' loading={isSubmitting}>
                                        Verify and sign in
                                    </Button>

                                    {!!status.error ? (
                                        <StyledHint error text={status.error} />
                                    ) : null}
                                </FormControl>
                            </StyledFrom>
                        );
                    }}
                </Formik>
            </Main>
        </Root>
    );
};

export { ConfirmSigninForm };
