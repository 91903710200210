import { BaseEntity, Entity as DescribeEntity, RelationList } from '@lib/entity';

import type { CategoryTypeDetails } from '@modules/types/graphql';
import type { Entity as DefaultTypeEntityType } from './default-type-entity';
import { DefalutTypeEntity } from './default-type-entity';
import { DefaultTypeEntityList } from './default-type-entity-list';

export type CategoryTypeDetailsEntityType = Partial<
    Omit<CategoryTypeDetails, 'defaultType'> & {
        defaultType: DefaultTypeEntityType[] | null;
    }
> | null;

@DescribeEntity('CategoryTypeDetailsEntity')
class CategoryTypeDetailsEntity extends BaseEntity {
    id: number;
    name: string;
    categoryId: number;
    companyId: number;

    @RelationList(() => DefaultTypeEntityList)
    defaultType: DefalutTypeEntity[];

    constructor(categorytype: CategoryTypeDetailsEntityType) {
        super(categorytype);

        this.id = categorytype?.id ?? -1;
        this.name = categorytype?.name ?? '';
        this.categoryId = categorytype?.category_id ?? -1;
        this.companyId = categorytype?.company_id ?? -1;
    }

    isPredefined() {
        const result = this.companyId === -1;

        return result;
    }
}

export { CategoryTypeDetailsEntity };
