import { gql } from '@apollo/client';

export const GetComponentsQuery = gql`
    query GetComponents($filter: String, $companyId: Int, $assessmentTypeId: Int, $projectId: Int) {
        listComponents(
            filter: $filter
            company_id: $companyId
            assessment_type_id: $assessmentTypeId
            project_id: $projectId
        ) {
            id
            name
            company_id
            assessment_type_name
            capabilities {
                id
                title
                key_factors
            }
        }
    }
`;
