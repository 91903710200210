import { gql } from '@apollo/client';

export const RecommendationSingleFragment = gql`
    fragment RecommendationSingle on Recommendation {
        id
        gap_id
        recommendation
        company_id
        is_ai_recommendations
    }
`;
