import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Button, Modal, ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { useListRecommendationGenerationCount } from '@modules/company/hooks';
import { Loader } from '@modules/layout/moleculas';

type RecommendationsCountModalProps = Omit<ModalProps, 'title' | 'children'> & {
    capability?: string;
    gap?: string;
    default_count: number | 3;
    handleSelectRecommendationCount: (count: string, count_id: number) => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const Info = styled.div`
    font-size: 1.4rem;
`;

const RecommendationsCountModal = (props: RecommendationsCountModalProps): React.ReactElement => {
    const {
        gap,
        capability,
        default_count = 3,
        handleSelectRecommendationCount,
        open,
        onClose,
        ...otherProps
    } = props;
    const {
        generationCount,
        loading: generationCountLoading,
    } = useListRecommendationGenerationCount();

    const initialValues = {
        default_count: default_count,
    };

    if (generationCountLoading) {
        return <Loader />;
    }

    return (
        <Modal
            title={
                gap
                    ? 'Select Recommendation Generation Count.'
                    : capability
                    ? 'Select Recommendation Generation Count under Capability Gaps.'
                    : ''
            }
            open={open}
            onClose={onClose}
            transitionDuration={{ enter: 300, exit: 0 }}
            {...otherProps}
            className='select_recommendation'
        >
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const levelName = generationCount.find(
                        (count: any) => count.id === values.default_count,
                    ).name;
                    handleSelectRecommendationCount(levelName, values.default_count);
                    onClose();
                }}
            >
                {formikProps => {
                    const { values, setFieldValue, isSubmitting } = formikProps;
                    const handleChangeCheckbox = (countId: number) => (
                        _: React.ChangeEvent<{}>,
                        checked: boolean,
                    ) => {
                        if (checked) {
                            setFieldValue('default_count', countId);
                        }
                    };
                    return (
                        <StyledForm>
                            <FormStyled.FormColumn>
                                <FormStyled.FormRow>
                                    <Info>{gap ? gap : capability ? capability : ''}</Info>
                                </FormStyled.FormRow>
                                <FormStyled.FormRow>
                                    {generationCount.map((count: any) => (
                                        <FormControlLabel
                                            key={count.id}
                                            label={count.name}
                                            control={
                                                <Checkbox
                                                    checked={values.default_count === count.id}
                                                    onChange={handleChangeCheckbox(count.id)}
                                                />
                                            }
                                        />
                                    ))}
                                </FormStyled.FormRow>
                                <FormStyled.FormRowSubmit>
                                    <Button type='submit' loading={isSubmitting}>
                                        Continue...
                                    </Button>

                                    <Button
                                        variant='outlined'
                                        disabled={isSubmitting}
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </Button>
                                </FormStyled.FormRowSubmit>
                            </FormStyled.FormColumn>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </Modal>
    );
};

export { RecommendationsCountModal };
