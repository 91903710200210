import React from 'react';
import styled from 'styled-components';
import { makeStyles, Dialog, Backdrop, Fade } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { white } from '@modules/ui/colors';
import { concatenateClasses } from '@modules/ui/utils';

import type { DialogProps as MuiModalProps } from '@material-ui/core';

export type ModalProps = Omit<MuiModalProps, 'children' | 'onClose'> & {
    children: React.ReactNode;
    onClose: () => void;
    title?: string;
    hideCloseIcon?: boolean;
    editAssetCloseIcon?: boolean;
    className?: string;
    refetchQuery?: any;
    refetchQueries?: any;
};

const useDialogStyles = makeStyles({
    paper: {
        maxWidth: 'initial',
    },
});

const Container = styled.div`
    position: relative;
    min-width: 550px;
    max-width: 992px;
    background-color: ${white[100]};
    border-radius: 4px;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    min-height: 56px;
    padding: 24px 32px 0 32px;
    font-family: 'Montserrat', Arial, sans-serif;

    ${p => p.theme.breakpoints.down('sm')} {
        height: auto;
        min-height: 56px;
    }
`;

const Title = styled.div`
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 28px;
`;

const CloseTrigger = styled.div`
    position: absolute;
    right: 32px;
    height: 38px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
`;

const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 24px 32px;
`;

const closeIcon = <Close style={{ fontSize: 38 }} />;

const Modal = (props: ModalProps): React.ReactElement => {
    const {
        title,
        classes,
        open,
        children,
        className,
        hideCloseIcon,
        editAssetCloseIcon,
        onClose,
        ...otherProps
    } = props;

    const dialogClasses = concatenateClasses(useDialogStyles(), classes);

    const showHeader = !!title && !hideCloseIcon;
    return (
        <Dialog
            closeAfterTransition
            classes={dialogClasses}
            open={open}
            onClose={onClose}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            {...otherProps}
        >
            <Fade in={open}>
                <Container className={className}>
                    {showHeader ? (
                        <Header>
                            {title ? <Title>{title}</Title> : null}
                            {hideCloseIcon || editAssetCloseIcon ? null : (
                                <CloseTrigger onClick={onClose}>{closeIcon}</CloseTrigger>
                            )}
                        </Header>
                    ) : null}

                    <Body>{children}</Body>
                </Container>
            </Fade>
        </Dialog>
    );
};

export { Modal };
