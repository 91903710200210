import React from 'react';
import styled from 'styled-components';
import { PageStyled } from '@modules/layout/styled';
import { TableCellText } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
import { Table } from '@modules/layout/organisms';
import { CompaniesListToolbar, CompaniesListItemActions } from '@modules/company/moleculas';

import { TableColumn } from '@modules/layout/organisms/table';
import type { Page } from '@modules/types/page';
import { useCompanyDetails } from '@modules/company/hooks/use-company-details';
import { useGapRecommendationsCount } from '@modules/custom-components/hooks';
import { Check, Close } from '@material-ui/icons';
import { AllProjectDataTable } from '@modules/projects/moleculas';
import { useCompaniesCount } from '@modules/company/hooks';
import { AuthContext } from '@modules/auth/context';

type GapRecomCountPropsType = {
    companyId: number;
    companyName: string;
    aiAccess: boolean;
    projectsCount: number;
    remainingProjects: number;
    gapsCount: number;
    aiGapsCount: number;
    recommendationsCount: number;
    aiRecommendationsCount: number;
    tasksCount: number;
    aiTasksCount: number;
    subTasksCount: number;
    aiSubTasksCount: number;
};

const columns: TableColumn[] = [
    {
        id: 'serial',
        label: '',
    },
    {
        id: 'name',
        label: 'Company Name',
    },
    {
        id: 'schema_name',
        label: 'Schema Name',
    },
    {
        id: 'action',
        label: '',
    },
];

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;

const AllProjectCount = (props: Page): React.ReactElement => {
    const { title = 'All Project Count' } = props;
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const { companiesCount, loading: companiesCountLoading } = useCompaniesCount();

    if (companiesCountLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            {/* <PageStyled.Bridge>
                <CompaniesListToolbar />
            </PageStyled.Bridge> */}

            <Main style={{ display: 'unset' }}>
                {(user?.roles[0] === 'super_admin') &&
                    (
                        <AllProjectDataTable
                            columns={columns}
                            allProjects={companiesCount}
                            projectsLoading={companiesCountLoading}
                        />
                    )
                }
            </Main>
        </>
    );
};

export { AllProjectCount };
