import React from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';

import { Button } from '@modules/ui/core';
import { routes } from '@config/routes';
import { AuthContext } from '@modules/auth/context';
import { Loader } from '@modules/layout/moleculas';

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
`;

const CompaniesListToolbar = (): React.ReactElement => {
    const navigate = useNavigate();
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const handleClickRedirect = (): void => {
        navigate(routes.companyCreate.path);
    };

    if (userLoading) {
        return <Loader />
    }
    return (
        <Root>
            {
                (user?.roles[0] === 'super_admin') && (
                    <>
                        <Actions>
                            <ButtonCreateLink onClick={handleClickRedirect}>
                                Create new company
                            </ButtonCreateLink>
                        </Actions>
                    </>
                )
            }

        </Root>
    );
};

export { CompaniesListToolbar };
