import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useMutation } from '@apollo/client';
import { AddAssetIncident, DeleteAssetIncident, UpdateAssetIncident } from '@modules/registry/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { Modal, Button, TextField } from '@modules/ui/core';
import { Table, TableHead, TableBody, TableCell, TableRow, MenuItem } from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import { ExtendedFormik } from '@modules/layout/organisms';
import { Asset } from '@modules/types/graphql';
import { useImpactLevels } from '@modules/shared/hooks';
import { Edit, Delete, Save, Cancel } from '@material-ui/icons';
import * as yup from 'yup';
const StyledModal = styled(Modal)`
    // width: 1200px;
    width: 100%;
    min-width: 100%;
    >div>div:nth-child(1) {
        padding-right: 35px;
    }
    .is-empty-results {
        td {
            padding: 20px;
            font-size: 14px;
            text-align: center;
        }
    }
`;

const IconButton = styled(Button)`
    min-width: 10px;
    svg {
        font-size: 20px;
        font-weight: 700;
    }
`;

const initialValues = {
    created_at: format(new Date(), 'yyyy-MM-dd'),
    description: '',
    impact: '',
};

const IncidentHistoryModal = (props: ModalProps & { activeAsset: Asset }): React.ReactElement => {
    const { onClose, activeAsset, refetchQuery, ...otherProps } = props;
    const [editingIncidentId, setEditingIncidentId] = useState<string | null>(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [loadingItems, setLoadingItems] = useState<Record<string, boolean>>({});
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [addAssetIncident] = useMutation(AddAssetIncident);
    const [editIncident, setEditIncident] = useState<any | null>(null);
    const [updateAssetIncident] = useMutation(UpdateAssetIncident);
    const [deleteAssetIncident] = useMutation(DeleteAssetIncident);
    const { ImpactLevels, loading: isImpactLoading } = useImpactLevels();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const handleEditClick = (incident: any) => {
        setEditingIncidentId(incident.id);
        setIsAddingNew(false);
        setEditIncident({ ...incident, created_at: format(new Date(incident.date), 'yyyy-MM-dd') });
    };

    const incidentData = (date: string) => {
        const utcDate = new Date(date);
        const zonedDate = utcToZonedTime(utcDate, timeZone);
        const originalDay = utcDate.getUTCDate();
        const zonedDay = zonedDate.getDate();
        let adjustedDate = zonedDate;
        if (zonedDay < originalDay) {
            adjustedDate = new Date(zonedDate.getTime() + 24 * 60 * 60 * 1000);
        }
        const formattedDate = format(adjustedDate, 'MM/dd/yyyy');
        return formattedDate;
    };

    const handleAddNewClick = () => {
        setEditingIncidentId(null);
        setIsAddingNew(true);
    };
    const handleDeleteIncident = async (id: string) => {
        setLoadingItems(prev => ({ ...prev, [id]: true }));
        try {
            await deleteAssetIncident({
                variables: { incidentId: id },
                refetchQueries: [{ query: refetchQuery }],
                awaitRefetchQueries: true,
            });
            enqueueSuccess('Incident deleted successfully!');
        } catch (e) {
            console.log(e);
            enqueueError('An error occurred while deleting the incident.');
        } finally {
            setLoadingItems(prev => ({ ...prev, [id]: false }));
        }
    };

    return (
        <StyledModal title={'"' + activeAsset?.name + '" ' + activeAsset?.itemType?.name + ' - Incidents'} onClose={onClose} {...otherProps}>
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={yup.object({
                    description: yup
                        .string()
                        .matches(/^[A-Za-z0-9#*-]/, 'Required field')
                        .required('Required field'),
                    impact: yup.mixed().required('Required field'),
                })}
                initialValues={
                    isAddingNew
                        ? initialValues
                        : editIncident || initialValues
                }
                onSubmit={async values => {
                    const { created_at, description, impact } = values;
                    try {
                        if (editingIncidentId) {
                            await updateAssetIncident({
                                variables: {
                                    input: {
                                        id: editingIncidentId,
                                        date: new Date(created_at),
                                        description: description?.replace(/'+/g, "'"),
                                        impact: impact,
                                    },
                                },
                                refetchQueries: [{ query: refetchQuery }],
                                awaitRefetchQueries: true,
                            });
                            enqueueSuccess('Asset incident successfully updated!');
                            setEditingIncidentId(null);
                        } else {
                            const { data: addAssetIncidentData } = await addAssetIncident({
                                variables: {
                                    input: {
                                        asset_id: activeAsset?.id,
                                        date: new Date(created_at),
                                        description: description?.replace(/'+/g, "'"),
                                        impact,
                                    },
                                },
                                refetchQueries: [{ query: refetchQuery }],
                                awaitRefetchQueries: true,
                            });

                            if (addAssetIncidentData?.addAssetIncident?.id) {
                                enqueueSuccess('Asset incident successfully added!');
                            } else {
                                enqueueError('An error occurred while adding asset incident!');
                            }
                        }
                        onClose();
                        setIsAddingNew(false);
                        setEditingIncidentId(null);
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                        handleSubmit,
                    } = formikProps;
                    const inputNumberMask = /^[0-9]*$/;
                    const handleChangeWithMask = (
                        mask: RegExp,
                        name: string,
                        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        if (mask.test(e.target.value)) {
                            setFieldValue(name, e.target.value);
                        }
                    };

                    return (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Impact</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeAsset?.incidents && activeAsset?.incidents.length ? (
                                        activeAsset?.incidents.map((data: any, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {editingIncidentId === data.id ? (
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            type='date'
                                                            id='created_at'
                                                            label='Created date'
                                                            value={values.created_at}
                                                            error={!!errors.created_at}
                                                            helperText={errors.created_at}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    ) : (
                                                        // format(new Date(data?.date), 'MM/dd/yyyy')
                                                        // format(utcToZonedTime(new Date(data?.date), timeZone), 'MM/dd/yyyy')
                                                        // format(
                                                        //     new Date(new Date(data?.date).getTime() + new Date(data?.date).getTimezoneOffset() * 60 * 1000),
                                                        //     'MM/dd/yyyy'
                                                        // )

                                                        // console.log('Formatted Date:', utcToZonedTime(new Date(data?.date), timeZone));
                                                        incidentData(data?.date)
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ maxWidth: '300px' }}>
                                                    {editingIncidentId === data.id ? (
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            id='description'
                                                            label='Description'
                                                            value={values.description?.replace(/'+/g, "'")}
                                                            error={!!errors.description}
                                                            helperText={errors.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    ) : (
                                                        data?.description?.replace(/'+/g, "'")
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {editingIncidentId === data.id ? (
                                                        <TextField
                                                            select
                                                            fullWidth
                                                            id='impact'
                                                            name='impact'
                                                            label='Impact'
                                                            className='is-select'
                                                            value={values.impact}
                                                            error={!!errors.impact}
                                                            helperText={errors.impact}
                                                            InputLabelProps={{ shrink: true }}
                                                            disabled={isImpactLoading}
                                                            onChange={handleChange}
                                                            style={{ width: '170px' }}
                                                        >
                                                            {ImpactLevels ? (
                                                                ImpactLevels.map(impact => {
                                                                    const { id, name } = impact;
                                                                    return (
                                                                        <MenuItem key={id} value={name}>
                                                                            {name}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            ) : (
                                                                <MenuItem key={0} value={''}>
                                                                    Select Impact
                                                                </MenuItem>
                                                            )}
                                                        </TextField>
                                                    ) : (
                                                        data?.impact || 'Pending'
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ whiteSpace: 'nowrap', }}>
                                                    {editingIncidentId === data.id ? (
                                                        <>
                                                            <IconButton
                                                                type='submit'
                                                                loading={isSubmitting}
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                <Save style={{ margin: '5px 5px 0 0' }} />
                                                            </IconButton>
                                                            <IconButton
                                                                variant='text'
                                                                disabled={isSubmitting}
                                                                onClick={() => {
                                                                    setEditingIncidentId(null);
                                                                    setIsAddingNew(false);
                                                                }}
                                                                style={{ marginLeft: '20px' }}
                                                            >
                                                                <Cancel style={{ margin: '5px 5px 0 0' }} />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconButton
                                                                size='extra-small'
                                                                onClick={() => handleEditClick(data)}
                                                            >
                                                                <Edit style={{ margin: '5px 5px 0 0' }} />
                                                            </IconButton>
                                                            <IconButton
                                                                size='extra-small'
                                                                loading={loadingItems[data.id] || false}
                                                                onClick={() => handleDeleteIncident(data.id)}
                                                                style={{ marginLeft: '20px' }}
                                                            >
                                                                <Delete style={{ margin: '5px 5px 0 0' }} />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow className='is-empty-results'>
                                            <TableCell colSpan={6}>No history</TableCell>
                                        </TableRow>
                                    )}
                                    {isAddingNew && (
                                        <TableRow>
                                            <TableCell>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type='date'
                                                    id='created_at'
                                                    label='Created date'
                                                    value={values.created_at}
                                                    error={!!errors.created_at}
                                                    helperText={errors.created_at}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id='description'
                                                    label='Description'
                                                    value={values.description}
                                                    error={!!errors.description}
                                                    helperText={errors.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    id='impact'
                                                    name='impact'
                                                    label='Impact'
                                                    className='is-select'
                                                    value={values.impact}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    disabled={isImpactLoading}
                                                    style={{ width: '170px' }}
                                                >
                                                    {ImpactLevels ? (
                                                        ImpactLevels.map(impact => {
                                                            const { id, name } = impact;
                                                            return (
                                                                <MenuItem key={id} value={name}>
                                                                    {name}
                                                                </MenuItem>
                                                            );
                                                        })
                                                    ) : (
                                                        <MenuItem key={0} value={''}>
                                                            Select Impact
                                                        </MenuItem>
                                                    )}
                                                </TextField>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    type='submit'
                                                    loading={isSubmitting}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant='text'
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setIsAddingNew(false);
                                                        setEditingIncidentId(null);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            {!isAddingNew && (
                                <div style={{ marginTop: 20 }}>
                                    <Button onClick={handleAddNewClick}>+ Add</Button>
                                </div>
                            )}
                        </>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { IncidentHistoryModal };
