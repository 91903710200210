import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { MenuCards } from '@modules/types/graphql';

export type Entity = Partial<MenuCards> | null;

@DescribeEntity('AllMenuCardsEntity')
class AllMenuCardsEntity extends BaseEntity {
    id: number;
    menuTitle: string;
    description: string;
    imageUrl: string;
    createdAt: string;

    constructor(menuCards: Entity) {
        super(menuCards);
        this.id = menuCards?.id ?? -1;
        this.menuTitle = menuCards?.menu_title ?? '';
        this.description = menuCards?.description ?? '';
        this.imageUrl = menuCards?.image_url ?? '';
        this.createdAt = menuCards?.created_at ?? '';
    }
}

export { AllMenuCardsEntity };
