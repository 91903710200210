import React, { useState } from 'react';
import styled from 'styled-components';

import { InnerListItemStyled } from '@modules/layout/styled';
import { CounterHint } from '@modules/layout/atoms';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { CapabilityMaturityLevel } from '@modules/capabilities/moleculas';
import { RoadmapRecommendationsList } from '@modules/roadmap/organisms';
import { RemoveRedEyeRounded } from '@material-ui/icons';
import type { ProjectEntity } from '@modules/projects/entities';
import type {
    ComponentAssessmentEntity,
    CapabilityAssessmentEntity,
} from '@modules/assessment/entities';
import { useCurrentUser } from '@modules/auth/hooks';
import { useOrganizationDetails, useProjectDetailsQuery } from '@modules/components/hooks';
import { useCompanyAccess, useTkoScope } from '@modules/company/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { ChatGptInstance } from '@helpers';
import { RecommendationCreateRequest } from '@modules/custom-components/requests';
import { useMutation } from '@apollo/client';
import {
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
    DeleteGapRecommendationMutationType,
    DeleteGapRecommendationMutationVariables,
    SetRecommendationsMutationType,
    SetRecommendationsMutationVariables,
} from '@modules/types/graphql';
import {
    CreateRecommendationMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';
import { SetRecommendationsMutation } from '@modules/roadmap/graphql';
import { Loader } from '@modules/layout/moleculas';
import { VisibilityOff } from '@material-ui/icons';
import { GetProjectQuery } from '@modules/projects/graphql';
import { DeleteGapRecommendationMutation } from '@modules/custom-components/graphql/mutations';
import { RecommendationsCountModal } from '../recommendation-count-modal';

type RoadmapCapabilitiesAssessmentListItemProps = {
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    capabilityAssessment: CapabilityAssessmentEntity;
};

const SummaryInfoCounter = styled(InnerListItemStyled.SummaryInfo)`
    margin-left: auto;
    margin-right: 32px;
`;

const EyeInfo = styled.div<{ isAIAccess: boolean; generated: string }>`
    color: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : '#9d9d9d'};
    cursor: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'not-allowed'};
    pointer-events: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'none'};
    > svg {
        font-size: 4rem;
        text-align: center;
        cursor: pointer;
        margin-right: 35px;
    }
    &:hover {
        color: #5a5a5a;
    }
`;

const RoadmapCapabilitiesAssessmentListItem = (
    props: RoadmapCapabilitiesAssessmentListItemProps,
) => {
    const { project, componentAssessment, capabilityAssessment } = props;
    const [
        recommendationGenerationLoading,
        setRecommendationGenerationLoading,
    ] = React.useState<boolean>(false);

    const { currentUser, loading: currentUserLoading } = useCurrentUser();

    const projectId = project.id;
    const userId = currentUser.id;

    const recommendationsLength = capabilityAssessment.scoredGaps.reduce((carry, scoredGap) => {
        const recommendations = scoredGap?.roadmapRecommendations;

        if (recommendations) {
            carry += recommendations.length;
        }

        return carry;
    }, 0);

    const [recommendationData, setRecommendationData] = useState({
        recommendations: [] as number[],
    });

    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);

    const [setRecommendations] = useMutation<
        SetRecommendationsMutationType,
        SetRecommendationsMutationVariables
    >(SetRecommendationsMutation);
    const [deleteGapRecommendation, { loading: deleteGapRecommendationLoading }] = useMutation<
        DeleteGapRecommendationMutationType,
        DeleteGapRecommendationMutationVariables
    >(DeleteGapRecommendationMutation);
    const [
        openDialogSelectRecommendationModal,
        setOpenDialogSelectRecommendationModal,
    ] = useState<boolean>(false);
    const handleOpenDialogSelectRecommendationModal = (): void =>
        setOpenDialogSelectRecommendationModal(true);
    const handleCloseDialogSelectRecommendationModal = (): void =>
        setOpenDialogSelectRecommendationModal(false);

    const { projectDetails, loading: projectDetailLoading } = useProjectDetailsQuery({
        skip: !projectId,
        variables: { id: projectId as number },
    });
    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const isAIAccess: boolean = companyAccess?.isAIAccess;
    //Get Assessment Type Name
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const filteredAssessmentTypes = assessmentTypes.filter(
        type => type.id === projectDetails.assessmentTypeId,
    );
    const assessmentTypeNames = filteredAssessmentTypes.map((type: any) => type.name);
    const assessmentTypeName = assessmentTypeNames[0];

    //Get Tko Scope Name
    // const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();
    // const filteredTkoscopes = tkoscopes?.filter(type => type?.id === projectDetails?.tkoScopeId);
    // const tkoScopeNames = filteredTkoscopes.map(type => type.name);
    // const tkoScopeName = tkoScopeNames[0];

    async function saveDataInDataBase(
        gapData: any,
        companyId: any,
        count: string,
        count_id: number,
    ) {
        let queryStart =
            'Take on the role of a risk management consultant hired by a company to provide expert advice. The company is a ';
        let employees = 'employees whose workforce is primarily';
        let beforeGap = 'assessment of the company and have identified the following gap: ';
        let recommendationDetails =
            'recommendations the company can implement to improve this gap? The company has the following controls in place: ';
        let afterRecommendation =
            'Please cite all privacy and data protection regulations (GDPR, DSS PCI, SOX, etc.) and security standards (NIST, ISO, etc.) that apply to';
        let queryEnd =
            'companies. Please provide specific sections of each relevant regulatory and security standard that apply to each recommendation and provide your recommendations in a numbered sequence.  Do not include a heading, summary, or any introductory text—just the Top';

        let chatGPTQuery = `${queryStart} ${organizationDetails.organizationType} located in  ${organizationDetails?.orgLocation} in the ${organizationDetails?.sector} sector with ${organizationDetails.noOfEmployees} ${employees} ${organizationDetails.workforce}. You have just completed a ${assessmentTypeName} ${beforeGap} ${gapData?.gap?.gap} What are the ${count} ${recommendationDetails} ${capabilityAssessment?.notes}. The company wants to: ${capabilityAssessment?.recommendations} ${afterRecommendation} ${organizationDetails?.sector} ${queryEnd} ${count} recommendations with applicable regulations and standards.`;
        setRecommendationGenerationLoading(true);

        const chatGptResponse = async (prompt: string) => {
            const completion: any = await ChatGptInstance.createChatCompletion({
                model: 'gpt-4o',
                messages: [{ role: 'user', content: prompt }],
                temperature: 0.7,
                max_tokens: 3097,
            });
            const dataArray: any = completion?.data?.choices[0]?.message?.content;
            // const startIndex = dataArray.indexOf('1.');
            // const lastIndex = dataArray.lastIndexOf('\n\n');
            // const result = dataArray.substring(startIndex, lastIndex);
            const itemsArray = dataArray.split(/\n\n\d+/);
            const prevData = itemsArray?.map((ele: any) => ele?.replace(/^\d+\.\s*|\n/gm, ''));
            return prevData.slice(0, count_id);
        };
        const numericValues = await chatGptResponse(chatGPTQuery);
        try {
            const { data: deleteRecommendationData } = await deleteGapRecommendation({
                variables: { gapId: gapData?.gap?.id as number },
            });
            for (let [index, items] of numericValues.entries()) {
                const item = items && items?.replace(/^\d+\.\s*|\n/gm, '');
                const recommendationInitialValues = {
                    recommendation: item,
                };
                const variables = new RecommendationCreateRequest(recommendationInitialValues, {
                    gapId: gapData?.gap?.id as number,
                    companyId,
                });

                const { data: createRecommendationData } = await createRecommendation({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationsQuery,
                            variables: { gapId: gapData?.gap?.id as number },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
                const id: any = createRecommendationData?.createRecommendation?.id;
                recommendationData.recommendations.push(id);
            }
            if (recommendationData.recommendations.length > 0) {
                const { data: setRecommendationsData } = await setRecommendations({
                    variables: {
                        scoredGapId: gapData.id as number,
                        recommendations: recommendationData.recommendations as number[],
                    },
                    refetchQueries: [
                        {
                            query: GetRecommendationsQuery,
                            variables: { gapId: gapData?.gap.id },
                        },
                        {
                            query: GetProjectQuery,
                            variables: { id: projectId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
                recommendationData.recommendations = [];
                setRecommendationGenerationLoading(false);
            }
        } catch (e) {
            setRecommendationGenerationLoading(false);
            throw e;
        }
    }
    const handleSelectRecommendationCount = (count: string, count_id: number) => {
        generateComponentRecommendation(capabilityAssessment?.scoredGaps, count, count_id);
    };
    //new code1
    async function generateComponentRecommendation(
        scoredGap: any,
        count: string,
        count_id: number,
    ) {
        for (let [index, items] of scoredGap.entries()) {
            await saveDataInDataBase(items, project?.companyId, count, count_id);
        }
    }

    if (
        projectDetailLoading ||
        assessmentTypesLoading ||
        orgDetailLoading ||
        companyLoading ||
        currentUserLoading
    ) {
        return <Loader />;
    }

    return (
        <AccordionInnerListItem
            title={capabilityAssessment.capability.title}
            subtitle={capabilityAssessment.capability.keyFactors}
            SummaryInfoComponents={
                <>
                    <InnerListItemStyled.SummaryInfo>
                        <CapabilityMaturityLevel
                            current={capabilityAssessment.completionLevel.getMaturityLevel()}
                        />
                    </InnerListItemStyled.SummaryInfo>

                    <SummaryInfoCounter>
                        <CounterHint label='Recommendations' count={recommendationsLength} />
                    </SummaryInfoCounter>
                    <SummaryInfoCounter>
                        <EyeInfo
                            onClick={() =>
                                // generateComponentRecommendation(capabilityAssessment?.scoredGaps)
                                handleOpenDialogSelectRecommendationModal()
                            }
                            isAIAccess={isAIAccess}
                            generated={componentAssessment?.roadmapStatus}
                        >
                            {recommendationGenerationLoading ? (
                                <Loader />
                            ) : isAIAccess ? (
                                <RemoveRedEyeRounded />
                            ) : (
                                <VisibilityOff />
                            )}
                        </EyeInfo>
                    </SummaryInfoCounter>
                </>
            }
        >
            <InnerListItemStyled.Details>
                {capabilityAssessment.gaps ? (
                    <InnerListItemStyled.DetailInfo>
                        <p>Gaps</p>
                        <p>{capabilityAssessment.gaps}</p>
                    </InnerListItemStyled.DetailInfo>
                ) : null}

                {capabilityAssessment.recommendations ? (
                    <InnerListItemStyled.DetailInfo>
                        <p>Recommendations</p>
                        <p>{capabilityAssessment.recommendations}</p>
                    </InnerListItemStyled.DetailInfo>
                ) : null}

                {capabilityAssessment.notes ? (
                    <InnerListItemStyled.DetailInfo>
                        <p>Notes</p>
                        <p>{capabilityAssessment.notes}</p>
                    </InnerListItemStyled.DetailInfo>
                ) : null}
            </InnerListItemStyled.Details>

            <RoadmapRecommendationsList
                project={project}
                capabilityAssessment={capabilityAssessment}
                componentAssessment={componentAssessment}
                scoredGaps={capabilityAssessment.scoredGaps}
                capabilityAssessmentId={capabilityAssessment.id}
            />
            <RecommendationsCountModal
                open={openDialogSelectRecommendationModal}
                capability={capabilityAssessment.capability.title}
                default_count={3}
                onClose={handleCloseDialogSelectRecommendationModal}
                handleSelectRecommendationCount={handleSelectRecommendationCount}
            />
        </AccordionInnerListItem>
    );
};

export { RoadmapCapabilitiesAssessmentListItem };
