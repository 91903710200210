import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';
import type { LearnTkoMenu } from '@modules/types/graphql';

export type Entity = Partial<LearnTkoMenu> | null;

@DescribeEntity('LearnTkoMenuDetailsEntity')
class LearnTkoMenuDetailsEntity extends BaseEntity {
    id: number;
    menu: string;
    subMenu: string;

    constructor(learnTkoMenuList: Entity) {
        super(learnTkoMenuList);

        this.id = learnTkoMenuList?.id ?? -1;
        this.menu = learnTkoMenuList?.menu ?? '';
        this.subMenu = learnTkoMenuList?.sub_menu ?? '';
    }
}

export { LearnTkoMenuDetailsEntity };
