import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { useCurrentUser } from '@modules/auth/hooks';
import { Grid, List, ListItem } from '@material-ui/core';
import checkMark from '../../modules/layout/icons/images/checkmark.svg';
import riskImg from '../../modules/layout/icons/images/risktko-logo.svg';
import manageRiskIcon from '../../modules/layout/icons/images/manage-icon.png';
import createProjectIcon from '../../modules/layout/icons/images/create-icon.png';
import addAssetsIcon from '../../modules/layout/icons/images/add-icon.png';
import assignTeamIcon from '../../modules/layout/icons/images/groups-icon.png';
import tutorialsIcon from '../../modules/layout/icons/images/tutorial-icon.png';
import viewProjectIcon from '../../modules/layout/icons/images/view-icon.png';
import askTkoIcon from '../../modules/layout/icons/images/eye-icon.png';
import SocIcon from '../../modules/layout/icons/images/soc-icon.png';
import WelcomeVideoPoster from '../../modules/layout/icons/images/WelcomeVideoPoster.png';
import VideoPlayer from '@pages/video-player/video-player';
import { AuthContext, useAuth } from '@modules/auth/context';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { Loader } from '@modules/layout/moleculas';
import { useCompanyAccess } from '@modules/company/hooks';

type userHighRolesDataProps = {
    id: number;
    title: string;
    description: string;
    iconLink: string;
    urlLink: string;
    welcomeRole: string;
    welcomeOrder: string;
};

const WelcomeVideoSection = styled.div`
    position: relative;
    padding: 10px 0px;
    text-align: center;
`;
const WelcomeHeaderStyle = styled.div`
    position: relative;
    justify-content: start;
    align-items: center;
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    .welcome-text {
        display: flex;
        margin-top: 55px;
    }
    .checkmark {
        width: 170px;
        height: 137px;
        position: relative;
        left: 47px;
        top: 6px;
    }
    h1 {
        font-size: 6.5rem;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
    }
    .risk-img {
        width: 250px;
        margin-top: 15px;
    }
    @media (max-width: 1720px) {
        .checkmark {
            width: 145px;
            height: 100px;
            position: relative;
            left: 40px;
            top: 7px;
        }
        h1 {
            font-size: 6rem;
            font-weight: 600;
            text-align: center;
        }
        .risk-img {
            width: 220px;
        }
        .welcome-text {
            margin-top: 45px;
        }
    }
    @media (max-width: 1560px) {
        .checkmark {
            width: 150px;
            height: 127px;
            position: relative;
            left: 40px;
        }
        h1 {
            font-size: 3.5rem;
            font-weight: 600;
            text-align: center;
        }
        .risk-img {
            width: 180px;
        }
        .welcome-text {
            margin-top: 35px;
        }
    }
    @media (max-width: 1260px) {
        .checkmark {
            width: 115px;
            height: 97px;
            position: relative;
            left: 30px;
            top: 5px;
        }
        h1 {
            font-size: 3.5rem;
            font-weight: 600;
            text-align: center;
        }
        .risk-img {
            width: 180px;
        }
        .welcome-text {
            margin-top: 35px;
        }
    }
`;

const WelcomeVideoStyle = styled.div`
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        max-height: 500px;
        overflow: hidden;
        border-radius: 40px;
        // background: #a1a1a16b;
    }
`;

const WelcomeListItem = styled.div<{ isAccess: any }>`
    padding: 8px;
    cursor: ${({ isAccess }) => (isAccess ? 'pointer' : 'not-allowed')};
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 15px auto;
    width: 70%;
    background: ${({ isAccess }) => (isAccess ? '#fffdfd' : '#c4c4c4')};
    color: ${({ isAccess }) => (isAccess ? '#000' : '#7a7a7a')};
    border: 2px solid #1c2b766b;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 40px -21px 60px -30px,
        rgba(50, 50, 93, 0.25) 62px 50px 100px -27px, rgba(10, 37, 64, 0.35) -7px 6px 8px -2px inset;
`;

const ListItemTextStyle = styled.div`
    font-family: 'Montserrat', Arial, sans-serif;
    margin-left: 25px;
    h2 {
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: 600;
    }
    p {
        font-size: 14px;
        color: #000;
        font-weight: 400;
    }
`;
const ListIconStyle = styled.div`
    color: #2657e2;
    img {
        width: 60px;
        height: 60px;
    }
`;
const SOCImgeStyle = styled.div`
    position: absolute;
    bottom: 60px;
    left: 30px;
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
    img {
        width: 85%;
    }
`;

const frozenIcons = ['manageRiskIcon', 'createProjectIcon', 'askTkoIcon', 'tutorialsIcon'];

const accessConditions: any = {
    manageRiskIcon: 'isRRAccess',
    createProjectIcon: 'isCreateProject',
    askTkoIcon: 'isAskTkoAccess',
    tutorialsIcon: 'isLearnTkoAccess',
};

const updateListData = (
    userHighRolesEntityList: any,
    isFreeze: boolean,
    isSuperAdmin: boolean,
    isRRAccess: boolean,
    isCreateProject: boolean,
    isAskTkoAccess: boolean,
    isLearnTkoAccess: boolean,
    isAiAccess: boolean,
) => {
    return userHighRolesEntityList?.map((item: any) => {
        let isAccess = true;

        if (isFreeze && frozenIcons.includes(item?.iconLink)) {
            isAccess = false;
        } else if (!isSuperAdmin) {
            const accessKey = accessConditions[item.iconLink];
            // isAccess = accessKey ? eval(accessKey) : true;
            if (accessKey) {
                isAccess = !!eval(accessKey);
            }
        }

        return { ...item, isAccess };
    });
};

// const updateListData = (userHighRolesEntityList: any, isFreeze: boolean, isSuperAdmin: boolean, isRRAccess: boolean, isCreateProject: boolean, isAskTkoAccess: boolean, isLearnTkoAccess: boolean) => {
//     return userHighRolesEntityList?.map((item: any) => {
//         let isAccess = true;
//         if (isFreeze) {
//             if (frozenIcons?.includes(item?.iconLink)) {
//                 isAccess = false;
//             }
//         } else if (!isSuperAdmin) {
//             switch (item.iconLink) {
//                 case 'manageRiskIcon':
//                     isAccess = isRRAccess;
//                     break;
//                 case 'createProjectIcon':
//                     isAccess = isCreateProject;
//                     break;
//                 case 'askTkoIcon':
//                     isAccess = isAskTkoAccess;
//                     break;
//                 case 'tutorialsIcon':
//                     isAccess = isLearnTkoAccess;
//                     break;
//                 default:
//                     break;
//             }
//         }
//         return { ...item, isAccess };
//     });
// };

const WelcomePage = (): React.ReactElement => {
    const srcUrl: string =
        'https://test-frontend-chetu.s3.amazonaws.com/Video-itmg/ITMG+Corporate+-+Video.mp4';
    const navigate = useNavigate();
    const [listData, setListData] = useState<any>(null);
    let authContext = useContext(AuthContext);
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { userHighRolesEntityList, loading: roleLoading } = useUserHighRole();
    const { companyAccess, loading: isCompanyLoading } = useCompanyAccess();
    const { refetch: refetchUserHighRole } = useUserHighRole();
    const { refetch: refetchCompanyAccess } = useCompanyAccess();
    useEffect(() => {
        const fetchData = async () => {
            const promises = [];
            if (!userHighRolesEntityList) {
                promises.push(refetchUserHighRole());
            }
            if (!companyAccess) {
                promises.push(refetchCompanyAccess());
            }
            if (promises.length > 0) {
                await Promise.all(promises);
            }
        };
        fetchData();
    }, [userHighRolesEntityList, companyAccess, refetchUserHighRole, refetchCompanyAccess]);

    const { setIsAiAccess, setIsFreeze } = useAuth();
    const isSuperAdmin: boolean = currentUser?.isSuperAdmin;
    const isLearnTkoAccess: boolean = companyAccess?.isLearnTko;
    const isAskTkoAccess: boolean = companyAccess?.isAskTko;
    const isRRAccess: boolean = companyAccess?.isRRAccess;
    const isCreateProject: boolean = companyAccess?.isCreateProject;
    const isFreeze: boolean = companyAccess?.isFreeze;
    const isAiAccess: boolean = companyAccess?.isAIAccess;

    const iconMap: any = {
        createProjectIcon: createProjectIcon,
        addAssetsIcon: addAssetsIcon,
        tutorialsIcon: tutorialsIcon,
        manageRiskIcon: manageRiskIcon,
        askTkoIcon: askTkoIcon,
        viewProjectIcon: viewProjectIcon,
        assignTeamIcon: assignTeamIcon,
    };

    const handleOpenPage = (path: string, role: string) => {
        if (path === '/projects/create') {
            navigate(`/projects`);
            authContext?.handleOpenProjectModal();
        } else if (path === '/registry/create') {
            navigate(`${'registry'}`, { state: { id: 1 } });
        } else {
            navigate(`${path || ''}`);
        }
    };

    const updateListDataMemoized = useCallback(
        () =>
            updateListData(
                userHighRolesEntityList,
                isFreeze,
                isSuperAdmin,
                isRRAccess,
                isCreateProject,
                isAskTkoAccess,
                isLearnTkoAccess,
                isAiAccess,
            ),
        [
            userHighRolesEntityList,
            isFreeze,
            isSuperAdmin,
            isRRAccess,
            isCreateProject,
            isAskTkoAccess,
            isLearnTkoAccess,
            isAiAccess,
        ],
    );
    useEffect(() => {
        const filteredData = updateListDataMemoized();
        setListData(filteredData);
    }, [updateListDataMemoized, userHighRolesEntityList, currentUser]);

    useEffect(() => {}, [userHighRolesEntityList, companyAccess, currentUser]);

    useEffect(() => {
        setIsAiAccess(companyAccess.isAIAccess);
        setIsFreeze(companyAccess.isFreeze);
    }, [companyAccess]);

    if (roleLoading || currentUserLoading || isCompanyLoading) {
        return <Loader />;
    }

    return (
        <>
            <Grid container spacing={2} style={{ padding: '20px 0px 150px 0px' }}>
                <Grid item md={6} sm={12}>
                    <WelcomeVideoSection>
                        <WelcomeHeaderStyle>
                            <img className='checkmark' src={checkMark || ''} alt='chekmark' />
                            <div className='welcome-text'>
                                <h1>Welcome to </h1>
                                <img className='risk-img' src={riskImg || ''} alt='riskImg' />
                            </div>
                        </WelcomeHeaderStyle>
                        <WelcomeVideoStyle>
                            <VideoPlayer srcUrl={srcUrl} posterUrl={WelcomeVideoPoster} />
                        </WelcomeVideoStyle>
                    </WelcomeVideoSection>
                </Grid>
                <Grid item md={6} sm={12} style={{ margin: 'auto' }}>
                    {listData?.map((ele: any) => (
                        <WelcomeListItem
                            key={ele.id}
                            isAccess={ele?.isAccess}
                            onClick={() =>
                                ele?.isAccess ? handleOpenPage(ele.urlLink, 'PM') : undefined
                            }
                        >
                            <List style={{ padding: '5px 0px' }}>
                                <ListItem>
                                    <ListIconStyle>
                                        <img src={iconMap[ele.iconLink]} alt={ele.iconLink} />
                                    </ListIconStyle>
                                    <ListItemTextStyle>
                                        <h2>{ele.title}</h2>
                                        <p>{ele.description}</p>
                                    </ListItemTextStyle>
                                </ListItem>
                            </List>
                        </WelcomeListItem>
                    ))}
                </Grid>
            </Grid>
            <SOCImgeStyle>
                <img src={SocIcon || 'went wrong'} alt={SocIcon} />
            </SOCImgeStyle>
        </>
    );
};

export { WelcomePage };
