import { format } from 'date-fns';

import { BaseEntity, Entity as DescribeEntity, Relation } from '@lib/entity';
import { ComponentEntity } from '@modules/components/entities';
import { GapEntity } from '@modules/custom-components/entities';
import { RoadmapRecommendationRefinementEntity } from '@modules/roadmap/entities';

import type { ComponentEntityType } from '@modules/components/entities';
import type { GapEntityType } from '@modules/custom-components/entities';
import type { RoadmapRecommendationRefinementEntityType } from '@modules/roadmap/entities';
import type { Maybe, RecommendationImplementation, User } from '@modules/types/graphql';

export type Entity = Partial<
    Omit<RecommendationImplementation, 'component' | 'gap' | 'recommendation_refinement'> & {
        component: ComponentEntityType;
        gap: GapEntityType;
        recommendation_refinement: RoadmapRecommendationRefinementEntityType;
    }
> | null;

@DescribeEntity('RecommendationImplementationEntity')
class RecommendationImplementationEntity extends BaseEntity {
    assessmentId: number;
    roadmapRecommendationId: number;
    recommendationId: number;
    componentId: number;
    gapId: number;
    status: string;
    startedAt: string;
    completedAt: string;
    completedInDays: number | null;
    operators?: Maybe<User>[];

    @Relation(() => ComponentEntity)
    component: ComponentEntity;

    @Relation(() => GapEntity)
    gap: GapEntity;

    @Relation(() => RoadmapRecommendationRefinementEntity)
    recommendationRefinement: RoadmapRecommendationRefinementEntity;

    constructor(implementation: Entity) {
        super(implementation);

        this.assessmentId = implementation?.assessment_id ?? -1;
        this.roadmapRecommendationId = implementation?.roadmap_recommendation_id ?? -1;
        this.recommendationId = implementation?.recommendation_id ?? -1;
        this.componentId = implementation?.component_id ?? -1;
        this.gapId = implementation?.gap_id ?? -1;
        this.status = implementation?.status ?? '';
        this.startedAt = implementation?.started_at ?? '';
        this.completedAt = implementation?.completed_at ?? '';
        this.completedInDays = implementation?.completed_in_days ?? null;
        this.operators = implementation?.operators ?? [];
    }

    getFormattedDate(field: 'startedAt' | 'completedAt') {
        const currentDate = this[field];

        if (!currentDate) {
            return '';
        }

        const date = new Date(currentDate);

        const mm = (date.getMonth() + 1).toString().padStart(2, '0');
        const dd = date.getDate().toString().padStart(2, '0');
        const yyyy = date.getFullYear().toString().padStart(4, '0');
        const hh = date.getHours().toString().padStart(2, '0');
        const min = date.getMinutes().toString().padStart(2, '0');
        const ss = date.getSeconds().toString().padStart(2, '0');

        return `${mm}/${dd}/${yyyy} ${hh}:${min}:${ss}`;

        // const formattedDate = format(date, 'dd.MM.yyyy hh:mm:ss');

        // return formattedDate;
    }

    isPending() {
        const result = this.status.toLowerCase() === 'pending';

        return result;
    }

    inProgress() {
        const result = this.status.toLowerCase() === 'in progress';

        return result;
    }

    isDone() {
        const result = this.status.toLowerCase() === 'done';

        return result;
    }
}

export { RecommendationImplementationEntity };
