import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';

import type { CustomItemCategoryTypeCreateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateItemCategoryTypeMutationType,
    UpdateItemCategoryTypeMutationVariables,
} from '@modules/types/graphql';

import { Loader } from '@modules/layout/moleculas';
import { useGetAssetTypeById } from '@modules/custom-components/hooks';
import { UpdateItemCategoryTypeMutation } from '@modules/custom-components/graphql/mutations';
import { GetlistAllItemTypesQuery } from '@modules/registry/graphql';

type CustomItemCategoryTypeEditFormProps = {
    categoryId: number;
    categoryTypeId?: number | any;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomItemCategoryTypeEditForm = (props: CustomItemCategoryTypeEditFormProps): React.ReactElement => {
    const { onCancel, categoryId, categoryTypeId } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [updateLoader, setUpdateLoader] = useState<boolean>(false);
    const [updateItemCategoryType] = useMutation<
        UpdateItemCategoryTypeMutationType,
        UpdateItemCategoryTypeMutationVariables
    >(UpdateItemCategoryTypeMutation);
    const { getAssetTypeById, loading: getAssetTypeByIdLoading } = useGetAssetTypeById({
        variables: {
            categoryTypeId: categoryTypeId,
            categoryId: categoryId,
        },
    });
    console.log(props,)
    const initialValues: CustomItemCategoryTypeCreateFormValues = {
        name: getAssetTypeById.name,
        categoryId: getAssetTypeById.categoryId
    };

    if (getAssetTypeByIdLoading) {
        return <Loader />;
    }

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    console.log(values, 'values')
                    setUpdateLoader(true);
                    const { data: updateItemCategoryTypeData } = await updateItemCategoryType({
                        variables: {
                            categoryTypeId: categoryTypeId,
                            categoryId: values.categoryId,
                            name: values.name,
                        },
                        refetchQueries: [{ query: GetlistAllItemTypesQuery }],
                        awaitRefetchQueries: true,
                    });

                    const categoryIds = updateItemCategoryTypeData?.updateItemCategoryType?.id;

                    if (categoryIds) {
                        enqueueSuccess('Category Type Details successfully updated!');
                        setUpdateLoader(false);
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while updating Category Type!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                } = formikProps;

                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Category Type Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting || updateLoader}>
                                Update Category
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting || updateLoader} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomItemCategoryTypeEditForm };