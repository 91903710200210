import React from 'react';

import { ListStyled } from '@modules/layout/styled';

import { ReportComponentListItem } from '@modules/assessment/organisms/report-components-list/report-component-list-item';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { GapCapabilityAssessment, GapViewData } from '@modules/assessment/gap-by-risk';
import { ProjectEntity } from '@modules/projects/entities';

type ReportComponentVIewProps = {
    componentsAssessmentList: ComponentAssessmentEntity[];
    componentAssessmentMap: Map<number, GapCapabilityAssessment[]>;
    componentsAssessmentRawList: any;
    project?: ProjectEntity;
};

export const ReportComponentView = (props: ReportComponentVIewProps): React.ReactElement => {
    const {
        componentsAssessmentList,
        componentAssessmentMap,
        project,
        componentsAssessmentRawList,
    } = props;

    if (!componentsAssessmentList.length) {
        return <ListStyled.Empty>No components found</ListStyled.Empty>;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                {project?.isrrimpact ? (
                    <TableHead>
                        <TableRow>
                            <TableCell>Component</TableCell>
                            <TableCell>Impact Rating</TableCell>
                            <TableCell>Factors</TableCell>
                        </TableRow>
                    </TableHead>
                ) : (
                    <TableHead>
                        <TableRow>
                            <TableCell>Component</TableCell>
                            <TableCell>Maturity Level</TableCell>
                            <TableCell>Capabilities</TableCell>
                            <TableCell>Gaps</TableCell>
                            <TableCell>Mitigation</TableCell>
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {componentsAssessmentList.map((assessment, index) => {
                        return (
                            <ReportComponentListItem
                                project={project}
                                key={`${assessment.id}-${index}`}
                                assessment={assessment}
                                componentsAssessmentRawList={componentsAssessmentRawList}
                                componentAssessmentMap={componentAssessmentMap}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
