import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import { Button } from '@modules/ui/core';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { ListStyled } from '@modules/layout/styled';
import { ImplementationRecommendationsSummary } from './implementation-recommendations-summary';

import type { ProjectEntity } from '@modules/projects/entities';
import {
    ImplementationEntity
} from '@modules/implementation/entities';
import { usePropState } from '@modules/shared/hooks/use-prop-state';
import { ProjectAssessmentComponentsFilter } from '@modules/projects/organisms';
import { UserEntity } from '@modules/users/entities';
import {
    assessmentCollectCO,
    assessmentImplementationFilterHandeler,
} from '@modules/projects/organisms/project-assessment-components-filter/filter-handlers';
import { ProjectAssessmentFilterValue } from '@modules/projects/organisms/project-assessment-components-filter/project-assessment-components-filter';
import { GapAssessment, getGapByAssessment } from '@modules/assessment/gap-assessment';
import {
    GapRiskLevel,
    ImplementationRiskLevelField,
    RiskLevel10PointKeyReverseList,
} from '@modules/shared/constants/risk-level';
import { ExportButton } from '@modules/ui/core/button/exportButton';

type ImplementationRecommendationsListProps = {
    project: ProjectEntity;
    implementation: ImplementationEntity;
};

const List = styled(ListStyled.List)`
    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Toolbar = styled.div`
    width: 100%;
    margin-top: 24px;

    > a {
        display: inline-block;
    }
`;

const ImplementationRecommendationsList = (
    props: ImplementationRecommendationsListProps,
): React.ReactElement => {
    const { project, implementation: implementationRaw } = props;
    const projectId = project.id;
    const reportLink = createRouteUrl(routes.projectImplementationReport, { id: projectId });
    const componentOwnerList: UserEntity[] = Array.from(
        assessmentCollectCO(project.assessments).values(),
    );
    const gapByAssessment: Map<number, GapAssessment> = getGapByAssessment(project.assessments);

    const [implementation, setImplementation] = usePropState(implementationRaw);

    function onFilterChange(filterValue: ProjectAssessmentFilterValue) {
        assessmentImplementationFilterHandeler(
            setImplementation,
            implementationRaw,
            gapByAssessment,
            filterValue,
        );
    }

    return (
        <ListStyled.Root>
            <ListStyled.Header>
                <h2>Implementation</h2>

                {project.stakeholder.exists() ? (
                    <ListStyled.HeaderInfo>
                        <p>Stakeholder:</p>
                        <p>{project.stakeholder.getFullName()}</p>
                    </ListStyled.HeaderInfo>
                ) : null}

                <ListStyled.HeaderInfo>
                    <Link to={reportLink}>
                        <Button>Show report</Button>
                    </Link>
                    <ExportButton
                        style={{ marginLeft: '20px', height: '48px' }}
                        exportfilename='implementation-recommendation-report'
                        size='small'
                    />
                </ListStyled.HeaderInfo>
            </ListStyled.Header>

            <ProjectAssessmentComponentsFilter
                componentOwnerList={componentOwnerList}
                hasRiskLevelFilter={true}
                hasImplementationStatusFilter={true}
                onFilterChange={onFilterChange}
            />

            <List>
                {implementation.hasPhases() ? (
                    <>
                        {RiskLevel10PointKeyReverseList?.map((key: string, index: number) => {
                            let typeKey = ImplementationRiskLevelField[key];

                            if (!implementation[typeKey]?.length) {
                                return null;
                            }

                            return (
                                <ImplementationRecommendationsSummary
                                    key={typeKey}
                                    title={`${GapRiskLevel[key]}`}
                                    project={project}
                                    recommendationsImplementation={implementation[typeKey]}
                                    riskLevelKey={`${GapRiskLevel[key]}`}
                                />
                            );
                        })}
                    </>
                ) : (
                    <ListStyled.Empty>Not found phases for this implementation</ListStyled.Empty>
                )}
            </List>
        </ListStyled.Root>
    );
};

export { ImplementationRecommendationsList };
