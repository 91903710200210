import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { DefaultTypeData } from '@modules/types/graphql';

export type Entity = Partial<DefaultTypeData>;

@DescribeEntity('PrevTypeDetailsEntity')
class PrevTypeDetailsEntity extends BaseEntity {
    // id: number;
    categoryId: number;
    typeIds: any;
    itemCategoryTypeId: number;

    constructor(itemType: Entity) {
        super(itemType);

        // this.id = itemType?.id ?? -1;
        this.categoryId = itemType?.category_id ?? -1;
        this.typeIds = itemType?.type_ids ?? -1;
        this.itemCategoryTypeId = itemType?.item_category_type_id ?? -1;
    }
}

export { PrevTypeDetailsEntity };
