import { BaseEntity, Entity as DescribeEntity, RelationList } from '@lib/entity';
import { RecommendationImplementationEntityList } from './recommendation-implementation-entity-list';

import type { Implementation } from '@modules/types/graphql';
import type {
    RecommendationImplementationEntity,
    Entity as RecommendationImplementationEntityType,
} from './recommendation-implementation-entity';
import { implementationLevelFields } from '@modules/shared/constants/risk-level';

export {
    implementationLevelFields,
    implementationLevelFieldsReversed,
} from '@modules/shared/constants/risk-level';

export type Entity = Partial<
    Omit<
        Implementation,
        | 'super_critical'
        | 'critical'
        | 'high_critical'
        | 'high'
        | 'medium_high'
        | 'medium'
        | 'low_medium'
        | 'low'
        | 'minimal_low'
        | 'minimal'
    > & {
        super_critical: RecommendationImplementationEntityType[] | null;
        critical: RecommendationImplementationEntityType[] | null;
        high_critical: RecommendationImplementationEntityType[] | null;
        high: RecommendationImplementationEntityType[] | null;
        medium_high: RecommendationImplementationEntityType[] | null;
        medium: RecommendationImplementationEntityType[] | null;
        low_medium: RecommendationImplementationEntityType[] | null;
        low: RecommendationImplementationEntityType[] | null;
        minimal_low: RecommendationImplementationEntityType[] | null;
        minimal: RecommendationImplementationEntityType[] | null;
    }
> | null;

@DescribeEntity('ImplementationEntity')
class ImplementationEntity extends BaseEntity {
    dataSource: Entity;

    projectId: number;

    @RelationList(() => RecommendationImplementationEntityList)
    superCritical: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    critical: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    highCritical: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    high: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    mediumHigh: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    medium: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    lowMedium: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    low: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    minimalLow: (RecommendationImplementationEntity | never)[];

    @RelationList(() => RecommendationImplementationEntityList)
    minimal: (RecommendationImplementationEntity | never)[];

    constructor(implementation: Entity) {
        super(implementation);

        this.projectId = implementation?.project_id ?? -1;
        this.dataSource = implementation;
    }

    clone(): ImplementationEntity {
        return new ImplementationEntity(this.dataSource);
    }

    hasPhases() {
        let result = implementationLevelFields.some((key: string) => Boolean(this[key]?.length));
        return result;
    }
}

export { ImplementationEntity };
