import { createCustomError } from '@lib/errors';

import type { CompanyEntity } from '@modules/company/entities';
import type { SignUpUserMutationVariables } from '@modules/types/graphql';
import { UserRolesEntity } from '../entities';

export type SignUpUserFormValues = {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    companyName: string;
    isAdmin: boolean;
    company: CompanyEntity | null;
    userRole: any;
    schemaName: any;
    website: any;
};

class SignUpUserRequest {
    username: SignUpUserMutationVariables['username'];
    email: SignUpUserMutationVariables['email'];
    firstName: SignUpUserMutationVariables['firstName'];
    lastName: SignUpUserMutationVariables['lastName'];
    isAdmin: SignUpUserMutationVariables['isAdmin'];
    companyId: SignUpUserMutationVariables['companyId'];
    schemaName: SignUpUserMutationVariables['schemaName'];
    userRole: SignUpUserMutationVariables['userRole'];
    website: SignUpUserMutationVariables['website'];

    constructor(values: SignUpUserFormValues) {
        if (!values.company) {
            throw createCustomError('Incorrect company');
        }

        this.username = values.username || '';
        this.email = values.username || '';
        this.firstName = values.firstName || '';
        this.lastName = values.lastName || '';
        this.isAdmin = values.isAdmin || false;
        this.schemaName = values.schemaName;
        this.companyId = values.company.id | (values.company! as any);
        this.userRole = values.userRole?.name || '';
        this.website = values.website;
    }
}

export { SignUpUserRequest };
