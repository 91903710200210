import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetAlignedAssetIdsQuery } from '../graphql';
import { useEntity } from '@lib/entity';
import { GetAlignedAssetIdsEntityList } from '../entities';
import {
    GetAlignedAssetIdsQueryType,
    GetAlignedAssetIdsQueryVariables,
} from '@modules/types/graphql';

const useGetAlignedAssetIds = (
    options?: QueryHookOptions<GetAlignedAssetIdsQueryType, GetAlignedAssetIdsQueryVariables>,
) => {
    const queryResult = useQuery<GetAlignedAssetIdsQueryType, GetAlignedAssetIdsQueryVariables>(
        GetAlignedAssetIdsQuery,
        options,
    );

    const { entities: alignedAssets, count } = useEntity(
        () => new GetAlignedAssetIdsEntityList(queryResult.data?.getAlignedAssetIds ?? null),
        [queryResult.data],
    );

    const result = {
        alignedAssets,
        count,
        ...queryResult,
    };

    return result;
};

export { useGetAlignedAssetIds };
