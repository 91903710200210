import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { AssessmentTypeEntityList } from '@modules/shared/entities';
import { GetAssessmentTypesQuery } from '@modules/shared/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetAssessmentTypesQueryType,
    GetAssessmentTypesQueryVariables,
} from '@modules/types/graphql';

const useAssessmentTypes = (
    options?: QueryHookOptions<GetAssessmentTypesQueryType, GetAssessmentTypesQueryVariables>,
) => {
    const queryResult = useQuery<GetAssessmentTypesQueryType, GetAssessmentTypesQueryVariables>(
        GetAssessmentTypesQuery,
        options,
    );

    const { entities, count } = useEntity(
        () => new AssessmentTypeEntityList(queryResult.data?.listAssessmentTypes ?? null),
        [queryResult.data],
    );

    const RRTypes = ['Impact', 'Vulnerability', 'Threat'];

    const assessmentTypes = entities?.filter(entity => {
        return !RRTypes.includes(entity?.name);
    });

    const result = {
        entities,
        assessmentTypes,
        count,
        ...queryResult,
    };

    return result;
};

export { useAssessmentTypes };
