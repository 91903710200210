import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, CapabilityLevel } from '@modules/types/graphql';

export type Entity = Maybe<Partial<CapabilityLevel>>;

@DescribeEntity('CapabilityLevelEntity')
class CapabilityLevelEntity extends BaseEntity {
    id: number;
    name: string;
    description: string;
    level: number;
    companyId: string | null;

    constructor(capabilityLevel: Entity) {
        super(capabilityLevel);

        this.id = capabilityLevel?.id ?? -1;
        this.name = capabilityLevel?.name ?? '';
        this.description = capabilityLevel?.description ?? '';
        this.level = capabilityLevel?.level ?? 0;
        this.companyId = capabilityLevel?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { CapabilityLevelEntity };
