import { gql } from '@apollo/client';

export const CreateProjectMutation = gql`
    mutation CreateProject(
        $companyId: Int!
        $name: String!
        $description: String!
        $stakeholderId: Int
        $tkoscopeId: Int!
        $assessmentTypeId: Int!
        $assetId: Int
        $pmId: Int!
    ) {
        createProgram(
            assessment_type_id: $assessmentTypeId
            company_id: $companyId
            name: $name
            tko_scope_id: $tkoscopeId
            description: $description
            stakeholder_id: $stakeholderId
            assetId: $assetId
            pm_id: $pmId
        ) {
            id
            name
            description
            company_id
            tko_scope_id
            assessment_type_id
        }
    }
`;
