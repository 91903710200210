import { gql } from '@apollo/client';

export const UpdateItemCategoryMutation = gql`
    mutation UpdateItemCategory(
        $categoryId: Int!
        $name: String!
        $itemId: Int!
        $riskFunctionId: Int
    ) {
        updateItemCategory(
            category_id: $categoryId
            name: $name
            item_id: $itemId
            risk_function_id: $riskFunctionId
        ) {
            id
            name
            risk_function_id
            item_id
        }
    }
`;
