import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AddAssetAssessment } from '@modules/registry/graphql';

import { Button, TextField } from '@modules/ui/core';
import { FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { gray } from '@modules/ui/colors';
import { weights } from '@modules/components/constants';
import {
    getRiskLevelLabelByScore,
    getRiskAssessmentLevelLabel,
    RiskLevel10Point,
    getScoreByLevelLabel,
    RiskLevel10PointKeyList,
} from '@modules/shared/constants/risk-level';
import { Asset } from '@modules/types/graphql';
import { CreateProjectMutation } from '@modules/projects/graphql';
import { RiskProgramTypeMap } from '@modules/shared/constants/risk-assessment-type';
import * as yup from 'yup';
import { useNavigate } from '@reach/router';
import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .is-select {
        width: 536px;
        background-color: ${gray[90]};

        .MuiInputBase-root > div {
            display: initial;
        }
    }

    .is-radio-line {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 10px;

        &.is-vulnerability-type,
        &.is-threat-type {
            flex-direction: row-reverse;
        }

        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 1 100%;
        }
    }
`;

const FormControlLabelStyle = styled(FormControlLabel)`
    span {
        font-size: 10px;
    }
`;
const SubmitButton = styled(Button)`
    min-width: 165px;
`;

export const EditAssessmentInfo = (props: {
    activityType?: string;
    refetchQuery?: any;
    activeAssessment?: any;
    activeAsset?: Asset;
    onClose: any;
}): React.ReactElement => {
    const { onClose, activityType, activeAsset, activeAssessment, refetchQuery } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [createProject] = useMutation(CreateProjectMutation);
    const [addAssetAssessment] = useMutation(AddAssetAssessment);
    const navigate = useNavigate();

    const defaultInitialValues = {
        risk_level_id: 'Medium',
        gaps: '',
        recommendations: '',
        notes: '',
        weight: 1,
    };

    const assessmentsHistory = useMemo(() => {
        if (!activeAsset || !activityType) {
            return [];
        }

        return activeAsset[`${activityType}Assessments`];
    }, [activeAsset, activityType]);
    const riskLevelId = useMemo(() => {
        if (assessmentsHistory && assessmentsHistory?.length) {
            const last = assessmentsHistory[assessmentsHistory?.length - 1];
            return getRiskLevelLabelByScore(last?.score, activityType);
        }

        return 'Medium';
    }, [assessmentsHistory]);

    let programId = useMemo(() => {
        if (!activeAsset || !activityType || !activeAsset[`${activityType}Program`]) {
            return 0;
        }
        return activeAsset[`${activityType}Program`].id;
    }, [activeAsset, activityType]);

    async function createNewProgram(): Promise<number> {
        const variables = {
            assessment: '',
            name: `${activityType} assessment`,
            description: '',
            companyId: Number(activeAsset?.company?.id),
            // stakeholderId: Number((stakeholder as any)?.id),
            assessmentTypeId: RiskProgramTypeMap[activityType as string],
            assetId: activeAsset?.id,
            tkoscopeId: 3,
            pmId: activeAsset?.pm?.id,
        };

        try {
            const { data: createProjectData } = await createProject({
                variables,
            });

            const project = createProjectData?.createProgram;

            if (!project?.id) {
                throw new Error('An error occurred while creating the project!');
            }
            navigate(createRouteUrl(routes.projectAssessment, { id: project?.id }));
            enqueueSuccess('Assessment was successfully created!');
            return project?.id;
        } catch (e) {
            onClose();
            throw e;
        }
    }

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={
                { ...activeAssessment, risk_level_id: riskLevelId } || defaultInitialValues
            }
            validationSchema={yup.object({
                // weight: yup.number().required('Required field'),
                // gaps: yup
                //     .string()
                //     .matches(/^[A-Za-z0-9#*-]/, 'Required field')
                //     .required('Required field')
                //     .min(2, 'At least 3 characters required'),
                // recommendations: yup
                //     .string()
                //     .matches(/^[A-Za-z0-9#*-]/, 'Required field')
                //     .required('Required field')
                //     .min(2, 'At least 3 characters required'),
                // notes: yup
                //     .string()
                //     .matches(/^[A-Za-z0-9#*-]/, 'Required field')
                //     .required('Required field')
                //     .min(2, 'At least 3 characters required'),
            })}
            onSubmit={async values => {
                // if (programId === 0) {
                //     programId = await createNewProgram();
                // }
                const variables = {
                    input: {
                        asset_id: activeAsset?.id,
                        assessment_type: RiskProgramTypeMap[activityType as string],
                        program_id: programId === 0 ? null : programId,
                        score: getScoreByLevelLabel(values?.risk_level_id, activityType),
                        weight: values?.weight || 1,
                        gaps: values?.gaps,
                        recommendations: values?.recommendations,
                        notes: values?.notes,
                    },
                };
                try {
                    const { data: AssessmentData } = await addAssetAssessment({
                        variables,
                        refetchQueries: [{ query: refetchQuery }],
                        awaitRefetchQueries: true,
                    });

                    const assessment = AssessmentData?.addAssetAssessment;

                    if (!assessment?.id) {
                        onClose();
                        throw new Error('An error occurred while creating the assessment!');
                    } else {
                        enqueueSuccess('Assessment was successfully updated!');
                        onClose();
                    }
                } catch (e) {
                    onClose();
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <StyledForm>
                        <FormLabel style={{ textTransform: 'capitalize' }}>
                            {activityType === 'vulnerability'
                                ? 'Control Capability'
                                : activityType === 'threat'
                                ? `${activityType} Control`
                                : activityType}{' '}
                            level
                        </FormLabel>
                        <RadioGroup
                            name='risk_level_id'
                            value={values.risk_level_id}
                            onChange={handleChange}
                            className={'is-radio-line is-' + activityType + '-type'}
                        >
                            {RiskLevel10PointKeyList.map((key: string, index: number) => {
                                let label =
                                    index % 1 === 0
                                        ? getRiskAssessmentLevelLabel(
                                              RiskLevel10Point[key],
                                              activityType,
                                          )
                                        : '';

                                return (
                                    <FormControlLabelStyle
                                        key={index}
                                        value={RiskLevel10Point[key]}
                                        control={<Radio />}
                                        label={label}
                                    />
                                );
                            })}
                        </RadioGroup>

                        {activityType !== 'impact' ? (
                            <TextField
                                select
                                fullWidth={true}
                                id='weight'
                                name='weight'
                                label='Weight'
                                value={values.weight !== undefined ? values.weight : ''}
                                error={!!errors.weight}
                                helperText={errors.weight}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {weights.map(weight => (
                                    <MenuItem key={weight} value={weight}>
                                        {weight}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            ''
                        )}

                        <TextField
                            multiline
                            fullWidth
                            id='gaps'
                            label={activityType === 'impact' ? 'Evidence' : 'Gaps'}
                            value={values.gaps}
                            error={!!errors.gaps}
                            helperText={errors.gaps}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <TextField
                            multiline
                            fullWidth
                            id='recommendations'
                            label={
                                activityType === 'impact' ? 'Unwanted events' : 'Recommendations'
                            }
                            value={values.recommendations}
                            error={!!errors.recommendations}
                            helperText={errors.recommendations}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <TextField
                            multiline
                            fullWidth
                            id='notes'
                            label={activityType === 'impact' ? 'Assumptions' : 'Measures'}
                            value={values.notes}
                            error={!!errors.notes}
                            helperText={errors.notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Update
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onClose}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};
