import { gql } from '@apollo/client';

export const UpdateItemCategoryTypeMutation = gql`
    mutation UpdateItemCategoryType(
        $categoryTypeId: Int!
        $name: String!
        $categoryId: Int!
    ) {
        updateItemCategoryType(
            category_type_id: $categoryTypeId
            name: $name
            category_id: $categoryId
        ) {
            id
            name
            category_id
        }
    }
`;
