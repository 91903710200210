import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, MaturityLevel } from '@modules/types/graphql';

export type Entity = Maybe<Partial<MaturityLevel>>;

@DescribeEntity('WorkForceEntity')
class WorkForceEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(workforce: Entity) {
        super(workforce);

        this.id = workforce?.id ?? -1;
        this.name = workforce?.name ?? '';
    }
}

export { WorkForceEntity };
