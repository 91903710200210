import { BaseEntity, Entity as DescribeEntity, RelationList } from '@lib/entity';
import type { AllAssessmentType } from '@modules/types/graphql';
import type { AssessmentDetailsEntityType } from './assessment-details-entity';
import { AssessmentDetailsEntity } from './assessment-details-entity';
import { AssessmentDetailsEntityList } from './assessment-details-entity-list';

export type Entity = Partial<
    Omit<AllAssessmentType, 'assessmentDetails'> & {
        assessmentDetails: AssessmentDetailsEntityType[] | null;
    }
> | null;

@DescribeEntity('AllAssessmentTypeEntity')
class AllAssessmentTypeEntity extends BaseEntity {
    id: number;
    name: string;
    companyId: number | null;

    @RelationList(() => AssessmentDetailsEntityList)
    assessmentDetails: AssessmentDetailsEntity[];

    constructor(assessmentDetails: Entity) {
        super(assessmentDetails);

        this.id = assessmentDetails?.id ?? -1;
        this.name = assessmentDetails?.name ?? '';
        this.companyId = assessmentDetails?.company_id ?? null;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { AllAssessmentTypeEntity };
