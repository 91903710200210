import React from 'react';

import { Modal } from '@modules/ui/core';

import type { ModalProps } from '@modules/ui/core';
import { CustomItemCategoryEditForm } from '@modules/custom-components/organisms';

type CustomAssetCategoryEditModalProps = Omit<ModalProps, 'title' | 'children'> & {
    categoryId: number;
    itemId?: number;
};

const CustomAssetCategoryEditModal = (props: CustomAssetCategoryEditModalProps): React.ReactElement => {
    const { categoryId, itemId, onClose, ...otherProps } = props;
    return (
        <Modal title='Edit Category Details' onClose={onClose} {...otherProps}>
            <CustomItemCategoryEditForm
                categoryId={categoryId}
                itemId={itemId}
                onCancel={onClose}
            />
        </Modal>
    );
};

export { CustomAssetCategoryEditModal };
