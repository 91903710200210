import React from 'react';

import { InnerListStyled } from '@modules/layout/styled';
import { RoadmapCapabilitiesAssessmentListItem } from './roadmap-capabilities-assessment-list-item';
import { Button } from '@modules/ui/core';
import { ListItemStyled } from '@modules/layout/styled';
import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import styled from 'styled-components';
import { Loader } from '@modules/layout/moleculas';
import { useCompanyAccess } from '@modules/company/hooks';

type RoadmapCapabilitiesAssessmentListProps = {
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    handleRecommendation: () => void;
    showLoader: boolean;
};

const RecommendationButton = styled(Button) <{ isAIAccess: boolean; generated: string }>`
    background: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : '#9d9d9d'};
    cursor: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'not-allowed'};
    pointer-events: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'none'};
    max-width: 300px;
    width: 100%;
    padding: 10px 0px;
    font-size: 16px;
    margin-bottom: 20px;
`;

const RoadmapCapabilitiesAssessmentList = (
    props: RoadmapCapabilitiesAssessmentListProps,
): React.ReactElement => {
    const { project, componentAssessment, handleRecommendation, showLoader } = props;

    const capabilitiesAssessment = componentAssessment.capabilities;
    const hasCapabilities = capabilitiesAssessment.length !== 0;
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const isAIAccess: boolean = companyAccess?.isAIAccess;

    if (companyLoading) {
        return <Loader />;
    }

    return (
        <InnerListStyled.Root>

            <ListItemStyled.Details>
                <ListItemStyled.ComponentDetailsInfo>
                    <b>Component owner:</b> {componentAssessment.componentOwnersToString()}
                </ListItemStyled.ComponentDetailsInfo>
            </ListItemStyled.Details>

            {/* {componentAssessment.componentOwnersToString().length ? (
                <InnerListStyled.Header>
                    <h3>Component owner:</h3>
                    <p className={'text-offset'}>
                        {componentAssessment.componentOwnersToString()}
                    </p>
                </InnerListStyled.Header>
            ) : null} */}
            {/* <RecommendationButton
                loading={showLoader}
                onClick={handleRecommendation}
                isAIAccess={isAIAccess}
                generated={componentAssessment?.roadmapStatus}
            >
                Generate Recommendation
            </RecommendationButton> */}
            <InnerListStyled.Header>
                <h2>Completion Levels</h2>
            </InnerListStyled.Header>

            <InnerListStyled.List>
                {hasCapabilities ? (
                    capabilitiesAssessment.map((capabilityAssessment, index) => {
                        return (
                            <RoadmapCapabilitiesAssessmentListItem
                                key={`${capabilityAssessment.id}-${index}`}
                                project={project}
                                componentAssessment={componentAssessment}
                                capabilityAssessment={capabilityAssessment}
                            />
                        );
                    })
                ) : (
                    <InnerListStyled.Empty>Not found capabilities</InnerListStyled.Empty>
                )}
            </InnerListStyled.List>
        </InnerListStyled.Root>
    );
};

export { RoadmapCapabilitiesAssessmentList };
