import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
    .loader {
        width: 50px;
        aspect-ratio: 1;
        display: grid;
        color: #0057ff;
        background: radial-gradient(
            farthest-side,
            currentColor calc(100% - 6px),
            #0000 calc(100% - 5px) 0
        );
        -webkit-mask: radial-gradient(
            farthest-side,
            #0000 calc(100% - 13px),
            #000 calc(100% - 12px)
        );
        border-radius: 50%;
        animation: l19 2s infinite linear;
        position: relative;
        z-index: 9;
    }
    .loader::before,
    .loader::after {
        content: '';
        grid-area: 1/1;
        background: linear-gradient(#0057ff 0 0) center, linear-gradient(#0057ff 0 0) center;
        background-size: 100% 10px, 10px 100%;
        background-repeat: no-repeat;
    }
    .loader::after {
        transform: rotate(45deg);
    }
    .loader_wrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .loader_wrapper:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #26262630;
        z-index: 2;
    }
    span.loader_text {
        font-weight: bold;
        color: #000;
        text-align: center;
        font-size: 16px;
        z-index: 5;
        position: relative;
    }

    @keyframes l19 {
        100% {
            transform: rotate(1turn);
        }
    }
`;

interface CompanyLoaderProps {
    value: string; // Define the type for the `value` prop
}

const CompanyLoader: React.FC<CompanyLoaderProps> = ({ value }) => (
    <Root>
        <div className='loader_wrapper'>
            <div className='loader'></div>
            <span className='loader_text'>{value}!</span>
        </div>
    </Root>
);

export { CompanyLoader };

// const CompanyLoader = (value: any) => (
//   <Root>
//     <div className='loader_wrapper'>
//       <div className="loader"></div>
//       <span className='loader_text'>{value}!</span>
//     </div>

//   </Root>
// );

// export { CompanyLoader };
