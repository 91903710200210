import { useQuery } from '@apollo/client';
import { GetAssignedAssets } from '@modules/registry/graphql';
import type { QueryHookOptions } from '@apollo/client';
import { Asset } from '@modules/types/graphql';
import { getRiskProgramScore } from '@modules/shared/constants/risk-level';
import { useMemo } from 'react';

const useAssignAssets = (options?: QueryHookOptions) => {
    const queryResult = useQuery<{ getAssignedAssets: Asset[] }>(GetAssignedAssets, options);

    const assetsRaw: { getAssignedAssets: Asset[] } = queryResult?.data ?? {
        getAssignedAssets: [],
    };
    const assignAssets = useMemo(() => {
        const getAssignedAssets = assetsRaw.getAssignedAssets.map((assetRaw: Asset) => {
            let asset = { ...assetRaw };
            // asset.riskScore = getRiskProgramScore(asset.vulnerabilityScore, asset.threatScore);
            return asset;
        });

        return { getAssignedAssets: getAssignedAssets };
    }, [assetsRaw.getAssignedAssets]);

    return { assignAssets, ...queryResult };
};

export { useAssignAssets };
