import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

type VideoPlayerProps = {
    srcUrl?: string;
    posterUrl?: string;
};

const ReactPlayerStyle = styled.div`
    position: relative;
    width: 100%;
    max-width: 740px;
    aspect-ratio: 16 / 9;
    border-radius: 40px;
    overflow: hidden;
    background-color: black;

    .video-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ srcUrl, posterUrl }) => {
    return (
        <ReactPlayerStyle>
            <ReactPlayer
                url={srcUrl}
                controls
                light={posterUrl} 
                playing={true}
                className="video-player"
                width="100%"
                height="100%"
            />
        </ReactPlayerStyle>
    );
};

export default VideoPlayer;



// import React, { useState, useRef } from 'react';
// import ReactPlayer from 'react-player';
// import styled from 'styled-components';

// type VideoPlayerProps = {
//     srcUrl?: string;
//     posterUrl?: string; // Poster image URL
// };

// const ReactPlayerStyle = styled.div`
//     position: relative;
//     width: 100%;
//     max-width: 740px;
//     aspect-ratio: 16 / 9;
//     border-radius: 40px;
//     overflow: hidden;
//     background-color: black;

//     .poster-image {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         cursor: pointer;
//         z-index: 1;
//         background-size: cover;
//         background-position: center;
//     }

//     .video-player {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 2;
//     }

//     .hidden {
//         display: none;
//     }
// `;

// const VideoPlayer: React.FC<VideoPlayerProps> = ({ srcUrl, posterUrl }) => {
//     const [showVideo, setShowVideo] = useState(false);
//     const playerRef = useRef<ReactPlayer | null>(null);

//     const handlePosterClick = () => {
//         setShowVideo(true);
//         setTimeout(() => {
//             if (playerRef.current) {
//                 playerRef.current.getInternalPlayer()?.play();
//             }
//         }, 0); 
//     };

//     return (
//         <ReactPlayerStyle>
//             {!showVideo && (
//                 <div
//                     className="poster-image"
//                     style={{ backgroundImage: `url(${posterUrl})` }}
//                     onClick={handlePosterClick}
//                 />
//             )}
//             {showVideo && (
//                 <ReactPlayer
//                     ref={playerRef}
//                     url={srcUrl}
//                     controls
//                     playing={true} 
//                     className="video-player"
//                     width="100%"
//                     height="100%"
//                 />
//             )}
//         </ReactPlayerStyle>
//     );
// };

// export default VideoPlayer;


// import React from 'react';
// import ReactPlayer from 'react-player';
// import styled from 'styled-components';

// type VideoPlayerProps = {
//     srcUrl?: string;
//     posterImg?: string;
// };

// const ReactPlayerStyle = styled.div`
//     border-radius: 40px;
//     overflow: hidden;
//     .react-player {
//         max-width: 740px !important;
//         width: 100% !important;
//         height: 100% !important;
//     }
// `;

// const VideoPlayer: React.FC<VideoPlayerProps> = ({ srcUrl, posterImg }) => {
//     return (
//         <ReactPlayerStyle>
//             <ReactPlayer url={srcUrl} controls light={posterImg} className='react-player' />
//         </ReactPlayerStyle>
//     );
// };

// export default VideoPlayer;
