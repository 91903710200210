import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import type { ModalProps } from '@modules/ui/core';
import { Dialog } from '@modules/ui/core';
import { Loader } from '@modules/layout/moleculas';

type DialogProjectComponentDeleteProps = Omit<ModalProps, 'title' | 'children'> & {
    deleteLoading: boolean;
    componentAssessmentTitle?: string;
    onDelete: () => void;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogProjectComponentDelete = (props: DialogProjectComponentDeleteProps): React.ReactElement => {
    const { componentAssessmentTitle, deleteLoading, onDelete } = props;
    if(deleteLoading) {
        return <Loader />;
    }
    return (
        <StyledDialog
            title='Are you sure you want to delete this component?'
            content={componentAssessmentTitle + ' Component.'}
            acceptText='Delete'
            onAccept={onDelete}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;
                return <MenuItem onClick={handleOpen}>Delete</MenuItem>;
            }}
        </StyledDialog>
    );
};

export { DialogProjectComponentDelete };
