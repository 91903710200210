import { gql } from '@apollo/client';

export const AssetCategoryFragment = gql`
    fragment AssetCategory on AssetCategory {
        id
        name
        risk_function_id
        item_id
    }
`;
