import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';

import { UserEntity } from '@modules/users/entities';
import { ComponentReportEntityList } from '@modules/assessment/entities';
import { PrioritizedRoadmapEntity } from './prioritized-roadmap-entity';

import type {
    ComponentReportEntity,
    ComponentReportEntityType,
} from '@modules/assessment/entities';
import type { AssessmentReport } from '@modules/types/graphql';
import type { Entity as PrioritizedRoadmapEntityType } from './prioritized-roadmap-entity';

export type Entity = Partial<
    Omit<AssessmentReport, 'component_reports' | 'prioritized_roadmap'> & {
        component_reports: ComponentReportEntityType[] | null;
        prioritized_roadmap: PrioritizedRoadmapEntityType;
    }
> | null;

@DescribeEntity('RoadmapReportEntity')
class RoadmapReportEntity extends BaseEntity {
    @Relation(() => UserEntity)
    programManager: UserEntity;

    @RelationList(() => ComponentReportEntityList)
    componentReports: (ComponentReportEntity | never)[];

    @Relation(() => PrioritizedRoadmapEntity)
    prioritizedRoadmap: PrioritizedRoadmapEntity;

    hasProgramManager() {
        const result = this.programManager.exists();

        return result;
    }

    programManagerToString() {
        if (!this.hasProgramManager()) {
            return '';
        }

        const result = this.programManager.getFullName();

        return result;
    }
}

export { RoadmapReportEntity };
