import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import { Button } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useCurrentUser } from '@modules/auth/hooks';
import { DialogCapabilityDelete } from '@modules/custom-components/moleculas';
import { CapabilityLevelList, CapabilityEditFormModal } from '@modules/custom-components/organisms';
import { GetCapabilitiesQuery, DeleteCapabilityMutation } from '@modules/custom-components/graphql';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import type { CapabilityEntity } from '@modules/capabilities/entities';
import type {
    DeleteCapabilityMutationType,
    DeleteCapabilityMutationVariables,
} from '@modules/types/graphql';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { PageStyled } from '@modules/layout/styled';
import { CustomCapabilitiesListToolbar } from '@modules/custom-components/moleculas/custom-components-list-toolbar';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { AccordionListItem } from '@modules/layout/organisms';
import { ExpandMore, MoreVert } from '@material-ui/icons';
import { AuthContext } from '@modules/auth/context';
import { cognitoUserGroupsForComponentAccess } from '@helpers';
import { Loader } from '@modules/layout/moleculas';

type CapabilityListProps = {
    capabilities: CapabilityEntity[];
    componentId: number;
};

const useAccordionStyles = makeStyles({
    root: {
        width: '100%',
        backgroundColor: gray[60],
    },
});

const useAccordionSummaryStyles = makeStyles({
    content: {
        alignItems: 'center',
        position: 'relative',
    },
});

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;
`;

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
    p {
        font-size: 1.4rem;
    }
    svg {
        font-size: 25px;
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;
const DeleteSpan = styled.div`
    color: crimson;
`;

const Actions = styled.div`
    margin-left: auto;

    > * {
        margin-left: 8px;
    }
`;

const Empty = styled.div`
    font-size: 1.6rem;
    font-weight: 700;
`;

const CapabilityList = (props: CapabilityListProps): React.ReactElement => {
    const { capabilities, componentId } = props;
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [capabilityEditId, setCapabilityEditId] = useState<number | any>(null);
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const componentActionStatus = true;

    const { currentUser, loading: userLoading } = useCurrentUser();
    const { user, loading: contextUserLoading } = React.useContext(AuthContext) || {};

    const [deleteCapability, { loading: deleteCapabilityLoading }] = useMutation<
        DeleteCapabilityMutationType,
        DeleteCapabilityMutationVariables
    >(DeleteCapabilityMutation);

    const [expanded, setExpanded] = React.useState<number | null>(null);
    const [selectedId, setSelectedId] = useState<number | any>(null);
    const [filterData, setFilterData] = useState<any[]>();

    const handleChangeAccordion = (id: number) => (_: any, isExpanded: boolean) =>
        setExpanded(isExpanded ? id : null);

    const handleDeleteCapability = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteCapabilityData } = await deleteCapability({
                variables: { capabilityId: id },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery, variables: { componentId } }],
                awaitRefetchQueries: true,
            });

            if (deleteCapabilityData?.deleteCapability) {
                enqueueSuccess('Capability successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the capabiity!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditCapability = (id: number) => {
        setCapabilityEditId(id);
        handleOpenEditModal();
    };
    const isAccess = cognitoUserGroupsForComponentAccess?.includes(user?.roles?.[0] || '');
    useEffect(() => {
        const filterComponent = capabilities?.filter((ele: any) => {
            if (typeof ele?.title === 'string') {
                return ele?.title.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setFilterData(filterComponent);
    }, [componentSearch, capabilities]);
    if (userLoading || contextUserLoading) {
        return <Loader />;
    }
    return (
        <div>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>Capabilities</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>
            {isAccess && (
                <>
                    <PageStyled.Bridge>
                        <CustomCapabilitiesListToolbar
                            componentId={componentId}
                            setComponentSearch={setComponentSearch}
                            componentSearch={componentSearch}
                        />
                    </PageStyled.Bridge>
                </>
            )}
            {filterData?.map(capability => {
                const canEditCapability = capability.isPredefined()
                    ? currentUser.hasSuperAdminPermission()
                    : true;

                return (
                    <Root key={capability?.id}>
                        <AccordionListItem
                            projectstatus={'Implementation'}
                            key={capability.id}
                            title={
                                selectedId === capability?.id ? (
                                    deleteCapabilityLoading ? (
                                        <DeleteSpan>Deleting...</DeleteSpan>
                                    ) : (
                                        capability?.title
                                    )
                                ) : (
                                    capability?.title
                                )
                            }
                            expanded={expanded === capability.id}
                            onChange={handleChangeAccordion(capability.id)}
                            componentActionStatus={componentActionStatus}
                            componentSearch={componentSearch}
                            subtitle=''
                            SummaryInfoComponents={
                                <>
                                    {canEditCapability && isAccess ? (
                                        <CategoryActions
                                            id={capability?.id}
                                            onDelete={handleDeleteCapability}
                                            onUpdate={handleEditCapability}
                                        />
                                    ) : (
                                        <ExpandMore style={{ fontSize: '24px', color: 'gray' }} />
                                        // null
                                    )}
                                </>
                            }
                        >
                            <CapabilityLevelList capability={capability} capabilityId={expanded} />
                        </AccordionListItem>
                    </Root>
                );
            })}

            <CapabilityEditFormModal
                open={openEditModal}
                onClose={handleCloseEditModal}
                componentId={componentId}
                capabilityId={capabilityEditId}
            />
        </div>
    );
};

export { CapabilityList };
// <Accordion
//     elevation={3}
//     key={capability.id}
//     classes={accordionClasses}
//     expanded={expanded === capability.id}
//     onChange={handleChangeAccordion(capability.id)}
// >
//     <AccordionSummary classes={accordionSummaryClasses}>
//         <Title>
//             <h3>{capability.title}</h3>
//             {expanded === capability.id ? (
//                 <ExpandMoreIcon />
//             ) : (
//                 <KeyboardArrowLeftIcon />
//             )}
//         </Title>

//         {canEditCapability ? (
//             <Actions onClick={e => e.stopPropagation()}>
//                 <Button
//                     size='small'
//                     onClick={() => handleSetEditCapability(capability.id)}
//                 >
//                     Edit
//                 </Button>

//                 <DialogCapabilityDelete
//                     onDelete={handleDeleteCapability(capability.id)}
//                 />
//             </Actions>
//         ) : null}
//     </AccordionSummary>

//     <AccordionDetails>
//         <CapabilityLevelList capability={capability} capabilityId={expanded} />
//     </AccordionDetails>
// </Accordion>
