import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';

import { UserEntity } from '@modules/users/entities';
import { ComponentReportEntityList } from './component-report-entity-list';

import type { AssessmentReport } from '@modules/types/graphql';
import type { ComponentReportEntity } from './component-report-entity';

export type Entity = Partial<AssessmentReport> | null;

@DescribeEntity('AssessmentReportEntity')
class AssessmentReportEntity extends BaseEntity {
    @Relation(() => UserEntity)
    programManager: UserEntity;

    @RelationList(() => ComponentReportEntityList)
    componentReports: (ComponentReportEntity | null)[];

    hasProgramManager() {
        const result = this.programManager.exists();

        return result;
    }

    programManagerToString() {
        if (!this.hasProgramManager()) {
            return '';
        }

        const result = this.programManager.getFullName();

        return result;
    }
}

export { AssessmentReportEntity };
