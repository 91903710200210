import { gql } from '@apollo/client';

import { ComponentSingleFragment } from '../fragments';

export const CreateComponentMutation = gql`
    mutation CreateComponent($assessmentTypeId: Int!, $name: String!, $companyId: Int, $projectId: Int, $isAssessment: Boolean) {
        createComponent(
            assessment_type_id: $assessmentTypeId
            name: $name
            company_id: $companyId
            project_id: $projectId
            is_assessment: $isAssessment
        ) {
            ...ComponentSingle
        }
    }
    ${ComponentSingleFragment}
`;
