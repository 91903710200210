import React from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';

import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
import { CompanyForm } from '@modules/company/organisms';

import type { Page } from '@modules/types/page';
import type {
    AddSubDomainToCloudFrontType,
    AddSubDomainToCloudFrontVariables,
    CreateCompanyDetailsMutationType,
    CreateCompanyDetailsMutationVariables,
    CreateSubdomainType,
    CreateSubdomainVariables,
} from '@modules/types/graphql';
import {
    AddSubDomainToCloudFrontMutation,
    CreateCompanyDetailsMutation,
    CreateSubdomainMutation,
} from '@modules/company/graphql/mutations';
import { CompanyCreateRequest, CompnayCreateFormValues } from '@modules/projects/requests';
import { useCurrentUser } from '@modules/auth/hooks';
import { Loader } from '@modules/layout/moleculas';
import { GetCompanyDetailsQuery } from '@modules/company/graphql/queries';
import { AuthContext } from '@modules/auth/context';
import { CompanyLoader } from '@modules/layout/moleculas/company-loader';

const Main = styled(PageStyled.Main)`
    margin-top: 48px;
`;

const FormWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-width: 625px;
`;

const CompanyCreatePage = (props: Page): React.ReactElement => {
    const { title = 'Create company' } = props;

    const navigate = useNavigate();
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const [loaderMessage, setLoaderMessage] = React.useState<string>('');
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const userId = currentUser.id;

    const initialValues: CompnayCreateFormValues = {
        userId: userId,
        name: '',
        address: '',
        phone: '',
        contactName: '',
        email: '',
        packageType: '',
        subscriptionType: '',
        isAskTko: false,
        isLearnTko: false,
        isSchema: false,
    };

    const [createCompanyDetails] = useMutation<
        CreateCompanyDetailsMutationType,
        CreateCompanyDetailsMutationVariables
    >(CreateCompanyDetailsMutation);
    const [addSubDomainToCloudFront] = useMutation<
        AddSubDomainToCloudFrontType,
        AddSubDomainToCloudFrontVariables
    >(AddSubDomainToCloudFrontMutation);
    const [createSubdomain] = useMutation<CreateSubdomainType, CreateSubdomainVariables>(
        CreateSubdomainMutation,
    );
    const handleSubmit = async (values: CompnayCreateFormValues): Promise<void> => {
        try {
            // if (user?.roles[0] === 'super_admin') {
            //     let variables = new CompanyCreateRequest(values);
            //     const { data: createCompanyDetailsData } = await createCompanyDetails({
            //         variables: variables,
            //         refetchQueries: [{ query: GetCompanyDetailsQuery }],
            //         awaitRefetchQueries: true,
            //     });
            //     if (createCompanyDetailsData?.createCompanyDetails?.id && createCompanyDetailsData.createCompanyDetails.name) {
            //         const { data: addSubDomainToCloudFrontData } = await addSubDomainToCloudFront({
            //             variables: {
            //                 subDomain: createCompanyDetailsData.createCompanyDetails.name
            //             }
            //         });
            //         if (addSubDomainToCloudFrontData?.addSubDomainToCloudFront && createCompanyDetailsData.createCompanyDetails.name) {
            //             const { data: createSubdomainData } = await createSubdomain({
            //                 variables: {
            //                     subDomain: createCompanyDetailsData.createCompanyDetails.name,
            //                     distributionNames: addSubDomainToCloudFrontData?.addSubDomainToCloudFront
            //                 }
            //             });
            //             if (createSubdomainData?.createSubdomain) {
            //                 enqueueSuccess('Company successfully created!');
            //                 await navigate(routes.companies.path);
            //             } else {
            //                 enqueueError('An error occurred while creating the company!');
            //             }
            //         } else {
            //             enqueueError('An error occurred while creating the company!');
            //         }
            //     } else {
            //         enqueueError('An error occurred while creating the company!');
            //     }
            // } else {
            //     enqueueError('you are not authorized user for create the company!');
            // }
            try {
                if (user?.roles[0] !== 'super_admin') {
                    enqueueError('You are not authorized to create the company!');
                    return;
                }
                setLoaderMessage(
                    'Company creation has started. Please wait a few minutes for the company settings to be applied.',
                );
                // Create the company
                const variables = new CompanyCreateRequest(values);
                const { data: createCompanyDetailsData } = await createCompanyDetails({
                    variables,
                    refetchQueries: [{ query: GetCompanyDetailsQuery }],
                    awaitRefetchQueries: true,
                });

                // Check if company was created successfully
                const company = createCompanyDetailsData?.createCompanyDetails;
                if (!company?.id || !company?.name) {
                    enqueueError('An error occurred while creating the company!');
                    return;
                } else {
                    setLoaderMessage(
                        '"' +
                            company?.name +
                            '" Company has been created. Now adding the "' +
                            company?.name +
                            '" subdomain to the CloudFront.',
                    );
                }

                // Add subdomain to CloudFront
                const { data: addSubDomainToCloudFrontData } = await addSubDomainToCloudFront({
                    variables: { subDomain: company.name },
                });

                if (!addSubDomainToCloudFrontData?.addSubDomainToCloudFront) {
                    enqueueError('An error occurred while adding subdomain to CloudFront!');
                    return;
                } else {
                    setLoaderMessage(
                        '"' +
                            company?.name +
                            '" subdomain successfully added to CloudFront. Now mapping the "' +
                            company?.name +
                            '" subdomain to ACM and Route 53.',
                    );
                }

                // Create the subdomain
                const { data: createSubdomainData } = await createSubdomain({
                    variables: {
                        subDomain: company.name,
                        distributionNames: addSubDomainToCloudFrontData.addSubDomainToCloudFront,
                    },
                });
                if (!createSubdomainData?.createSubdomain) {
                    enqueueError('An error occurred while creating the subdomain!');
                    return;
                } else {
                    enqueueSuccess(createSubdomainData?.createSubdomain);
                }

                // Success
                enqueueSuccess('Company successfully created!');
                await navigate(routes.companies.path);
            } catch (e) {
                console.error(e); // Log the error for debugging purposes
                enqueueError('An unexpected error occurred!');
            }
        } catch (e) {
            throw e;
        }
    };
    if (currentUserLoading || userLoading) {
        return <Loader />;
    }
    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                <CompanyForm
                    type='create'
                    initialValues={initialValues}
                    FormWrapComponent={FormWrap}
                    onSubmit={handleSubmit}
                />
            </Main>
            {loaderMessage && <CompanyLoader value={loaderMessage} />}
        </>
    );
};
export { CompanyCreatePage };
