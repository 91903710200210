import { BaseEntity, Entity as DescribeEntity, RelationList } from '@lib/entity';

import type { AllItemType } from '@modules/types/graphql';
import type { CategoryDetailsEntityType } from './category-details-entity';
import { CategoryDetailsEntity } from './category-details-entity';
import { CategoryDetailsEntityList } from './category-details-entity-list';

export type Entity = Partial<
    Omit<AllItemType, 'itemCategory'> & {
        itemCategory: CategoryDetailsEntityType[] | null;
    }
> | null;

@DescribeEntity('AllItemTypesEntity')
class AllItemTypesEntity extends BaseEntity {
    id: number;
    name: string;

    @RelationList(() => CategoryDetailsEntityList)
    itemCategory: CategoryDetailsEntity[];

    constructor(itemCategory: Entity) {
        super(itemCategory);

        this.id = itemCategory?.id ?? -1;
        this.name = itemCategory?.name ?? '';
    }
}

export { AllItemTypesEntity };
