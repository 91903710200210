import React from 'react';
import { ListItemActions } from '@modules/layout/moleculas';
import { DialogProjectDelete } from '@modules/projects/moleculas';
import { Button } from '@material-ui/core';

type ProjectsListItemActionsProps = {
    onDelete: () => Promise<void>;
    onEdit: () => Promise<void>;
};

const ProjectsListItemActions = (props: ProjectsListItemActionsProps): React.ReactElement => {
    const { onDelete, onEdit } = props;

    return (
        <ListItemActions>
            {() => (
                <>
                    <DialogProjectDelete onDelete={onDelete} />
                    <Button onClick={onEdit} variant="text" style={{ textAlign: 'center', padding: '6px 30px', backgroundColor: 'white', color: 'black' }}>
                        Edit
                    </Button>
                </>
            )}
        </ListItemActions>
    );
};

export { ProjectsListItemActions };
