import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import { GetListRecommendationGenerationCountQuery } from '../graphql/queries';

const useListRecommendationGenerationCount = (options?: QueryHookOptions) => {
    const { data, loading } = useQuery(GetListRecommendationGenerationCountQuery, options);

    const result = {
        generationCount: data?.listRecommendationGenerationCount,
        loading,
    };

    return result;
};

export { useListRecommendationGenerationCount };
