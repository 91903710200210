import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Recommendation } from '@modules/types/graphql';

export type Entity = Partial<Recommendation> | null;

@DescribeEntity('RecommendationEntity')
class RecommendationEntity extends BaseEntity {
    id: number;
    gapId: number;
    recommendation: string;
    companyId: number | null;
    isAiRecommendations: boolean;

    constructor(recommendation: Entity) {
        super(recommendation);

        this.id = recommendation?.id ?? -1;
        this.gapId = recommendation?.gap_id ?? -1;
        this.recommendation = recommendation?.recommendation ?? '';
        this.companyId = recommendation?.company_id ?? null;
        this.isAiRecommendations = recommendation?.is_ai_recommendations ?? false;
    }

    isPredefined() {
        const result = this.companyId === null;

        return result;
    }
}

export { RecommendationEntity };
