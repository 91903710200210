import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { useCurrentUser } from '@modules/auth/hooks';
import { ProjectListFilters } from '@modules/projects/moleculas';
import { ProjectCreateModal } from '@modules/projects/organisms';
import { GetAssignedProjectsQuery } from '@modules/projects/graphql';
import { AuthContext } from '@modules/auth/context';
import { useOrganizationDetails } from '@modules/components/hooks';
import { Loader } from '@modules/layout/moleculas';

type ProjectToolProps = {
    isCreateProject?: boolean;
    isFreeze?: boolean;
};
const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div`
    width: 100%;
`;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
`;
const Filters = styled.div`
    margin-left: auto;
`;

const ProjectListToolbar = (props: ProjectToolProps): React.ReactElement => {
    const { isCreateProject, isFreeze } = props;
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const isSuperAdmin = currentUser?.isSuperAdmin;
    let authContext = useContext(AuthContext);
    let openCreateModal: any = authContext?.showModal;
    function handleOpenCreateModal() {
        authContext?.handleOpenProjectModal();
    }
    function handleCloseCreateModal() {
        authContext?.handleCloseProjectModal();
    }

    const createProjectAccess = useMemo(() => {
        if (organizationDetails && organizationDetails?.user_id) {
            return true;
        } else {
            return false;
        }
    }, [organizationDetails]);

    if (currentUserLoading || orgDetailLoading) {
        return <Loader />;
    }

    return (
        <Root>
            {currentUser.hasCreateProject() ? (
                <Actions>
                    <ButtonCreateLink
                        disabled={!(isSuperAdmin || (isCreateProject && !isFreeze))}
                        onClick={handleOpenCreateModal}
                    >
                        Create New Project
                    </ButtonCreateLink>
                    {
                        createProjectAccess
                        &&
                        <ProjectCreateModal
                            projectType={'project'}
                            refetchQuery={GetAssignedProjectsQuery}
                            open={openCreateModal}
                            onClose={handleCloseCreateModal}
                        />
                    }
                </Actions>
            ) : null}

            <Filters>
                <ProjectListFilters />
            </Filters>
        </Root>
    );
};

export { ProjectListToolbar };
