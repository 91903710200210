import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { ListData } from '@modules/types/graphql';

export type ListDataEntity = Partial<ListData> | null;

@DescribeEntity('TkoScopeEntity')
class S3DataEntity extends BaseEntity {
    key: string;
    value: string;

    constructor(listData: ListDataEntity) {
        super(listData);

        this.key = listData?.key ?? '';
        this.value = listData?.value ?? '';
    }
}

export { S3DataEntity };
