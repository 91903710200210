import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

import { Dialog } from '@modules/ui/core';

type DialogDeleteProps = {
    onDelete: () => void;
};

const StyledDialog = styled(Dialog)`
    min-height: initial;
`;

const DialogDelete = (props: DialogDeleteProps): React.ReactElement => {
    const { onDelete } = props;

    return (
        <StyledDialog
            title='Are you sure you want to delete it?'
            acceptText='Delete'
            onAccept={onDelete}
        >
            {dialogProps => {
                const { handleOpen } = dialogProps;

                return <MenuItem
                    onClick={(e) => {
                        e.stopPropagation(); 
                        handleOpen(); 
                    }}
                >Delete</MenuItem>;
            }}
        </StyledDialog>
    );
};

export { DialogDelete };
