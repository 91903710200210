import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import {
    ListAssessmentCategoriesQuery,
    UpdateAssessmentCategoriesMutation,
} from '@modules/custom-components/graphql';

import type { CustomCategoryCreateFormValues } from '@modules/custom-components/requests';
import type {
    UpdateAssessmentCategoriesMutationType,
    UpdateAssessmentCategoriesMutationVariables,
} from '@modules/types/graphql';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useGetAssessmentCategoryById } from '@modules/custom-components/hooks';
import { Loader } from '@modules/layout/moleculas';

type CustomCategoryEditFormProps = {
    companyId?: number;
    onCancel?: () => void;
    type: string;
    categoryId?: number;
    GetAllAssessmentTypeQuery?: any;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomCategoryEditForm = (props: CustomCategoryEditFormProps): React.ReactElement => {
    const { onCancel, type, categoryId, GetAllAssessmentTypeQuery } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateAssessmentCategories] = useMutation<
        UpdateAssessmentCategoriesMutationType,
        UpdateAssessmentCategoriesMutationVariables
    >(UpdateAssessmentCategoriesMutation);

    const { getAsseessmentCategoryById, loading: categoryLoading } = useGetAssessmentCategoryById({
        variables: { id: categoryId as number },
    });
    const initialValues: CustomCategoryCreateFormValues = {
        name: getAsseessmentCategoryById?.name,
        isRRType: getAsseessmentCategoryById?.isRRType || false,
    };

    if (categoryLoading) {
        return <Loader />;
    }

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    // const variables = new CustomCategoryCreateRequest(values);
                    const { data: updateCategoryData } = await updateAssessmentCategories({
                        variables: {
                            id: categoryId as number,
                            name: values?.name,
                            isRRType: values?.isRRType,
                        },
                        refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                        awaitRefetchQueries: true,
                    });
                    const categoryIds = updateCategoryData?.updateAssessmentCategories?.id;
                    if (categoryIds) {
                        enqueueSuccess('Category successfully updated!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while updating category!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Category Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values?.isRRType}
                                    value={values?.isRRType}
                                    onChange={handleChange}
                                    name='isRRType'
                                />
                            }
                            label='Risk Registry Category'
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Update Category
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomCategoryEditForm };
