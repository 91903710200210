import React from 'react';
import {
    makeStyles,
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableBody,
    FormControlLabel,
} from '@material-ui/core';

import { TableCheckbox, TableCellText } from '@modules/layout/atoms';
import { TableRow, TableCell } from '@modules/layout/moleculas';

export type TableColumn = {
    id: string;
    label?: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
};

export type TableProps = {
    children: React.ReactNode;
    columns?: TableColumn[];
    withSelectAll?: boolean;
    countSelected?: number;
    countRows?: number;
    onSelectAll?: () => void;
};

const useTableStyles = makeStyles({
    root: {
        borderCollapse: 'separate',
        borderSpacing: '0 4px',
    },
});

const Table = (props: TableProps): React.ReactElement => {
    const {
        columns = [],
        withSelectAll,
        countSelected,
        countRows,
        children,
        onSelectAll,
        ...otherProps
    } = props;

    const tableClasses = useTableStyles();

    const headColumns = withSelectAll ? columns.slice(1) : columns;
    const hasSelected = countSelected !== 0;
    const isIndeterminate = countSelected !== 0 && countSelected !== countRows;

    return (
        <TableContainer>
            <MuiTable classes={tableClasses} {...otherProps}>
                {columns && columns?.length !== 0 ? (
                    <TableHead>
                        <TableRow>
                            {withSelectAll ? (
                                <TableCell width='150'>
                                    <FormControlLabel
                                        control={
                                            <TableCheckbox
                                                name='select-all'
                                                checked={hasSelected}
                                                indeterminate={isIndeterminate}
                                                onChange={onSelectAll}
                                            />
                                        }
                                        label={<TableCellText>Select all</TableCellText>}
                                    />
                                </TableCell>
                            ) : null}

                            {headColumns?.map(column => (
                                <TableCell
                                    key={column.id}
                                    width={column.width}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : null}

                <TableBody>{children}</TableBody>
            </MuiTable>
        </TableContainer>
    );
};

export { Table };
