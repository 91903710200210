import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

export type Entity = string | null;

@DescribeEntity('CapabilityImpactEntity')
class CapabilityImpactEntity extends BaseEntity {
    id: string;
    definition: string;

    constructor(capabilityImpact: Entity) {
        super({ capabilityImpact });

        const definition = capabilityImpact ?? '';

        this.id = this.getId(definition);
        this.definition = definition;
    }

    private getId(definition: string) {
        const sanitizeDefinition = definition.replace(/{|}/g, '');
        const [, id] = sanitizeDefinition.split('=');

        return id;
    }
}

export { CapabilityImpactEntity };
