import { useQuery } from '@apollo/client';
import { GetAssetsQuery } from '@modules/registry/graphql';
import type { QueryHookOptions } from '@apollo/client';
import { Asset } from '@modules/types/graphql';
import { useMemo } from 'react';

const useAssets = (options?: QueryHookOptions) => {
    const queryResult = useQuery<{ listAssets: Asset[] }>(GetAssetsQuery, options);

    const assetsRaw: { listAssets: Asset[] } = queryResult?.data ?? { listAssets: [] };
    const assets = useMemo(() => {
        const listAssets = assetsRaw.listAssets.map((assetRaw: Asset) => {
            let asset = { ...assetRaw };
            return asset;
        });

        return { listAssets: listAssets };
    }, [assetsRaw.listAssets]);

    return { assets, ...queryResult };
};

export { useAssets };
