import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@modules/ui/core';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { CustomComponentCreateRequest } from '@modules/custom-components/requests';
import { CreateComponentMutation } from '@modules/custom-components/graphql';

import type { CustomComponentCreateFormValues } from '@modules/custom-components/requests';
import type {
    CreateComponentMutationType,
    CreateComponentMutationVariables,
} from '@modules/types/graphql';
import { GetProjectQuery } from '@modules/projects/graphql';

type CustomComponentCreateFormProps = {
    projectId?: number;
    companyId?: number;
    assessmentId?: number;
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const CustomComponentCreateForm = (props: CustomComponentCreateFormProps): React.ReactElement => {
    const { companyId, projectId, assessmentId, onCancel } = props;

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { assessmentTypes } = useAssessmentTypes();
    const [createComponent] = useMutation<
        CreateComponentMutationType,
        CreateComponentMutationVariables
    >(CreateComponentMutation);

    const initialValues: CustomComponentCreateFormValues = {
        name: '',
        assessmentTypeId: assessmentId ?? -1,
        companyId,
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                name: yup.string().required('Required field'),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const variables = new CustomComponentCreateRequest(values);

                    const { data: createComponentData } = await createComponent({
                        variables: {
                            assessmentTypeId: variables.assessmentTypeId,
                            name: variables.name,
                            companyId: variables.companyId,
                            projectId: projectId,
                            isAssessment: true as boolean,
                        },
                        refetchQueries: [
                            { query: GetAllAssessmentTypeQuery },
                            { query: GetProjectQuery, variables: { id: projectId } },
                        ],
                        awaitRefetchQueries: true,
                    });

                    const componentId = createComponentData?.createComponent?.id;

                    if (componentId) {
                        enqueueSuccess('Component successfully created!');
                        onCancel?.();
                    } else {
                        enqueueError('An error occurred while creating component!');
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            id='name'
                            label='Component Name'
                            value={values.name}
                            error={!!errors.name}
                            helperText={errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormStyled.FormRowSubmit>
                            <SubmitButton type='submit' loading={isSubmitting}>
                                Create Component
                            </SubmitButton>

                            <SubmitButton variant='text' disabled={isSubmitting} onClick={onCancel}>
                                Cancel
                            </SubmitButton>
                        </FormStyled.FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { CustomComponentCreateForm };
