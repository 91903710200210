import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { LearnTkoDetails } from '@modules/types/graphql';

export type Entity = Partial<LearnTkoDetails> | null;

@DescribeEntity('AllLearnTkoDetailsEntity')
class AllLearnTkoDetailsEntity extends BaseEntity {
    id: number;
    menu: string;
    title: string;
    description: string;
    videoUrl: string;
    createdAt: string;

    constructor(learnTkoDetails: Entity) {
        super(learnTkoDetails);
        this.id = learnTkoDetails?.id ?? -1;
        this.menu = learnTkoDetails?.menu ?? '';
        this.title = learnTkoDetails?.title ?? '';
        this.description = learnTkoDetails?.description ?? '';
        this.videoUrl = learnTkoDetails?.video_url ?? '';
        this.createdAt = learnTkoDetails?.created_at ?? '';
    }
}

export { AllLearnTkoDetailsEntity };
