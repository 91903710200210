import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Autocomplete, Button, Modal, TextField } from '@modules/ui/core';
import type { ModalProps } from '@modules/ui/core';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Grid,
    MenuItem,
    Menu,
    Select,
    Checkbox,
    ListItemText,
} from '@material-ui/core';
import {
    Asset,
    DeleteAssetMutationType,
    DeleteAssetMutationVariables,
} from '@modules/types/graphql';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import { CreateAsset, UpdateAsset } from '@modules/registry/graphql';
import { useImpactLevels } from '@modules/shared/hooks';
import { tolerances } from '@modules/components/constants';
import { useAssetCategory, useAssetType } from '@modules/registry/hooks';
import { Field } from 'formik';
import { AssetTypeEntity } from '@modules/registry/entities';
import { DeleteAssetMutation } from '@modules/custom-components/graphql';
import { AssetListItemActions } from '@modules/projects/moleculas/asset-list-item-actions';
import { MoreVert } from '@material-ui/icons';
import { AuthContext } from '@modules/auth/context';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { AlignedScoreLabel, AssetRiskLabel } from '../asset-score-label';
import { gray } from '@modules/ui/colors';
import { Loader } from '@modules/layout/moleculas';

const CustomField = styled.input`
    color: black;
    border: 1px solid #8c8c8c61;
    background: #eaeaea;
    height: 48px;
    padding: 6px 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
`;
const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: '#000 !important',
    },
    '& svg': {
        display: 'none',
    },
}));
const CustomHeading = styled.h2`
    background: #2f4f6f;
    text-align: center;
    padding: 8px 10px;
    color: white;
    border-radius: 6px 6px 0 0;
`;
const StyledModal = styled(Modal)`
    min-width: 100%;
    width: 100%;
    > div > div:nth-child(1) {
        padding-right: 35px;
    }
`;

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const FieldDiv = styled.div`
    flex: 5;
    margin-bottom: 5px !important;
    table {
        tbody {
            tr {
                td:nth-child(3),
                td:nth-child(2) {
                    .MuiSelect-selectMenu.MuiSelect-select {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        max-height: 36px;
                        white-space: unset !important;
                        line-height: 16px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    th,
    td {
        border: 1px solid #d1d2d2;
        padding: 8px;
        text-align: left;
    }
    th {
        background-color: #2f4f6f;
        color: white;
    }

    .MuiSelect-selectMenu {
        white-space: wrap;
    }
`;

const ActionButton = styled(Button)`
    min-width: 60px;
    height: 32px;
    font-size: 12px;
    line-height: 12px;
    svg {
        font-size: 2rem;
        bottom: -5px;
        position: relative;
    }
`;

const Empty = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 12px 0;

    > p {
        color: ${gray[20]};
        font-size: 1.4rem;
        font-weight: 400;
    }
`;

type RowType = {
    riskPosture: number | any;
    riskScore: number | any;
    item_id: number;
    dataType: string;
    name: string;
    category_id: string | number;
    type_id: string | number;
};

type AlignedRowType = {
    category_id: string | number;
    type_ids: (string | number)[];
};

const defaultInitialValues = {
    id: '',
    name: '',
    incidents_creation_process: '',
    incidents_storage_retention_and_retrieval: '',
    incidents_usage_and_processing: '',
    incidents_is_classified_or_labeled: 1,
    incidents_notes: '',
    risk_appetite_desc: '',
    risk_appetite_level: null,
    risk_tolerance_desc: '',
    risk_tolerance_level: null,
    risk_capacity_desc: '',
    risk_capacity_level: null,
    unacceptable_risk_desc: '',
    unacceptable_risk_level: null,
    owner: null,
    pm: null,
    stakeholders: [],
    itemType: null,
    itemClass: null,
    riskGroup: null,
    riskFunction: null,
};

const AssetManagementModal = (
    props: ModalProps & {
        activeAsset: null | Asset;
        assetData: null | Asset[];
        setActiveRow: (id: any) => void;
    },
): React.ReactElement => {
    const { onClose, activeAsset, assetData, refetchQuery, setActiveRow, ...otherProps } = props;
    const [initialValues, setInitialValues] = useState(defaultInitialValues);
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { assetCategory, loading: assetCategoryLoading } = useAssetCategory();
    const { assetTypeData, loading: assetTypeLoading } = useAssetType();
    const [itemTypeData, setItemTypeData] = useState<number | any>(0);
    const { ImpactLevels, loading: isImpactLoading } = useImpactLevels();
    const [riskAppetiteLevelId, setRiskAppetiteLevelId] = useState<number | any>(0);
    const [riskToleranceLevelId, setRiskToleranceLevelId] = useState<number | any>(0);
    const [riskCapacityCal, setRiskCapacityCal] = useState<number | any>(0);
    const [unacceptableRiskCal, setUnacceptableRiskCal] = useState<number | any>(0);
    const [assetPrevTypeDetails, setAssetPrevTypeDetails] = useState<{
        [key: number]: AssetTypeEntity[];
    }>({});
    const [rows, setRows] = useState<RowType[]>([]);
    const [alignedRows, setAlignedRows] = useState<AlignedRowType[]>([]);
    const [addAlignmentItemLoader, setAddAlignmentItemLoader] = useState<boolean>(false);
    const addRow = () => {
        setRows([
            ...rows,
            {
                riskPosture: '',
                riskScore: '',
                item_id: 0,
                dataType: 'new',
                name: '',
                category_id: '',
                type_id: '',
            },
        ]);
    };
    const addAlignedRow = () => {
        setAlignedRows([...alignedRows, { category_id: '', type_ids: [] }]);
    };
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const { userHighRolesEntityList, loading: userRoleLoading } = useUserHighRole();
    const userHighRole: string = userHighRolesEntityList[0]?.welcomeRole;

    const [deleteAsset, { loading: deleteAssetLoading }] = useMutation<
        DeleteAssetMutationType,
        DeleteAssetMutationVariables
    >(DeleteAssetMutation);

    const [createAsset] = useMutation(CreateAsset);
    const [updateAsset] = useMutation(UpdateAsset);

    const handleDeleteAssetRow = async (assetId: any): Promise<void> => {
        try {
            const { data: deleteAssetData } = await deleteAsset({
                variables: { assetId: assetId },
                refetchQueries: [{ query: refetchQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteAssetData?.deleteAsset) {
                enqueueSuccess('Asset successfully deleted!');
                handleClose();
            } else {
                enqueueError('An error occurred while deleting the asset!');
            }
        } catch (e) {
            console.log(e);
        }
    };
    const isRMAndPMRole = (role: string) => {
        return role === 'RM' ||
            role === 'RA' ||
            user?.roles[0] === 'risk_admin' ||
            user?.roles[0] === 'risk_manager'
            ? true
            : false;
    };
    useEffect(() => {
        if (!activeAsset) {
            return;
        }
        const values: any = { ...activeAsset };
        delete values.__typename;
        values.owner = activeAsset?.owner?.id;
        values.pm = activeAsset?.pm?.id;
        values.stakeholders = activeAsset?.stakeholders?.map((ele: any) => ele.id);
        values.itemType = activeAsset?.itemType?.id;
        values.itemClass = activeAsset?.itemClass?.id;
        values.riskGroup = activeAsset?.riskGroup?.id;
        values.riskFunction = activeAsset?.riskFunction?.id;
        values.risk_capacity_level =
            activeAsset?.risk_capacity_level && activeAsset?.risk_capacity_level >= 10
                ? 10
                : activeAsset?.risk_capacity_level;
        values.unacceptable_risk_level =
            activeAsset?.unacceptable_risk_level && activeAsset?.unacceptable_risk_level >= 10
                ? 10
                : activeAsset?.unacceptable_risk_level;
        let datas: Asset[] | any = null;
        const alignedAssetIds = values.alignedAssets.id;
        console.log(alignedAssetIds, 'alignedAssetIds');
        if (alignedAssetIds !== null) {
            datas = assetData?.filter((ele: any) => alignedAssetIds.includes(ele.id)) || [];
            const prevData: RowType[] = [];
            const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
            if (datas !== null) {
                datas?.forEach((data: any, index: any) => {
                    // data.alignment_type === 'alignment' ? data.itemType.id === 1 ? setItemTypeData(2) : setItemTypeData(1) : setItemTypeData(data.itemType.id);
                    setItemTypeData(data.itemType.id);
                    const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(
                        asset => asset.categoryId === data.assetType?.category_id,
                    );
                    prevData.push({
                        riskPosture: data.riskPosture,
                        riskScore: data.riskScore,
                        item_id: data?.id,
                        dataType: 'prev',
                        name: data.name,
                        category_id: data.assetCategory.id,
                        type_id: data.assetType.id,
                    });
                    newAssetTypeDetails[index] = assetTypeDataValue;
                });
            } else {
                // activeAsset?.itemType?.id === 1 ? setItemTypeData(2) : setItemTypeData(1);
                setItemTypeData(activeAsset?.itemType?.id);
            }
            setRows(prevData);
            setAssetPrevTypeDetails(newAssetTypeDetails);
        } else {
            if (activeAsset?.itemAlignment != null) {
                const prevData: AlignedRowType[] = [];
                const newAssetTypeDetails: { [key: number]: AssetTypeEntity[] } = {};
                activeAsset?.itemAlignment.forEach((data: any, index: any) => {
                    const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(
                        asset => asset.categoryId === data?.category_id,
                    );
                    prevData.push({ category_id: data.category_id, type_ids: data.type_ids });
                    newAssetTypeDetails[index] = assetTypeDataValue;
                });
                setAlignedRows(prevData);
                setAssetPrevTypeDetails(newAssetTypeDetails);
            }
        }
        setInitialValues(values);
        setRiskAppetiteLevelId(activeAsset?.risk_appetite_level);
        setRiskToleranceLevelId(activeAsset.risk_tolerance_level);
        setRiskCapacityCal(activeAsset?.risk_capacity_level);
        setUnacceptableRiskCal(activeAsset?.unacceptable_risk_level);
    }, [activeAsset]);

    useEffect(() => {
        if (riskAppetiteLevelId !== null && riskToleranceLevelId !== null) {
            setRiskCapacityCal(riskAppetiteLevelId + riskToleranceLevelId);
            setUnacceptableRiskCal(riskAppetiteLevelId + riskToleranceLevelId + 1);
        }
    }, [riskToleranceLevelId, riskAppetiteLevelId]);

    useEffect(() => {
        setRiskCapacityCal(0);
        setUnacceptableRiskCal(0);
    }, [onClose]);

    const handleAssetsModal = (rowId: number) => {
        const rowIndex = assetData?.findIndex((ele: any) => ele.id === rowId);
        setActiveRow(rowIndex);
        onClose();
    };
    const selectedCategory: any = {};
    alignedRows?.map((ele: any) =>
        !selectedCategory[ele?.category_id]
            ? (selectedCategory[ele?.category_id] = ele?.category_id)
            : null,
    );

    // const selectdCategorytype: any = {};
    // rows?.map((ele: any) => (!selectdCategorytype[ele?.type_id]) ? selectdCategorytype[ele?.type_id] = ele?.type_id : null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    if (userLoading || userRoleLoading) {
        return <Loader />;
    }
    return (
        <StyledModal
            title={
                '"' + activeAsset?.name + '" ' + activeAsset?.itemType?.name + ' Management Card'
            }
            onClose={onClose}
            {...otherProps}
            className='increase-modal_width'
        >
            <ExtendedFormik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async values => {
                    const {
                        id,
                        incidents_creation_process,
                        incidents_storage_retention_and_retrieval,
                        incidents_usage_and_processing,
                        incidents_is_classified_or_labeled,
                        incidents_notes,
                        risk_appetite_desc,
                        risk_appetite_level,
                        risk_tolerance_desc,
                        risk_tolerance_level,
                        risk_capacity_desc,
                        unacceptable_risk_desc,
                    } = values;
                    try {
                        const { data: updateAssetData } = await updateAsset({
                            variables: {
                                input: {
                                    id,
                                    incidents_creation_process,
                                    incidents_storage_retention_and_retrieval,
                                    incidents_usage_and_processing,
                                    incidents_is_classified_or_labeled: Boolean(
                                        incidents_is_classified_or_labeled,
                                    ),
                                    incidents_notes,
                                    risk_appetite_desc,
                                    risk_appetite_level,
                                    risk_tolerance_desc,
                                    risk_tolerance_level,
                                    risk_capacity_desc,
                                    risk_capacity_level: riskCapacityCal,
                                    unacceptable_risk_desc,
                                    unacceptable_risk_level: unacceptableRiskCal,
                                },
                            },
                            refetchQueries: [{ query: refetchQuery }],
                            awaitRefetchQueries: true,
                        });

                        if (updateAssetData) {
                            enqueueSuccess('Asset successfully updated!');
                            onClose();
                        } else {
                            enqueueError('An error occurred while updating asset!');
                            onClose();
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {formikProps => {
                    const {
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue,
                    } = formikProps;

                    const handleAlignedSave = async (index: number) => {
                        try {
                            const row = alignedRows[index];
                            const regex = /^(.*?)(?:\s*-\s*.*)?$/;
                            const parentitem = activeAsset?.name.match(regex);
                            row.type_ids.forEach(async function (items: any) {
                                const itemTypeDetails: any = assetTypeData
                                    .filter(item => item.id === items)
                                    .map(ele => ele.name);
                                if (parentitem) {
                                    const { data: createAssetData } = await createAsset({
                                        variables: {
                                            name: parentitem[1] + ' - ' + itemTypeDetails,
                                            owner: values.owner,
                                            pm: values.pm,
                                            sh: values.stakeholders,
                                            alignmentType: 'alignment',
                                            itemType: values.itemType === 1 ? 2 : 1,
                                            itemClass: values.itemClass,
                                            riskGroup: values.riskGroup,
                                            riskFunction: values.riskFunction,
                                            assetCategory: row.category_id,
                                            assetType: items,
                                            prevItemId: values.id,
                                        },
                                        refetchQueries: [{ query: refetchQuery }],
                                        awaitRefetchQueries: true,
                                    });
                                    if (createAssetData) {
                                        enqueueSuccess(
                                            createAssetData?.createAsset?.name +
                                                ' Alignment successfully created!',
                                        );
                                    } else {
                                        enqueueError(
                                            'An error occurred while creating Asset Alignment!',
                                        );
                                    }
                                }
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    };

                    const handleSave = async (index: number) => {
                        setAddAlignmentItemLoader(true);
                        try {
                            const row = rows[index];
                            const regex = /^(.*?)(?:\s*-\s*.*)?$/;
                            const parentitem = row.name
                                ? row.name
                                : activeAsset?.name.match(regex)?.[1];
                            const itemTypeDetails: any = assetTypeData
                                .filter(item => item.id === row.type_id)
                                .map(ele => ele.name);
                            const alignemntCount = rows.filter(ele => ele.type_id === row.type_id)
                                .length;
                            const itemNameWithCount =
                                parentitem +
                                ' ' +
                                itemTypeDetails +
                                (alignemntCount === 1 ? '' : ' (' + alignemntCount + ')');
                            const { data: createAssetData } = await createAsset({
                                variables: {
                                    name: itemNameWithCount,
                                    owner: values.owner,
                                    pm: values.pm,
                                    sh: values.stakeholders,
                                    alignmentType: 'alignment',
                                    itemType: values.itemType === 1 ? 2 : 1,
                                    itemClass: values.itemClass,
                                    riskGroup: values.riskGroup,
                                    riskFunction: values.riskFunction,
                                    assetCategory: row.category_id,
                                    assetType: row.type_id,
                                    prevItemId: values.id,
                                },
                                refetchQueries: [{ query: refetchQuery }],
                                awaitRefetchQueries: true,
                            });

                            if (createAssetData) {
                                enqueueSuccess(
                                    createAssetData?.createAsset?.name +
                                        ' Asset Alignment successfully created!',
                                );
                                setAddAlignmentItemLoader(false);
                            } else {
                                enqueueError('An error occurred while creating Asset Alignment!');
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    };

                    const handleRiskAppetiteChange = (event: any) => {
                        handleChange(event);
                        setRiskAppetiteLevelId(event.target.value);
                    };

                    const handleRiskToleranceChange = (event: any) => {
                        handleChange(event);
                        setRiskToleranceLevelId(event.target.value);
                    };

                    const handleNameChange = (index: number, value: string) => {
                        const newRows = [...rows];
                        newRows[index] = { ...newRows[index], name: value, dataType: 'new' };
                        setRows(newRows);
                    };

                    const handleCategoryChange = (index: number, value: string) => {
                        const newRows = [...rows];
                        newRows[index] = {
                            ...newRows[index],
                            category_id: Number(value),
                            type_id: '',
                        };
                        const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(
                            asset => asset.categoryId === Number(value),
                        );
                        setAssetPrevTypeDetails({
                            ...assetPrevTypeDetails,
                            [index]: assetTypeDataValue,
                        });
                        setRows(newRows);
                    };

                    const handleTypeChange = (index: number, value: string | number) => {
                        const newRows = [...rows];
                        newRows[index] = { ...newRows[index], type_id: value };
                        setRows(newRows);
                    };

                    const handleAlignedCategoryChange = (index: number, value: string) => {
                        const newRows = [...alignedRows];
                        newRows[index] = {
                            ...newRows[index],
                            category_id: Number(value),
                            type_ids: [],
                        };
                        const assetTypeDataValue: AssetTypeEntity[] = assetTypeData.filter(
                            asset => asset.categoryId === Number(value),
                        );
                        setAssetPrevTypeDetails({
                            ...assetPrevTypeDetails,
                            [index]: assetTypeDataValue,
                        });
                        setAlignedRows(newRows);
                    };

                    const handleAlignedTypeChange = (index: number, value: (string | number)[]) => {
                        const newRows = [...alignedRows];
                        newRows[index] = { ...newRows[index], type_ids: value };
                        setAlignedRows(newRows);
                    };

                    return (
                        <StyledForm>
                            <FieldDiv
                                className='fields_column_spacing'
                                style={{ border: ' 1px solid #ededed', padding: '10px' }}
                            >
                                <CustomHeading
                                    style={{
                                        marginBottom: '10px',
                                        borderRadius: 0,
                                        marginTop: '-10px',
                                        marginLeft: '-10px',
                                        marginRight: '-10px',
                                    }}
                                >
                                    Risk Posture​{' '}
                                </CustomHeading>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskAppetite'
                                            value={'Risk Appetite'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_appetite_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_appetite_desc}
                                            error={!!errors.risk_appetite_desc}
                                            helperText={errors.risk_appetite_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            id='risk_appetite_level'
                                            name='risk_appetite_level'
                                            label='Risk Level'
                                            error={!!errors.risk_appetite_level}
                                            helperText={errors.risk_appetite_level}
                                            value={values?.risk_appetite_level}
                                            disabled={isImpactLoading}
                                            onChange={handleRiskAppetiteChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskTolerance'
                                            value={'Risk Tolerance'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_tolerance_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_tolerance_desc}
                                            error={!!errors.risk_tolerance_desc}
                                            helperText={errors.risk_tolerance_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            fullWidth
                                            id='risk_tolerance_level'
                                            name='risk_tolerance_level'
                                            label='Tolerance Level'
                                            error={!!errors.risk_tolerance_level}
                                            helperText={errors.risk_tolerance_level}
                                            value={values?.risk_tolerance_level}
                                            onChange={handleRiskToleranceChange}
                                        >
                                            {tolerances.map(tolerance => (
                                                <MenuItem key={tolerance} value={tolerance}>
                                                    {tolerance}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='riskCapacity'
                                            value={'Risk Capacity'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='risk_capacity_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.risk_capacity_desc}
                                            error={!!errors.risk_capacity_desc}
                                            helperText={errors.risk_capacity_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField
                                            select
                                            fullWidth
                                            id='risk_capacity_level'
                                            name='risk_capacity_level'
                                            value={
                                                riskCapacityCal === 0
                                                    ? values?.risk_capacity_level
                                                    : riskCapacityCal >= 10
                                                    ? 10
                                                    : riskCapacityCal
                                            }
                                            error={!!errors.risk_capacity_level}
                                            helperText={errors.risk_capacity_level}
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <CustomField
                                            id='unacceptableRisk​'
                                            value={'Unacceptable Risk'}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id='unacceptable_risk_desc'
                                            label='Description'
                                            placeholder='Add Description Here..'
                                            value={values.unacceptable_risk_desc}
                                            error={!!errors.unacceptable_risk_desc}
                                            helperText={errors.unacceptable_risk_desc}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomTextField
                                            select
                                            fullWidth
                                            id='unacceptable_risk_level'
                                            name='unacceptable_risk_level'
                                            value={
                                                unacceptableRiskCal === 0
                                                    ? values?.unacceptable_risk_level
                                                    : unacceptableRiskCal >= 10
                                                    ? 10
                                                    : unacceptableRiskCal
                                            }
                                            error={!!errors.unacceptable_risk_level}
                                            helperText={errors.unacceptable_risk_level}
                                            disabled
                                            onChange={handleChange}
                                        >
                                            {ImpactLevels ? (
                                                ImpactLevels.map(impact => {
                                                    const { id, name } = impact;
                                                    return (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem key={0} value={''}>
                                                    Select Impact
                                                </MenuItem>
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                </Grid>
                            </FieldDiv>

                            <FieldDiv style={{ marginTop: '20px' }}>
                                <CustomHeading>
                                    Aligned{' '}
                                    {activeAsset?.itemType?.name === 'Risk' ? 'Assets' : 'Risks'}
                                </CustomHeading>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {activeAsset?.alignedAssets?.id !== null ? (
                                            <>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                style={{
                                                                    width: '200px',
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                name
                                                            </th>
                                                            <th
                                                                style={{
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Category
                                                            </th>
                                                            <th
                                                                style={{
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Type
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '120px',
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Risk Rating
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '120px',
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Risk Posture
                                                            </th>
                                                            {activeAsset?.alignment_type ===
                                                                'item' && (
                                                                <>
                                                                    <th
                                                                        style={{
                                                                            width: '90px',
                                                                            fontSize: '14px',
                                                                            textTransform:
                                                                                'capitalize',
                                                                            fontWeight: 600,
                                                                            background: '#eaeaea',
                                                                            color: 'black',
                                                                        }}
                                                                    >
                                                                        Add to RR
                                                                    </th>
                                                                    <th
                                                                        style={{
                                                                            width: '70px',
                                                                            fontSize: '14px',
                                                                            textTransform:
                                                                                'capitalize',
                                                                            fontWeight: 600,
                                                                            background: '#eaeaea',
                                                                            color: 'black',
                                                                        }}
                                                                    >
                                                                        Action
                                                                    </th>
                                                                </>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.length > 0 ? (
                                                            rows.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {row.dataType === 'new' ? (
                                                                            <TextField
                                                                                // required
                                                                                fullWidth
                                                                                id={`name-${index}`}
                                                                                label='Item name'
                                                                                placeholder='Item name'
                                                                                value={row.name}
                                                                                error={false}
                                                                                helperText={''}
                                                                                onChange={e =>
                                                                                    handleNameChange(
                                                                                        index,
                                                                                        e.target
                                                                                            .value,
                                                                                    )
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <h3
                                                                                style={{
                                                                                    cursor:
                                                                                        'pointer',
                                                                                    color:
                                                                                        activeAsset
                                                                                            ?.itemType
                                                                                            ?.name ===
                                                                                        'Risk'
                                                                                            ? '#0b6bcb'
                                                                                            : '#000',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleAssetsModal(
                                                                                        row.item_id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <u>{row.name}</u>
                                                                            </h3>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <Field
                                                                            as={TextField}
                                                                            select
                                                                            fullWidth
                                                                            name={`rows[${index}].category`}
                                                                            label={
                                                                                activeAsset
                                                                                    ?.itemType
                                                                                    ?.name ===
                                                                                'Risk'
                                                                                    ? 'Asset Category'
                                                                                    : activeAsset
                                                                                          ?.itemType
                                                                                          ?.name ===
                                                                                      'Asset'
                                                                                    ? 'Risk Category'
                                                                                    : ''
                                                                            }
                                                                            value={row.category_id}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleCategoryChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                row.dataType !==
                                                                                'new'
                                                                            }
                                                                            className={
                                                                                row.dataType !==
                                                                                    'new' &&
                                                                                'notNewColumn'
                                                                            }
                                                                        >
                                                                            {assetCategory &&
                                                                                assetCategory
                                                                                    .filter(
                                                                                        risk =>
                                                                                            risk.itemId ===
                                                                                            itemTypeData,
                                                                                    )
                                                                                    .map(
                                                                                        (item: {
                                                                                            id: number;
                                                                                            name: string;
                                                                                        }) => (
                                                                                            <MenuItem
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                                value={
                                                                                                    item.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item.name
                                                                                                }
                                                                                            </MenuItem>
                                                                                        ),
                                                                                    )}
                                                                        </Field>
                                                                    </td>
                                                                    <td>
                                                                        <Field
                                                                            as={TextField}
                                                                            select
                                                                            fullWidth
                                                                            name={`rows[${index}].type`}
                                                                            label={
                                                                                activeAsset
                                                                                    ?.itemType
                                                                                    ?.name ===
                                                                                'Risk'
                                                                                    ? 'Asset Category Type'
                                                                                    : activeAsset
                                                                                          ?.itemType
                                                                                          ?.name ===
                                                                                      'Asset'
                                                                                    ? 'Risk Category Type'
                                                                                    : ''
                                                                            }
                                                                            value={row.type_id}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleTypeChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                row.dataType !==
                                                                                'new'
                                                                            }
                                                                            className={
                                                                                row.dataType !==
                                                                                    'new' &&
                                                                                'notNewColumn'
                                                                            }
                                                                        >
                                                                            {assetPrevTypeDetails[
                                                                                index
                                                                            ] &&
                                                                                assetPrevTypeDetails[
                                                                                    index
                                                                                ].map(
                                                                                    (item: {
                                                                                        id: number;
                                                                                        name: string;
                                                                                    }) => (
                                                                                        <MenuItem
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            value={
                                                                                                item.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.name
                                                                                            }
                                                                                        </MenuItem>
                                                                                    ),
                                                                                )}
                                                                        </Field>
                                                                    </td>
                                                                    <td>
                                                                        {!row.riskScore ? (
                                                                            <div
                                                                                className='is-status'
                                                                                style={{
                                                                                    textAlign:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                Pending
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className='is-status-donut'>
                                                                                    <AlignedScoreLabel
                                                                                        score={
                                                                                            row.riskScore
                                                                                        }
                                                                                        type='risk'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {!row.riskPosture ? (
                                                                            <div
                                                                                className='is-status'
                                                                                style={{
                                                                                    textAlign:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                Pending
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className='is-status-donut'>
                                                                                    <AssetRiskLabel
                                                                                        score={
                                                                                            row.riskPosture
                                                                                        }
                                                                                        type='riskAligned'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                    {activeAsset?.alignment_type ===
                                                                        'item' && (
                                                                        <>
                                                                            <td
                                                                                style={{
                                                                                    textAlign:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                {addAlignmentItemLoader &&
                                                                                row.dataType ===
                                                                                    'new' ? (
                                                                                    <Loader />
                                                                                ) : (
                                                                                    <ActionButton
                                                                                        type='button'
                                                                                        onClick={() =>
                                                                                            handleSave(
                                                                                                index,
                                                                                            )
                                                                                        }
                                                                                        disabled={
                                                                                            row.dataType !==
                                                                                            'new'
                                                                                        }
                                                                                    >
                                                                                        Add
                                                                                    </ActionButton>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    {isRMAndPMRole(
                                                                                        userHighRole,
                                                                                    ) ? (
                                                                                        deleteAssetLoading ? (
                                                                                            <Loader />
                                                                                        ) : (
                                                                                            <AssetListItemActions
                                                                                                onDelete={
                                                                                                    handleDeleteAssetRow
                                                                                                }
                                                                                                assetId={
                                                                                                    row.item_id
                                                                                                }
                                                                                            />
                                                                                        )
                                                                                    ) : (
                                                                                        <MoreVert />
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <Empty>
                                                                <p>Aligned Item not found</p>
                                                            </Empty>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                {activeAsset?.alignment_type === 'item' && (
                                                    <Button
                                                        type='button'
                                                        onClick={addRow}
                                                        style={{ marginTop: 15 }}
                                                    >
                                                        + Category
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            {activeAsset?.itemType?.name ===
                                                                'Risk' && (
                                                                <th
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        textTransform: 'capitalize',
                                                                        fontWeight: 600,
                                                                        background: '#eaeaea',
                                                                        color: 'black',
                                                                    }}
                                                                >
                                                                    name
                                                                </th>
                                                            )}
                                                            <th
                                                                style={{
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Category
                                                            </th>
                                                            <th
                                                                style={{
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Type
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '90px',
                                                                    fontSize: '14px',
                                                                    textTransform: 'capitalize',
                                                                    fontWeight: 600,
                                                                    background: '#eaeaea',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Add to RR
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {alignedRows &&
                                                            alignedRows.map((row, index) => (
                                                                <tr key={index}>
                                                                    {activeAsset?.itemType?.name ===
                                                                        'Risk' && (
                                                                        <td>
                                                                            <Autocomplete
                                                                                options={
                                                                                    assetData
                                                                                        ? assetData
                                                                                              .filter(
                                                                                                  ele =>
                                                                                                      ele
                                                                                                          .itemType
                                                                                                          ?.id ===
                                                                                                          2 &&
                                                                                                      ele
                                                                                                          .assetCategory
                                                                                                          ?.id ===
                                                                                                          row.category_id,
                                                                                              )
                                                                                              .map(
                                                                                                  ele =>
                                                                                                      ele.name,
                                                                                              )
                                                                                        : []
                                                                                }
                                                                                renderInput={params => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        label='Name'
                                                                                    />
                                                                                )}
                                                                                label='Choose Name'
                                                                                onChange={(
                                                                                    e: any,
                                                                                ) => {
                                                                                    handleChange(e);
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <Field
                                                                            as={TextField}
                                                                            select
                                                                            fullWidth
                                                                            name={`rows[${index}].category`}
                                                                            label={
                                                                                activeAsset
                                                                                    ?.itemType
                                                                                    ?.name ===
                                                                                'Risk'
                                                                                    ? 'Asset Category'
                                                                                    : activeAsset
                                                                                          ?.itemType
                                                                                          ?.name ===
                                                                                      'Asset'
                                                                                    ? 'Risk Category'
                                                                                    : ''
                                                                            }
                                                                            value={row.category_id}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleAlignedCategoryChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {assetCategory &&
                                                                                assetCategory
                                                                                    .filter(
                                                                                        risk =>
                                                                                            risk.itemId ===
                                                                                            (activeAsset
                                                                                                ?.itemType
                                                                                                ?.id ===
                                                                                            1
                                                                                                ? 2
                                                                                                : 1),
                                                                                    )
                                                                                    .map(
                                                                                        (item: {
                                                                                            id: number;
                                                                                            name: string;
                                                                                        }) => (
                                                                                            <MenuItem
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                                value={
                                                                                                    item.id
                                                                                                }
                                                                                                disabled={
                                                                                                    item?.id ===
                                                                                                    selectedCategory[
                                                                                                        item
                                                                                                            ?.id
                                                                                                    ]
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item.name
                                                                                                }
                                                                                            </MenuItem>
                                                                                        ),
                                                                                    )}
                                                                        </Field>
                                                                    </td>
                                                                    <td>
                                                                        <Field
                                                                            as={Select}
                                                                            multiple
                                                                            fullWidth
                                                                            name={`rows[${index}].type`}
                                                                            label={'Item Type'}
                                                                            value={row.type_ids}
                                                                            onChange={(e: any) => {
                                                                                handleChange(e);
                                                                                handleAlignedTypeChange(
                                                                                    index,
                                                                                    e.target.value,
                                                                                );
                                                                            }}
                                                                            renderValue={(
                                                                                selected: any,
                                                                            ) =>
                                                                                selected
                                                                                    .map(
                                                                                        (
                                                                                            id: number,
                                                                                        ) =>
                                                                                            assetPrevTypeDetails[
                                                                                                index
                                                                                            ]?.find(
                                                                                                item =>
                                                                                                    item.id ===
                                                                                                    id,
                                                                                            )?.name,
                                                                                    )
                                                                                    .join(', ')
                                                                            }
                                                                        >
                                                                            {assetPrevTypeDetails[
                                                                                index
                                                                            ] &&
                                                                                assetPrevTypeDetails[
                                                                                    index
                                                                                ].map(
                                                                                    (item: {
                                                                                        id: number;
                                                                                        name: string;
                                                                                    }) => (
                                                                                        <MenuItem
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            value={
                                                                                                item.id
                                                                                            }
                                                                                        >
                                                                                            <Checkbox
                                                                                                checked={
                                                                                                    row.type_ids.indexOf(
                                                                                                        item.id,
                                                                                                    ) >
                                                                                                    -1
                                                                                                }
                                                                                            />
                                                                                            <ListItemText
                                                                                                primary={
                                                                                                    item.name
                                                                                                }
                                                                                            />
                                                                                        </MenuItem>
                                                                                    ),
                                                                                )}
                                                                        </Field>
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {/* <ActionButton type="button" disabled={type !== 'edit'} onClick={() => setIndexValue(index)} onClick={() => handleSave(index)}>Add</ActionButton> */}
                                                                        <ActionButton
                                                                            type='button'
                                                                            onClick={() =>
                                                                                handleAlignedSave(
                                                                                    index,
                                                                                )
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            + Add
                                                                        </ActionButton>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                                <Button
                                                    type='button'
                                                    onClick={addAlignedRow}
                                                    style={{ marginTop: 15 }}
                                                >
                                                    + Category
                                                </Button>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </FieldDiv>
                            <FieldDiv
                                style={{
                                    marginTop: '20px',
                                    border: ' 1px solid #ededed',
                                    padding: '10px',
                                }}
                            >
                                <CustomHeading
                                    style={{
                                        marginBottom: '10px',
                                        borderRadius: 0,
                                        marginTop: '-10px',
                                        marginLeft: '-10px',
                                        marginRight: '-10px',
                                    }}
                                >
                                    {activeAsset?.itemType?.name === 'Risk' ? 'Risk' : 'Asset'}{' '}
                                    Attributes
                                </CustomHeading>
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_creation_process'
                                                label={
                                                    activeAsset?.itemType?.name === 'Asset'
                                                        ? 'Creation Process'
                                                        : 'Unwanted events'
                                                }
                                                value={values.incidents_creation_process}
                                                error={!!errors.incidents_creation_process}
                                                helperText={errors.incidents_creation_process}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_storage_retention_and_retrieval'
                                                label={
                                                    activeAsset?.itemType?.name === 'Asset'
                                                        ? 'Storage, Retention, and Retrieval'
                                                        : 'Current controls'
                                                }
                                                value={
                                                    values.incidents_storage_retention_and_retrieval
                                                }
                                                error={
                                                    !!errors.incidents_storage_retention_and_retrieval
                                                }
                                                helperText={
                                                    errors.incidents_storage_retention_and_retrieval
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                id='incidents_usage_and_processing'
                                                label={
                                                    activeAsset?.itemType?.name === 'Asset'
                                                        ? 'Usage and Processing'
                                                        : 'Control gaps'
                                                }
                                                value={values.incidents_usage_and_processing}
                                                error={!!errors.incidents_usage_and_processing}
                                                helperText={errors.incidents_usage_and_processing}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                    {activeAsset?.itemType?.name === 'Asset' && (
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'Center',
                                                        gap: '20px',
                                                    }}
                                                >
                                                    <FormLabel
                                                        style={{ fontSize: '12px', color: 'black' }}
                                                    >
                                                        Classified/Labeled
                                                    </FormLabel>
                                                    <RadioGroup
                                                        style={{
                                                            flexDirection: 'row',
                                                            gap: '12px',
                                                        }}
                                                        id='incidents_is_classified_or_labeled'
                                                        name='incidents_is_classified_or_labeled'
                                                        value={Number(
                                                            values.incidents_is_classified_or_labeled,
                                                        )}
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'incidents_is_classified_or_labeled',
                                                                Number(e.target.value),
                                                            );
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            value={1}
                                                            control={<Radio />}
                                                            label='Yes'
                                                        />
                                                        <FormControlLabel
                                                            value={0}
                                                            control={<Radio />}
                                                            label='No'
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                id='incidents_notes'
                                                label='Notes'
                                                value={values.incidents_notes}
                                                error={!!errors.incidents_notes}
                                                helperText={errors.incidents_notes}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            </FieldDiv>
                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    Save
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    );
                }}
            </ExtendedFormik>
        </StyledModal>
    );
};

export { AssetManagementModal };
