import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { RoleId } from '@modules/types/graphql';

export type Entity = Partial<RoleId> | null;

@DescribeEntity('RoleEntity')
class RoleEntity extends BaseEntity {
    id: string;

    constructor(roleId: Entity) {
        super(roleId);

        this.id = roleId?.id ?? '';
    }
}

export { RoleEntity };
