import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ListItemStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import { ProjectMaturityLevel } from '@modules/projects/moleculas';
import { RoadmapCapabilitiesAssessmentList } from '@modules/roadmap/organisms';
import { RoadmapComponentAssessmentListItemActions } from './roadmap-components-assessment-list-item-actions';

import type { ProjectEntity } from '@modules/projects/entities';
import type { ComponentAssessmentEntity } from '@modules/assessment/entities';
import { useCurrentUser } from '@modules/auth/hooks';
import { useOrganizationDetails, useProjectDetailsQuery } from '@modules/components/hooks';
import { ChatGptInstance } from '@helpers';
import { RecommendationCreateRequest } from '@modules/custom-components/requests';
import { useMutation } from '@apollo/client';
import {
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
    DeleteGapRecommendationMutationType,
    DeleteGapRecommendationMutationVariables,
    SetRecommendationsMutationType,
    SetRecommendationsMutationVariables,
} from '@modules/types/graphql';
import {
    CreateRecommendationMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useTkoScope } from '@modules/company/hooks';
import { SetRecommendationsMutation } from '@modules/roadmap/graphql';
import { DeleteGapRecommendationMutation } from '@modules/custom-components/graphql/mutations';

type RoadmapComponentsAssessmentListItemProps = {
    project: ProjectEntity;
    componentAssessment: ComponentAssessmentEntity;
    expanded: boolean;
    onExpand: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
};

const SummaryInfoStatus = styled(ListItemStyled.SummaryInfo)`
    margin-left: 32px;
`;

const SummaryInfoMaturityLevel = styled(ListItemStyled.SummaryInfo)`
    margin-right: 64px;
`;

const RoadmapComponentsAssessmentListItem = (
    props: RoadmapComponentsAssessmentListItemProps,
): React.ReactElement => {
    const { project, componentAssessment, expanded, onExpand } = props;
    const [newCapabilities, setNewCapabilities] = useState<any>(null);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const [recommendationData, setRecommendationData] = useState({
        recommendations: [] as number[],
    });
    const projectId = project.id;

    const { currentUser } = useCurrentUser();
    const userId = currentUser.id;
    const { organizationDetails } = useOrganizationDetails();
    const { projectDetails, loading: projectDetailLoading } = useProjectDetailsQuery({
        skip: !projectId,
        variables: { id: projectId as number },
    });
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const filteredAssessmentTypes = assessmentTypes.filter(
        type => type.id === projectDetails.assessmentTypeId,
    );
    const maturityLevel = componentAssessment.getMaturityLevel();
    const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();

    const filteredTkoscopes = tkoscopes.filter(type => type.id === projectDetails.tkoScopeId);

    const assessmentTypeNames = filteredAssessmentTypes.map(type => type.name);
    const tkoScopeNames = filteredTkoscopes.map(type => type.name);

    const assessmentTypeName = assessmentTypeNames[0];
    const tkoScopeName = tkoScopeNames[0];

    const [setRecommendations] = useMutation<
        SetRecommendationsMutationType,
        SetRecommendationsMutationVariables
    >(SetRecommendationsMutation);

    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);
    const [deleteGapRecommendation, { loading: deleteGapRecommendationLoading }] = useMutation<
        DeleteGapRecommendationMutationType,
        DeleteGapRecommendationMutationVariables
    >(DeleteGapRecommendationMutation);

    async function saveRecommendationWithChatGPT(
        chatGptinputData: string,
        gapId: any,
        scoreGapId: any,
    ) {
        const chatGptResponse = async (prompt: string) => {
            try {
                const completion: any = await ChatGptInstance.createChatCompletion({
                    model: 'gpt-4o',
                    messages: [{ role: 'user', content: prompt }],
                    temperature: 0.7,
                    max_tokens: 3097,
                });
                const dataArray: any = completion?.data?.choices[0]?.message?.content;
                const startIndex = dataArray.indexOf('1.');
                const lastIndex = dataArray.lastIndexOf('\n\n');
                const result = dataArray.substring(startIndex, lastIndex);
                const itemsArray = result.split(/\n\n+/);
                const prevData = itemsArray?.map((ele: any) => ele?.replace(/^\d+\.\s*|\n/gm, ''));
                return prevData.slice(0, 5);
            } catch (error) {
                setShowLoader(false);
            }
        };

        const numericValues = await chatGptResponse(chatGptinputData);
        try {
            const { data: deleteRecommendationData } = await deleteGapRecommendation({
                variables: { gapId: gapId }
            });
        } catch (e) {
            setShowLoader(false);
            throw e;
        }
        const companyId = project.companyId;
        numericValues?.forEach(async (items: any) => {
            const item = items && items?.replace(/^\d+\.\s*|\n/gm, '');
            const recommendationInitialValues = {
                recommendation: item,
            };
            try {
                const variables = new RecommendationCreateRequest(recommendationInitialValues, {
                    gapId,
                    companyId,
                });
                const { data: createRecommendationData } = await createRecommendation({
                    variables,
                    refetchQueries: [{ query: GetRecommendationsQuery, variables: { gapId } }],
                    awaitRefetchQueries: true,
                });
                const id: any = createRecommendationData?.createRecommendation?.id;
                recommendationData.recommendations.push(id);
            } catch (e) {
                setShowLoader(false);
                throw e;
            }
        });

        if (recommendationData.recommendations.length > 0) {
            const { data: setRecommendationsData } = await setRecommendations({
                variables: {
                    scoredGapId: scoreGapId,
                    recommendations: recommendationData.recommendations,
                },
                refetchQueries: [
                    {
                        query: GetRecommendationsQuery,
                        variables: { gapId: gapId },
                    },
                ],
                awaitRefetchQueries: true,
            });
            const recommendationsDataId = setRecommendationsData?.setRecommendations;
            if (recommendationsDataId) {
                setShowLoader(false);
            }
        }
    }

    const handleRecommendation = async () => {
        setShowLoader(true);
        let queryStart =
            'Take on the role of a risk management consultant hired by a company to provide expert advice. The company is a ';
        let employees = 'employees whose workforce is primarily';
        let beforeGap = 'assessment of the company and have identified the following gap: ';
        let recommendationDetails =
            'recommendations the company can implement to improve this gap? The company has the following controls in place: ';
        let afterRecomandation =
            'Please cite all privacy and data protection regulations (GDPR, DSS PCI, SOX, etc.) and security standards (NIST, ISO, etc.) that apply to';
        let queryEnd =
            'companies. Please provide specific sections of each relevant regulatory and security standard that apply to each recommendation and provide your recommendations in a numbered sequence.';

        const capabilities = componentAssessment.capabilities.filter(
            ele => ele.scoredGaps.length !== 0 ?? ele.scoredGaps,
        );

        for (let i = 0; i < capabilities.length; i++) {
            for (let j = 0; j < capabilities[i].scoredGaps.length; j++) {
                let chatGPTQuery = `${queryStart} ${organizationDetails.organizationType} located in ${organizationDetails?.orgLocation} in the ${organizationDetails?.sector} sector with ${organizationDetails.noOfEmployees} ${employees} ${organizationDetails.workforce}. You have just completed a ${assessmentTypeName} ${beforeGap} ${capabilities[i].scoredGaps[j]?.gap.gap} What are the ${tkoScopeName} ${recommendationDetails} MEASURES. The company wants to: RECOMMENDATIONS ${afterRecomandation} ${organizationDetails?.sector} ${queryEnd}`;
                await saveRecommendationWithChatGPT(
                    chatGPTQuery,
                    capabilities[i]?.scoredGaps[j]?.gap?.id,
                    capabilities[i].scoredGaps[j]?.id,
                );
            }
        }
    };

    function getComponentStatus(prevStatus: string) {
        let status;
        switch(prevStatus){
            case 'Generated':
                status = 'Pending';
                break;
            case 'Reviewed':
                status = 'Generated';
                break;
            case 'Refined':
                status = 'Validated';
                break;
            case 'Approved by PM':
                status = 'Reviewed';
                break;
            default:
                break;           
        }
        return status;
    }

    return (
        <AccordionListItem
            title={componentAssessment.component.name}
            subtitle={componentAssessment.objectives}
            componentOwners={componentAssessment.componentOwnersToString()}
            expanded={expanded}
            onChange={onExpand}
            SummaryInfoComponents={
                <>
                    <SummaryInfoStatus>
                        <b>Status:</b> {getComponentStatus(componentAssessment.roadmapStatus)}
                    </SummaryInfoStatus>

                    <SummaryInfoMaturityLevel>
                        {maturityLevel ? <ProjectMaturityLevel current={maturityLevel} /> : null}
                    </SummaryInfoMaturityLevel>
                </>
            }
        >
            <RoadmapCapabilitiesAssessmentList
                project={project}
                showLoader={showLoader}
                handleRecommendation={handleRecommendation}
                componentAssessment={componentAssessment}
            />

            <RoadmapComponentAssessmentListItemActions componentAssessment={componentAssessment} />
        </AccordionListItem>
    );
};

export { RoadmapComponentsAssessmentListItem };
