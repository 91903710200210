import React, { useState, useMemo, ChangeEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PageStyled } from '@modules/layout/styled';
import {
    Grid,
    Card,
    CardHeader,
    CardActions,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Tab,
    Tabs,
} from '@material-ui/core';
import { Button } from '@modules/ui/core';
import { Person, Edit } from '@material-ui/icons';
import { Loader } from '@modules/layout/moleculas';
import type { Page } from '@modules/types/page';
import { AssetsDataTable } from '@modules/registry/organisms/assets-datatable';
import {
    EditRiskManagersModal,
    EditRiskExecutivesModal,
    RatingCard,
    TopAssetsCard,
} from '@modules/registry/organisms';
import { useRiskRegistryUsers } from '@modules/registry/hooks';
import { RiskRegistryUser } from '@modules/types/graphql';
import { GetAssetsQuery } from '@modules/registry/graphql';
import { useCurrentUser } from '@modules/auth/hooks';
import { useUserHighRole } from '@modules/assessment/hooks/use-user-high-role';
import { AuthContext } from '@modules/auth/context';

const Main = styled(PageStyled.Main)`
    h4 {
        font-weight: 600;
        font-size: 18px;
    }

    .is-user {
        p,
        span {
            font-size: 14px;
        }

        svg {
            font-size: 26px;
        }
    }

    .is-users-list {
        max-height: 145px;
        overflow-y: auto;
    }

    .is-grid-tabs {
        position: absolute;
        top: -65px;
        right: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
            font-size: 14px;
            font-weight: bold;
            text-transform: none;
        }
    }
`;

const Chart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 312px;

    svg {
        font-size: 200px;
        fill: #efefef;
    }
`;

const StyledFiltersNumbers = styled.ul`
    list-style: none;
    padding: 0;
    margin: 20px 0 0;

    li {
        padding-bottom: 5px;

        p,
        strong,
        span {
            font-size: 14px;
        }
    }
`;

const RegistryPage = (props: Page): React.ReactElement => {
    const { title = 'Risk Registry' } = props;
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const [openRiskManagersModal, setOpenRiskManagersModal] = useState<boolean>(false);
    const [openRiskExecutivesModal, setOpenRiskExecutivesModal] = useState<boolean>(false);
    const [riskManagerRole, setRiskManagerRole] = useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState(0);
    const isMounted = useRef(true);
    const [filteredAssets, setFilteredAssets] = useState();
    const [rowId, setRowId] = useState<any>(0);
    const handleChangeTab = (e: ChangeEvent<{}>, newValue: any) => {
        setActiveTab(newValue);
    };
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { userHighRolesEntityList, loading: userRoleLoading } = useUserHighRole();

    const { users, loading: usersLoading } = useRiskRegistryUsers({
        roleId: ['RE', 'RM'],
    });
    const riskManagers: RiskRegistryUser[] = useMemo(() => {
        if (!users) return [];
        return users?.listRiskRegistryUsers?.filter(
            (user: RiskRegistryUser) => user?.role?.id === 'RM',
        );
    }, [users]);

    const riskExecutives: RiskRegistryUser[] = useMemo(() => {
        if (!users) return [];
        return users?.listRiskRegistryUsers?.filter(
            (user: RiskRegistryUser) => user?.role?.id === 'RE',
        );
    }, [users]);
    const userHighRole: string = userHighRolesEntityList[0]?.welcomeRole;
    const handleClickRating = (rowId: any) => {
        setRowId(rowId);
    };
    useEffect(() => {
        const userId = currentUser?.id;
        const isRiskManager = riskManagers.some((manager: any) => manager.user.id === userId);
        setRiskManagerRole(isRiskManager);
    }, [currentUser, riskManagers, userHighRole]);

    const is_rm_re: boolean = useMemo(() => {
        const userId = currentUser?.id;
        const isRiskManager = riskManagers.some((manager: any) => manager.user.id === userId);
        const isRiskExecutive = riskExecutives.some(
            (executive: any) => executive.user.id === userId,
        );
        return isRiskManager || isRiskExecutive;
    }, [currentUser, riskManagers, riskExecutives]);

    const riskAdmin: boolean = useMemo(() => {
        const isRiskAdmin = user?.roles[0] === 'risk_admin' || user?.roles[0] === 'company_admin';
        const userRole =
            currentUser?.userRole === 'Risk Registry Admin' ||
            currentUser?.userRole === 'Company Admin' ||
            userHighRole === 'RA' ||
            userHighRole === 'CA';
        return !(isRiskAdmin && userRole);
    }, [user, currentUser, userHighRole]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    function onSetFilteredAssets(rows: any) {
        setTimeout(() => {
            if (rows.length !== 0) {
                if (isMounted.current) {
                    setFilteredAssets(rows);
                }
            }
        }, 0);
    }

    // function onSetFilteredAssets(rows: any) {
    //     console.log(rows, 'rows')
    //     setTimeout(() => {
    //         setFilteredAssets(rows);
    //     }, 0);
    // }

    if (userLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <Main>
                {usersLoading || currentUserLoading || userRoleLoading ? (
                    <Loader />
                ) : (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Card style={{ marginBottom: 20 }}>
                                    <CardHeader
                                        style={{ paddingBottom: 0 }}
                                        title={
                                            <Typography variant='h4' style={{ marginTop: -10 }}>
                                                Risk managers
                                            </Typography>
                                        }
                                        action={
                                            <CardActions>
                                                <Button
                                                    size='extra-small'
                                                    variant='outlined'
                                                    disabled={riskAdmin}
                                                    onClick={() => setOpenRiskManagersModal(true)}
                                                >
                                                    <Edit style={{ margin: '5px 5px 0 0' }} />
                                                    Edit
                                                </Button>
                                            </CardActions>
                                        }
                                    />
                                    <List className='is-users-list'>
                                        {riskManagers?.map((manager: RiskRegistryUser) => (
                                            <ListItem key={manager?.user?.id} className='is-user'>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Person />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        manager?.user?.first_name +
                                                        ' ' +
                                                        manager?.user?.last_name
                                                    }
                                                    secondary={manager?.role?.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>

                                <Card>
                                    <CardHeader
                                        style={{ paddingBottom: 0 }}
                                        title={
                                            <Typography variant='h4' style={{ marginTop: -10 }}>
                                                Risk Executives
                                            </Typography>
                                        }
                                        action={
                                            <CardActions>
                                                <Button
                                                    size='extra-small'
                                                    variant='outlined'
                                                    disabled={riskAdmin}
                                                    onClick={() => setOpenRiskExecutivesModal(true)}
                                                >
                                                    <Edit style={{ margin: '5px 5px 0 0' }} />
                                                    Edit
                                                </Button>
                                            </CardActions>
                                        }
                                    />
                                    <List className='is-users-list'>
                                        {riskExecutives?.map((executive: RiskRegistryUser) => (
                                            <ListItem key={executive?.user?.id} className='is-user'>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <Person />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        executive?.user?.first_name +
                                                        ' ' +
                                                        executive?.user?.last_name
                                                    }
                                                    secondary={executive?.role?.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>

                            <Grid item xs={9} style={{ position: 'relative' }}>
                                <div className='is-grid-tabs'>
                                    <Tabs
                                        value={activeTab}
                                        onChange={handleChangeTab}
                                        indicatorColor='primary'
                                        textColor='primary'
                                        className='is-tabs'
                                    >
                                        <Tab label='Ratings' value={0} />
                                        <Tab label='Top 10 assets' value={1} />
                                    </Tabs>
                                </div>
                                {activeTab === 0 ? (
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <RatingCard
                                                title='Risk Rating'
                                                filteredAssets={filteredAssets}
                                                type='risk'
                                                rowId={rowId}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <RatingCard
                                                title='Impact Rating'
                                                filteredAssets={filteredAssets}
                                                type='impact'
                                                rowId={rowId}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <RatingCard
                                                title='Vulnerability Rating'
                                                filteredAssets={filteredAssets}
                                                type='vulnerability'
                                                rowId={rowId}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <RatingCard
                                                title='Threat Rating'
                                                filteredAssets={filteredAssets}
                                                type='threat'
                                                rowId={rowId}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <TopAssetsCard
                                                title='Top 10 Risk'
                                                filteredAssets={filteredAssets}
                                                type='risk'
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TopAssetsCard
                                                title='Top 10 Impact'
                                                filteredAssets={filteredAssets}
                                                type='impact'
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TopAssetsCard
                                                title='Top 10 Vulnerability'
                                                filteredAssets={filteredAssets}
                                                type='vulnerability'
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TopAssetsCard
                                                title='Top 10 Threat'
                                                filteredAssets={filteredAssets}
                                                type='threat'
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container>
                                    <Grid item>
                                        <StyledFiltersNumbers>
                                            <li>
                                                <strong>Number of assets: </strong>
                                                <span>
                                                    {filteredAssets
                                                        ? (filteredAssets as any)?.length
                                                        : ''}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Filter: </strong>
                                                <span></span>
                                            </li>
                                            <li>
                                                <strong>Date/time: </strong>
                                                <span></span>
                                            </li>
                                        </StyledFiltersNumbers>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} style={{ marginTop: 30 }}>
                            <Grid item xs={12}>
                                <AssetsDataTable
                                    handleClickRating={handleClickRating}
                                    refetchQuery={GetAssetsQuery}
                                    setFilteredAssets={onSetFilteredAssets}
                                    riskManagerRole={riskManagerRole}
                                    is_rm_re={is_rm_re}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Main>

            <EditRiskManagersModal
                open={openRiskManagersModal}
                onClose={() => setOpenRiskManagersModal(false)}
                initialValues={{
                    riskManagers: riskManagers?.map(
                        (manager: RiskRegistryUser) => manager?.user?.id,
                    ),
                }}
            >
                <></>
            </EditRiskManagersModal>

            <EditRiskExecutivesModal
                open={openRiskExecutivesModal}
                onClose={() => setOpenRiskExecutivesModal(false)}
                initialValues={{
                    riskExecutives: riskExecutives?.map(
                        (execute: RiskRegistryUser) => execute?.user?.id,
                    ),
                }}
            >
                <></>
            </EditRiskExecutivesModal>
        </>
    );
};

export { RegistryPage };
