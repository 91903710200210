import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, CapabilityLevelDefinition } from '@modules/types/graphql';

export type Entity = Maybe<Partial<CapabilityLevelDefinition>>;

@DescribeEntity('CapabilityLevelDefinitionEntity')
class CapabilityLevelDefinitionEntity extends BaseEntity {
    id: number;
    name: string;
    level: number;

    constructor(capabilityLevel: Entity) {
        super(capabilityLevel);

        this.id = capabilityLevel?.id ?? -1;
        this.name = capabilityLevel?.name ?? '';
        this.level = capabilityLevel?.level ?? 0;
    }
}

export { CapabilityLevelDefinitionEntity };
