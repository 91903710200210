import React, { useEffect, useState } from 'react';
import { PageStyled } from '@modules/layout/styled';
import { AccordionListItem } from '@modules/layout/organisms';
import styled from 'styled-components';
import { CustomAllComponentListToolbar } from '@modules/custom-components/moleculas/custom-components-list-toolbar';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { useMutation } from '@apollo/client';
import {
    DeleteComponentMutationType,
    DeleteComponentMutationVariables,
} from '@modules/types/graphql';
import { DeleteComponentMutation } from '@modules/custom-components/graphql';
import { GetAllAssessmentTypeQuery } from '@modules/components/graphql';
import { CapabilityList } from '@modules/custom-components/organisms';
import {
    CustomAssessmentTypeEditModal,
    CustomComponentEditModal,
} from '@modules/custom-components/moleculas/component-page-modal';
import { useCurrentUser } from '@modules/auth/hooks';
import { ExpandMore, MoreVert } from '@material-ui/icons';
import { AuthContext } from '@modules/auth/context';
import { cognitoUserGroupsForComponentAccess } from '@helpers';
import { Loader } from '@modules/layout/moleculas';

type AllComponentsProps = {
    allComponent?: any;
    assessmentId?: number;
};

const DeleteSpan = styled.div`
    color: crimson;
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
    margin-top: 20px;
    position: relative;
`;

const AllComponent = (props: AllComponentsProps): React.ReactElement => {
    const { allComponent, assessmentId } = props;

    const [componentSearch, setComponentSearch] = useState<string>('');
    const [filterData, setFilterData] = useState<any[]>();
    const [componentId, setComponentId] = useState<number | any>(null);
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [selectedId, setSelectedId] = useState<number | any>(null);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const { currentUser, loading: userLoading } = useCurrentUser();
    const { user, loading: contextUserLoading } = React.useContext(AuthContext) || {};

    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);

    const componentActionStatus = true;

    const [deleteComponent, { loading: deleteComponentLoading }] = useMutation<
        DeleteComponentMutationType,
        DeleteComponentMutationVariables
    >(DeleteComponentMutation);

    const handleDeleteComponent = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteComponentData } = await deleteComponent({
                variables: { componentId: id },
                refetchQueries: [{ query: GetAllAssessmentTypeQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteComponentData?.deleteComponent) {
                enqueueSuccess('Component successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the component!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditComponent = (id: number) => {
        setComponentId(id);
        handleOpenEditModal();
    };
    const isAccess = cognitoUserGroupsForComponentAccess?.includes(user?.roles?.[0] || '');
    useEffect(() => {
        const filterComponent = allComponent?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setFilterData(filterComponent);
    }, [componentSearch, allComponent]);
    if (userLoading || contextUserLoading) {
        return <Loader />;
    }
    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>Components</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>
            {isAccess && (
                <>
                    <PageStyled.Bridge>
                        <CustomAllComponentListToolbar
                            ButtonTitleName={'Create New Component'}
                            setComponentSearch={setComponentSearch}
                            componentSearch={componentSearch}
                            assessmentId={assessmentId}
                        />
                    </PageStyled.Bridge>
                </>
            )}
            <Root>
                {filterData?.length ? (
                    filterData?.map((component: any) => {
                        const canEditComponent = component.isPredefined()
                            ? currentUser.hasSuperAdminPermission()
                            : true;
                        return (
                            <React.Fragment key={component?.id}>
                                <AccordionListItem
                                    projectstatus={'Implementation'}
                                    title={
                                        selectedId === component?.id ? (
                                            deleteComponentLoading ? (
                                                <DeleteSpan>Deleting...</DeleteSpan>
                                            ) : (
                                                component?.name
                                            )
                                        ) : (
                                            component?.name
                                        )
                                    }
                                    componentActionStatus={componentActionStatus}
                                    componentSearch={componentSearch}
                                    subtitle=''
                                    SummaryInfoComponents={
                                        <>
                                            {canEditComponent && isAccess ? (
                                                <CategoryActions
                                                    id={component?.id}
                                                    onDelete={handleDeleteComponent}
                                                    onUpdate={handleEditComponent}
                                                />
                                            ) : (
                                                <ExpandMore
                                                    style={{ fontSize: '24px', color: 'gray' }}
                                                />
                                                // null
                                            )}
                                        </>
                                    }
                                >
                                    <CapabilityList
                                        capabilities={component?.capabilities}
                                        componentId={component?.id}
                                    />
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <h2>No Component found</h2>
                )}

                <CustomComponentEditModal
                    open={openEditModal}
                    assessmentId={assessmentId}
                    componentId={componentId}
                    GetAllAssessmentTypeQuery={GetAllAssessmentTypeQuery}
                    onClose={handleCloseEditModal}
                />
            </Root>
        </>
    );
};

export { AllComponent };
