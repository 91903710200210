import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';
import type { LearnTkoMenu } from '@modules/types/graphql';

export type Entity = Partial<LearnTkoMenu> | null;

@DescribeEntity('LearnTkoMenuEntity')
class LearnTkoMenuEntity extends BaseEntity {
    id: number;
    menu: string;
    subMenu: string;

    constructor(learnTkoMenu: Entity) {
        super(learnTkoMenu);

        this.id = learnTkoMenu?.id ?? -1;
        this.menu = learnTkoMenu?.menu ?? '';
        this.subMenu = learnTkoMenu?.sub_menu ?? '';
    }
}

export { LearnTkoMenuEntity };
