import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { CompanyDetails } from '@modules/types/graphql';

export type Entity = Partial<CompanyDetails> | null;

@DescribeEntity('CompanyDetailsEntity')
class CompanyDetailsEntity extends BaseEntity {
    id: number;
    userId: number;
    name: string;
    phone: string;
    address: string;
    email: string;
    contactName: string;
    packageType: string;
    subscriptionType: string;
    schemaName: string;
    isAskTko: boolean;
    isLearnTko: boolean;

    constructor(company: Entity) {
        super(company);

        this.id = company?.id ?? -1;
        this.userId = company?.user_id ?? -1;
        this.name = company?.name ?? '';
        this.phone = company?.phone ?? '';
        this.address = company?.address ?? '';
        this.email = company?.email ?? '';
        this.contactName = company?.contact_name ?? '';
        this.packageType = company?.package_type ?? '';
        this.subscriptionType = company?.subscription_type ?? '';
        this.schemaName = company?.schema_name ?? '';
        this.isAskTko = company?.is_ask_tko ?? false;
        this.isLearnTko = company?.is_learn_tko ?? false;
    }
}

export { CompanyDetailsEntity };
