import { useMutation } from '@apollo/client';
import { DeleteItemCategoryMutation } from '@modules/custom-components/graphql';
import { CategoryActions } from '@modules/custom-components/moleculas/component-actions/category';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { AccordionListItem } from '@modules/layout/organisms';
import { PageStyled } from '@modules/layout/styled';
import {
    DeleteItemCategoryMutationType,
    DeleteItemCategoryMutationVariables,
} from '@modules/types/graphql';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCurrentUser } from '@modules/auth/hooks';
import { ExpandMore } from '@material-ui/icons';
import { TypeDataList } from './type-data-list';
import { CustomCategoryListToolbar } from '@modules/custom-components/moleculas/custom-items-list-toolbar';
import { CustomAssetCategoryEditModal } from '@modules/custom-components/moleculas/component-page-modal';
import { GetlistAllItemTypesQuery } from '@modules/registry/graphql';
import { AuthContext } from '@modules/auth/context';
import { Loader } from '@modules/layout/moleculas';
import { cognitoUserGroupsForComponentAccess } from '@helpers';

type CategoryDataProps = {
    expandCategory: boolean;
    expandCategoryType: boolean;
    title?: string;
    categoryData?: any;
    itemId?: any;
};
const DeleteSpan = styled.div`
    color: crimson;
`;

const Root = styled.div`
    width: 100%;
    border-bottom: 1px solid #d1d2d2;
    margin-top: 20px;
    position: relative;
`;

const CategoryDataList = (props: CategoryDataProps): React.ReactElement => {
    const { title = 'Category', categoryData, itemId, expandCategory, expandCategoryType } = props;
    const [componentSearch, setComponentSearch] = useState<string>('');
    const [assessmentFilterData, setAssessmentFilterData] = useState<any[]>();
    const [expandType, setExpandType] = useState<boolean>(expandCategoryType);
    const [openEditModal, setOpenEditModal] = useState<Boolean | any>(false);
    const [categoryEditId, setCategoryEditId] = useState<number | any>(null);
    const [selectedId, setSelectedId] = useState<number | any>(null);
    const { user, loading: userLoading } = React.useContext(AuthContext) || {};
    const componentActionStatus = true;
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [deleteItemCategory, { loading: deleteAssessmentLoading }] = useMutation<
        DeleteItemCategoryMutationType,
        DeleteItemCategoryMutationVariables
    >(DeleteItemCategoryMutation);
    const handleOpenEditModal = () => setOpenEditModal(true);
    const handleCloseEditModal = () => setOpenEditModal(false);
    const { currentUser } = useCurrentUser();

    const handleDeleteAssetCategory = async (id: number): Promise<void> => {
        setSelectedId(id);
        try {
            const { data: deleteItemCategoryData } = await deleteItemCategory({
                variables: {
                    categoryId: id,
                    itemId: itemId,
                },
                refetchQueries: [{ query: GetlistAllItemTypesQuery }],
                awaitRefetchQueries: true,
            });
            if (deleteItemCategoryData?.deleteItemCategory?.id) {
                enqueueSuccess('Category Details successfully deleted!');
            } else {
                enqueueError('An error occurred while deleting the Category Details!');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditAssetCategory = (id: number) => {
        setCategoryEditId(id);
        handleOpenEditModal();
    };

    const handleEventData = (event: any) => {
        const assessmentsId = event.target.value;
        if (assessmentsId) {
            setExpandType(true);
        }
    };

    useEffect(() => {
        const filterAssessment = categoryData?.filter((ele: any) => {
            if (typeof ele?.name === 'string') {
                return ele?.name.toLowerCase().includes(componentSearch.toLocaleLowerCase());
            }
            return false;
        });
        setAssessmentFilterData(filterAssessment);
    }, [componentSearch, categoryData]);
    const isAccess = cognitoUserGroupsForComponentAccess?.includes(user?.roles?.[0] || '');

    if (userLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>
            {
                isAccess && (
                    <>
                        <PageStyled.Bridge>
                            <CustomCategoryListToolbar
                                ButtonTitleName={'Create New Item Category'}
                                setComponentSearch={setComponentSearch}
                                componentSearch={componentSearch}
                                itemId={itemId}
                            />
                        </PageStyled.Bridge>
                    </>
                )
            }
            <Root>
                {assessmentFilterData?.length ? (
                    assessmentFilterData?.map((assessments: any) => {
                        const canEditCategory = assessments.isPredefined()
                            ? currentUser.hasSuperAdminPermission()
                            : true;
                        return (
                            <React.Fragment key={assessments?.id}>
                                <AccordionListItem
                                    title={
                                        selectedId === assessments?.id ? (
                                            deleteAssessmentLoading ? (
                                                <DeleteSpan>Deleting...</DeleteSpan>
                                            ) : (
                                                assessments?.name
                                            )
                                        ) : (
                                            assessments?.name
                                        )
                                    }
                                    componentActionStatus={componentActionStatus}
                                    subtitle=''
                                    componentSearch={componentSearch}
                                    SummaryInfoComponents={
                                        <>
                                            {(canEditCategory && isAccess) ? (
                                                <CategoryActions
                                                    id={assessments?.id}
                                                    onDelete={handleDeleteAssetCategory}
                                                    onUpdate={handleEditAssetCategory}
                                                />
                                            ) : (
                                                <ExpandMore style={{ fontSize: '24px', color: 'gray' }} />
                                            )}
                                        </>
                                    }
                                    onClick={() => handleEventData({ target: { value: assessments?.id } })}
                                >
                                    <>
                                        {expandCategory && expandType ? (
                                            <TypeDataList
                                                categoryId={assessments?.id}
                                                allTypeData={assessments?.categoryType}
                                            />
                                        ) : ''}
                                    </>
                                </AccordionListItem>
                            </React.Fragment>
                        );
                    })
                ) : (
                    <h2 className='py-2'>Not Category Found</h2>
                )}

                <CustomAssetCategoryEditModal
                    open={openEditModal}
                    categoryId={categoryEditId}
                    itemId={itemId}
                    onClose={handleCloseEditModal}
                />
            </Root>
        </>
    );
};

export default CategoryDataList;
