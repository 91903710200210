import React from 'react';
import styled from 'styled-components';

import { RoadmapRecommendationsListItem } from './roadmap-recommendations-list-item';

import type { ProjectEntity } from '@modules/projects/entities';
import type {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import type { ScoredGapEntity } from '@modules/roadmap/entities';

type RoadmapRecommendationsListProps = {
    project: ProjectEntity;
    capabilityAssessment: CapabilityAssessmentEntity;
    capabilityAssessmentId: number;
    componentAssessment: ComponentAssessmentEntity;
    scoredGaps: (ScoredGapEntity | null)[];
};

const Root = styled.div`
    width: 100%;
    padding: 16px;
`;

const Header = styled.div`
    width: 100%;

    > h3 {
        font-size: 2.2rem;
        font-weight: 700;
    }
`;

const List = styled.div`
    width: 100%;
    margin-top: 32px;

    > *:not(:last-child) {
        margin-bottom: 32px;
    }
`;

const Empty = styled.div`
    width: 100%;
    font-size: 1.8rem;
`;

const RoadmapRecommendationsList = (props: RoadmapRecommendationsListProps): React.ReactElement => {
    const {
        project,
        componentAssessment,
        capabilityAssessment,
        capabilityAssessmentId,
        scoredGaps,
    } = props;

    const hasScoredGaps = scoredGaps.length !== 0;

    return (
        <Root>
            <Header>
            
                <h3>{componentAssessment.roadmapStatus === 'Generated' ? 'Gaps' : 'Validated Gaps'}</h3>
            </Header>

            <List>
                {hasScoredGaps ? (
                    (scoredGaps as ScoredGapEntity[]).map((scoredGap, index) => (
                        <RoadmapRecommendationsListItem
                            key={`${scoredGap.id}-${index}`}
                            project={project}
                            capabilityAssessment={capabilityAssessment}
                            scoredGap={scoredGap}
                            componentAssessment={componentAssessment}
                            capabilityAssessmentId={capabilityAssessmentId}
                        />
                    ))
                ) : (
                    <Empty>Recommendations not found fff</Empty>
                )}
            </List>
        </Root>
    );
};

export { RoadmapRecommendationsList };
