import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { useMutation } from '@apollo/client';

import { createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { PageStyled } from '@modules/layout/styled';
// import { TableCellText } from '@modules/layout/atoms';
import { Loader, TableRow, TableCell } from '@modules/layout/moleculas';
// import { Table } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/auth/hooks';
import { useUsersDetails } from '@modules/users/hooks';
// import { UsersListItemActions } from '@modules/users/moleculas';
import { UsersListToolbar } from '@modules/users/organisms';
import { DeleteUserMutation, GetUsersDetailsQuery } from '@modules/users/graphql';
import { UsersListRequest } from '@modules/users/requests';
import { useLocationQuery } from '@modules/shared/hooks';
import { CommonHelper } from '@helpers';

import { TableColumn, TableSort } from '@modules/layout/organisms/table';
import type { Page } from '@modules/types/page';
import type { DeleteUserMutationType, DeleteUserMutationVariables } from '@modules/types/graphql';
import { useCompanies } from '@modules/company/hooks';
import { Button } from '@material-ui/core';

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
`;

const columns: TableColumn[] = [
    {
        id: 'firstName',
        label: 'First Name',
    },
    {
        id: 'lastName',
        label: 'Last Name',
    },
    {
        id: 'companyName',
        label: 'Company',
    },
    {
        id: 'userRoles',
        label: 'Role',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'totalUsageTime',
        label: 'Usage',
    },
    {
        id: 'projectName',
        label: 'Project Name',
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'right',
    },
];

const Main = styled(PageStyled.Main)`
    margin-top: 64px;
`;

const projectsFormatter = (projectsQuery: string) => {
    if (typeof projectsQuery === 'undefined') {
        return undefined;
    }

    if (typeof projectsQuery === 'string') {
        return projectsQuery.split(',');
    }

    return projectsQuery;
};

const UsersPage = (props: Page): React.ReactElement => {
    const { title = 'Users' } = props;

    const navigate = useNavigate();
    const { enqueueSuccess, enqueueError } = useEnqueueStacks();
    const [selectedUserId, setSelectedUserId] = useState<number | any>(null);

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { companies, loading: companiesLoading } = useCompanies();
    const canDeleteUser = currentUser.hasSuperAdminPermission() || currentUser.hasAdminPermission();
    const isPmRole: boolean = currentUser?.isAdmin;

    const [query, setQuery] = useLocationQuery();
    const filters = {
        role: CommonHelper.multipleQueryFormatter(query?.role as string),
        project: CommonHelper.multipleQueryFormatter(query?.project as string),
    };

    const variables = new UsersListRequest(filters);
    const { usersDetails, loading: usersDetailsLoading } = useUsersDetails({
        fetchPolicy: 'network-only',
        variables,
    });
    const handleClickRedirect = (userId: number) => {
        navigate(createRouteUrl(routes.usersEdit, { id: userId }));
    };

    const [deleteUser, { loading: deleteUserLoading }] = useMutation<
        DeleteUserMutationType,
        DeleteUserMutationVariables
    >(DeleteUserMutation);

    const handleDeleteUser = async (userId: number): Promise<void> => {
        setSelectedUserId(userId);
        const schemaName = companies.find((el: any) => el.name === usersDetails.find((ele: any) => ele.id === userId)?.companyName)?.schema_name;
        try {
            const { data: deleteUserData } = await deleteUser({
                variables: { id: userId, schemaName: schemaName },
                refetchQueries: [{ query: GetUsersDetailsQuery }],
                awaitRefetchQueries: true,
            });

            if (deleteUserData?.deleteUser !== null) {
                throw new Error('An error occurred while deleting the user!');
            }
            enqueueSuccess('User successfully deleted!');
        } catch (e) {
            console.log(e);
            enqueueError(e);
        }
    };

    const handleEditOrgEdit = (): void => {
        navigate(createRouteUrl(routes.organizationProfileEditPage, { id: currentUser?.id }));
    };

    if (currentUserLoading || usersDetailsLoading || companiesLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageStyled.Header>
                <PageStyled.HeaderTitle>
                    <h3>{title}</h3>
                </PageStyled.HeaderTitle>
            </PageStyled.Header>

            <PageStyled.Bridge>
                <UsersListToolbar handleEditOrgEdit={handleEditOrgEdit} />
            </PageStyled.Bridge>
            <Main style={{ display: 'unset' }}>
                <TableSort
                    columns={columns}
                    handleClickRedirect={handleClickRedirect}
                    canDeleteUser={canDeleteUser}
                    selectedUserId={selectedUserId}
                    deleteUserLoading={deleteUserLoading}
                    handleDeleteUser={handleDeleteUser}
                    usersDetails={usersDetails}
                    usersDetailsLoading={usersDetailsLoading}
                />
            </Main>

            {/* commented previous code due to create new sorting table */}
            {/* <Table columns={columns}>
                    {usersDetails?.map(user => (
                        <TableRow key={user.id} size='m' onClick={handleClickRedirect(user.id)}>
                            <TableCell>
                                <TableCellText>{user.firstName}</TableCellText>
                            </TableCell>

                            <TableCell>
                                <TableCellText>{user.lastName}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{findCompanyName(user.companyId)}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{user.userRoles}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{user.email}</TableCellText>
                            </TableCell>
                            <TableCell>
                                <TableCellText>{user.username}</TableCellText>
                            </TableCell>

                            <TableCell align='right' onClick={e => e.stopPropagation()}>
                                {canDeleteUser ? (
                                    <UsersListItemActions
                                        userId={user.id}
                                        onDelete={handleDeleteUser}
                                    />
                                ) : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </Table> */}
        </>
    );
};

export { UsersPage };
