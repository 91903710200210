import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, Requirement } from '@modules/types/graphql';

export type Entity = Maybe<Partial<Requirement>>;

@DescribeEntity('RequirementEntity')
class RequirementEntity extends BaseEntity {
    id: number;
    recommendationId: number;
    requirement: string;

    constructor(requirement: Entity) {
        super(requirement);

        this.id = requirement?.id ?? -1;
        this.recommendationId = requirement?.recommendation_id ?? -1;
        this.requirement = requirement?.requirement ?? '';
    }
}

export { RequirementEntity };
