import { gql } from '@apollo/client';

export const DeleteItemCategoryMutation = gql`
    mutation DeleteItemCategory($categoryId: Int!, $itemId: Int!) {
        deleteItemCategory(category_id: $categoryId, item_id: $itemId) {
            id
            name
            risk_function_id
            item_id
        }
    }
`;
