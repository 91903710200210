import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { Maybe, MaturityLevel } from '@modules/types/graphql';

export type Entity = Maybe<Partial<MaturityLevel>>;

@DescribeEntity('OrgLocationEntity')
class OrgLocationEntity extends BaseEntity {
    id: number;
    name: string;

    constructor(orgLocation: Entity) {
        super(orgLocation);

        this.id = orgLocation?.id ?? -1;
        this.name = orgLocation?.name ?? '';
    }
}

export { OrgLocationEntity };
