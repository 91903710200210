import { gql } from '@apollo/client';

export const CompanyFragment = gql`
    fragment Company on Company {
        id
        name
        schema_name
        website
    }
`;
