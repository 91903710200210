import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { gray } from '@modules/ui/colors';
import { RecommendationCreateFormModal } from '@modules/custom-components/organisms';
import { useCapabilityAssessment, useRoles } from '@modules/assessment/hooks';
import { RoadmapRecommendationsEditFormModal } from './roadmap-recommendations-edit-form-modal';
import { RoadmapRecommendationItem } from './roadmap-recommendation-item';
import type { ProjectEntity } from '@modules/projects/entities';
import type {
    CapabilityAssessmentEntity,
    ComponentAssessmentEntity,
} from '@modules/assessment/entities';
import type { ScoredGapEntity, RoadmapRecommendationEntity } from '@modules/roadmap/entities';
import {
    AddCustomRecommendationRequest,
    RecommendationCreateRequest,
} from '@modules/custom-components/requests';
import { useCurrentUser } from '@modules/auth/hooks';
import { useMutation } from '@apollo/client';
import {
    AddCustomRecommendationMutationType,
    AddCustomRecommendationMutationVariables,
    CreateRecommendationMutationType,
    CreateRecommendationMutationVariables,
    DeleteGapRecommendationMutationType,
    DeleteGapRecommendationMutationVariables,
    SetRecommendationsMutationType,
    SetRecommendationsMutationVariables,
} from '@modules/types/graphql';
import {
    AddCustomRecommendationMutation,
    CreateRecommendationMutation,
    GetRecommendationsQuery,
} from '@modules/custom-components/graphql';
import { useOrganizationDetails, useProjectDetailsQuery } from '@modules/components/hooks';
import { useAssessmentTypes } from '@modules/shared/hooks';
import { useCompanyAccess, useTkoScope } from '@modules/company/hooks';
import { ChatGptInstance } from '@helpers';
import { LocalConvenienceStoreOutlined, VisibilityOff } from '@material-ui/icons';
import { RemoveRedEyeRounded } from '@material-ui/icons';
import { SetRecommendationsMutation } from '@modules/roadmap/graphql';
import { GetCapabilityAssessmentQuery } from '@modules/assessment/graphql';
import { LinearProgress } from '@material-ui/core';
import { AccordionInnerListItem } from '@modules/layout/organisms';
import { GetProjectQuery } from '@modules/projects/graphql';
import { DeleteGapRecommendationMutation } from '@modules/custom-components/graphql/mutations';
import { RecommendationsCountModal } from '../recommendation-count-modal';
import { Loader } from '@modules/layout/moleculas';
import { AuthContext } from '@modules/auth/context';
type RoadmapRecommendationsListItemProps = {
    project: ProjectEntity;
    capabilityAssessment: CapabilityAssessmentEntity;
    componentAssessment: ComponentAssessmentEntity;
    capabilityAssessmentId: number;
    scoredGap: ScoredGapEntity;
};

const Root = styled.div`
    width: 100%;
    border-bottom: 2px solid #d1d2d2;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h4 {
        flex: 3;
        font-size: 1.8rem;
        font-weight: 700;
    }
`;

const List = styled.div`
    width: 100%;
    padding: 16px;
    margin-bottom: 40px;

    > * {
        border-bottom: 1px solid ${gray[60]};

        &:first-child {
            border-top: 1px solid ${gray[60]};
        }
    }
`;

const Empty = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 12px 0;

    > p {
        color: ${gray[20]};
        font-size: 1.4rem;
        font-weight: 400;
    }
`;

const Toolbar = styled.div`
    width: 100%;
    margin: 15px 0px;

    > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const EyeInfo = styled.div<{ isAIAccess: boolean; generated: string }>`
    color: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : '#9d9d9d'};
    cursor: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'not-allowed'};
    pointer-events: ${({ isAIAccess, generated }) =>
        generated === 'Generated' && isAIAccess ? '' : 'none'};
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    > svg {
        font-size: 4rem;
        text-align: center;
        cursor: pointer;
    }
    &:hover {
        color: #5a5a5a;
    }
`;
const HeaderRec = styled.div`
    width: 100%;

    > h3 {
        font-size: 2.2rem;
        font-weight: 700;
    }
`;
const Actions = styled.div`
    margin-left: auto;

    > *:not(:last-child) {
        margin-right: 8px;
    }
`;
// const EyeInfo = styled.div<{ isAIAccess: boolean }>`
//     color: ${({ isAIAccess }) =>
//         isAIAccess ? '' : '#9d9d9d'};
//     cursor: ${({ isAIAccess }) =>
//         isAIAccess ? '' : 'not-allowed'};
//     pointer-events: ${({ isAIAccess }) =>
//         isAIAccess ? '' : 'none'};
//     > svg {
//         font-size: 4rem;
//         text-align: center;
//         cursor: pointer;
//         margin-right: 35px;
//     }
//     &:hover {
//         color: #5a5a5a;
//     }
// `;

const LinearProgressStyle = styled.div`
    margin: 20px 0px;
`;

const RoadmapRecommendationsListItem = (
    props: RoadmapRecommendationsListItemProps,
): React.ReactElement => {
    const {
        project,
        capabilityAssessment,
        componentAssessment,
        capabilityAssessmentId,
        scoredGap,
    } = props;
    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const { user, loading: userLoading } = useContext(AuthContext) || {};
    const adminRoles = useMemo(() => ['risk_admin', 'project_admin', 'company_admin'], []);

    const isAdminRole = useMemo(() => {
        if (user && user.roles && user.roles.length > 0) {
            return adminRoles.includes(user.roles[0]);
        }
        return false;
    }, [user, adminRoles]);

    const projectId = project.id;
    const userId = currentUser.id;
    const { refetch: capabilityAssessmentRefetch } = useCapabilityAssessment({ skip: true });
    const { rolesEntityList } = useRoles({ variables: { assessmentId: componentAssessment.id } });

    const isGenerated = componentAssessment.isGeneratedInRoadmap();

    const [
        openRecommendationsEditFormModal,
        setOpenRecommendationsEditFormModal,
    ] = React.useState<boolean>(false);

    const handleOpenRecommendationsEditFormModal = (): void =>
        setOpenRecommendationsEditFormModal(true);

    const handleCloseRecommendationsEditFormModal = (): void => {
        handleCreateSubmit();
        setOpenRecommendationsEditFormModal(false);
    };

    const [
        recommendationGenerationLoading,
        setRecommendationGenerationLoading,
    ] = React.useState<boolean>(false);
    const [
        openRecommendationCreateModal,
        setOpenRecommendationCreateModal,
    ] = React.useState<boolean>(false);
    const handleOpenRecommendationCreateModal = (): void => setOpenRecommendationCreateModal(true);
    const handleCloseRecommendationCreateModal = (): void =>
        setOpenRecommendationCreateModal(false);

    const [
        openDialogSelectRecommendationModal,
        setOpenDialogSelectRecommendationModal,
    ] = React.useState<boolean>(false);
    const handleOpenDialogSelectRecommendationModal = (): void =>
        setOpenDialogSelectRecommendationModal(true);
    const handleCloseDialogSelectRecommendationModal = (): void =>
        setOpenDialogSelectRecommendationModal(false);

    const handleCreateSubmit = async (): Promise<void> => {
        await capabilityAssessmentRefetch({ surveyId: capabilityAssessmentId });
    };

    const roadmapRecommendations = scoredGap.roadmapRecommendations;
    const hasRoadmapRecommendations = roadmapRecommendations.length !== 0;
    const canEditRecommendations = useMemo(() => {
        return rolesEntityList && (
            isAdminRole ||
            rolesEntityList.hasProgramManager() ||
            rolesEntityList.hasComponentOwner() ||
            (!isGenerated && rolesEntityList.hasOperator)
        );
    }, [isAdminRole, rolesEntityList, isGenerated]);

    const addNotesAccess = useMemo(()=>{
        return rolesEntityList && (
            isAdminRole ||
            rolesEntityList.hasComponentOwner() ||
            rolesEntityList.hasProgramManager() ||
            rolesEntityList.hasStakeholder()
        );
    },[isAdminRole, rolesEntityList]);

    const isRefinedAccess = useMemo(()=>{
        return rolesEntityList && (
            isAdminRole || 
            rolesEntityList.hasComponentOwner() ||
            rolesEntityList.hasProgramManager() ||
            rolesEntityList.hasOperator()
        );
    },[isAdminRole, rolesEntityList]);

    const [recommendationData, setRecommendationData] = useState({
        recommendations: [] as number[],
    });
    const [createRecommendation] = useMutation<
        CreateRecommendationMutationType,
        CreateRecommendationMutationVariables
    >(CreateRecommendationMutation);
    const [addCustomRecommendation] = useMutation<
        AddCustomRecommendationMutationType,
        AddCustomRecommendationMutationVariables
    >(AddCustomRecommendationMutation);

    const [deleteGapRecommendation, { loading: deleteGapRecommendationLoading }] = useMutation<
        DeleteGapRecommendationMutationType,
        DeleteGapRecommendationMutationVariables
    >(DeleteGapRecommendationMutation);

    const [setRecommendations] = useMutation<
        SetRecommendationsMutationType,
        SetRecommendationsMutationVariables
    >(SetRecommendationsMutation);

    const { projectDetails, loading: projectDetailLoading } = useProjectDetailsQuery({
        skip: !projectId,
        variables: { id: projectId as number },
    });

    const { organizationDetails, loading: orgDetailLoading } = useOrganizationDetails();
    const { companyAccess, loading: companyLoading } = useCompanyAccess();
    const isAIAccess: boolean = companyAccess?.isAIAccess;
    //Get Assessment Type Name
    const { assessmentTypes, loading: assessmentTypesLoading } = useAssessmentTypes();
    const filteredAssessmentTypes = assessmentTypes.filter(
        type => type.id === projectDetails.assessmentTypeId,
    );
    const assessmentTypeNames = filteredAssessmentTypes.map((type: any) => type.name);
    const assessmentTypeName = assessmentTypeNames[0];
    //Get Tko Scope Name
    // const { tkoscopes, loading: tkoScopeLoading } = useTkoScope();
    // const filteredTkoscopes = tkoscopes.filter(type => type.id === projectDetails.tkoScopeId);
    // const tkoScopeNames = filteredTkoscopes.map(type => type.name);
    // const tkoScopeName = tkoScopeNames[0];
    const recommendationStatus: boolean = true;
    const handleSelectRecommendationCount = (count: string, count_id: number) => {
        console.log(count, 'count');
        findChatGptResponseRecommendation(scoredGap, count, count_id);
    };

    //new code1
    let ids: any = [];
    async function findChatGptResponseRecommendation(
        scoredGap: any,
        count: string,
        count_id: number,
    ) {
        const companyId = project.companyId;
        // debugger
        // ChatGPT Query code here
        let queryStart =
            'Take on the role of a risk management consultant hired by a company to provide expert advice. The company is a';
        let employees = 'employees whose workforce is primarily';
        let beforeGap = 'assessment of the company and have identified the following gap:';
        let recommendationDetails =
            'recommendations the company can implement to improve this gap? The company has the following controls in place:';
        let afterRecommendation =
            'Please cite all privacy and data protection regulations (GDPR, DSS PCI, SOX, etc.) and security standards (NIST, ISO, etc.) that apply to';
        let queryEnd =
            'companies. Please provide specific sections of each relevant regulatory and security standard that apply to each recommendation and provide your recommendations in a numbered sequence. Do not include a heading, summary, or any introductory text—just the Top';

        let chatGPTQuery = `${queryStart} ${organizationDetails.organization_type} located in ${organizationDetails.organization_location} in the ${organizationDetails?.sector} sector with ${organizationDetails.number_of_employees} ${employees} ${organizationDetails.workforce}. You have just completed a ${assessmentTypeName} ${beforeGap} ${scoredGap?.gap?.gap} What are the Top ${count} ${recommendationDetails} ${capabilityAssessment?.notes}. The company wants to: ${capabilityAssessment?.recommendations} ${afterRecommendation} ${organizationDetails?.sector} ${queryEnd} ${count} recommendations with applicable regulations and standards.`;
        setRecommendationGenerationLoading(true);

        const chatGptResponse = async (prompt: string) => {
            const completion: any = await ChatGptInstance.createChatCompletion({
                model: 'gpt-4o',
                messages: [{ role: 'user', content: prompt }],
                temperature: 0.7,
                max_tokens: 3097,
            });
            const dataArray: any = completion?.data?.choices[0]?.message?.content;
            // const startIndex = dataArray.indexOf('1.');
            // const lastIndex = dataArray.lastIndexOf('\n\n');
            // const result = dataArray.substring(startIndex, lastIndex);
            const itemsArray = dataArray.split(/\n\n\d+/);
            const prevData = itemsArray?.map((ele: any) => ele?.replace(/^\d+\.\s*|\n/gm, ''));
            return prevData.slice(0, count_id);
        };
        const numericValues = await chatGptResponse(chatGPTQuery);
        // setRecommendationGenerationLoading(false);
        try {
            const { data: deleteRecommendationData } = await deleteGapRecommendation({
                variables: { gapId: scoredGap?.gap.id },
            });

            for (let [index, items] of numericValues.entries()) {
                const item = items && items?.replace(/^\d+\.\s*|\n/gm, '');
                const recommendationInitialValues = {
                    recommendation: item,
                };
                const variables = new RecommendationCreateRequest(recommendationInitialValues, {
                    gapId: scoredGap?.gap.id,
                    companyId,
                });

                const { data: createRecommendationData } = await createRecommendation({
                    variables,
                    refetchQueries: [
                        {
                            query: GetRecommendationsQuery,
                            variables: { gapId: scoredGap?.gap.id },
                        },
                    ],
                    awaitRefetchQueries: true,
                });

                const id: any = createRecommendationData?.createRecommendation?.id;
                recommendationData.recommendations.push(id);
            }
            if (recommendationData.recommendations.length > 0) {
                const { data: setRecommendationsData } = await setRecommendations({
                    variables: {
                        scoredGapId: scoredGap?.id as any,
                        recommendations: recommendationData.recommendations as number[],
                    },
                    refetchQueries: [
                        {
                            query: GetRecommendationsQuery,
                            variables: { gapId: scoredGap?.gap.id },
                        },
                        {
                            query: GetProjectQuery,
                            variables: { id: projectId },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
            }
        } catch (e) {
            setRecommendationGenerationLoading(false);
            throw e;
        }
        setRecommendationGenerationLoading(false);
    }
    if (companyLoading || projectDetailLoading || currentUserLoading || userLoading) {
        return <Loader />;
    }
    return (
        <>
            <AccordionInnerListItem
                title={scoredGap.gap.gap}
                subtitle={
                    <EyeInfo
                        onClick={() => {
                            handleOpenDialogSelectRecommendationModal();
                        }}
                        isAIAccess={isAIAccess}
                        generated={componentAssessment?.roadmapStatus}
                    >
                        {recommendationGenerationLoading ? (
                            <Loader />
                        ) : isAIAccess ? (
                            <RemoveRedEyeRounded />
                        ) : (
                            <VisibilityOff />
                        )}
                    </EyeInfo>
                }
                status={recommendationStatus}
            >
                <Root>
                    {recommendationGenerationLoading ? (
                        <LinearProgressStyle>
                            <LinearProgress />
                        </LinearProgressStyle>
                    ) : (
                        <>
                            <HeaderRec>
                                <h3>Recommendations</h3>
                            </HeaderRec>
                            <List>
                                {hasRoadmapRecommendations ? (
                                    (roadmapRecommendations as RoadmapRecommendationEntity[]).map(
                                        roadmapRecommendation => {
                                            const canRefine =
                                                !roadmapRecommendation.isRefined() &&
                                                !isGenerated && isRefinedAccess;

                                            return (
                                                <RoadmapRecommendationItem
                                                    key={roadmapRecommendation.id}
                                                    canRefine={canRefine}
                                                    capabilityAssessmentId={capabilityAssessmentId}
                                                    project={project}
                                                    roadmapRecommendation={roadmapRecommendation}
                                                    addNotesAccess={addNotesAccess}
                                                    roadmapStatus={
                                                        componentAssessment?.roadmapStatus
                                                    }
                                                />
                                            );
                                        },
                                    )
                                ) : (
                                    <Empty>
                                        <p>Recommendations not found</p>
                                    </Empty>
                                )}
                            </List>
                        </>
                    )}
                    {canEditRecommendations ? (
                        <Toolbar>
                            <Button size='small' onClick={handleOpenRecommendationsEditFormModal}>
                                Edit recommendations
                            </Button>

                            <Button
                                size='small'
                                variant='outlined'
                                onClick={handleOpenRecommendationCreateModal}
                            >
                                + Add recommendation
                            </Button>
                        </Toolbar>
                    ) : null}
                </Root>

                {canEditRecommendations ? (
                    <>
                        <RoadmapRecommendationsEditFormModal
                            companyId={project.companyId}
                            capabilityAssessmentId={capabilityAssessmentId}
                            scoredGap={scoredGap}
                            open={openRecommendationsEditFormModal}
                            onClose={handleCloseRecommendationsEditFormModal}
                        />

                        <RecommendationCreateFormModal
                            gapId={scoredGap.gap.id}
                            scoredGapId={scoredGap.id}
                            companyId={project.companyId}
                            open={openRecommendationCreateModal}
                            onClose={handleCloseRecommendationCreateModal}
                            onSuccess={handleCreateSubmit}
                        />
                    </>
                ) : null}
                <RecommendationsCountModal
                    open={openDialogSelectRecommendationModal}
                    gap={scoredGap.gap.gap}
                    default_count={3}
                    onClose={handleCloseDialogSelectRecommendationModal}
                    handleSelectRecommendationCount={handleSelectRecommendationCount}
                />
            </AccordionInnerListItem>
        </>
    );
};

export { RoadmapRecommendationsListItem };
