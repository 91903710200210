import { gql } from '@apollo/client';

export const GetUsersDetailsQuery = gql`
    query GetUsersDetails($filter: String, $projects: [Int], $roles: [String]) {
        listUserDetails(filter: $filter, projects: $projects, roles: $roles) {
            id
            first_name
            last_name
            email
            company_id
            company_name
            user_role
            total_usage_time
            is_editable
        }
    }
`;
