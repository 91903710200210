import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Form } from 'formik';
import { useNavigate } from '@reach/router';
import { FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';

import { Autocomplete, TextField, Button } from '@modules/ui/core';
import { routes } from '@config/routes';
import { ExtendedFormik } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';
import { useCurrentUser } from '@modules/auth/hooks';
import { useCompanies } from '@modules/company/hooks';

import type { SignUpUserFormValues, UpdateUserFormValues } from '@modules/users/requests';
import type { CompanyEntity } from '@modules/company/entities';
import { useUserRoles } from '@modules/users/hooks';
import { UserRolesEntity } from '@modules/users/entities/user-roles-entity';
import { useCreateUserRoles } from '@modules/users/hooks/use-create-user-roles';
import { AuthContext } from '@modules/auth/context';

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;

const UserForm = <T extends FormSemanticType = 'create'>(
    props: FormProps<
        // prettier-ignore
        T extends 'create' ? SignUpUserFormValues :
        T extends 'edit' ? UpdateUserFormValues :
        SignUpUserFormValues & UpdateUserFormValues
    >,
): React.ReactElement => {
    const { type, initialValues, FormWrapComponent, onSubmit } = props;

    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const { user } = React.useContext(AuthContext) || {};

    const { companies, loading: companiesLoading } = useCompanies();

    const { createRoles, loading: userCreateRoleLoading } = useCreateUserRoles({
        variables: { role: user?.roles[0] },
    });

    const getCompanyOptionLabel = React.useCallback(
        (option: CompanyEntity | null) => option?.name ?? 'Choose the company',
        [],
    );
    const getUserRolesOptionLabel = React.useCallback(
        (option: UserRolesEntity | null) => option?.name ?? 'Choose the Role',
        [],
    );
    const handleClickCancel = (): void => {
        navigate(routes.users.path);
    };

    const isEdit = type === 'edit';

    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            // validationSchema={yup.object({
            //     username: yup.string().email('Invalid email').required('Email is required'),
            //     firstName: yup.string().required('First name is required'),
            //     lastName: yup.string().required('Last name is required'),
            //     company:
            //         !isEdit && currentUser.isSuperAdmin
            //             ? yup.object().nullable().required('Company is required')
            //             : yup.string().nullable(), // Assuming company is a string field if not super admin or during edit
            //     userRole: yup.mixed().nullable().required('User roles are required'),
            // })}
            // validationSchema={() => {
            //     let rules: Record<string, any> = {};

            //     if (!isEdit) {
            //         rules = {
            //             ...rules,
            //             username: yup.string().email().required('Required field'),
            //             firstName: yup.string().required('Required field'),
            //             lastName: yup.string().required('Required field'),
            //             company: currentUser.isSuperAdmin
            //                 ? yup.object().nullable().required('Required field')
            //                 : ' ',
            //             userRoles: yup.mixed().nullable().required('Required field'),
            //         };
            //     }

            //     return yup.object(rules);
            // }}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    isSubmitting,
                } = formikProps;
                const handleChangeAutocomplete = (field: string) => (
                    _: React.ChangeEvent<{}>,
                    value: any | null,
                ) => setFieldValue(field, value);

                // const handleChangeCheckbox = (field: string) => (
                //     _: React.ChangeEvent<{}>,
                //     checked: boolean,
                // ) => setFieldValue(field, checked);

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <TextField
                                fullWidth
                                id='username'
                                label='Email'
                                value={values.username}
                                error={!!errors.username}
                                helperText={errors.username}
                                disabled={isEdit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                id='firstName'
                                label='First Name'
                                value={values.firstName}
                                error={!!errors.firstName}
                                helperText={errors.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                fullWidth
                                id='lastName'
                                label='Last Name'
                                value={values.lastName}
                                error={!!errors.lastName}
                                helperText={errors.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <Autocomplete<CompanyEntity | null, false, false, false>
                                fullWidth
                                id='company'
                                label='Company'
                                placeholder='Choose the company'
                                options={companies}
                                value={values.company || null}
                                disabled={companiesLoading}
                                getOptionLabel={getCompanyOptionLabel}
                                onChange={(event: React.ChangeEvent<{}>, value: any | null) => {
                                    handleChangeAutocomplete('company')(event, value);
                                }}
                                style={{
                                    display:
                                        currentUser.isAdmin && !currentUser.isSuperAdmin
                                            ? 'none'
                                            : '',
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        error={!!errors.company}
                                        helperText={errors.company}
                                    />
                                )}
                            />
                            <Autocomplete<UserRolesEntity | null, false, false, false>
                                fullWidth
                                id='userRole'
                                label='Roles'
                                placeholder='Choose the Role'
                                options={createRoles}
                                value={values?.userRole || null}
                                disabled={userCreateRoleLoading}
                                getOptionLabel={getUserRolesOptionLabel}
                                onChange={(event: React.ChangeEvent<{}>, value: any | null) => {
                                    handleChangeAutocomplete('userRole')(event, value);
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        error={!!errors.userRole}
                                        helperText={errors.userRole}
                                    />
                                )}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {!isEdit ? 'Create User' : 'Update user'}
                                </SubmitButton>

                                <SubmitButton variant='text' onClick={handleClickCancel}>
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { UserForm };
