import React, { useEffect, useMemo, useState } from 'react';

import { ReportComponentTopGapsList } from '@modules/assessment/organisms';

import type {
    CapabilityAssessmentEntity,
    ComponentReportEntity,
} from '@modules/assessment/entities';
import { ListStyled } from '@modules/layout/styled';
import { ComponentAssessmentEntity } from '@modules/assessment/entities';
import {
    Collapse,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { GapByRisk, GapCapabilityAssessment } from '@modules/assessment/gap-by-risk';
import { AccordionInnerListItem, AccordionListItem } from '@modules/layout/organisms';
import { ReportComponentCapabilityTableItem } from '@modules/assessment/organisms/report-components-list/report-component-capability-table-item';
import { UserEntity } from '@modules/users/entities';
import { ReportComponentDataGraph } from '@modules/assessment/organisms/report-components-list/report-component-data-graph';
import {
    gapLevelFieldLabelList,
    gapLevelFieldList,
} from '@modules/assessment/entities/capability-report-entity';
import { ProjectEntity } from '@modules/projects/entities';
import { ReportFactorCapabilityTableItem } from './report-factor-capability-table-item';
import { result } from 'lodash';

type ReportComponentInnerItemProps = {
    assessment: ComponentAssessmentEntity;
    componentAssessmentMap: Map<number, GapCapabilityAssessment[]>;
    recordCount: number;
    openComponent: boolean;
    project?: ProjectEntity;
    componentsAssessmentRawList?: any;
};

export const ReportComponentInnerItem = (
    props: ReportComponentInnerItemProps,
): React.ReactElement => {
    const {
        assessment,
        recordCount,
        openComponent,
        componentAssessmentMap,
        project,
        componentsAssessmentRawList,
    } = props;
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [recommendationIdCount, setRecommendationIdCount] = useState<any>(null);
    let gapCapabilityList: GapCapabilityAssessment[] = componentAssessmentMap.get(
        assessment?.id,
    ) as GapCapabilityAssessment[];
    // if (!gapCapabilityList.length) {
    //   gapCapabilityList = GapByRisk.getGapEmptyAssessmentList(assessment);
    // }
    function factorAssessmentGapList(componentAssessment: any[]) {
        let capabilityAssessmentPrev: CapabilityAssessmentEntity | null = null;
        let canShowCapability = true;
        const capabilityAssessments = componentAssessment.flatMap((cap: any) => {
            if (cap && cap?.capabilities) {
                if (cap.id === assessment.id) {
                    return cap.capabilities.map((capability: any) => {
                        canShowCapability = capabilityAssessmentPrev !== capability;
                        capabilityAssessmentPrev = capability;
                        return {
                            capabilityAssessment: capability,
                            canShowCapability: canShowCapability,
                        };
                    });
                }
            }
            return [];
        });
        return capabilityAssessments;
    }

    const factorAssessment: any = factorAssessmentGapList(componentsAssessmentRawList);
    const handleExpandComponent = (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
        setExpanded(isExpanded);

    useEffect(() => {
        const capabilityIdCount = gapCapabilityList.reduce(
            (recCapabilityList, objCapabilityList) => {
                if (
                    objCapabilityList.capabilityAssessment &&
                    objCapabilityList.capabilityAssessment.id
                ) {
                    const id = objCapabilityList.capabilityAssessment.id;
                    if (!recCapabilityList[id]) {
                        recCapabilityList[id] = { id, count: 0, done: 0 };
                    }
                    objCapabilityList.scoredGap.roadmapRecommendations.map(
                        (recommendationCount: any) =>
                            recommendationCount.status !== '' && recCapabilityList[id].count++,
                    );
                    objCapabilityList.scoredGap.roadmapRecommendations.map(
                        (recommendationDoneStatus: any) =>
                            recommendationDoneStatus.status === 'Done' &&
                            recCapabilityList[id].done++,
                    );
                }
                return recCapabilityList;
            },
            {},
        );

        setRecommendationIdCount(capabilityIdCount);
    }, [gapCapabilityList]);

    return (
        <Collapse in={openComponent} timeout='auto' unmountOnExit>
            <ListStyled.Container>
                {Boolean(recordCount) && (
                    <AccordionListItem
                        className={'block-offset'}
                        title={''}
                        subtitle={'Top 5 Gaps'}
                        expanded={expanded}
                        onChange={handleExpandComponent}
                        SummaryInfoComponents={<></>}
                    >
                        <ReportComponentTopGapsList gapCapabilityList={gapCapabilityList} />
                    </AccordionListItem>
                )}
                {/*<ReportComponentCapabilitiesList capabilitiesReportList={componentReport.capabilities} />*/}

                <div className={'container-flex'}>
                    <div className={'col-info'}>
                        {assessment.componentOwners.length ? (
                            <>
                                <h3>Component owner:</h3>
                                <p className={'text-offset'}>
                                    {assessment.componentOwnersToString()}
                                </p>
                            </>
                        ) : (
                            <p className={'text-offset'}>No component owners available</p>
                        )}

                        {assessment.hasOperator() ? (
                            <>
                                <h3>Operator:</h3>
                                {assessment.operators.map((operator: UserEntity) => (
                                    <p className={'text-offset'}>{operator.getFullName()}</p>
                                ))}
                            </>
                        ) : null}

                        <p className={'text-offset'}>
                            <b>Status:</b> {assessment.status ?? ''}
                        </p>
                        <p className={'block-offset'}>
                            <b>Weight:</b> {assessment.weight ?? ''}
                        </p>

                        {Boolean(recordCount) && (
                            <ReportComponentDataGraph
                                mode={'Gap'}
                                project={project}
                                recordCapabilityList={gapCapabilityList}
                                levelFields={gapLevelFieldList}
                                levelFieldLabels={gapLevelFieldLabelList}
                            />
                        )}
                    </div>

                    <TableContainer component={Paper} className={'col-table'}>
                        {/*style={{ width: "auto", tableLayout: "auto" }}*/}
                        <Table>
                            {project?.isrrimpact ? (
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Factor</TableCell>
                                        <TableCell>Impact Level</TableCell>
                                        <TableCell>History</TableCell>
                                    </TableRow>
                                </TableHead>
                            ) : (
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Capability</TableCell>
                                        <TableCell>Completion Level</TableCell>
                                        <TableCell>Gaps</TableCell>
                                        <TableCell>Risk Level</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>History</TableCell>
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {project?.isrrimpact
                                    ? factorAssessment?.map((factor: any, index: number) => (
                                        <ReportFactorCapabilityTableItem
                                            key={`${factor?.id}-${index}`}
                                            gapCapability={factor}
                                            recommendationIdCount={recommendationIdCount}
                                            project={project}
                                        />
                                    ))
                                    : gapCapabilityList.map(
                                        (
                                            gapCapability: GapCapabilityAssessment,
                                            index: number,
                                        ) => (
                                            <ReportComponentCapabilityTableItem
                                                key={`${gapCapability?.scoredGap?.id}-${index}`}
                                                gapCapability={gapCapability}
                                                recommendationIdCount={recommendationIdCount}
                                                project={project}
                                            />
                                        ),
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </ListStyled.Container>
        </Collapse>
    );
};
