import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { IdHolder } from '@modules/types/graphql';

export type Entity = Partial<IdHolder>;

@DescribeEntity('GetAlignedAssetIdsEntity')
class GetAlignedAssetIdsEntity extends BaseEntity {
    id: number;

    constructor(alignedAsset: Entity) {
        super(alignedAsset);

        this.id = alignedAsset?.id ?? -1;
    }
}

export { GetAlignedAssetIdsEntity };
