import { gql } from '@apollo/client';

import { AssetCategoryFragment } from '../fragments';

export const CreateItemCategoryMutation = gql`
    mutation CreateItemCategory($name: String!, $itemId: Int!, $riskFunctionId: Int) {
        createItemCategory(
            name: $name
            item_id: $itemId
            risk_function_id: $riskFunctionId
        ) {
            ...AssetCategory
        }
    }
    ${AssetCategoryFragment}
`;
