import type { CreateItemCategoryMutationVariables } from '@modules/types/graphql';

export type CustomItemCategoryCreateFormValues = {
    name: string;
    itemId: number | any;
    riskFunctionId: number | any;
};

class CustomItemCategoryCreateRequest {
    name: CreateItemCategoryMutationVariables['name']
    itemId: CreateItemCategoryMutationVariables['itemId'];
    riskFunctionId: CreateItemCategoryMutationVariables['riskFunctionId'];

    constructor(values: CustomItemCategoryCreateFormValues) {
        this.name = values.name;
        this.itemId = values.itemId;
        this.riskFunctionId = values.riskFunctionId ?? null;
    }
}

export { CustomItemCategoryCreateRequest };
