import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { MenuItem } from '@material-ui/core';

import { TextField, Button } from '@modules/ui/core';
import { StringHelper } from '@helpers';
import { useCapabilityLevelDefinitions } from '@modules/custom-components/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { FormStyled } from '@modules/layout/styled';

import type {
    CapabilityLevelCreateFormValues,
    CapabilityLevelUpdateFormValues,
} from '@modules/custom-components/requests';
import { Loader } from '@modules/layout/moleculas';

type CapabilityLevelFormProps<T> = FormProps<T> & {
    onCancel?: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }
`;

const SubmitButton = styled(Button)`
    min-width: 165px;
`;
const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
        color: '#000 !important'
    },
    '& svg': {
        display: 'none'
    }
}));

const CapabilityLevelForm = <T extends FormSemanticType = 'create'>(
    props: CapabilityLevelFormProps<
        // prettier-ignore
        T extends 'create' ? CapabilityLevelCreateFormValues :
        T extends 'edit' ? CapabilityLevelUpdateFormValues :
        CapabilityLevelCreateFormValues & CapabilityLevelUpdateFormValues
    >,
): React.ReactElement => {
    const { type, initialValues, FormWrapComponent, onSubmit, onCancel } = props;

    const {
        capabilityLevelDefinitions,
        loading: capabilityLevelDefinitionsLoading,
    } = useCapabilityLevelDefinitions();

    const capabilityLevelData: any = capabilityLevelDefinitions.find((ele) => ele.level === initialValues.capabilityLevelDefinitionId);
    initialValues.capabilityLevelDefinitionId = capabilityLevelData?.id;
    const isEdit = type === 'edit';

    if (capabilityLevelDefinitionsLoading) {
        return <Loader />;
    }
    return (
        <ExtendedFormik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object({
                capabilityLevelDefinitionId: yup.number().moreThan(-1, 'Required field'),
            })}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <FormWrapComponent>
                        <StyledForm>
                            <CustomTextField
                                fullWidth
                                select
                                id='capabilityLevelDefinitionId'
                                name='capabilityLevelDefinitionId'
                                label='Capability Level Definition'
                                value={values.capabilityLevelDefinitionId}
                                error={!!errors.capabilityLevelDefinitionId}
                                helperText={errors.capabilityLevelDefinitionId}
                                disabled={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <MenuItem value={-1} disabled>
                                    Pick the capability level definition
                                </MenuItem>

                                {capabilityLevelDefinitions.map(capabilityLevelDefinition => (
                                    <MenuItem
                                        key={capabilityLevelDefinition.id}
                                        value={capabilityLevelDefinition.id}
                                    >
                                        {StringHelper.capitalizeFirstLetter(
                                            capabilityLevelDefinition.name,
                                        )}
                                    </MenuItem>
                                ))}
                            </CustomTextField>

                            <TextField
                                fullWidth
                                multiline
                                id='description'
                                label='Description'
                                rows='4'
                                value={values.description}
                                error={!!errors.description}
                                helperText={errors.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormStyled.FormRowSubmit>
                                <SubmitButton type='submit' loading={isSubmitting}>
                                    {isEdit ? 'Update Capability Level' : 'Create Capability Level'}
                                </SubmitButton>

                                <SubmitButton
                                    variant='text'
                                    disabled={isSubmitting}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </SubmitButton>
                            </FormStyled.FormRowSubmit>
                        </StyledForm>
                    </FormWrapComponent>
                );
            }}
        </ExtendedFormik>
    );
};

export { CapabilityLevelForm };
