import { BaseEntity, Entity as DescribeEntity } from '@lib/entity';

import type { CompanyAccess } from '@modules/types/graphql';

export type Entity = Partial<CompanyAccess> | null;

@DescribeEntity('CompanyAccessEntity')
class CompanyAccessEntity extends BaseEntity {
    id: number;
    name: string;
    projectCount: number;
    isRRAccess: boolean;
    isAIAccess: boolean;
    isFreeze: boolean;
    isAskTko: boolean;
    isLearnTko: boolean;
    isCreateProject: boolean;

    constructor(company: Entity) {
        super(company);

        this.id = company?.id ?? -1;
        this.name = company?.name ?? '';
        this.projectCount = company?.project_count ?? 0;
        this.isRRAccess = company?.is_rr_access ?? false;
        this.isAIAccess = company?.is_ai_access ?? false;
        this.isFreeze = company?.is_freeze ?? false;
        this.isAskTko = company?.is_ask_tko ?? false;
        this.isLearnTko = company?.is_learn_tko ?? false;
        this.isCreateProject = company?.is_create_project ?? false;
    }
}

export { CompanyAccessEntity };
