import React from 'react';
import styled from 'styled-components';

import { Button } from '@modules/ui/core';
import { TextField } from '@modules/ui/core';
import { InputAdornment } from '@material-ui/core';
import {
    searchIcon,
    useBackgroundStyles,
} from '@modules/projects/moleculas/project-list-filters/input-box-css';
import { CustomItemCategoryCreateModal } from '../custom-items-page-modal';

type ComponentProps = {
    setComponentSearch: (e: any) => void;
    componentSearch: string | any;
    ButtonTitleName?: string;
    itemId: number | any;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
`;

const Actions = styled.div``;

const ButtonCreateLink = styled(Button)`
    min-width: 205px;
    margin-right: 10px;
`;

const CustomCategoryListToolbar = (props: ComponentProps): React.ReactElement => {
    const { componentSearch, setComponentSearch, ButtonTitleName, itemId } = props;
    const [openItemCategoryCreateModal, setOpenItemCategoryCreateModal] = React.useState<boolean>(
        false,
    );
    const backgroundClasses = useBackgroundStyles();

    const handleOpenItemCategoryCreateModal = (): void => setOpenItemCategoryCreateModal(true);
    const handleCloseItemCategoryCreateModal = (): void => setOpenItemCategoryCreateModal(false);

    return (
        <Root>
            <Actions>
                <ButtonCreateLink onClick={handleOpenItemCategoryCreateModal}>
                    {ButtonTitleName}
                </ButtonCreateLink>

                <TextField
                    corner='round'
                    name='search'
                    label='Search'
                    autoComplete='off'
                    value={componentSearch}
                    InputProps={{
                        classes: backgroundClasses,
                        endAdornment: <InputAdornment position='end'>{searchIcon}</InputAdornment>,
                    }}
                    onChange={e => setComponentSearch(e.target.value)}
                />

                <CustomItemCategoryCreateModal
                    type='create'
                    open={openItemCategoryCreateModal}
                    itemId={itemId}
                    onClose={handleCloseItemCategoryCreateModal}
                />
            </Actions>
        </Root>
    );
};

export { CustomCategoryListToolbar };
