import { BaseEntity, Entity as DescribeEntity, Relation, RelationList } from '@lib/entity';

import { ProjectStatusEnum } from '@modules/projects/constants';
import { UserEntity } from '@modules/users/entities';
import { ComponentAssessmentEntityList } from '@modules/assessment/entities';
import { PrioritizedRoadmapEntity } from '@modules/roadmap/entities';
import { ImplementationEntity } from '@modules/implementation/entities';

import type { ProjectMaturityLevelEnum } from '@modules/projects/constants';
import type {
    ComponentAssessmentEntity,
    ComponentAssessmentEntityType,
} from '@modules/assessment/entities';
import type { ImplementationEntityType } from '@modules/implementation/entities';
import type { Project, MaturityLevel } from '@modules/types/graphql';

export type Entity = Partial<
    Omit<Project, 'assessments'> & {
        assessments: ComponentAssessmentEntityType[] | null;
        implementation: ImplementationEntityType | null;
    }
> | null;

@DescribeEntity('ProjectEntity')
class ProjectEntity extends BaseEntity {
    id: number;
    name: string;
    description: string;
    status: string;
    maturityLevel: MaturityLevel;
    companyId?: number;
    assessmentTypeId?: number;
    roadmapStatus?: string;
    isRiskRegistry?: boolean;
    isAssigned?: boolean;
    rr?: string;
    isrrimpact?: boolean;
    createdAt?: Date;

    @RelationList(() => ComponentAssessmentEntityList)
    assessments: ComponentAssessmentEntity[];

    @Relation(() => ImplementationEntity)
    implementation: ImplementationEntity;

    @Relation(() => UserEntity)
    stakeholder: UserEntity;

    @Relation(() => UserEntity)
    programManager: UserEntity;

    @Relation(() => PrioritizedRoadmapEntity)
    roadmap: PrioritizedRoadmapEntity;

    constructor(project: Entity) {
        super(project);

        this.id = project?.id ?? -1;
        this.name = project?.name ?? '';
        this.description = project?.description ?? '';
        this.status = project?.status ?? '';
        this.maturityLevel = project?.maturity_level ?? {
            id: -1,
            name: '',
        };
        this.companyId = project?.company_id ?? undefined;
        this.assessmentTypeId = project?.assessment_type_id ?? undefined;
        this.createdAt = project?.created_at ?? null;
        this.roadmapStatus = project?.roadmap_status ?? undefined;
        this.isRiskRegistry = project?.is_risk_registry ?? false;
        this.rr = project?.rr ?? '';
        this.isrrimpact = project?.is_rr_impact ?? false;
        this.isAssigned = project?.is_assigned ?? false;
    }

    getStatus() {
        const result = this.status.toLowerCase();

        return result as ProjectStatusEnum;
    }

    getMaturityLevel() {
        if (this.maturityLevel.id <= 0 || !this.maturityLevel.name) {
            return null;
        }

        const maturityLevel = this.maturityLevel.name;

        return maturityLevel as ProjectMaturityLevelEnum;
    }

    isImplementation() {
        const result = this.status.toLowerCase() === ProjectStatusEnum.IMPLEMENTATION;

        return result;
    }

    isRoadMap() {
        const result = this.status.toLowerCase() === ProjectStatusEnum.ROAD_MAP;

        return result;
    }

    isAssessment() {
        const result = this.status.toLowerCase() === ProjectStatusEnum.ASSESSMENT;

        return result;
    }

    isNew() {
        const result = this.status.toLowerCase() === ProjectStatusEnum.NEW;

        return result;
    }
}

export { ProjectEntity };
