import type { CreateItemCategoryTypeMutationVariables } from '@modules/types/graphql';

export type CustomItemCategoryTypeCreateFormValues = {
    name: string;
    categoryId: number | any;
};

class CustomItemCategoryTypeCreateRequest {
    name: CreateItemCategoryTypeMutationVariables['name']
    categoryId: CreateItemCategoryTypeMutationVariables['categoryId'];

    constructor(values: CustomItemCategoryTypeCreateFormValues) {
        this.name = values.name;
        this.categoryId = values.categoryId;
    }
}

export { CustomItemCategoryTypeCreateRequest };
